import { Message } from "@/types/message";

export function removeTyping(msgs: Message[]) {
  const toBeDeleted = [];

  const length = msgs.length;

  if (!length || msgs[0].platform !== "web") {
    return msgs;
  }

  let userHasSaidFlag = false;

  for (let j = length - 1; j >= 0; j--) {
    if (
      (msgs[j].cmd === "user" || msgs[j].from === "user") &&
      !["typing"].includes(msgs[j].type!)
    ) {
      userHasSaidFlag = true;
    }

    if (msgs[j].cmd === "typing" || msgs[j].type === "typing") {
      if (!userHasSaidFlag) {
        userHasSaidFlag = true;
        continue;
      } else {
        toBeDeleted.unshift(j);
      }
    }
  }

  for (let i = toBeDeleted.length - 1; i >= 0; i--) {
    msgs.splice(toBeDeleted[i], 1);
  }

  return msgs;
}
