<template>
  <div class="container">
    <template v-if="message.media_type === 'news'">
      <a href="#">
        <div class="wechat-news">
          <div class="msg-title">
            {{ message.news_title }}
          </div>
          <div class="msg-desc">
            微信图文贴
            <span v-if="message.news_count !== '1'">(多条)</span>
          </div>
        </div>
      </a>
    </template>

    <template v-if="message.media_type === 'image'">
      <img :src="message.url" class="media-image" alt="" />
    </template>

    <template v-if="message.media_type === 'video'">
      <div class="media-video-container">
        <img :src="message.thumb_url" alt="" />
        <div class="right-container">
          <p>{{ message.name }}</p>
          <p class="font-gray">{{ message.update_time }}</p>
        </div>
      </div>
    </template>

    <template v-if="message.media_type === 'voice'">
      <div class="media-voice-container">
        <audio :src="message.url" controls></audio>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    news: Object,
  },

  data() {
    return {
        message: null
    };
  },

  beforeMount() {
    const message = {... this.news};
    if (!message.media_type) { message.media_type = "news"; }
    if (message.media_type === "news") {
      message.news_count = message.news_count?.toString();
      if (message.news?.length) {
        message.url = message.news[0].url;
        message.news_title = message.news[0].title;
      }
    }

    this.message = message;
  },
});
</script>

<style lang="less" scoped>
.container {
  text-align: left;
  display: inline-block;
  background-color: white;

  .wechat-news {
    background-color: white;
    cursor: pointer;
    padding: 10px;
    max-width: 300px;
    display: inline-block;
    border: 1px solid #e7e7eb;
  }

  .msg-title {
    color: #459ae9;
  }

  .msg-desc {
    color: #8d8d8d;
  }

  .media-image {
    max-height: 90px;
  }

  .media-video-container {
    border: 1px solid #ddd;
    display: flex;
    height: 90px;
    width: 350px;
    img {
      width: auto;
      height: 100%;
      max-width: 160px;
    }

    .right-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 10px;
      p {
        margin-bottom: 0;
      }
    }
  }

  .media-voice-container {
    height: 90px;
    width: 350px;
    display: flex;
    align-items: center;
  }
}
</style>
