import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f10b91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-container" }
const _hoisted_2 = { class: "cell-title-container" }
const _hoisted_3 = { class: "van-ellipsis name-slot" }
const _hoisted_4 = {
  key: 0,
  class: "badge badge-brown"
}
const _hoisted_5 = {
  key: 1,
  class: "badge badge-blue"
}
const _hoisted_6 = {
  key: 2,
  class: "badge badge-blue"
}
const _hoisted_7 = {
  key: 0,
  class: "badge badge-red"
}
const _hoisted_8 = {
  key: 1,
  class: "badge badge-blue"
}
const _hoisted_9 = {
  key: 4,
  class: "badge badge-blue"
}
const _hoisted_10 = { class: "cell-slot-text" }
const _hoisted_11 = { class: "cell-title-container" }
const _hoisted_12 = { class: "van-ellipsis name-slot" }
const _hoisted_13 = {
  key: 0,
  class: "badge badge-blue"
}
const _hoisted_14 = {
  key: 1,
  class: "badge badge-red"
}
const _hoisted_15 = {
  key: 2,
  class: "badge badge-red"
}
const _hoisted_16 = {
  key: 3,
  class: "badge badge-blue"
}
const _hoisted_17 = { class: "cell-slot-text" }
const _hoisted_18 = { class: "cell-title-container" }
const _hoisted_19 = { class: "van-ellipsis name-slot" }
const _hoisted_20 = {
  key: 0,
  class: "badge badge-blue"
}
const _hoisted_21 = {
  key: 1,
  class: "badge badge-red"
}
const _hoisted_22 = {
  key: 2,
  class: "badge badge-blue"
}
const _hoisted_23 = { class: "cell-slot-text" }
const _hoisted_24 = {
  key: 0,
  class: "info-container"
}
const _hoisted_25 = { class: "cell-title-container" }
const _hoisted_26 = { class: "van-ellipsis name-slot" }
const _hoisted_27 = {
  key: 0,
  class: "badge badge-blue"
}
const _hoisted_28 = {
  key: 1,
  class: "badge badge-blue"
}
const _hoisted_29 = {
  key: 2,
  class: "badge badge-red"
}
const _hoisted_30 = {
  key: 3,
  class: "badge badge-blue"
}
const _hoisted_31 = { class: "cell-slot-text" }
const _hoisted_32 = { class: "cell-title-container" }
const _hoisted_33 = { class: "van-ellipsis name-slot" }
const _hoisted_34 = {
  key: 0,
  class: "badge badge-blue"
}
const _hoisted_35 = {
  key: 1,
  class: "badge badge-blue"
}
const _hoisted_36 = {
  key: 2,
  class: "badge badge-red"
}
const _hoisted_37 = {
  key: 3,
  class: "badge badge-blue"
}
const _hoisted_38 = { class: "cell-slot-text" }
const _hoisted_39 = { class: "tab-container" }
const _hoisted_40 = { class: "cell-title-container" }
const _hoisted_41 = { class: "van-ellipsis name-slot" }
const _hoisted_42 = {
  key: 0,
  class: "badge badge-blue"
}
const _hoisted_43 = {
  key: 1,
  class: "badge badge-blue"
}
const _hoisted_44 = {
  key: 2,
  class: "badge badge-red"
}
const _hoisted_45 = {
  key: 3,
  class: "badge badge-blue"
}
const _hoisted_46 = {
  key: 0,
  class: "badge badge-yellow"
}
const _hoisted_47 = {
  key: 1,
  class: "badge badge-yellow"
}
const _hoisted_48 = { class: "cell-slot-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_list, {
      loading: _ctx.loading,
      finished: _ctx.finished,
      "finished-text": "没有更多了",
      onLoad: _ctx.onLoad,
      ref: "listContainer",
      onScroll: _ctx.scrollEvent
    }, {
      default: _withCtx(() => [
        (_ctx.tabType === _ctx.TabList.web)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.list, (item) => {
              return (_openBlock(), _createBlock(_component_van_cell, {
                key: item.id,
                "is-link": "",
                onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    (item.device === 'pc')
                      ? (_openBlock(), _createBlock(_component_van_image, {
                          key: 0,
                          class: "cell-image",
                          width: "20",
                          height: "20",
                          src: item.avatar || require('@/assets/img/computer.png')
                        }, null, 8, ["src"]))
                      : _createCommentVNode("", true),
                    (item.device === 'mobile')
                      ? (_openBlock(), _createBlock(_component_van_image, {
                          key: 1,
                          class: "cell-image",
                          width: "20",
                          height: "20",
                          src: item.avatar || require('@/assets/img/mobile.png')
                        }, null, 8, ["src"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.feName), 1),
                    (item.reply_status === 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          (item.type === 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "沉默"))
                            : _createCommentVNode("", true),
                          (item.type === 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "互动"))
                            : _createCommentVNode("", true),
                          (item.type === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "开口"))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.reply_status === 1)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          (item.is_offline === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "未读留言"))
                            : _createCommentVNode("", true),
                          (item.is_offline === 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "开口"))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.reply_status === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, "已读未回"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                "right-icon": _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(item.endTimeText), 1),
                  _createVNode(_component_van_icon, {
                    name: "arrow",
                    class: "cell-slot-icon"
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.tabType === _ctx.TabList.wechat)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.list, (item) => {
              return (_openBlock(), _createBlock(_component_van_cell, {
                key: item.id,
                "is-link": "",
                onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_van_image, {
                      class: "cell-image",
                      width: "20",
                      height: "20",
                      src: item.avatar || require('@/assets/img/wechat-avatars.png')
                    }, null, 8, ["src"]),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(item.name), 1),
                    (item.reply_status === 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.type === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, "开口"))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.follow === 0 && ![2, 3].includes(item.app?.type))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "已取关"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, "未读未回"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, "已读未回"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                "right-icon": _withCtx(() => [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(item.lastEventTimeText), 1),
                  _createVNode(_component_van_icon, {
                    name: "arrow",
                    class: "cell-slot-icon"
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.tabType === _ctx.TabList.weibo)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.list, (item) => {
              return (_openBlock(), _createBlock(_component_van_cell, {
                key: item.id,
                "is-link": "",
                onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_van_image, {
                      class: "cell-image",
                      width: "20",
                      height: "20",
                      src: item.avatar || require('@/assets/img/weibo-avatars.png')
                    }, null, 8, ["src"]),
                    _createElementVNode("span", _hoisted_19, _toDisplayString(item.name), 1),
                    (item.reply_status === 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.type === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_20, "开口"))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.reply_status === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, "未读未回"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, "已读未回"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                "right-icon": _withCtx(() => [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(item.lastEventTimeText), 1),
                  _createVNode(_component_van_icon, {
                    name: "arrow",
                    class: "cell-slot-icon"
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.tabType === _ctx.TabList.douyin)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.showDouyinUpdateInfo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                return (_openBlock(), _createBlock(_component_van_cell, {
                  key: item.id,
                  "is-link": "",
                  onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
                }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_component_van_image, {
                        class: "cell-image",
                        width: "20",
                        height: "20",
                        src: item.avatar || require('@/assets/img/douyin-avatars.png')
                      }, null, 8, ["src"]),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(item.name), 1),
                      (item.reply_status === 0 && item.type === 2)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_27, "开口"))
                        : _createCommentVNode("", true),
                      (item.reply_status === 0 && item.type === 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_28, "互动"))
                        : _createCommentVNode("", true),
                      (item.reply_status === 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_29, "未读未回"))
                        : _createCommentVNode("", true),
                      (item.reply_status === 2)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_30, "已读未回"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  "right-icon": _withCtx(() => [
                    _createElementVNode("span", _hoisted_31, _toDisplayString(item.lastEventTimeText), 1),
                    _createVNode(_component_van_icon, {
                      name: "arrow",
                      class: "cell-slot-icon"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.tabType === _ctx.TabList.redbook)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.list, (item) => {
              return (_openBlock(), _createBlock(_component_van_cell, {
                key: item.id,
                "is-link": "",
                onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_van_image, {
                      class: "cell-image",
                      width: "20",
                      height: "20",
                      src: item.avatar || require('@/assets/img/redbook-icon.png')
                    }, null, 8, ["src"]),
                    _createElementVNode("span", _hoisted_33, _toDisplayString(item.name), 1),
                    (item.reply_status === 0 && item.type === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_34, "开口"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 0 && item.type === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_35, "互动"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_36, "未读未回"))
                      : _createCommentVNode("", true),
                    (item.reply_status === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_37, "已读未回"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                "right-icon": _withCtx(() => [
                  _createElementVNode("span", _hoisted_38, _toDisplayString(item.lastEventTimeText), 1),
                  _createVNode(_component_van_icon, {
                    name: "arrow",
                    class: "cell-slot-icon"
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          : _createCommentVNode("", true),
        (_ctx.tabType === _ctx.TabList.kuaishou)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", {
                  class: _normalizeClass(["btn", { 'active': _ctx.kuaishouTab === 0 }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateKuaishouTab(0)))
                }, "私信访客", 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn", { 'active': _ctx.kuaishouTab === 1 }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateKuaishouTab(1)))
                }, "潜在访客", 2)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                return (_openBlock(), _createBlock(_component_van_cell, {
                  key: item.id,
                  "is-link": "",
                  onClick: ($event: any) => (_ctx.goToDetailPage(_ctx.tabType, item))
                }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_van_image, {
                        class: "cell-image",
                        width: "20",
                        height: "20",
                        src: item.avatar || require('@/assets/img/kuaishou-icon.png')
                      }, null, 8, ["src"]),
                      _createElementVNode("span", _hoisted_41, _toDisplayString(item.name), 1),
                      (_ctx.kuaishouTab === 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (item.reply_status === 0 && item.type === 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_42, "开口"))
                              : _createCommentVNode("", true),
                            (item.reply_status === 0 && item.type === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_43, "互动"))
                              : _createCommentVNode("", true),
                            (item.reply_status === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_44, "未读未回"))
                              : _createCommentVNode("", true),
                            (item.reply_status === 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_45, "已读未回"))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.kuaishouTab === 1)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (item.potential_type === 'live')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_46, "直播评论"))
                              : _createCommentVNode("", true),
                            (item.potential_type === 'video')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_47, "视频评论"))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  "right-icon": _withCtx(() => [
                    _createElementVNode("span", _hoisted_48, _toDisplayString(item.lastEventTimeText), 1),
                    _createVNode(_component_van_icon, {
                      name: "arrow",
                      class: "cell-slot-icon"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["loading", "finished", "onLoad", "onScroll"])
  ]))
}