<template>
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    :style="{ height: '60%' }"
    teleport="body"
    :closeable="true"
    @close="close"
    class="name-card-list"
  >
    <div class="top-action">
      <span>名片</span>

      <!-- <span class="font-gray" @click="close()">X</span> -->
    </div>

    <template v-if="!nameCardList.length">
      <van-empty description="暂无名片，请联系云雀客服协助开通功能" />
    </template>

    <template v-if="nameCardList.length">
      <div class="list-container">
        <div class="name-card" v-for="item in nameCardList" :key="item.id">
          <div class="left">
            <div>{{ item.name }}</div>
            <div class="font-gray">{{ item.title }}</div>
          </div>

          <div class="right">
            <van-button
              color="rgb(25, 137, 250)"
              style="padding: 0 20px"
              round
              plain
              @click="send(item)"
              >发送</van-button
            >
          </div>
        </div>

        <div style="margin: 20px 0; color: #aaaaaa" class="text-center">
          - 如需更多，可前往小红书专业号后台添加 -
        </div>
      </div>
    </template>
  </van-popup>
</template>

<script>
import { Popup, Empty, Button } from "vant";
import { defineComponent } from "vue";
import { getNameCardList } from "@/api/common";
import CommetFactory from "@/api/CommetFactory";
import { mapGetters } from "vuex";

export default defineComponent({
  props: {
    appid: String,
  },

  emits: ["close"],

  components: {
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Button.name]: Button,
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
    }),
  },

  data() {
    return {
      showPopup: true,
      nameCardList: [],
    };
  },

  beforeMount() {
    getNameCardList({
      appid: this.appid,
      pagesize: 100,
    }).subscribe((res) => {
      console.log(res);

      this.nameCardList = res;
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },

    send(nameCard) {
      const Commet = CommetFactory(this.$store);

      const cmicrotime = Date.now();
      const msg = {
        platform: "redbook",
        cmd: "worker",
        body: JSON.stringify({
          ...nameCard,
          format: "social_card",
        }),
        uid: this.currentVisitor.uid,
        cmicrotime: cmicrotime,
      };

      try {
        Commet.send(msg);
      } catch (error) {
        console.log(error);
      }

      this.close();
    },
  },
});
</script>

<style scoped>
.list-container {
  height: calc(100% - 43px);
  overflow-y: auto;
  padding: 0 16px;
}

.font-gray {
  color: #666;
}
.text-center {
  text-align: center;
}

.top-action {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;

  padding: 10px 16px;
}

.name-card {
  height: 80px;
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.name-card .left div {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

button.van-button {
  transform: scale(0.9);
}
</style>

<style>
.van-popup.name-card-list {
  background-color: #fafafa !important;
  font-size: 14px;
}
</style>
