import websocketService from "./websocket";

let websocket: any = null;

export default function websocketFactory() {
  if (!websocket) {
    console.log("new websocket inited");
    websocket = new websocketService();
  }

  return websocket;
}
