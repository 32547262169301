
import { defineComponent } from "vue";
import { Button, Dialog } from "vant";
import { HostTypes } from "@/enums/host";

import {
  getNotificationInstructionText,
  isPreferWechat,
} from "@/utils/notification";
import { getHostType } from "@/utils/host";
import { sendPush } from "@/api/common";
import { getUrlParam, getUrlParamWithPlusUntouched } from "@/utils/url";

export default defineComponent({
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      device: "",
      os: "",
      SDK: "",
      mobile_id: "",
      mobile_type: "",
      instructionText: "",
      hostType: "",
      HostTypes,

      showPreferWechat: false,

      isFirstRerendered: false,
    };
  },

  created() {
    this.mobile_id = getUrlParamWithPlusUntouched("mobile_id");
    this.mobile_type = getUrlParam("mobile_type");
    this.device = getUrlParam("device");
    this.os = getUrlParam("os");
    this.SDK = getUrlParam("SDK");

    localStorage.setItem("device", this.device);
    localStorage.setItem("os", this.os);
    localStorage.setItem("SDK", this.SDK);
    localStorage.setItem("mobile_id", this.mobile_id);
    localStorage.setItem("mobile_type", this.mobile_type);

    this.hostType = getHostType();

    this.showPreferWechat = isPreferWechat();

    this.instructionText = getNotificationInstructionText();

    let firstRerender = localStorage.getItem("first-rerender");
    if (firstRerender) {
      this.isFirstRerendered = true;
    } else {
      localStorage.setItem("first-rerender", "true");
    }
  },

  methods: {
    goSettings() {
      window.location.href = "/yunque_turn_on_notification";
    },

    next() {
      window.location.href = "/yunque_direct_access";
    },
  },
});
