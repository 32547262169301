import { HostTypes } from "@/enums/host";
import { getHostType } from "./host";

let device: any, SDK: number;

export function isPreferWechat() {
  const device = localStorage.getItem("device")!;
  const deviceName = device.toLowerCase() || "";
  console.log(deviceName);

  const hostType = getHostType();

  return (
    hostType !== HostTypes.iOS &&
    !["huawei", "honor", "mi", "vivo", "oppo", "realme"].some((key) => {
      return deviceName.includes(key);
    })
  );
}

export function getNotificationInstructionText() {
  device = localStorage.getItem("device")!;
  SDK = parseInt(localStorage.getItem("SDK")!);

  switch (getHostType()) {
    case HostTypes.iOS:
      return getIOSText();

    default: {
      const deviceName = device.toLowerCase() || "";
      if (deviceName.includes("huawei") || deviceName.includes("HONOR")) {
        return getHuaweiText();
      } else if (deviceName.includes("mi")) {
        return getXiaomiText();
      } else if (deviceName.includes("vivo")) {
        return getVivoText();
      } else if (deviceName.includes("oppo") || deviceName.includes("realme")) {
        return getOppoText();
      } else {
        //   showWechatConsoleTip = true;
        return getOtherText();
      }
    }
  }
}

function getIOSText() {
  return `
        点击下方按钮打开设置页面，点击【通知】，打开所有设置项，并将提醒样式设为【横幅】<br>
      `;
}

function getHuaweiText() {
  if (SDK === 24) {
    return `
       1.打开【设置】-【通知栏和任务栏】，打开【通知亮屏提示】开关<br>
       2.在【通知栏和任务栏】-【通知中心】，找到云雀客服，并打开所有的开关
      `;
  } else if (SDK < 26) {
    return `
        1.打开【设置】-【通知栏和状态栏】-【通知中心】，找到云雀客服，并打开所有的开关<br>
        2.打开【锁屏和密码】，打开【通知时亮屏提示】开关
      `;
  } else if (SDK === 27) {
    return `
        1.打开【设置】-【应用和通知】-【通知管理】，找到云雀客服，并打开所有的开关<br>
        2.打开【应用和通知】-【通知和状态栏设置】，打开【通知亮屏提示】
      `;
  } else {
    return `
        1.打开【设置】-【应用和通知】-【通知管理】，找到云雀客服，并打开所有的开关<br>
        2.打开【应用和通知】-【通知和状态栏设置】，打开【通知亮屏提示】
      `;
  }
}

function getXiaomiText() {
  if (SDK < 26) {
    return `
        1.打开设置页面，点击【通知】<br>
        2.打开通知设置页面的所有开关
      `;
  } else {
    return `
        1.打开设置页面，打开所有开关
      `;
  }
}

function getVivoText() {
  if (SDK < 26) {
    return `
        1.打开设置页面，点击【状态栏与通知】，打开【来锁屏通知时亮屏】<br>
        2.下拉找到【云雀客服】，进入打开所有开关 <br>
      `;
  } else {
    return `
    1、打开设置页面，点击【通知与状态栏】 <br>
    2、点击【应用通知管理】，下拉找到【云雀客服】，进入后打开【允许通知】 <br>
    3、需勾选提醒方式内的【悬浮通知】和【锁屏通知】 <br>
    4、点击系统消息，打开【悬浮通知】，在【重要程度】中勾选【优先显示】 <br>
      `;
  }
}

function getOppoText() {
  return `
      1.打开设置页面，点击【通知与状态栏】<br>
      2.点击【通知管理】，下拉找到【云雀客服】，进入后打开/勾选所有开关 <br>
    `;
}

function getOtherText() {
  return `
       1.打开设置页面，找到带有【通知】字样的按钮，找到云雀客服的通知开关并打开 <br>
       2.在设置或手机管家中打开云雀客服的自启动权限 <br>
    `;
}
