export enum MessagePlatform {
  web = "web",
  wechat = "wechat",
  weibo = "weibo",
  douyin = "douyin",
  kuaishou = "kuaishou",
  redbook = "redbook",
}

export enum MessageCategory {
  system = "system",
  interaction = "interaction",
}

export enum MessageFeFrom {
  client = "client",
  worker = "worker",
  worker_temp = "worker_temp",
}

export enum WeiboMessageDivision {
  post = "post",
  pm = "pm",
}

export interface Message {
  category: MessageCategory; // 'system' | 'interaction'
  platform: MessagePlatform; // 'web' | 'wechat' | 'weibo'
  feType: string;
  feFrom: MessageFeFrom; // 'client' | 'worker' | 'worker_temp'
  feDateTime: string;
  feHidden?: boolean;
  feMuted?: boolean;
  isFormarted?: boolean;

  content?: any;
  format?: string;

  type?: string;
  microtime: number;
  recall_microtime?: number;
  body?: any;
  worker?: any;
  text: string;
  url?: string;
  is_autoreply?: boolean;
  auto_type?: string;
  link_title?: string;
  link_url?: string;
  map_url?: string;
  map_cover?: string;
  label?: string;
  recognition?: string;
  division?: WeiboMessageDivision;
  style?: string;
  cmd?: string;
  uid?: string;
  cmicrotime?: string | number;
  from?: string;
  error?: boolean;
  ext?: any;
  sendingStatus?: string;
  event?: string;
  tagsData?: any;
  extractedUser?: string;
  post?: any;
  cmt?: any;
  update_time?: string;
  thumb_url?: string;
  name?: string;
  title?: string;
  auto_typeText?: string;
  delay?: number;
  nickname?: string;
  share_url?: string;
  cover_url?: string;
  video_create_time?: number;
  reply_content?: string;
  displayText?: string;
  filename?: string;
  filesize: string;
  filesizeText: string;
  act_type: string;
  rawText?: string;
  scope?: string;
  is_preview?: boolean | number;
  ai_msg_id?: string;
  ai_bot_id?: string;
  ai_status?: boolean | number;

  extractedInfo?: {
    weixin?: string;
    mobile?: string;
    qq?: string;
  };

  recalled?: boolean;
  is_recallable?: boolean;
}
