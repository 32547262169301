import { Message } from "@/types/Message";

export function extractMessage(messages: Message[]) {
  if (!messages) {
    return [];
  }

  return messages.map((msg) => {
    const platform = msg.platform;

    if (platform === "web") {
      if (msg.cmd === "user" && typeof msg.extractedInfo === "undefined") {
        const text =
          typeof msg.body === "string" ? msg.body : msg.body.content || "";
        msg.extractedInfo = extract(text);
      }

      if (msg.from === "user" && ["html", "text"].includes(msg.format!)) {
        msg.extractedInfo = extract(msg.content);
      }
    }

    if (platform === "wechat" || platform === "douyin" || platform === "kuaishou" || platform === "redbook") {
      if (
        msg.from === "user" &&
        msg.style === "message" &&
        msg.text &&
        typeof msg.extractedInfo === "undefined"
      ) {
        msg.extractedInfo = extract(msg.text);
      }
    }

    if (platform === "weibo") {
      if (
        msg.from === "user" &&
        msg.style === "message" &&
        msg.text &&
        typeof msg.extractedInfo === "undefined"
      ) {
        msg.extractedInfo = extract(msg.text);
      }
    }

    return msg;
  });
}

function extract(text: string) {
  text = text.replace(/<\/?[^>]+(>|$)/g, "");

  // 过滤微信表情
  const wechatEmojis = [
    "/:handclap",
    "[TearingUp]",
    "/:basketb",
    "/:coffee",
    "/:showlove",
    "/:ladybug",
    "/:strong",
    "/:circle",
    "/:hiphot",
    "[Facepalm]",
    "[Packet]",
    "[Concerned]",
  ];
  wechatEmojis.forEach((emoji) => {
    while (text.indexOf(emoji) !== -1) {
      text = text.replace(emoji, "");
    }
  });

  const result: any = {};

  if (!text) {
    return;
  }

  let matched;

  // 获取微信
  matched = text.match(/[a-zA-Z_]([-_a-zA-Z0-9]{5,19})|1\d{10}|(\d{5,11})/m);
  if (matched) {
    result.weixin = matched[0].match(
      /[a-zA-Z_]([-_a-zA-Z0-9]{5,19})+|1\d{10}|\d{5,11}/m
    )![0];
  }

  // 获取手机
  matched = text.match(/([^0-9]|^)1\d{10}([^0-9]|$)/m);
  if (matched) {
    result.mobile = matched[0].match(/1\d{10}/m)![0];
  }

  // 获取QQ
  matched = text.match(/([0-9]{5,11})([^0-9]|$)/m);

  if (matched) {
    result.qq = matched[0].match(/([0-9]{5,11})/m)![0];
  }

  return result;
}
