<template>
  <div class="container">
    你评论了：
    <span v-if="content" v-html="content"></span>

    <div class="inner-bubble" nz-row>
      <div class="video-card">
        <p v-if="message.share_url">视频标题：{{ message.title || "" }}</p>
        <p v-if="!message.share_url">未知视频</p>
      </div>
    </div>

    <div class="bubble-type noselect">
      <template v-if="message.auto_typeText">{{ message.auto_typeText }}：</template>
      评论
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { DouyinfaceFilter as douyinface } from "@/filters/douyinface";

export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    content() {
      return douyinface(this.message.content || "");
    },
  },

  data() {
    return {};
  },

  beforeMount() {},
});
</script>


<style lang="less" scoped>
.container {
  display: block;
  padding-top: 5px;
  padding-bottom: 3px;
  max-width: 456px;

  .at-span {
    color: #2593f4;
  }

  .inner-bubble {
    background-color: #f2f2f5;
    border-radius: 3px;
    margin: 3px;
    padding: 6px 10px;
    color: #4f5a62;

    a {
      color: #2593f4;
      text-decoration: none;
    }
  }

  .weibo-user {
    font-weight: bold;
  }

  .post-footer {
    font-size: smaller;
    color: #858585;
  }

  .action-text {
    color: #2593f4;
    cursor: pointer;
  }

  .douyin-video-cover {
    width: 50px;
    margin-right: 10px;
  }

  .video-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    p {
      margin: 0;
      max-width: 350px;
      min-width: 250px;
      word-break: break-all;
    }
  }
}
</style>