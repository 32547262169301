<template>
  <van-tabbar route>
    <van-tabbar-item
      to="/chat-list"
      icon="chat-o"
      :badge="chatOtherTotalNewCount || null"
      >对话</van-tabbar-item
    >
    <van-tabbar-item
      to="/history-list"
      icon="todo-list-o"
      :badge="totalBadgeCount"
      >历史</van-tabbar-item
    >
    <van-tabbar-item
      to="/settings"
      icon="setting-o"
      :badge="settingsBadge"
      >设置</van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
import { defineComponent } from "vue";
import { Tabbar, TabbarItem } from "vant";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },

  computed: {
    ...mapGetters({
      chatOtherTotalNewCount: "chatOtherTotalNewCount",
    }),

    ...mapState({
      totalBadgeCount: (state) => {
        return state.common.historyBadge.total
          ? state.common.historyBadge.total
          : null;
      },
      settingsBadge: (state) => {
        return state.common.settingsBadge ? state.common.settingsBadge : null;
      },
    }),
  },
});
</script>
