<template>
    <div class="container">
        <div class="header" nz-row nzAlign="middle" nzJustify="space-between">
            <span>{{ group.group_name }}</span>

            <span class="font-gray">共{{ group.roll_msg_num }}条</span>
        </div>

        <div class="body">
            <p class="font-gray">
                滚动方式:
                {{ group.allot_type === "seq" ? "顺序" : "权重" }}
            </p>

            <p class="font-gray">记忆: {{ group.remember ? "记忆" : "不记忆" }}</p>
        </div>
    </div>
</template>
  
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        group: Object,
    },

    data() {
        return {

        };
    },

    beforeMount() {

    },
});
</script>
  
  
<style lang="less" scoped>
.container {
    display: inline-block;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    background: white;
    color: black;
    width: 90%;
}

.header {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.body {
    padding: 5px 10px;

    p {
        margin: 0
    }
}
</style>