<template>
    <template v-if="status !== 'exhaust'">
        <div class="container">
            <span class="dot" :style="{ color: MobileValidationStatus[status].color }">
                ●
            </span>
            &nbsp;
            {{ MobileValidationStatus[status].name }}
        </div>
    </template>

    <template v-if="status === 'exhaust'">
        余量已用尽，请购买套餐包
    </template>
</template>

<script>

import { MobileValidationStatus } from "@/types/MobileValidationStatus";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        status: null,
    },

    data() {
        return {
            MobileValidationStatus: MobileValidationStatus
        };
    },

})

</script>

<style lang="less" scoped>
.dot {
    font-family: "LucidaGrande", "Lucida Grande", sans-serif;
}

.container {
    display: flex;
    align-items: center;
}
</style>