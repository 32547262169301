export function checkWechatEmpty(inputData: any): boolean {
  if (inputData.format === "text" && !inputData.content.trim()) {
    return true;
  }

  if (inputData.format === "html" && !inputData.content.trim()) {
    return true;
  }

  if (inputData.format === "miniprogrampage" && !inputData.url) {
    return true;
  }
  if (
    inputData.format === "linknews" &&
    (!inputData.content || !inputData.content.length)
  ) {
    return true;
  }

  if (inputData.format === "news" && !inputData.media_id) {
    return true;
  }
  if (inputData.format === "roll_msg" && !inputData.group_id) {
    return true;
  }
  if (inputData.format === "faq_menu" && !inputData.faq_menu_id) {
    return true;
  }

  if (inputData.format === "image") {
    if (inputData.feFormat === "media") {
      if (!inputData.media_id) {
        return true;
      }
    } else {
      if (!inputData.filename) {
        return true;
      }
    }
  }

  if (inputData.format === "voice") {
    if (inputData.feFormat === "media") {
      if (!inputData.media_id) {
        return true;
      }
    } else {
      if (!inputData.filename) {
        return true;
      }
    }
  }

  //抖音独有
  if (inputData.format === "video" && !inputData.media_id) {
    return true;
  }

  if (["video", "card"].includes(inputData.format) && !inputData.media_id) {
    return true;
  }

  if (inputData.format === "wework_card" && !inputData.name) {
    return true;
  }

  if (inputData.format === "note" && !inputData.id) {
    return true;
  }

  if (inputData.format === "loading_page" && !inputData.id) {
    return true;
  }

  if (inputData.format === "service_card" && !inputData.id) {
    return true;
  }
  
  return false;
}
