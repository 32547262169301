
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    tag: null,
  },

  beforeMount() {
  }
});
