
import { defineComponent } from "vue";
import { NavBar, ActionSheet, Button, Overlay, Loading, Dialog } from "vant";
import { mapState } from "vuex";
import TagEdit from "@/views/modals/TagEdit.vue";
import { startConversation } from "@/api/chat";
import {
  getDouyinHistoryDetail,
  getWebHistoryDetail,
  getWechatHistoryDetail,
  getWeiboHistoryDetail,
  getKuaishouHistoryDetail,
  getRedbookuHistoryDetail,
} from "@/api/history";
import Message from "@/components/messages/Message.vue";
import HistoryDetailPageSubtitle from "@/components/HistoryDetailPageSubtitle.vue";
import HistorySourceDialog from "@/components/HistorySourceDialog.vue";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    TagEdit,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Dialog.name]: Dialog,
    "yunque-message": Message,
    "history-detail-page-subtitle": HistoryDetailPageSubtitle,
    [Dialog.Component.name]: Dialog.Component,
    "history-source-dialog": HistorySourceDialog,
  },

  data() {
    return {
      actions: [
        { name: "编辑标签", value: "edit-tag" },
        { name: "编辑客资", value: "edit-lead" },
        { name: "访客来源", value: "show-source" },
      ],

      showEditTag: false,
      showLoadingStartingCon: false,

      lastID: "",

      messageList: [],
      showSourceDialog: false,
    };
  },

  computed: {
    ...mapState({
      currentHistoryDetail: (state: any) => {
        return state.common.currentHistoryDetail;
      },
      companyID: (state: any) => {
        return state.auth.companyID;
      },
      isOnDuty: (state: any) => {
        return state.chat.isOnDuty;
      },
    }),
  },

  beforeMount() {
    if (
      !this.currentHistoryDetail?.feName &&
      !this.currentHistoryDetail?.name
    ) {
      this.$router.push("/history-list");
      return;
    }

    this.getHistory();
  },

  methods: {
    goBack() {
      // window.history.back();
      this.$router.push("/history-list");
    },

    editLead() {
      this.$router.push({
        path: "/edit-lead",
        query: {
          uid: this.currentHistoryDetail.uid,
        },
      });
    },



    startCon() {
      if (!this.isOnDuty) {
        Dialog.alert({
          message: "您已将手机值班状态设置为离线",
        });

        return;
      }

      const info = this.currentHistoryDetail;

      if (info.platform === "web") {
        if (info.has_talk) {
          Dialog.alert({
            message: "对话正在进行中, 无法发起对话",
          });
          return;
        }
      }

      startConversation(info.uid).subscribe(
        (res) => {
          if (res?.data?.result) {
            this.showLoadingStartingCon = true;
            setTimeout(() => {
              this.showLoadingStartingCon = false;
              this.$router.push({
                path: "/chat",
                query: {
                  uid: info.uid,
                },
              });
            }, 3000);
          } else {
            Dialog.alert({
              message: "正在对话中，可前往电脑端查看",
            });
          }
        },
        () => {
          Dialog.alert({
            message: "发起对话失败",
          });
        }
      );
    },

    getHistory(lastID = "", direction = "") {
      let obs: any;

      switch (this.currentHistoryDetail.platform) {
        case "web":
          obs = getWebHistoryDetail(
            this.currentHistoryDetail.uid,
            lastID ? lastID : this.currentHistoryDetail.last_microtime,
            this.companyID,
            direction
          );

          break;
        case "wechat":
          obs = getWechatHistoryDetail(this.currentHistoryDetail.uid, lastID);

          break;

        case "weibo":
          obs = getWeiboHistoryDetail(this.currentHistoryDetail.uid, lastID);

          break;
        case "douyin":
          obs = getDouyinHistoryDetail(this.currentHistoryDetail.uid, lastID);

          break;

        case "kuaishou":
          obs = getKuaishouHistoryDetail(this.currentHistoryDetail.uid, lastID);

          break;

        case "redbook":
          obs = getRedbookuHistoryDetail(this.currentHistoryDetail.uid, lastID);
          break;
      }

      obs!.subscribe((res: any) => {
        if (!res.data.length) return;

        if (direction === "early") {
          this.messageList = res.data.slice().concat(this.messageList);
        } else {
          this.messageList = this.messageList.concat(res.data.slice());
        }
      });
    },

    clickHistoryMessage(data: any) {
      const direction = data.type === "history" ? "early" : "later";
      if (direction === "early") {
        this.messageList.shift();
      } else {
        this.messageList.pop();
      }

      this.getHistory(data.microtime, direction);
    },

    recallMessage($event: any) {
      // @ts-ignore 莫名其妙的错误
      this.messageList = this.messageList.map((item: any) => {
        if (item.microtime === $event.microtime) {
          item.recalled = true;
        }

        return item;
      });
    },
  },
});
