<template>
  <div
    class="msg-wrapper msg-client msg-weibo"
    v-if="message.feType === 'weibo_client_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div v-html="weiboFaceFilter(message.text)"></div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-weibo"
    v-if="message.feType === 'weibo_client_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <img referrerpolicy="no-referrer" :src="message.url" />
      <div class="bubble-type">私信</div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-weibo"
    v-if="message.feType === 'weibo_client_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <video :src="message.url" controls width="400" height="300"></video>
      <div class="bubble-type">私信</div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-weibo"
    v-if="message.feType === 'weibo_client_voice'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <audio :src="message.url" controls></audio>
      <div class="bubble-type">私信</div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-weibo"
    v-if="message.feType === 'weibo_client_location'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div v-if="message.label">{{ message.label }}</div>
      <a href="#">
        <img referrerpolicy="no-referrer" :src="message.map_cover" alt="" />
      </a>
      <div class="bubble-type">私信</div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-weibo weibo-post"
    v-if="message.feType === 'weibo_user_post'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <app-weibo-post :message="message"></app-weibo-post>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";
import WeiboPost from "@/components/messages/special/WeiboPost.vue";

import { WeibofaceFilter as weiboface } from "@/filters/weiboface";


export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    weiboFaceFilter() {
      return (v) => {
          return weiboface(v);
      }
    },
  },

  components: {
    "app-extra-message": ExtraMessage,
    "app-weibo-post": WeiboPost,
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {},
});
</script>
