export const MobileValidationStatus: any = {
    normal: {
      name: "正常号",
      color: "#00A854",
    },
  
    unchecked: {
      name: "未检查",
      color: "#f50",
    },
  
    shutdown: {
      name: "停机",
      color: "#FFBF00",
    },
  
    power_off: {
      name: "可能关机",
      color: "#FFBF00",
    },
  
    not_exist: {
      name: "空号",
      color: "#F04134",
    },
  
    suspected_power_off: {
      name: "开通防骚扰",
      color: "#FFBF00",
    },
  
    defect: {
      name: "未开通电话",
      color: "#FFBF00",
    },
  
    unknown: {
      name: "未知",
      color: "#FFBF00",
    },
  };
  
  export const MobileValidationStatusList = Object.keys(
    MobileValidationStatus
  ).map((key) => {
    return {
      key,
      ...MobileValidationStatus[key],
    };
  });
  