<template>
  <div class="message-container" :class="{ recalled: message.recalled }">
    <system-message :message="message" @click="click"
      v-if="message.category === messageCategories.system"></system-message>

    <template v-if="message.category === messageCategories.interaction">
      <template v-if="message.platform === messagePlatforms.web">
        <web-client-message :message="message" v-if="message.feFrom === messageFeFroms.client"></web-client-message>

        <web-worker-message :message="message" v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
          message.feFrom
        )
        "></web-worker-message>
      </template>

      <template v-if="message.platform === messagePlatforms.wechat">
        <wechat-user-message :message="message" v-if="message.feFrom === messageFeFroms.client"></wechat-user-message>

        <wechat-worker-message @onRecall="$emit('onRecall', $event)" :message="message" :usedLocation="usedLocation"
          v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
            message.feFrom
          )
          "></wechat-worker-message>
      </template>
    </template>

    <template v-if="message.platform === messagePlatforms.weibo">
      <weibo-user-message :message="message" v-if="message.feFrom === messageFeFroms.client"></weibo-user-message>
      <weibo-worker-message :message="message" v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
        message.feFrom
      )
      "></weibo-worker-message>
    </template>

    <template v-if="message.platform === messagePlatforms.douyin">
      <douyin-user-message :message="message" v-if="message.feFrom === messageFeFroms.client"></douyin-user-message>
      <douyin-worker-message :message="message" v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
        message.feFrom
      )
      "></douyin-worker-message>
    </template>

    <template v-if="message.platform === messagePlatforms.kuaishou">
      <kuaishou-user-message :message="message" v-if="message.feFrom === messageFeFroms.client"></kuaishou-user-message>
      <kuaishou-worker-message :message="message" v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
        message.feFrom
      )
      "></kuaishou-worker-message>
    </template>

    <template v-if="message.platform === messagePlatforms.redbook">
      <redbook-user-message :message="message" v-if="message.feFrom === messageFeFroms.client"></redbook-user-message>
      <redbook-worker-message :message="message" v-if="[messageFeFroms.worker, messageFeFroms.worker_temp].includes(
        message.feFrom
      )
      "></redbook-worker-message>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import SystemMessage from "@/components/messages/SystemMessage.vue";
import WebClientMessage from "@/components/messages/WebClientMessage.vue";
import WebWorkerMessage from "@/components/messages/WebWorkerMessage.vue";
import WechatUserMessage from "@/components/messages/WechatUserMessage.vue";
import WechatWorkerMessage from "@/components/messages/WechatWorkerMessage.vue";
import WeiboUserMessage from "@/components/messages/WeiboUserMessage.vue";
import WeiboWorkerMessage from "@/components/messages/WeiboWorkerMessage.vue";
import DouyinWorkerMessage from "@/components/messages/DouyinWorkerMessage.vue";
import DouyinUserMessage from "@/components/messages/DouyinUserMessage.vue";
import KuaishouWorkerMessage from "@/components/messages/KuaishouWorkerMessage.vue";
import KuaishouUserMessage from "@/components/messages/KuaishouUserMessage.vue";
import RedbookUserMessage from "@/components/messages/RedbookUserMessage.vue";
import RedbookWorkerMessage from "@/components/messages/RedbookWorkerMessage.vue";

export default defineComponent({
  props: {
    message: Object,
    usedLocation: String,
  },

  emits: ["click", "onRecall"],

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  components: {
    SystemMessage,
    WebClientMessage,
    WebWorkerMessage,
    WechatUserMessage,
    WechatWorkerMessage,
    WeiboUserMessage,
    WeiboWorkerMessage,
    DouyinUserMessage,
    DouyinWorkerMessage,
    KuaishouWorkerMessage,
    KuaishouUserMessage,
    RedbookUserMessage,
    RedbookWorkerMessage,
  },

  beforeMount() { },

  methods: {
    click(data) {
      this.$emit("click", data);
    },
  },
});
</script>

<style lang="less">
.message-container {
  position: relative;
  font-size: 13px;
  overflow-y: hidden;
  max-width: 100%;

  &.recalled .inner-bubble {
    opacity: 0.65;
  }

  * {
    box-sizing: border-box;
  }

  .msg-wrapper {
    display: block;
    position: relative;
    margin: 10px 0;
    max-width: 100%;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    p {
      margin: 0 auto;
    }

    .emoji-img {
      width: 25px;
      height: 25px;
    }
  }

  .msg-subtitle {
    font-size: 12px;
    padding: 3px 0;
    color: #4f5a62;
  }

  .msg-bubble {
    color: #4f5a62;
    min-height: 30px;
    max-width: 75%;
    position: relative;
    border-radius: 6px;
    padding: 6px 10px;
    word-wrap: break-word;
    word-break: break-word;

    img {
      // max-width: 100%;
      height: auto;
      cursor: pointer;
      max-width: 240px;
    }
  }

  .auto-conversation-tip {
    margin-top: 10px;
  }

  .btn-text {
    font-style: normal;
    cursor: pointer;
  }

  .miniapppage-cover {
    height: 200px;
    width: 200px;
    margin: 3px 0;
  }

  .preline {
    white-space: pre-line;
  }

  app-douyin-card {
    margin: 0;
    background-color: #f0f0f4;
    border-radius: 5px;
  }

  .bubble-type {
    position: absolute;
    left: calc(100% + 10px);
    min-width: 100px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    color: #4f5a62;

    &.bubble-type-long {
      min-width: 140px;
    }
  }

  .temp-status {
    position: absolute;
    left: -23px;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);

    .sending {
      color: #0e2352;
      animation: spin 4s linear infinite;
      display: block;
    }

    .error {
      color: #ef6660;
    }
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .msg-info {
    text-align: center;
    word-break: break-all;

    &.msg-history {
      margin: -9px 0 0 0;

      .content {
        display: inline-block;
        padding: 6px 10px;
        color: #2593f4;
        // border-radius: 0 0 4px 4px;
        // background-color: #fff;
        // box-shadow: 0 0 20px 0 rgba(52, 63, 75, 0.12),
        //   0 0 0 1px rgba(0, 0, 0, 0.04);
        // cursor: pointer;
      }
    }
  }

  .msg-client {
    .msg-bubble {
      float: left;
      position: relative;
      background: #e9eef0;

      &.typing-bubble {
        background-color: #fff;

        img {
          vertical-align: text-bottom;
        }
      }
    }

    .msg-bubble:after:not(.typing-bubble) {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: #e9eef0;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  .msg-worker {
    .msg-bubble {
      float: right;
      position: relative;
      background: #2593f4;
      color: #fff;

      div::selection,
      p::selection {
        color: #2593f4;
        background: white;
      }
    }

    .msg-subtitle {
      text-align: right;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    .bubble-type {
      left: initial;
      right: 100%;
      color: #4f5a62;

      width: 120px;
      padding-right: 10px;
      text-align: right;
    }
  }

  .wechat-worker-video-container {
    border: 1px solid #ddd;
    height: 90px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: white;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);

    .image-container {
      width: 157px;
      text-align: center;
      height: 100%;
    }

    img {
      width: auto;
      height: 100%;
    }

    .right-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 10px;

      p {
        margin-bottom: 0;
        margin-left: unset;
        margin-right: unset;
      }
    }
  }

  .douyin-worker-video-container {
    background-color: #f0f0f4;
    border-radius: 5px;
    display: flex;
    color: #4f5a62;
    padding: 10px;
    min-width: 250px;

    a {
      color: #2593f4;
    }

    .douyin-video-cover {
      width: 50px;
      margin-right: 10px;
    }

    .video-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      p {
        margin: 0;
        max-width: 350px;
        min-width: 250px;
        word-break: break-all;
      }
    }
  }

  .msg-client.weibo-post {
    .msg-bubble {
      border: 2px solid #f2f2f5;
      background-color: #fff;
      padding: 0 10px;
    }

    .msg-bubble:after {
      border-right-color: #f2f2f5;
    }
  }
}

.kuaishou-face {
  display: inline-block;
  height: 16px;
  width: 16px;
}
</style>
