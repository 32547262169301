<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>

    <div class="body">
      <div class="font-gray" style="max-width: 180px">
        <template v-if="linknews?.length <= 1">{{ description }}</template>
        <template v-if="linknews?.length > 1">多条图文链接</template>
      </div>
      <div  style="padding-top: 6px">
        <img :src="picurl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    linknews: Object,
  },

  data() {
    return {
      title: "标题",
      description: "描述",
      picurl: require("@/assets/img/linknews-default.png"),
      url: "",
    };
  },

  beforeMount() {
    const first = this.linknews && this.linknews[0] ? this.linknews[0] : {};
    this.title = first.title ? first.title : "标题";
    this.description = first.description ? first.description : "描述";
    this.picurl = first.picurl ? first.picurl : this.picurl;
    this.url = first.url ? first.url : "";
  },
});
</script>

<style lang="less" scoped>
.container {
  display: inline-block;
  width: 260px;
  border: 1px solid #ddd;
  background-color: white;
  padding: 6px 12px;
  text-align: left;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  word-break: break-all;
  height: 90px;
  overflow: hidden;
  white-space: nowrap;

  .body {
    display: flex;
    justify-content: space-between;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    height: 60px;
    white-space: initial;
  }

  img {
    width: 45px;
    height: 45px;
  }

  .title {
    height: 20px;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }
}
</style>
