import { getSiteConfigs } from "@/api/site-config";
import {
  Message,
  MessageCategory,
  MessageFeFrom,
  WeiboMessageDivision,
} from "@/types/Message";
import { ENGINES_TYPES, SERACH_SOURCES } from "@/types/Referrer";
import cloneDeep from "lodash/cloneDeep";
import { fileSizeCalc, isOldFormat } from "./common";
import { timestampToDateTime } from "./date";
import { getTagMsg } from "./parseTagMsg";


export const autoTypeTextDict: any = {
  subscribe: "关注回复",
  keyword: "关键词回复",
  batch_reply: "批量发送",
  auto_reply: "关注追粉",
  autoreply: "欢迎语", // 网页渠道
  web_auto_reply: "欢迎语", // 旧版网页渠道
  send_plan: "发送计划",
  noreply_reply: "无响应回复",
  offline_reply: "离线回复",
  qqfmc_sys: "腾讯系统消息",
  qqfmc_robot: "腾讯机器人消息",
  faq_menu: "问答菜单回复",
  custom_menu: "点菜单回复",
  bdbcp_autoreply: "百度-机器人消息",
  enter_im: "点私信追粉",
  ai_reply: "AI智能回复",
  talk_reply: "开口追粉",
  ai_ask: "AI智能询问",
  click_menu: "点菜单回复",
  ai_rt_awaken: "AI即时唤醒",
  ai_cron_awaken: "AI定时唤醒",
  kuailiao: "快聊消息",
  auto_invite: "欢迎语（邀请消息）",
  redbook: "小红书消息",
  welcome: "欢迎语"
};

export const messageTypeDict = {
  text: "文字",
  image: "图片",
  linknews: "图文链接",
  miniprogrampage: "小程序卡片",
  voice: "语音",
  video: "视频",
  news: "图文消息",
  roll_msg: "滚动消息",
  card: "卡片消息",
  faq_menu: "问答菜单",
};

export function handleWebMessage(message: any) {
  if (message.displayText) return message;

  let displayText: string;
  if (!message.format) {
    //老格式
    displayText = message.body;
  } else {
    displayText = message.body?.content || message.content;
  }

  displayText = displayText?.replace(/\r\n/g, "<br/>");

  displayText = displayText || "";
  message.displayText = displayText;

  return message;
}

export function transformPV(msg: any): any {
  if (msg.body.title && msg.body.title.length > 100) {
    msg.body.title = msg.body.title.slice(0, 100) + "...";
  }

  msg.body.slicedUrl = msg.body.url;
  if (msg.body.url && msg.body.url.length > 100) {
    msg.body.slicedUrl = msg.body.url.slice(0, 100) + "...";
  }

  msg.body.displayText = msg.body.title ? msg.body.title : msg.body.slicedUrl;

  msg.body.prefixText =
    msg.body.referrer && msg.body.referrer.source === "direct"
      ? "直接打开"
      : "正在访问";

  if (msg.body.referrer) {
    msg.body.referrerText = transformReferrer(msg);
  }

  return msg;
}

export function transformReferrer(msg: any): string {
  const referrer = msg.body.referrer;
  let text = "";

  if (referrer.source === "direct") {
    return "";
  }

  if (referrer.source === "custom") {
    text += referrer.custom_source;
    return `<br/>来源: ${text}`;
  }

  if (referrer.source === "search") {
    text += ENGINES_TYPES[referrer.engine];
    if (referrer.keyword) {
      text += "(" + referrer.keyword + ")";
    }
  } else if (referrer.source === "other") {
    let url = referrer.url;
    if (url.length > 40) {
      url = url.slice(0, 39) + "...";
    }
    text += "其他(" + url + ")";
  } else {
    text += SERACH_SOURCES[referrer.source];
  }

  return `<br/>来源：<a href="#">${text}</a>
    `;
}

export function transformMessageList(
  messageList: Message[],
  platform: string,
  uid: string = "",
  extra: { key: string; val: any }[] = []
): Message[] {
  return messageList
    .map((rawMessage: any) => {
      let message: any = cloneDeep(rawMessage);
      if (message.isFormarted) return message;

      extra.forEach((o) => {
        if (o.val) {
          message[o.key] = o.val;
        }
      });

      message.platform = platform;
      message.feDateTime = timestampToDateTime(
        message.microtime || message.cmicrotime || Date.now()
      );

      message.uid = uid;

      if (message.is_autoreply && !message.auto_type) {
        message.auto_type = "auto_reply";
      }

      if (message.cmd === "autoreply") {
        message.auto_type = "web_auto_reply";
      }

      if (message.cmd === "autoreply" && typeof message.body !== "string") {
        message.cmd = "worker";
        message.format = message.body?.format;
      }

      if (message.auto_type) {
        message.auto_typeText = autoTypeTextDict[message.auto_type];

        if (message.text?.includes("【关注追粉】")) {
          message.text = message.text.replace("【关注追粉】", "");
        }

        if (message.cmd === "worker" && message.auto_typeText === "关注追粉") {
          message.auto_typeText = "欢迎语";
        }
      }

      if (message.format === "roll_msg" || message.roll_msg_id) {
        message.auto_typeText = message.auto_typeText
          ? message.auto_typeText + ": 滚动消息"
          : "滚动消息";
      }

      if (message.faq_menu_id || message.body?.faq_menu_id) {
        message.auto_typeText = message.auto_typeText
          ? message.auto_typeText + ": 问答菜单"
          : "问答菜单";
      }

      if (message.format === "social_card") {
        // 产品的画蛇添足逻辑
        if (message.auto_typeText && message.auto_typeText !== "小红书消息") {
          message.auto_typeText = message.auto_typeText + ": 名片";
        } else if (message.auto_typeText === "小红书消息") {
          message.auto_typeText = "小红书消息";
        } else {
          message.auto_typeText = "名片";
        }
      }

      /**
       * 系统消息
       */

      if (rawMessage.cmd === "connect") {
        message.category = MessageCategory.system;
        message.feType = "connect";

        return message as Message;
      }

      // 老版 web 格式，新版直接走 notice
      if (rawMessage.cmd === "disconnect" || rawMessage.type === "disconnect") {
        message.category = MessageCategory.system;
        message.feType = "disconnect";

        return message as Message;
      }

      // 老版 web 格式，新版直接走 notice
      if (rawMessage.cmd === "block") {
        message.category = MessageCategory.system;
        message.feType = "block";

        return message as Message;
      }

      if (rawMessage.feType === "history") {
        message.category = MessageCategory.system;

        return message as Message;
      }

      if (rawMessage.feType === "history_later") {
        message.category = MessageCategory.system;

        return message as Message;
      }

      if (rawMessage.cmd === "reassign") {
        message.category = MessageCategory.system;
        message.feType = "reassign";

        return message as Message;
      }

      if (rawMessage.cmd === "transfer") {
        message.category = MessageCategory.system;
        message.feType = "transfer";

        return message as Message;
      }

      if (rawMessage.cmd === "doassign") {
        message.category = MessageCategory.system;
        message.feType = "doassign";

        return message as Message;
      }

      if (rawMessage.cmd === "send_fail" || rawMessage.type === "send_fail") {
        message.category = MessageCategory.system;
        message.feType = "send_fail";

        if (message.text.length > 120) {
          message.text = message.text.substring(0, 120) + "...";
        }

        return message as Message;
      }

      if (rawMessage.cmd === "edit_user" || rawMessage.type === "edit_user") {
        message.category = MessageCategory.system;
        message.feType = "lead_edit";

        if (rawMessage.cmd === "edit_user") {
          if (message?.body?.worker?.name === 901) {
            if (message?.body?.is_exist) {
              message.text = "客资重复，AI无法保存";
            } else {
              message.text = "AI客服自动保存了访客信息";
            }
          } else {
            message.text =
              message.text ||
              (message?.body?.worker?.name
                ? `工号 ${message?.body?.worker?.name}`
                : "") + " 保存了访客信息";
          }
        }

        return message as Message;
      }

      if (rawMessage.type === "send_preview") {
        message.category = MessageCategory.system;
        message.feType = "send_preview_notice";
        return message as Message;
      }

      if (
        rawMessage.cmd === "check_mobile" ||
        rawMessage.type === "check_mobile"
      ) {
        message.category = MessageCategory.system;

        if (rawMessage.ext?.status === "limit") {
          message.feType = "check_mobile_limit";
        } else {
          message.feType = "check_mobile";
          message.text = message.text.split("：")[0];
        }

        return message as Message;
      }

      const BypassNoticeTypes = ["pv", "recall"];
      const BypassNoticeCmd = ["start"];
      if (
        rawMessage.style === "notice" &&
        !BypassNoticeCmd.includes(rawMessage.cmd) &&
        !BypassNoticeTypes.includes(rawMessage.type)
      ) {
        message.category = MessageCategory.system;
        message.feType = "notice";

        if (message.subscribe_from && message.text === "关注公众号") {
          if (message.qrcode_name && message.subscribe_from === "扫描二维码") {
            message.subscribe_from = `渠道二维码 - ${message.qrcode_name}`;
          }

          message.text = `<span>通过</span><span class="font-yellow"> ${message.subscribe_from} </span><span>关注公众号</span>`;
        }

        if (message.subscribe_from && message.text === "访客进入对话") {
          message.text = `<span>通过</span><span class="font-yellow"> ${message.subscribe_from} </span><span>进入对话</span>`;
        }

        return message as Message;
      }

      if (rawMessage.cmd === "tags_add" || rawMessage.type === "tags_add") {
        message.category = MessageCategory.system;
        message.feType = "tags_add";
        message.tagsData = getTagMsg(message);

        return message as Message;
      }

      if (
        rawMessage.cmd === "tags_remove" ||
        rawMessage.type === "tags_remove"
      ) {
        message.category = MessageCategory.system;
        message.feType = "tags_remove";
        message.tagsData = getTagMsg(message);

        return message as Message;
      }

      // 系统消息结束

      /**
       * 互动消息
       */

      // 主动发起对话
      if (rawMessage.cmd === "start") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_start";

        return message as Message;
      }

      // 网页 客服发送 文字消息
      if (message.cmd === "worker" && message.format === "text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_text";

        message = handleWebMessage(message);

        return message as Message;
      }

      // 网页 客服发送 文字消息 新版
      if (
        message.platform === "web" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_text";

        message = handleWebMessage(message);

        return message as Message;
      }

      // 网页 客服发送 富文本消息
      if (message.cmd === "worker" && message.format === "html") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_html";

        message = handleWebMessage(message);

        return message as Message;
      }

      // 网页 客服发送 富文本消息 新版
      if (
        message.platform === "web" &&
        message.from === "worker" &&
        message.format === "html"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_html";

        message = handleWebMessage(message);

        return message as Message;
      }

      // 网页 图片消息
      // format=image 时，H5 网页消息是会转换成 html 格式的，不过三个智能咨询页的客服侧消息且带有 auto_type 时是不做转换的
      if (
        message.platform === "web" &&
        message.from === "worker" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_image";

        return message as Message;
      }

      // 网页 客服发送 小程序卡片
      if (
        platform === "web" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "miniprogrampage"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_miniprogrampage";

        const domain = getSiteConfigs().CHAT_IMAGE_CDN_SITE;

        message.url = domain + "/" + message.filename;

        return message as Message;
      }

      // 网页 客服发送 问答菜单
      if (message.cmd === "worker" && message.format === "faq_menu") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "web_worker_message_faq_menu";

        message = handleWebMessage(message);

        return message as Message;
      }

      //qqfmc 图片消息
      if (message.cmd === "worker" && message.format === "image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "worker_message_fmc_image";

        return message as Message;
      }

      // 网页 客服发送 临时消息
      if (rawMessage.feType === "temp_worker_message") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        message = handleWebMessage(message);

        return message as Message;
      }

      // 网页 客服发送 自动回复
      // 自动回复老格式
      if (message.cmd === "autoreply") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "autoreply";
        message.feMuted = true;

        return message as Message;
      }

      // 网页 客服发送 无回复回复
      if (rawMessage.cmd === "noreply_reply") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "noreply_reply";
        message.feMuted = true;

        return message as Message;
      }

      // 网页 客服发送 bcp 自动回复邀请
      if (rawMessage.cmd === "auto_invite") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "auto_invite";
        message.feMuted = true;

        return message as Message;
      }

      // 网页 客服发送 留言评论
      if (rawMessage.cmd === "comment") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "legacy_worker_message";

        return message as Message;
      }

      if (rawMessage.cmd === "off_cmt") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "offline_msg_cmt";
        message.body = message.body.content;

        return message as Message;
      }

      // 网页 用户发送 正在输入
      if (rawMessage.cmd === "typing" || rawMessage.type === "typing") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "client_typing";
        message.feMuted = true;
        message.content = message.content || message.body;

        return message as Message;
      }

      //qqfmc 图片消息
      if (rawMessage.cmd === "user" && rawMessage.format === "image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "client_message_fmc_image";

        return message as Message;
      }

      //baidu bcp 语音消息
      if (message.platform === "web" && rawMessage.format === "audio") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "client_message_web_audio";

        return message as Message;
      }

      // 网页 用户发送 普通消息
      if (rawMessage.cmd === "user" && rawMessage.format !== "image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "client_message";

        message = handleWebMessage(message);

        return message as Message;
      }

      if (
        rawMessage.from === "user" &&
        rawMessage.format === "html" &&
        message.platform === "web"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "web_client_html_message";
        message.rawText = message.content;
        message = handleWebMessage(message);

        return message as Message;
      }

      if (
        rawMessage.from === "user" &&
        rawMessage.format === "text" &&
        message.platform === "web"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "web_client_text_message";
        message.rawText = message.content;
        message = handleWebMessage(message);

        return message as Message;
      }

      if (
        (rawMessage.from === "user" &&
          rawMessage.format === "image" &&
          message.platform === "web") ||
        rawMessage.cmd === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "client_web_image_message";

        if (rawMessage.cmd === "image" && rawMessage.body?.url) {
          message.url = rawMessage.body.url;
        }

        return message as Message;
      }

      // 网页 用户发送 抖动
      if (rawMessage.cmd === "shake" || rawMessage.format === "shake") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "shake";

        return message as Message;
      }

      // 网页 用户发送 pv
      if (rawMessage.cmd === "pv" || rawMessage.type === "pv") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "pv";
        message.feMuted = true;

        if (!message.body) {
          message.body = message.ext;
        }

        message = transformPV(message);

        return message as Message;
      }

      // 网页 老格式的访客留言
      if (rawMessage.cmd === "message") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "legacy_offline_msg";

        return message as Message;
      }

      if (rawMessage.cmd === "off_msg") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "offline_msg";

        return message as Message;
      }

      // 微信 客服发送 文字消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_text";
        // message.text = wechatfaceToHtml(message.text);

        return message as Message;
      }

      // 微信 临时 客服发送 文字消息
      if (message.feType === "temp_wechat_worker_text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        message.text = message.body.content;
      }

      // 微信 客服发送 图片消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_image";

        return message as Message;
      }

      // 微信 临时 客服发送 图片消息
      if (message.feType === "temp_wechat_worker_image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 语音消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "voice"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_voice";

        return message as Message;
      }

      // 微信 临时 客服发送 视频消息
      if (message.feType === "temp_wechat_worker_video") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 视频消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "video"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_video";

        return message as Message;
      }

      // 微信 临时 客服发送 语音消息
      if (message.feType === "temp_wechat_worker_voice") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 图文链接
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "linknews"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_linknews";

        return message as Message;
      }

      // 微信 临时 客服发送 图文链接
      if (message.feType === "temp_wechat_worker_linknews") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 图文消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "news"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_news";

        return message as Message;
      }

      // 微信 临时 客服发送 图文消息
      if (message.feType === "temp_wechat_worker_news") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 小程序卡片
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "miniprogrampage"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wechat_worker_miniprogrampage";

        const domain =
          message.type === "chatcdn"
            ? getSiteConfigs().CHAT_IMAGE_CDN_SITE
            : getSiteConfigs().COMPANY_IMAGE_CDN_SITE;

        message.url = domain + "/" + message.filename;

        return message as Message;
      }

      // 微信 临时 客服发送 小程序卡片
      if (message.feType === "temp_wechat_worker_miniprogrampage") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 滚动消息
      if (message.format === "roll_msg") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "worker_roll_msg";
      }

      // 微信 临时 客服发送 滚动消息
      if (message.feType === "temp_worker_roll_msg") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
      }

      // 微信 客服发送 微信客服名片
      if (platform === "wechat" && message.format === "wework_card") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "wework_card";

        return message as Message;
      }

      // 微信 用户发送 文字消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_text";
        // message.text = wechatfaceToHtml(message.text);

        return message as Message;
      }

      // 微信 用户发送 图片消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_image";

        return message as Message;
      }

      // 微信 用户发送 视频消息
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "video"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_video";

        return message as Message;
      }

      // 微信 用户发送 短视频
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "shortvideo"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_shortvideo";

        return message as Message;
      }

      // 微信 用户发送 链接
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "link"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_link";

        return message as Message;
      }

      // 微信 用户发送 地址
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "location"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_location";

        return message as Message;
      }

      // 微信 用户发送 语音
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "voice"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_voice";

        return message as Message;
      }

      // 微信 用户发送 小程序
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "miniprogrampage"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_miniprogrampage";

        return message as Message;
      }

      // 微信 用户发送 文件
      if (
        platform === "wechat" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "file"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "wechat_client_file";

        message.filesizeText = fileSizeCalc(message.filesize);

        return message as Message;
      }

      // 微博 客服发送 私信 文字
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_worker_text";
        // message.text = weibofaceToHtml(message.text);

        return message as Message;
      }

      // 微博 客服发送 临时消息
      if (rawMessage.feType === "temp_weibo_worker_text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        // message.text = weibofaceToHtml(message.body.content);
        message.text = message.body.content;

        return message as Message;
      }

      // 微博 客服发送 评论
      if (
        platform === "weibo" &&
        message.from === "worker" &&
        message.format === "text-cmt"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.division = WeiboMessageDivision.post;
        message.feType = "weibo_worker_cmt";

        return message as Message;
      }

      // 微博 客服发送 临时评论
      if (message.feType === "temp_weibo_worker_cmt") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        message.division = WeiboMessageDivision.post;

        return message as Message;
      }

      // 微博 用户发送 私信 文字
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_client_text";
        // message.text = weibofaceToHtml(message.text);

        return message as Message;
      }

      // 微博 用户发送 私信 图片
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_client_image";

        return message as Message;
      }

      // 微博 用户发送 私信 视频
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "video"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_client_video";

        return message as Message;
      }

      // 微博 用户发送 私信 语音
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "voice"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_client_voice";

        return message as Message;
      }

      // 微博 用户发送 私信 地址
      if (
        platform === "weibo" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "location"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.pm;
        message.feType = "weibo_client_location";

        return message as Message;
      }

      // 微博 用户发送 博文
      if (
        platform === "weibo" &&
        (message.type === "at" ||
          message.type === "like" ||
          message.type === "cmt" ||
          message.type === "link") &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.division = WeiboMessageDivision.post;
        message.feType = "weibo_user_post";

        return message as Message;
      }

      // 抖音 客服发送 文字消息
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "douyin_worker_text";
        // message.text = douyinfaceToHtml(message.text);

        return message as Message;
      }

      // 抖音 客服发送 临时文字消息
      if (rawMessage.feType === "temp_douyin_worker_text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        // message.text = douyinfaceToHtml(message.body.content);
        message.text = message.body.content;

        return message as Message;
      }

      // 抖音 客服发送 图片消息
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "douyin_worker_image";

        return message as Message;
      }

      // 抖音 客服发送 临时图片消息
      if (rawMessage.feType === "temp_douyin_worker_image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

      // 抖音 客服发送 评论
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "cmt" &&
        !rawMessage.feType
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "douyin_worker_cmt";

        return message as Message;
      }

      // 抖音 客服发送 临时评论
      if (rawMessage.feType === "temp_douyin_worker_cmt") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

      //抖音 客服发送 视频消息
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "video"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "douyin_worker_video";

        return message as Message;
      }

      // 抖音 客服发送 临时视频消息
      if (rawMessage.feType === "temp_douyin_worker_video") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

      //抖音 客服发送 卡片消息
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.card_id
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "douyin_worker_card";

        return message as Message;
      }

      // 抖音 客服发送 临时卡片消息
      if (rawMessage.feType === "temp_douyin_worker_card") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

        //抖音 客服发送 获取手机号
        if (
          platform === "douyin" &&
          message.style === "message" &&
          message.from === "worker" &&
          message.format === "collect_phone"
        ) {
          message.category = MessageCategory.interaction;
          message.feFrom = MessageFeFrom.worker;
          message.feType = "douyin_worker_collect_phone";

          return message as Message;
        }

      // 抖音 用户发送 文字消息
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_text";
        // message.text = douyinfaceToHtml(message.text);

        return message as Message;
      }

      // 抖音 用户发送 私信 图片
      if (
        platform === "douyin" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_image";

        return message as Message;
      }

      // 抖音 用户发送 评论
      if (
        platform === "douyin" &&
        message.format === "cmt" &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_cmt";

        return message as Message;
      }

      // 抖音 用户发送 h5
      if (
        platform === "douyin" &&
        message.format === "h5" &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_h5";

        message.body = {
          card_name: "",
          card_type: "h5",
          content: JSON.stringify({
            image_url: message.icon_url,
            link_url: message.link_url,
            text: message.description,
            title: message?.title,
          }),
        };
        return message as Message;
      }

      // 抖音 用户发送 卡片
      if (
        platform === "douyin" &&
        message.format === "card" &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_card";

        return message as Message;
      }

      // 抖音 用户发送 视频
      if (
        platform === "douyin" &&
        message.format === "video" &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_video";

        return message as Message;
      }

       // 抖音 用户发送 上传 视频
       if (
        platform === "douyin" &&
        message.format === "user_video" &&
        message.from === "user"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "douyin_client_user_video";

        return message as Message;
      }

      // 快手 客服发送 文字消息
      if (
        platform === "kuaishou" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "kuaishou_worker_text";
        message.rawText = message.text;

        return message as Message;
      }

      // 快手 客服发送 临时文字消息
      if (rawMessage.feType === "temp_kuaishou_worker_text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        message.text = message.body.content;

        return message as Message;
      }

      // 快手 客服发送 图片消息
      if (
        platform === "kuaishou" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "kuaishou_worker_image";

        return message as Message;
      }

      // 快手 客服发送 临时图片消息
      if (rawMessage.feType === "temp_kuaishou_worker_image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

      // 快手 用户发送 文字消息
      if (
        platform === "kuaishou" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "kuaishou_client_text";
        message.rawText = message.text;

        return message as Message;
      }

      // 小红书 用户发送 文字消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_text";
        message.rawText = message.text;
        // message.text = redbookFaceToHtml(message.text);

        return message as Message;
      }

      // 小红书 用户发送 图片消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_image";

        return message as Message;
      }

      // 小红书 用户发送 视频消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "video"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_video";

        return message as Message;
      }

      // 小红书 用户发送 笔记消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "user" &&
        message.format === "note"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_note";


        return message as Message;
      }

      // 小红书 用户发送 卡片评论
      if (
        platform === "redbook" &&
        message.from === "user" &&
        message.type === "cmt"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_note_cmt";

        return message as Message;
      }

      // 小红书 用户发送 落地页
      if (
        platform === "redbook" &&
        message.from === "user" &&
        message.format === "loading_page"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_loading_page";

        return message as Message;
      }

      // 小红书 用户发送 服务卡片
      if (
        platform === "redbook" &&
        message.from === "user" &&
        message.format === "service_card"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_card";

        return message as Message;
      }

       // 小红书 用户发送 名片
       if (
        platform === "redbook" &&
        message.from === "user" &&
        message.format === "social_card"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.client;
        message.feType = "redbook_client_social_card";

        return message as Message;
      }

      // 小红书 客服发送 文字消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "text"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_text";
        message.rawText = message.text;

        return message as Message;
      }

      // 小红书 客服发送 临时文字消息
      if (rawMessage.feType === "temp_redbook_worker_text") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;
        message.text = message.body.content;

        return message as Message;
      }

      // 小红书 客服发送 图片消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "image"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_image";

        return message as Message;
      }

      // 小红书 客服发送 临时图片消息
      if (rawMessage.feType === "temp_redbook_worker_image") {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker_temp;

        return message as Message;
      }

      // 小红书 客服发送 笔记消息
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "note"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_note";

        return message as Message;
      }

      // 小红书 客服发送 服务卡
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "service_card"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_card";

        return message as Message;
      }

      // 小红书 客服发送 落地页
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "loading_page"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_loading_page";

        return message as Message;
      }

      // 小红书 客服发送 名片
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "social_card"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_social_card";

        return message as Message;
      }

      // 小红书 客服发送 评论
      if (
        platform === "redbook" &&
        message.style === "message" &&
        message.from === "worker" &&
        message.format === "cmt"
      ) {
        message.category = MessageCategory.interaction;
        message.feFrom = MessageFeFrom.worker;
        message.feType = "redbook_worker_cmt";

        return message as Message;
      }

      return message as Message;
    })
    .map((message) => {
      message.isFormarted = true;
      return message;
    });
}

export function getFormatedMsg(
  platform: string,
  uid: string,
  inputData: any,
  toReplyCMT?: any
) {
  const cmicrotime = Date.now();
  inputData = cloneDeep(inputData);

  if (platform === "web") {
    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        type: "website",
        cmicrotime,
        platform,
      },
      {
        feType: "temp_worker_message",
        body: {
          format: "html",
          content: inputData.content,
        },
        format: "html",
        uid: uid,
        type: "website",
        cmicrotime,
        platform,
      },
    ];
  }

  if (platform === "wechat") {
    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        platform: "wechat",
        cmicrotime,
      },
      {
        feType: "temp_wechat_worker_" + inputData.format,
        body: inputData,
        uid: uid,
        platform: "wechat",
        cmicrotime,
      },
    ];
  }

  if (platform === "weibo") {
    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        platform: "weibo",
        cmicrotime,
      },
      {
        feType: "temp_weibo_worker_" + inputData.format,
        body: inputData,
        uid: uid,
        platform: "weibo",
        cmicrotime,
      },
    ];
  }

  if (platform === "douyin") {
    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        platform: "douyin",
        cmicrotime,
      },
      {
        feType: "temp_douyin_worker_" + inputData.format,
        body: inputData,
        uid: uid,
        platform: "douyin",
        cmicrotime,
      },
    ];
  }

  if (platform === "kuaishou") {
    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        platform: "kuaishou",
        cmicrotime,
      },
      {
        feType: "temp_kuaishou_worker_" + inputData.format,
        body: inputData,
        uid: uid,
        platform: "kuaishou",
        cmicrotime,
      },
    ];
  }

  // 小红书
  if (platform === "redbook") {
    if (toReplyCMT) {
      toReplyCMT = cloneDeep(toReplyCMT);
      toReplyCMT.format = "cmt";
      toReplyCMT.reply_content = inputData.content;

      inputData = {
        ...inputData,
        ...toReplyCMT,
      };
    }

    return [
      {
        cmd: "worker",
        body: JSON.stringify(inputData),
        uid: uid,
        platform: "redbook",
        cmicrotime,
      },
      {
        feType: "temp_redbook_worker_" + inputData.format,
        body: inputData,
        uid: uid,
        platform: "redbook",
        cmicrotime,
      },
    ];
  }
}

export function formatSnippetToSend(
  content: any,
  platform: string,
  uid: string
) {
  const cmicrotime = Date.now();

  const msg: any = {
    cmd: "worker",
    body: content,
    uid: uid,
    cmicrotime: cmicrotime,
  };

  if (platform === "web") {
    msg.type = "website";

    if (!msg.body?.includes("format")) {
      const content = msg.body.replace(/\$.+?\$/g, (match: any) => {
        const keyword = match.replace(/\$/g, "");
        return `<img src="https://${window.location.host}/static/web-emojis/${keyword}.png" class="emoji-img">`;
      });

      msg.body = JSON.stringify({
        format: "html",
        content: content,
      });
      msg.format = "html";
    }
  }

  if (platform === "wechat") {
    msg.platform = "wechat";
    if (!isOldFormat(content)) {
      const contentObj = JSON.parse(content);
      if (contentObj.format === "news") {
        contentObj.url = contentObj.news[0].url;
        contentObj.news_title = contentObj.news[0].title;
      }

      msg.body = JSON.stringify(contentObj);
    } else {
      if (content.format === "image") {
        msg.body = JSON.stringify(content);
      } else {
        msg.body = JSON.stringify({
          format: "text",
          content: content,
        });
      }
    }
  }

  if (platform === "weibo") {
    msg.platform = "weibo";

    if (!isOldFormat(content)) {
      msg.body = content;
    } else {
      msg.body = JSON.stringify({
        format: "text",
        content: content,
      });
    }
  }

  if (platform === "douyin") {
    msg.platform = "douyin";

    if (!isOldFormat(content)) {
      const contentObj = JSON.parse(content);
      msg.body = JSON.stringify(contentObj);
    } else {
      if (content.format === "image") {
        msg.body = JSON.stringify(content);
      } else {
        msg.body = JSON.stringify({
          format: "text",
          content: content,
        });
      }
    }
  }

  if (platform === "kuaishou") {
    msg.platform = "kuaishou";

    if (!isOldFormat(content)) {
      const contentObj = JSON.parse(content);
      msg.body = JSON.stringify(contentObj);
    } else {
      if (content.format === "image") {
        msg.body = JSON.stringify(content);
      } else {
        msg.body = JSON.stringify({
          format: "text",
          content: content,
        });
      }
    }
  }

  if (platform === "redbook") {
    msg.platform = "redbook";

    if (!isOldFormat(content)) {
      const contentObj = JSON.parse(content);
      msg.body = JSON.stringify(contentObj);
    } else {
      if (content.format === "image") {
        msg.body = JSON.stringify(content);
      } else {
        msg.body = JSON.stringify({
          format: "text",
          content: content,
        });
      }
    }
  }

  return msg;
}
