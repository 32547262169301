import Commet from "./Commet";

let commet: any = null;

export default function CommetFactory($store: any) {
  if (!commet) {
    console.log("new commet inited");
    commet = new Commet($store);
  }

  return commet;
}
