<template>
    <div class="msg-wrapper msg-worker msg-kuaishou preline" v-if="message.feType === 'kuaishou_worker_text'">
        <div class="msg-subtitle">
            {{ message.feDateTime }}
            <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
        </div>
        <div class="msg-bubble">
            <div v-html="kuaishoufaceFilter(message.text)"></div>
            <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
                {{ message.auto_typeText }}
            </div>
        </div>
    </div>

    <div class="msg-wrapper msg-worker msg-temp msg-kuaishou preline" v-if="message.feType === 'temp_kuaishou_worker_text'">
        <div class="msg-subtitle">
            {{ message.feDateTime }}
            <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
        </div>
        <div class="msg-bubble">
            <div class="temp-status">
                <i class="iconfont sending" v-if="message.sendingStatus !== 'sent' &&
                    message.sendingStatus !== 'error'
                    ">&#xe609;</i>
                <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
            </div>
            <div v-html="kuaishoufaceFilter(message.text)"></div>
            <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
                {{ message.auto_typeText }}
            </div>
        </div>
    </div>

    <div class="msg-wrapper msg-worker" v-if="message.feType === 'kuaishou_worker_image'">
        <div class="msg-subtitle">
            {{ message.feDateTime }}
            <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
        </div>
        <div class="msg-bubble">
            <img :src="message.url" />
            <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
                {{ message.auto_typeText }}
            </div>
        </div>
    </div>
</template>
  
<script>
import { defineComponent } from "vue";
import {
    MessageFeFrom,
    MessagePlatform,
    MessageCategory,
    WeiboMessageDivision,
} from "@/types/Message";



import { KuaishoufaceFilter as kuaishouface } from "@/filters/kuaishouface";

export default defineComponent({
    props: {
        message: Object,
    },

    computed: {
        kuaishoufaceFilter() {
            return (v) => {
                return kuaishouface(v);
            };
        },
    },

    components: {

    },

    data() {
        return {
            messageFeFroms: MessageFeFrom,
            messagePlatforms: MessagePlatform,
            messageCategories: MessageCategory,
            WeiboMessageDivision: WeiboMessageDivision,
        };
    },

    beforeMount() { },
});
</script>