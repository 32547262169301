function escapeRegExp(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export const WECHAT_FACE_MAP: any = {
  "/::)": "1",
  "[微笑]": "1",
  "/::~": "2",
  "[撇嘴]": "2",
  "/::B": "3",
  "[色]": "3",
  "/::|": "4",
  "[发呆]": "4",
  "/:8-)": "5",
  "[得意]": "5",
  "/::<": "6",
  "[流泪]": "6",
  "/::$": "7",
  "[害羞]": "7",
  "/::X": "8",
  "[闭嘴]": "8",
  "/::Z": "9",
  "[睡]": "9",
  "/::'(": "10",
  "[大哭]": "10",
  "/::-|": "11",
  "[尴尬]": "11",
  "/::@": "12",
  "[发怒]": "12",
  "/::P": "13",
  "[调皮]": "13",
  "/::D": "14",
  "[呲牙]": "14",
  "[笑脸]": "14",
  "/::O": "15",
  "[惊讶]": "15",
  "/::(": "16",
  "[难过]": "16",
  "/::+": "17",
  "/:--b": "18",
  "[冷汗]": "18",
  "[囧]": "18",
  "/::Q": "19",
  "[抓狂]": "19",
  "/::T": "20",
  "[吐]": "20",
  "/:,@P": "21",
  "[偷笑]": "21",
  "/:,@-D": "22",
  "[愉快]": "22",
  "/::d": "23",
  "[白眼]": "23",
  "/:,@o": "24",
  "[傲慢]": "24",
  "/::g": "25",
  "/:|-)": "26",
  "[困]": "25",
  "/::!": "27",
  "[惊恐]": "27",
  "/::L": "28",
  "/::>": "29",
  "[憨笑]": "29",
  "/::,@": "30",
  "[悠闲]": "30",
  "/:,@f": "31",
  "/::-S": "32",
  "[咒骂]": "32",
  "/:?": "33",
  "[疑问]": "33",
  "/:,@x": "34",
  "[嘘]": "34",
  "/:,@@": "35",
  "[晕]": "35",
  "/::8": "36",
  "/:,@!": "37",
  "[衰]": "37",
  "/:!!!": "38",
  "[骷髅]": "38",
  "/:xx": "39",
  "[敲打]": "39",
  "/:bye": "40",
  "[再见]": "40",
  "/:wipe": "41",
  "/:dig": "42",
  "[抠鼻]": "42",
  "/:handclap": "43",
  "[鼓掌]": "43",
  "/:&-(": "44",
  "/:B-)": "45",
  "[坏笑]": "45",
  "/:<@": "46",
  "/:@>": "47",
  "[右哼哼]": "47",
  "/::-O": "48",
  "/:>-|": "49",
  "[鄙视]": "49",
  "/:P-(": "50",
  "[委屈]": "50",
  "[TearingUp]": "51",
  "[快哭了]": "51",
  "/::'|": "51",
  "/:X-)": "52",
  "[阴险]": "52",
  "/::*": "53",
  "[亲亲]": "53",
  "/:@x": "54",
  "/:8*": "55",
  "[可怜]": "55",
  "/:pd": "56",
  "[菜刀]": "56",
  "/:<W>": "57",
  "/:beer": "58",
  "[啤酒]": "58",
  "/:basketb": "59",
  "/:oo": "60",
  "/:coffee": "61",
  "[咖啡]": "61",
  "/:eat": "62",
  "/:pig": "63",
  "[猪头]": "63",
  "/:rose": "64",
  "[玫瑰]": "64",
  "/:fade": "65",
  "[凋谢]": "65",
  "/:showlove": "66",
  "[嘴唇]": "66",
  "/:heart": "67",
  "[爱心]": "67",
  "/:break": "68",
  "[心碎]": "68",
  "/:cake": "69",
  "[蛋糕]": "69",
  "/:li": "70",
  "/:bome": "71",
  "[炸弹]": "71",
  "/:kn": "72",
  "/:footb": "73",
  "/:ladybug": "74",
  "/:shit": "75",
  "[便便]": "75",
  "/:moon": "76",
  "[月亮]": "76",
  "/:sun": "77",
  "[太阳]": "77",
  "/:gift": "78",
  "[礼物]": "78",
  "/:hug": "79",
  "[拥抱]": "79",
  "/:strong": "80",
  "[强]": "80",
  "/:weak": "81",
  "[弱]": "81",
  "/:share": "82",
  "[握手]": "82",
  "/:v": "83",
  "[胜利]": "83",
  "/:@)": "84",
  "[抱拳]": "84",
  "/:jj": "85",
  "[勾引]": "85",
  "/:@@": "86",
  "[拳头]": "86",
  "/:bad": "87",
  "/:lvu": "88",
  "/:no": "89",
  "/:ok": "90",
  "[OK]": "90",
  "/:love": "91",
  "/:<L>": "92",
  "/:jump": "93",
  "[跳跳]": "93",
  "/:shake": "94",
  "[发抖]": "94",
  "/:<O>": "95",
  "/:circle": "96",
  "[转圈]": "96",
  "/:kotow": "97",
  "/:turn": "98",
  "/:skip": "99",
  "/:oY": "100",
  "/:#-0": "101",
  "/:hiphot": "102",
  "/:kiss": "103",
  "/:<&": "104",
  "/:&>": "105",
  "[奸笑]": "106",
  "[Smirk]": "106",
  "[嘿哈]": "107",
  "[Hey]": "107",
  "[捂脸]": "108",
  "[Facepalm]": "108",
  "[机智]": "109",
  "[Smart]": "109",
  "[红包]": "111",
  "[Packet]": "111",
  "[耶]": "113",
  "[Yeah!]": "113",
  "[皱眉]": "114",
  "[Concerned]": "114",
  "[Chick]": "115",
  "[吃瓜]": "116",
  "[Onlooker]": "116",
  "[加油]": "117",
  "[GoForIt]": "117",
  "[汗]": "118",
  "[Sweats]": "118",
  "[擦汗]": "118",
  "[天啊]": "119",
  "[OMG]": "119",
  "[Emm]": "120",
  "[社会社会]": "121",
  "[Respect]": "121",
  "[旺柴]": "122",
  "[Doge]": "122",
  "[好的]": "123",
  "[NoProb]": "123",
  "[打脸]": "124",
  "[MyBad]": "124",
  "[哇]": "125",
  "[Wow]": "125",
  "[Boring]": "126",
  "[翻白眼]": "126",
  "[666]": "127",
  "[LetMeSee]": "128",
  "[让我看看]": "128",
  "[Sigh]": "129",
  "[叹气]": "129",
  "[Hurt]": "130",
  "[苦涩]": "130",
  "[Broken]": "131",
  "[裂开]": "131",
  "[Party]": "132",
  "[庆祝]": "132",
  "[Lol]": "133",
  "[破涕为笑]": "133",
  "[Worship]": "134",
  "[合十]": "134",
  "[Happy]": "135",
  "[Rich]": "136",
  "[發]": "136",
  "[Sick]": "137",
  "[生病]": "137",
  "[Flushed]": "138",
  "[脸红]": "138",
  "[Awesome]": "139",
  "[Blessing]": "140",
  "[福]": "140",
  "[Fireworks]": "141",
  "[烟花]": "141",
  "[Firecracker]": "142",
  "[爆竹]": "142",
  "[Terror]": "143",
  "[恐惧]": "143",
  "[LetDown]": "144",
  "[失望]": "144",
  "[Duh]": "145",
  "[无语]": "145",
};

export function WechatfaceFilter(input: string) {
  const faces = WECHAT_FACE_MAP;

  for (const face in faces) {
    const re = new RegExp(escapeRegExp(face), "g");

    input = input.replace(
      re,
      `<span class="wechatface wechatface_${faces[face]}"></span>`
    );
  }
  return input;
}
