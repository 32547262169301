import { clientSpokenTypes } from "@/types/spokenTypes";
import { Talk } from "@/types/Talk";


export function getSpokenFlag(talk: Talk) {
  const con = talk.conversation;

  const flag = con.every((msg) => {
    if (clientSpokenTypes.includes(msg.feType)) {
      return false;
    }

    return true;
  });

  return !flag;
}
