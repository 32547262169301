import { checkToken } from "@/api/auth";
import { ERROR_TYPES } from "@/enums/error-types";

// initial State
const state = {
  token: "",
  failure: "",
  companyID: "",

  hasMobilePermission: false,
};

const mutations = {
  AUTHENTICATE(
    s: typeof state,
    { token, companyID }: { token: string; companyID: string }
  ) {
    s.token = token;
    s.companyID = companyID;
  },

  AUTHENTICATE_FAILURE(s: any, reason: string) {
      s.failure = reason;
  },

  UPDATE_MOBILE_PERMISSION(s: any, hasMobilePermission: boolean) {
    s.hasMobilePermission = hasMobilePermission;
  }
};

const actions = {
  AUTHENTICATE({ commit }: any, payload: any) {
    checkToken().then(
      () => {
        commit("AUTHENTICATE", payload);
      },
      () => {
        commit("AUTHENTICATE_FAILURE", ERROR_TYPES.INVALID_TOKEN)
      }
    );
  },

};

export default {
  state,
  mutations,
  actions,
};
