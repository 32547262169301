
import { defineComponent } from "vue";
import { NavBar, Button, Dialog } from "vant";
import { HostTypes } from "@/enums/host";

import {
  getNotificationInstructionText,
  isPreferWechat,
} from "@/utils/notification";
import { getHostType } from "@/utils/host";
import { sendWechatPush } from "@/api/common";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      device: "",
      os: "",
      SDK: 0,
      mobile_id: "",
      mobile_type: "",
      instructionText: "",
      hostType: "",
      HostTypes,

      showPreferWechat: false,
    };
  },

  beforeMount() {

  },

  methods: {
    goBack() {
      this.$router.push("/settings");
    },


    testPush() {
      sendWechatPush().subscribe(
        () => {
          Dialog.alert({
            message: "10秒后将收到公众号推送，您可锁屏观察推送情况",
          }).then(() => {
            this.$router.push("/settings");
          })
        },
        () => {
          Dialog.alert({
            message: "请求失败",
          });
        }
      );
    },
  },
});
