<template>
  <div class="container">
    <div class="bubble-type noselect">@我</div>

    <div>
      <span class="weibo-user"> @{{ ext.user.nickname }}: </span>

      <span v-html="ext.text || ''"> </span>

      <div class="weibo-pics" v-if="ext.pics">
        <img
          referrerpolicy="no-referrer"
          :src="'http:' + pic"
          class="weibo-pic"
          v-for="pic in ext.pics"
          :key="pic"
        />
      </div>
    </div>

    <template v-if="ext.source_post">
      <div class="inner-bubble">
        <span class="weibo-user"> @{{ ext.source_post.user.nickname }}: </span>

        <span v-html="ext.source_post.text || ''"> </span>

        <div class="weibo-pics" v-if="ext.source_post.pics">
          <img
            referrerpolicy="no-referrer"
            :src="'http:' + pic"
            class="weibo-pic"
            v-for="pic in ext.source_post.pics"
            :key="pic"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: Object,
  },

  data() {
    return {
      ext: null,
    };
  },

  beforeMount() {
    this.ext = this.message.ext;
  },
});
</script>


<style lang="less" scoped>
.container {
  display: block;
  padding-top: 5px;
  padding-bottom: 3px;
  max-width: 456px;

  .weibo-text-link {
    cursor: pointer;
  }

  .at-span {
    color: #2593f4;
  }

  .weibo-pics {
    width: 250px;

    .weibo-pic {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin-right: 10px;
      margin-top: 6px;
    }
  }

  .inner-bubble {
    background-color: #f2f2f5;
    border-radius: 3px;
    margin: 3px;
    padding: 6px 10px;

    & .inner-bubble {
      background-color: #fff;

      & .inner-bubble {
        background-color: #f2f2f5;
      }
    }
  }

  .weibo-user {
    font-weight: bold;
  }

  .post-footer {
    font-size: smaller;
    color: #858585;
  }

  .action-text {
    color: #2593f4;
    cursor: pointer;
  }
}
</style>