import { imapiClient } from "@/utils/http";
import { findServer } from "@/utils/network";
import { from, of, Subject, throwError, timer } from "rxjs";
import {
  mergeMap,
  repeat,
  retryWhen,
  switchMap,
  takeUntil,
  tap,
  timeout,
} from "rxjs/operators";
import { loop, online, sendMessage } from "./chat";
import { ERROR_TYPES } from "@/enums/error-types";
import { Dialog } from "vant";
import { getHostType, redirectToMobileLogin } from "@/utils/host";
import { HostTypes } from "@/enums/host";
import { compareObjects } from "@/utils/common";
import {
  sanitizeNewTalkList,
  transformTalkListToFe,
  transformTalkListToFeV3,
} from "@/utils/talk";
import { isOnIMUpdateList } from "@/utils/IMUpdate";
import { LoopDataV2, LoopDataV3 } from "@/types/Chat";

export default class Commet {
  connectRetryMaxAttempts = 1;
  connectRetryInterval = 5000;
  loopRetryMaxAttempts = 2;
  loopRetryInterval = 1000;
  pollingInterval = 15000;

  sendQueue: any[] = [];

  previousCons: any = {};

  connected = false;
  connecting = false;
  sending = false;
  looping = false;
  stopPolling$ = new Subject();

  onlineCount = 0;
  firstOnlineTime: any = null;
  secondOnlineTime: any = null;

  postConversation: any = {};
  previousWorkerIndex: any = {};
  v3Conversation: any = [];

  $store: any;

  constructor($store: any) {
    this.$store = $store;
  }

  connect() {
    console.log("connect func starts", new Date());

    if (this.connected || this.connecting) {
      return;
    }

    this.connecting = true;

    online()
      .pipe(
        tap(this.handleConnectRes.bind(this)),
        retryWhen(this.connectRetryStrategy.bind(this))
      )
      .subscribe(() => {}, this.handleConnectError.bind(this));
  }

  loop() {
    if (!this.connected) return;
    if (this.looping) return;

    this.looping = true;

    of({})
      .pipe(
        switchMap(this.requestConversation.bind(this)),
        timeout(this.pollingInterval),
        tap(this.handleLoopRes.bind(this)),
        retryWhen(this.loopRetryStrategy.bind(this)),
        repeat(),
        takeUntil(this.stopPolling$)
      )
      .subscribe(() => {
        this.looping = false;
      }, this.handleLoopError.bind(this));
  }

  handleConnectRes(res: any) {
    if (res?.data?.result?.worker && res?.data?.use_mobile) {
      this.handleConnectSuccessRes(res);
    } else {
      this.handleConnectFailRes(res);
    }
  }

  connectRetryStrategy(attempts: any) {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttemp = i + 1;
        if (retryAttemp > this.connectRetryMaxAttempts) {
          return throwError(error);
        }
        return timer(this.connectRetryInterval);
      })
    );
  }

  handleConnectSuccessRes(res: any) {
    this.$store.commit("UPDATE_DUTY_STATUS", true);
    this.$store.commit("UPDATE_MOBILE_PERMISSION", true);

    const now = Date.now();
    if (!this.firstOnlineTime) {
      this.firstOnlineTime = now;
    }
    if (!this.secondOnlineTime) {
      this.secondOnlineTime = now;
    }

    if ((now - this.firstOnlineTime) / 1000 <= 10 * 60) {
      this.onlineCount++;

      if (this.onlineCount >= 8) {
        findServer().subscribe();

        Dialog.alert({
          message: "您的网络可能有异常，如您无法正常接入对话，请及时联系客服",
        });

        this.onlineCount = 0;
      }
    } else {
      this.firstOnlineTime = this.secondOnlineTime;
      this.secondOnlineTime = now;
      this.onlineCount = 0;
    }

    //成功才更新状态
    this.connected = true;
    this.connecting = false;
    this.loop();
  }

  handleConnectFailRes(res: any) {
    console.log("Connect fails", new Date());

    this.$store.commit("UPDATE_MOBILE_PERMISSION", res?.data?.use_mobile === 1);
    if (res?.data?.use_mobile === 0) {
      this.$store.commit(
        "AUTHENTICATE_FAILURE",
        ERROR_TYPES.USE_MOBILE_PERMISSION_FAIL
      );
    } else if (res?.data?.message === "Expired token") {
      this.$store.commit("AUTHENTICATE_FAILURE", ERROR_TYPES.INVALID_TOKEN);
    } else if (res?.data?.result?.worker === false) {
      this.$store.commit("AUTHENTICATE_FAILURE", ERROR_TYPES.WORKER_NOT_ONLINE);
    }

    this.connected = false;
    this.connecting = false;
  }

  handleConnectError({ response }: any) {
    console.log("connect always fails", new Date());

    this.connected = false;
    this.connecting = false;

    this.$store.commit("UPDATE_DUTY_STATUS", false);

    Dialog.confirm({
      message: "因为网络原因您的客服账号已掉线，是否重连?",
    })
      .then(() => {
        this.connecting = false;
        this.connect();
      })
      .catch(() => {});
  }

  getLoopData(): LoopDataV2 | LoopDataV3 {
    return isOnIMUpdateList()
      ? {
          device: "mobile",
          from: this.previousWorkerIndex,
          conversations: this.v3Conversation,
        }
      : {
          _version: 2,
          device: "mobile",
          conversations: this.postConversation,
        };
  }

  requestConversation() {
    return loop(this.getLoopData());
  }

  loopRetryStrategy(attempts: any) {
    console.log("retry loop", new Date());
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttemp = i + 1;
        if (retryAttemp > this.loopRetryMaxAttempts) {
          console.log("error throw", new Date());
          return throwError(error);
        }
        return timer(this.loopRetryInterval);
      })
    );
  }

  handleLoopRes(res: any) {
    if (parseInt(res.data?.success)) {
      this.handleLoopSuccessRes(res);
    } else {
      this.handleLoopFailRes(res);
    }
  }

  handleLoopSuccessRes({ data: res }: any) {
    if (!isOnIMUpdateList()) {
      this.handleLoopSuccessResV2({ data: res });
    } else {
      this.handleLoopSuccessResV3({ data: res });
    }
  }

  handleLoopSuccessResV2({ data: res }: any) {
    const resTalkList = res.data.talklist;
    this.postConversation = this.formartConversations(resTalkList);

    if (!compareObjects(this.previousCons, this.postConversation)) {
      this.previousCons = this.postConversation;

      try {
        const currentTalkList = this.$store.getters.talkList;

        const newTalkList = transformTalkListToFe(resTalkList);

        const talkList = sanitizeNewTalkList(currentTalkList, newTalkList);

        this.$store.commit("UPDATE_TALK_LIST", talkList);
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleLoopSuccessResV3({ data: res }: any) {
    const resTalkList = res.data.talklist;
    const resMessages = res.data.messages;
    this.previousWorkerIndex = res.data.message_total;
    this.v3Conversation = Object.keys(resTalkList);

    try {
      const currentTalkList = this.$store.getters.talkList;

      const newTalkList = transformTalkListToFeV3(resTalkList, resMessages);

      const talkList = sanitizeNewTalkList(currentTalkList, newTalkList);

      this.$store.commit("UPDATE_TALK_LIST", talkList);
    } catch (error) {
      console.log(error);
    }
  }

  handleLoopFailRes(res: any) {
    console.log("Loop fails", new Date());

    const message = res.data?.message || "";

    if (["Worker already got new token", "Expired token"].includes(message)) {
      if (getHostType() !== HostTypes.WeChat) {
        redirectToMobileLogin();
        return;
      }

      this.$store.commit("AUTHENTICATE_FAILURE", ERROR_TYPES.INVALID_TOKEN);
      this.connected = false;
      this.looping = false;
      this.stopPolling$.next();

      return;
    }
  }

  handleLoopError() {
    console.log("handle loop error");
    this.connected = false;
    this.looping = false;
    this.connect();
  }

  formartConversations(data: any) {
    const conversations: any = {};

    if (!data) {
      return conversations;
    }

    Object.keys(data).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        conversations[key] = Number(data[key].total);
      }
    });

    return conversations;
  }

  stop() {
    this.connected = false;
    this.looping = false;
    this.stopPolling$.next();
    this.$store.commit("UPDATE_DUTY_STATUS", false);
  }

  send(msg: any) {
    if (!this.connected) {
      Dialog.alert({
        message: "您已处于离线状态",
      });
    }

    this.sendQueue.push(msg);

    if (this.connected && !this.sending) {
      this.doSendMsg();
    }
  }

  doSendMsg() {
    if (!this.sendQueue.length) {
      this.sending = false;
      return;
    }

    const msg = this.sendQueue.pop();
    this.sending = true;

    sendMessage(msg).subscribe(
      () => {
        this.$store.commit("UPDATE_SEND_STATUS", {
          uid: msg.uid,
          cmicrotime: msg.cmicrotime,
          status: true,
        });
        this.doSendMsg();
      },
      () => {
        this.$store.commit("UPDATE_SEND_STATUS", {
          uid: msg.uid,
          cmicrotime: msg.cmicrotime,
          status: false,
        });
      }
    );
  }
}
