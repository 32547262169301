<template>
  <div class="container mx-auto text-center mt-28">
    <span class="iconfont"> &#xe606; </span>
    <h2 class="text-xl font-bold mt-5">网络连接失败</h2>
    <span class="mt-3 block">请返回重新进入，如持续遇到该问题请联系客服。</span>
  </div>
</template>

<script>
export default {
  name: "Expire",
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  margin-top: 7rem;
  text-align: center;
}

.iconfont {
  font-size: 3.75rem;
  line-height: 1;
  color: #eeb142;
}

h2 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.25rem;
}
</style>

