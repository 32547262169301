<template>
  <van-dialog
    title="编辑标签"
    v-model:show="showDialog"
    show-cancel-button
    confirmButtonText="保存"
    @close="close"
    @confirm="save"
  >
    <div class="container">
      <tag
        v-for="tag in currentTagList"
        :tag="tag"
        :key="tag.id"
        @click="removeTag(tag)"
      />

      <tag :tag="deleteTag" @click="showTagSelectorPopup = true" />
    </div>
  </van-dialog>

  <van-popup
    v-model:show="showTagSelectorPopup"
    position="bottom"
    :style="{ height: '50%' }"
    teleport="body"
  >
    <div class="tag-list">
      <tag
        v-for="tag in filterdTagsList"
        :tag="tag"
        :key="tag.id"
        @click="addTag(tag)"
      />
    </div>
  </van-popup>
</template>

<script>
import { getUserTag, getTagsList, setTag } from "@/api/common";
import { defineComponent } from "vue";
import Tag from "@/components/Tag.vue";
import { Popup, Dialog } from "vant";
import { combineLatest } from "rxjs";
import { Toast } from "vant";

export default defineComponent({
  props: {
    uid: String,
  },

  emits: ["close"],

  components: {
    Tag,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      showDialog: true,
      tagList: [],
      filterdTagsList: [],
      currentTagList: [],
      deleteTag: {
        text: "+添加",
        color: "808080",
      },
      showTagSelectorPopup: false,
    };
  },

  beforeMount() {
    if (!this.uid) return;

    combineLatest([getTagsList(), getUserTag(this.uid)]).subscribe(
      ([tagList, currentTagList]) => {
        this.tagList = tagList;
        this.currentTagList = currentTagList;

        this.filterTag();
      }
    );
  },

  methods: {
    close() {
      this.$emit("close");
    },

    addTag(tag) {
      this.currentTagList.push(tag);
      this.filterTag();
    },

    removeTag(tag) {
      const tags = this.currentTagList;
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].text === tag.text) {
          tags.splice(i, 1);
          break;
        }
      }
    },

    filterTag() {
      this.filterdTagsList = this.tagList.filter((item) => {
        const text = item.text;
        const currentTagList = this.currentTagList;

        for (let i = 0; i < currentTagList.length; i++) {
          let tag = currentTagList[i];
          if (text === tag.text) return false;
        }

        return true;
      });
    },

    save() {
      const arr = [];
      this.currentTagList.forEach((tag) => {
        arr.push(tag.id);
      });
      let info = {
        uid: this.uid,
        tags: arr.join(","),
      };

      setTag(info).subscribe(
        () => {
          Toast.success("保存成功");
          this.$emit("close");
        },
        () => {
          Toast.error("保存失败");
        }
      );
    },
  },
});
</script>

<style lang="less" scoped>
.container {
  padding: 10px;
  height: 200px;
}
.tag-list {
  padding: 5px;
}
</style>
