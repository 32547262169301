import { Message } from "@/types/Message";
import { Talk } from "@/types/Talk";

export function isAIPreview(talk: Talk) {
  const con = talk.conversation.slice();
  return con.some((item) => {
    return item.type === "ai_cs" && item.is_preview;
  });
}

export function getAIReplyStatus(
  talk: Talk,
  talkIsPreview: boolean
): boolean | "closed" | "void" {
  const con = talk.conversation.slice();
  if (talkIsPreview) {
    return getPreviewAIReplyStatus(con);
  } else {
    return getNormalAIReplyStatus(con);
  }
}

function getPreviewAIReplyStatus(con: any[]) {
  let item: Message;

  while (con.length !== 0) {
    item = con.pop();

    if (item.type === "ai_status" && item.scope !== "global") {
      return item["ai_status"] === 1;
    }

    if (item.type === "ai_cs") {
      return true;
    }
  }

  return "void";
}

function getNormalAIReplyStatus(con: any[]) {
  let item: Message;

  while (con.length !== 0) {
    item = con.pop();

    if (item.type === "ai_status" || item.cmd === "ai_status") {
      if (item.scope === "global" && item["ai_status"] === 0) {
        return "closed";
      }

      return item["ai_status"] === 1;
    }

    if (item.type === "ai_cs") {
      return true;
    }
  }

  return "void";
}
