/**
 * for (let i = 0; i < data.length; i++) {
  const province = data[i];
  province_list[province.code] = province.name;
  const cities = province.children;
  if (!cities) continue;
  for (let j = 0; j < cities.length; j++) {
    const city = cities[j];
    city_list[city.code] = city.name;
    const counties = city.children;
    if (!counties) continue;
    for (let k = 0; k < counties.length; k++) {
      const county = counties[k];
      county_list[county.code] = county.name;
    }
  }
}
 */

export const areaList = {
  province_list: {
    "110000": "北京",
    "120000": "天津",
    "130000": "河北",
    "140000": "山西",
    "150000": "内蒙古",
    "210000": "辽宁",
    "220000": "吉林",
    "230000": "黑龙江",
    "310000": "上海",
    "320000": "江苏",
    "330000": "浙江",
    "340000": "安徽",
    "350000": "福建",
    "360000": "江西",
    "370000": "山东",
    "410000": "河南",
    "420000": "湖北",
    "430000": "湖南",
    "440000": "广东",
    "450000": "广西",
    "460000": "海南",
    "500000": "重庆",
    "510000": "四川",
    "520000": "贵州",
    "530000": "云南",
    "540000": "西藏",
    "610000": "陕西",
    "620000": "甘肃",
    "630000": "青海",
    "640000": "宁夏",
    "650000": "新疆",
    "710000": "台湾",
    "810000": "香港",
    "820000": "澳门",
    "910000": "国外",
  },

  city_list: {
    "110100": "北京",
    "120100": "天津",
    "130100": "石家庄",
    "130200": "唐山",
    "130300": "秦皇岛",
    "130400": "邯郸",
    "130500": "邢台",
    "130600": "保定",
    "130700": "张家口",
    "130800": "承德",
    "130900": "沧州",
    "131000": "廊坊",
    "131100": "衡水",
    "140100": "太原",
    "140200": "大同",
    "140300": "阳泉",
    "140400": "长治",
    "140500": "晋城",
    "140600": "朔州",
    "140700": "晋中",
    "140800": "运城",
    "140900": "忻州",
    "141000": "临汾",
    "141100": "吕梁",
    "150100": "呼和浩特",
    "150200": "包头",
    "150300": "乌海",
    "150400": "赤峰",
    "150500": "通辽",
    "150600": "鄂尔多斯",
    "150700": "呼伦贝尔",
    "150800": "巴彦淖尔",
    "150900": "乌兰察布",
    "152200": "兴安",
    "152500": "锡林郭勒",
    "152900": "阿拉善",
    "210100": "沈阳",
    "210200": "大连",
    "210300": "鞍山",
    "210400": "抚顺",
    "210500": "本溪",
    "210600": "丹东",
    "210700": "锦州",
    "210800": "营口",
    "210900": "阜新",
    "211000": "辽阳",
    "211100": "盘锦",
    "211200": "铁岭",
    "211300": "朝阳",
    "211400": "葫芦岛",
    "220100": "长春",
    "220200": "吉林市",
    "220300": "四平",
    "220400": "辽源",
    "220500": "通化",
    "220600": "白山",
    "220700": "松原",
    "220800": "白城",
    "222400": "延边",
    "230100": "哈尔滨",
    "230200": "齐齐哈尔",
    "230300": "鸡西",
    "230400": "鹤岗",
    "230500": "双鸭山",
    "230600": "大庆",
    "230700": "伊春",
    "230800": "佳木斯",
    "230900": "七台河",
    "231000": "牡丹江",
    "231100": "黑河",
    "231200": "绥化",
    "232700": "大兴安岭",
    "310100": "上海",
    "320100": "南京",
    "320200": "无锡",
    "320300": "徐州",
    "320400": "常州",
    "320500": "苏州",
    "320600": "南通",
    "320700": "连云港",
    "320800": "淮安",
    "320900": "盐城",
    "321000": "扬州",
    "321100": "镇江",
    "321200": "泰州",
    "321300": "宿迁",
    "330100": "杭州",
    "330200": "宁波",
    "330300": "温州",
    "330400": "嘉兴",
    "330500": "湖州",
    "330600": "绍兴",
    "330700": "金华",
    "330800": "衢州",
    "330900": "舟山",
    "331000": "台州",
    "331100": "丽水",
    "340100": "合肥",
    "340200": "芜湖",
    "340300": "蚌埠",
    "340400": "淮南",
    "340500": "马鞍山",
    "340600": "淮北",
    "340700": "铜陵",
    "340800": "安庆",
    "341000": "黄山",
    "341100": "滁州",
    "341200": "阜阳",
    "341300": "宿州",
    "341500": "六安",
    "341600": "亳州",
    "341700": "池州",
    "341800": "宣城",
    "350100": "福州",
    "350200": "厦门",
    "350300": "莆田",
    "350400": "三明",
    "350500": "泉州",
    "350600": "漳州",
    "350700": "南平",
    "350800": "龙岩",
    "350900": "宁德",
    "360100": "南昌",
    "360200": "景德镇",
    "360300": "萍乡",
    "360400": "九江",
    "360500": "新余",
    "360600": "鹰潭",
    "360700": "赣州",
    "360800": "吉安",
    "360900": "宜春",
    "361000": "抚州",
    "361100": "上饶",
    "370100": "济南",
    "370200": "青岛",
    "370300": "淄博",
    "370400": "枣庄",
    "370500": "东营",
    "370600": "烟台",
    "370700": "潍坊",
    "370800": "济宁",
    "370900": "泰安",
    "371000": "威海",
    "371100": "日照",
    "371300": "临沂",
    "371400": "德州",
    "371500": "聊城",
    "371600": "滨州",
    "371700": "菏泽",
    "410100": "郑州",
    "410200": "开封",
    "410300": "洛阳",
    "410400": "平顶山",
    "410500": "安阳",
    "410600": "鹤壁",
    "410700": "新乡",
    "410800": "焦作",
    "410900": "濮阳",
    "411000": "许昌",
    "411100": "漯河",
    "411200": "三门峡",
    "411300": "南阳",
    "411400": "商丘",
    "411500": "信阳",
    "411600": "周口",
    "411700": "驻马店",
    "419000": "省辖县",
    "420100": "武汉",
    "420200": "黄石",
    "420300": "十堰",
    "420500": "宜昌",
    "420600": "襄阳",
    "420700": "鄂州",
    "420800": "荆门",
    "420900": "孝感",
    "421000": "荆州",
    "421100": "黄冈",
    "421200": "咸宁",
    "421300": "随州",
    "422800": "恩施",
    "429000": "省辖县",
    "430100": "长沙",
    "430200": "株洲",
    "430300": "湘潭",
    "430400": "衡阳",
    "430500": "邵阳",
    "430600": "岳阳",
    "430700": "常德",
    "430800": "张家界",
    "430900": "益阳",
    "431000": "郴州",
    "431100": "永州",
    "431200": "怀化",
    "431300": "娄底",
    "433100": "湘西",
    "440100": "广州",
    "440200": "韶关",
    "440300": "深圳",
    "440400": "珠海",
    "440500": "汕头",
    "440600": "佛山",
    "440700": "江门",
    "440800": "湛江",
    "440900": "茂名",
    "441200": "肇庆",
    "441300": "惠州",
    "441400": "梅州",
    "441500": "汕尾",
    "441600": "河源",
    "441700": "阳江",
    "441800": "清远",
    "441900": "东莞",
    "442000": "中山",
    "445100": "潮州",
    "445200": "揭阳",
    "445300": "云浮",
    "450100": "南宁",
    "450200": "柳州",
    "450300": "桂林",
    "450400": "梧州",
    "450500": "北海",
    "450600": "防城港",
    "450700": "钦州",
    "450800": "贵港",
    "450900": "玉林",
    "451000": "百色",
    "451100": "贺州",
    "451200": "河池",
    "451300": "来宾",
    "451400": "崇左",
    "460100": "海口",
    "460200": "三亚",
    "460300": "三沙",
    "460400": "儋州",
    "469000": "省辖县",
    "500100": "重庆城区",
    "500200": "重庆郊县",
    "510100": "成都",
    "510300": "自贡",
    "510400": "攀枝花",
    "510500": "泸州",
    "510600": "德阳",
    "510700": "绵阳",
    "510800": "广元",
    "510900": "遂宁",
    "511000": "内江",
    "511100": "乐山",
    "511300": "南充",
    "511400": "眉山",
    "511500": "宜宾",
    "511600": "广安",
    "511700": "达州",
    "511800": "雅安",
    "511900": "巴中",
    "512000": "资阳",
    "513200": "阿坝",
    "513300": "甘孜",
    "513400": "凉山",
    "520100": "贵阳",
    "520200": "六盘水",
    "520300": "遵义",
    "520400": "安顺",
    "520500": "毕节",
    "520600": "铜仁",
    "522300": "黔西南",
    "522600": "黔东南",
    "522700": "黔南",
    "530100": "昆明",
    "530300": "曲靖",
    "530400": "玉溪",
    "530500": "保山",
    "530600": "昭通",
    "530700": "丽江",
    "530800": "普洱",
    "530900": "临沧",
    "532300": "楚雄",
    "532500": "红河",
    "532600": "文山",
    "532800": "西双版纳",
    "532900": "大理",
    "533100": "德宏",
    "533300": "怒江",
    "533400": "迪庆",
    "540100": "拉萨",
    "540200": "日喀则",
    "540300": "昌都",
    "540400": "林芝",
    "540500": "山南",
    "540600": "那曲",
    "542500": "阿里",
    "610100": "西安",
    "610200": "铜川",
    "610300": "宝鸡",
    "610400": "咸阳",
    "610500": "渭南",
    "610600": "延安",
    "610700": "汉中",
    "610800": "榆林",
    "610900": "安康",
    "611000": "商洛",
    "620100": "兰州",
    "620200": "嘉峪关",
    "620300": "金昌",
    "620400": "白银",
    "620500": "天水",
    "620600": "武威",
    "620700": "张掖",
    "620800": "平凉",
    "620900": "酒泉",
    "621000": "庆阳",
    "621100": "定西",
    "621200": "陇南",
    "622900": "临夏",
    "623000": "甘南",
    "630100": "西宁",
    "630200": "海东",
    "632200": "海北",
    "632300": "黄南",
    "632500": "海南",
    "632600": "果洛",
    "632700": "玉树",
    "632800": "海西",
    "640100": "银川",
    "640200": "石嘴山",
    "640300": "吴忠",
    "640400": "固原",
    "640500": "中卫",
    "650100": "乌鲁木齐",
    "650200": "克拉玛依",
    "650400": "吐鲁番",
    "650500": "哈密",
    "652300": "昌吉",
    "652700": "博尔塔拉",
    "652800": "巴音郭楞",
    "652900": "阿克苏",
    "653000": "克孜勒苏",
    "653100": "喀什",
    "653200": "和田",
    "654000": "伊犁",
    "654200": "塔城",
    "654300": "阿勒泰",
    "659000": "省辖县",
    "710100": "台北",
    "710200": "高雄",
    "710300": "台南",
    "710400": "台中",
    "710600": "南投",
    "710700": "基隆",
    "710800": "新竹市",
    "710900": "嘉义市",
    "711100": "新北",
    "711200": "宜兰",
    "711300": "新竹县",
    "711400": "桃园",
    "711500": "苗栗",
    "711700": "彰化",
    "711900": "嘉义县",
    "712100": "云林",
    "712400": "屏东",
    "712500": "台东",
    "712600": "花莲",
    "712700": "澎湖",
    "810000": "香港",
    "820000": "澳门",
    "910000": "国外",
  },

  county_list: {
    "110101": "东城",
    "110102": "西城",
    "110105": "朝阳",
    "110106": "丰台",
    "110107": "石景山",
    "110108": "海淀",
    "110109": "门头沟",
    "110111": "房山",
    "110112": "通州",
    "110113": "顺义",
    "110114": "昌平",
    "110115": "大兴",
    "110116": "怀柔",
    "110117": "平谷",
    "110118": "密云",
    "110119": "延庆",
    "120101": "和平",
    "120102": "河东",
    "120103": "河西",
    "120104": "南开",
    "120105": "河北",
    "120106": "红桥",
    "120110": "东丽",
    "120111": "西青",
    "120112": "津南",
    "120113": "北辰",
    "120114": "武清",
    "120115": "宝坻",
    "120116": "滨海新区",
    "120117": "宁河",
    "120118": "静海",
    "120119": "蓟州",
    "130102": "长安",
    "130104": "桥西",
    "130105": "新华",
    "130107": "井陉矿区",
    "130108": "裕华",
    "130109": "藁城",
    "130110": "鹿泉",
    "130111": "栾城",
    "130121": "井陉",
    "130123": "正定",
    "130125": "行唐",
    "130126": "灵寿",
    "130127": "高邑",
    "130128": "深泽",
    "130129": "赞皇",
    "130130": "无极",
    "130131": "平山",
    "130132": "元氏",
    "130133": "赵县",
    "130181": "辛集",
    "130183": "晋州",
    "130184": "新乐",
    "130202": "路南",
    "130203": "路北",
    "130204": "古冶",
    "130205": "开平",
    "130207": "丰南",
    "130208": "丰润",
    "130209": "曹妃甸",
    "130224": "滦南",
    "130225": "乐亭",
    "130227": "迁西",
    "130229": "玉田",
    "130281": "遵化",
    "130283": "迁安",
    "130284": "滦州",
    "130302": "海港",
    "130303": "山海关",
    "130304": "北戴河",
    "130306": "抚宁",
    "130321": "青龙",
    "130322": "昌黎",
    "130324": "卢龙",
    "130402": "邯山",
    "130403": "丛台",
    "130404": "复兴",
    "130406": "峰峰矿区",
    "130407": "肥乡",
    "130408": "永年",
    "130423": "临漳",
    "130424": "成安",
    "130425": "大名",
    "130426": "涉县",
    "130427": "磁县",
    "130430": "邱县",
    "130431": "鸡泽",
    "130432": "广平",
    "130433": "馆陶",
    "130434": "魏县",
    "130435": "曲周",
    "130481": "武安",
    "130502": "襄都",
    "130503": "信都",
    "130505": "任泽",
    "130506": "南和",
    "130522": "临城",
    "130523": "内丘",
    "130524": "柏乡",
    "130525": "隆尧",
    "130528": "宁晋",
    "130529": "巨鹿",
    "130530": "新河",
    "130531": "广宗",
    "130532": "平乡",
    "130533": "威县",
    "130534": "清河",
    "130535": "临西",
    "130581": "南宫",
    "130582": "沙河",
    "130602": "竞秀",
    "130606": "莲池",
    "130607": "满城",
    "130608": "清苑",
    "130609": "徐水",
    "130623": "涞水",
    "130624": "阜平",
    "130626": "定兴",
    "130627": "唐县",
    "130628": "高阳",
    "130629": "容城",
    "130630": "涞源",
    "130631": "望都",
    "130632": "安新",
    "130633": "易县",
    "130634": "曲阳",
    "130635": "蠡县",
    "130636": "顺平",
    "130637": "博野",
    "130638": "雄县",
    "130681": "涿州",
    "130682": "定州",
    "130683": "安国",
    "130684": "高碑店",
    "130702": "桥东",
    "130703": "桥西",
    "130705": "宣化",
    "130706": "下花园",
    "130708": "万全",
    "130709": "崇礼",
    "130722": "张北",
    "130723": "康保",
    "130724": "沽源",
    "130725": "尚义",
    "130726": "蔚县",
    "130727": "阳原",
    "130728": "怀安",
    "130730": "怀来",
    "130731": "涿鹿",
    "130732": "赤城",
    "130802": "双桥",
    "130803": "双滦",
    "130804": "鹰手营子矿区",
    "130821": "承德县",
    "130822": "兴隆",
    "130824": "滦平",
    "130825": "隆化",
    "130826": "丰宁",
    "130827": "宽城",
    "130828": "围场",
    "130881": "平泉",
    "130902": "新华",
    "130903": "运河",
    "130921": "沧县",
    "130922": "青县",
    "130923": "东光",
    "130924": "海兴",
    "130925": "盐山",
    "130926": "肃宁",
    "130927": "南皮",
    "130928": "吴桥",
    "130929": "献县",
    "130930": "孟村",
    "130981": "泊头",
    "130982": "任丘",
    "130983": "黄骅",
    "130984": "河间",
    "131002": "安次",
    "131003": "广阳",
    "131022": "固安",
    "131023": "永清",
    "131024": "香河",
    "131025": "大城",
    "131026": "文安",
    "131028": "大厂",
    "131081": "霸州",
    "131082": "三河",
    "131102": "桃城",
    "131103": "冀州",
    "131121": "枣强",
    "131122": "武邑",
    "131123": "武强",
    "131124": "饶阳",
    "131125": "安平",
    "131126": "故城",
    "131127": "景县",
    "131128": "阜城",
    "131182": "深州",
    "140105": "小店",
    "140106": "迎泽",
    "140107": "杏花岭",
    "140108": "尖草坪",
    "140109": "万柏林",
    "140110": "晋源",
    "140121": "清徐",
    "140122": "阳曲",
    "140123": "娄烦",
    "140181": "古交",
    "140212": "新荣",
    "140213": "平城",
    "140214": "云冈",
    "140215": "云州",
    "140221": "阳高",
    "140222": "天镇",
    "140223": "广灵",
    "140224": "灵丘",
    "140225": "浑源",
    "140226": "左云",
    "140302": "城区",
    "140303": "矿区",
    "140311": "郊区",
    "140321": "平定",
    "140322": "盂县",
    "140403": "潞州",
    "140404": "上党",
    "140405": "屯留",
    "140406": "潞城",
    "140423": "襄垣",
    "140425": "平顺",
    "140426": "黎城",
    "140427": "壶关",
    "140428": "长子",
    "140429": "武乡",
    "140430": "沁县",
    "140431": "沁源",
    "140502": "城区",
    "140521": "沁水",
    "140522": "阳城",
    "140524": "陵川",
    "140525": "泽州",
    "140581": "高平",
    "140602": "朔城",
    "140603": "平鲁",
    "140621": "山阴",
    "140622": "应县",
    "140623": "右玉",
    "140681": "怀仁",
    "140702": "榆次",
    "140703": "太谷",
    "140721": "榆社",
    "140722": "左权",
    "140723": "和顺",
    "140724": "昔阳",
    "140725": "寿阳",
    "140727": "祁县",
    "140728": "平遥",
    "140729": "灵石",
    "140781": "介休",
    "140802": "盐湖",
    "140821": "临猗",
    "140822": "万荣",
    "140823": "闻喜",
    "140824": "稷山",
    "140825": "新绛",
    "140826": "绛县",
    "140827": "垣曲",
    "140828": "夏县",
    "140829": "平陆",
    "140830": "芮城",
    "140881": "永济",
    "140882": "河津",
    "140902": "忻府",
    "140921": "定襄",
    "140922": "五台",
    "140923": "代县",
    "140924": "繁峙",
    "140925": "宁武",
    "140926": "静乐",
    "140927": "神池",
    "140928": "五寨",
    "140929": "岢岚",
    "140930": "河曲",
    "140931": "保德",
    "140932": "偏关",
    "140981": "原平",
    "141002": "尧都",
    "141021": "曲沃",
    "141022": "翼城",
    "141023": "襄汾",
    "141024": "洪洞",
    "141025": "古县",
    "141026": "安泽",
    "141027": "浮山",
    "141028": "吉县",
    "141029": "乡宁",
    "141030": "大宁",
    "141031": "隰县",
    "141032": "永和",
    "141033": "蒲县",
    "141034": "汾西",
    "141081": "侯马",
    "141082": "霍州",
    "141102": "离石",
    "141121": "文水",
    "141122": "交城",
    "141123": "兴县",
    "141124": "临县",
    "141125": "柳林",
    "141126": "石楼",
    "141127": "岚县",
    "141128": "方山",
    "141129": "中阳",
    "141130": "交口",
    "141181": "孝义",
    "141182": "汾阳",
    "150102": "新城",
    "150103": "回民",
    "150104": "玉泉",
    "150105": "赛罕",
    "150121": "土默特左旗",
    "150122": "托克托",
    "150123": "和林格尔",
    "150124": "清水河",
    "150125": "武川",
    "150202": "东河",
    "150203": "昆都仑",
    "150204": "青山",
    "150205": "石拐",
    "150206": "白云鄂博矿区",
    "150207": "九原",
    "150221": "土默特右旗",
    "150222": "固阳",
    "150223": "达尔罕茂明安联合旗",
    "150302": "海勃湾",
    "150303": "海南",
    "150304": "乌达",
    "150402": "红山",
    "150403": "元宝山",
    "150404": "松山",
    "150421": "阿鲁科尔沁旗",
    "150422": "巴林左旗",
    "150423": "巴林右旗",
    "150424": "林西",
    "150425": "克什克腾旗",
    "150426": "翁牛特旗",
    "150428": "喀喇沁旗",
    "150429": "宁城",
    "150430": "敖汉旗",
    "150502": "科尔沁",
    "150521": "科尔沁左翼中旗",
    "150522": "科尔沁左翼后旗",
    "150523": "开鲁",
    "150524": "库伦旗",
    "150525": "奈曼旗",
    "150526": "扎鲁特旗",
    "150581": "霍林郭勒",
    "150602": "东胜",
    "150603": "康巴什",
    "150621": "达拉特旗",
    "150622": "准格尔旗",
    "150623": "鄂托克前旗",
    "150624": "鄂托克旗",
    "150625": "杭锦旗",
    "150626": "乌审旗",
    "150627": "伊金霍洛旗",
    "150702": "海拉尔",
    "150703": "扎赉诺尔区",
    "150721": "阿荣旗",
    "150722": "莫力达瓦",
    "150723": "鄂伦春自治旗",
    "150724": "鄂温克族自治旗",
    "150725": "陈巴尔虎旗",
    "150726": "新巴尔虎左旗",
    "150727": "新巴尔虎右旗",
    "150781": "满洲里",
    "150782": "牙克石",
    "150783": "扎兰屯",
    "150784": "额尔古纳",
    "150785": "根河",
    "150802": "临河",
    "150821": "五原",
    "150822": "磴口",
    "150823": "乌拉特前旗",
    "150824": "乌拉特中旗",
    "150825": "乌拉特后旗",
    "150826": "杭锦后旗",
    "150902": "集宁",
    "150921": "卓资",
    "150922": "化德",
    "150923": "商都",
    "150924": "兴和",
    "150925": "凉城",
    "150926": "察哈尔右翼前旗",
    "150927": "察哈尔右翼中旗",
    "150928": "察哈尔右翼后旗",
    "150929": "四子王旗",
    "150981": "丰镇",
    "152201": "乌兰浩特",
    "152202": "阿尔山",
    "152221": "科尔沁右翼前旗",
    "152222": "科尔沁右翼中旗",
    "152223": "扎赉特旗",
    "152224": "突泉",
    "152501": "二连浩特",
    "152502": "锡林浩特",
    "152522": "阿巴嘎旗",
    "152523": "苏尼特左旗",
    "152524": "苏尼特右旗",
    "152525": "东乌珠穆沁旗",
    "152526": "西乌珠穆沁旗",
    "152527": "太仆寺旗",
    "152528": "镶黄旗",
    "152529": "正镶白旗",
    "152530": "正蓝旗",
    "152531": "多伦",
    "152921": "阿拉善左旗",
    "152922": "阿拉善右旗",
    "152923": "额济纳旗",
    "210102": "和平",
    "210103": "沈河",
    "210104": "大东",
    "210105": "皇姑",
    "210106": "铁西",
    "210111": "苏家屯",
    "210112": "浑南",
    "210113": "沈北新区",
    "210114": "于洪",
    "210115": "辽中",
    "210123": "康平",
    "210124": "法库",
    "210181": "新民",
    "210202": "中山",
    "210203": "西岗",
    "210204": "沙河口",
    "210211": "甘井子",
    "210212": "旅顺口",
    "210213": "金州",
    "210214": "普兰店",
    "210224": "长海",
    "210281": "瓦房店",
    "210283": "庄河",
    "210302": "铁东",
    "210303": "铁西",
    "210304": "立山",
    "210311": "千山",
    "210321": "台安",
    "210323": "岫岩",
    "210381": "海城",
    "210402": "新抚",
    "210403": "东洲",
    "210404": "望花",
    "210411": "顺城",
    "210421": "抚顺县",
    "210422": "新宾",
    "210423": "清原",
    "210502": "平山",
    "210503": "溪湖",
    "210504": "明山",
    "210505": "南芬",
    "210521": "本溪满族自治县",
    "210522": "桓仁",
    "210602": "元宝",
    "210603": "振兴",
    "210604": "振安",
    "210624": "宽甸",
    "210681": "东港",
    "210682": "凤城",
    "210702": "古塔",
    "210703": "凌河",
    "210711": "太和",
    "210726": "黑山",
    "210727": "义县",
    "210781": "凌海",
    "210782": "北镇",
    "210802": "站前",
    "210803": "西市",
    "210804": "鲅鱼圈",
    "210811": "老边",
    "210881": "盖州",
    "210882": "大石桥",
    "210902": "海州",
    "210903": "新邱",
    "210904": "太平",
    "210905": "清河门",
    "210911": "细河",
    "210921": "阜新蒙古族自治县",
    "210922": "彰武",
    "211002": "白塔",
    "211003": "文圣",
    "211004": "宏伟",
    "211005": "弓长岭",
    "211011": "太子河",
    "211021": "辽阳县",
    "211081": "灯塔",
    "211102": "双台子",
    "211103": "兴隆台",
    "211104": "大洼",
    "211122": "盘山",
    "211202": "银州",
    "211204": "清河",
    "211221": "铁岭县",
    "211223": "西丰",
    "211224": "昌图",
    "211281": "调兵山",
    "211282": "开原",
    "211302": "双塔",
    "211303": "龙城",
    "211321": "朝阳县",
    "211322": "建平",
    "211324": "喀喇沁左翼",
    "211381": "北票",
    "211382": "凌源",
    "211402": "连山",
    "211403": "龙港",
    "211404": "南票",
    "211421": "绥中",
    "211422": "建昌",
    "211481": "兴城",
    "220102": "南关",
    "220103": "宽城",
    "220104": "朝阳",
    "220105": "二道",
    "220106": "绿园",
    "220112": "双阳",
    "220113": "九台",
    "220122": "农安",
    "220182": "榆树",
    "220183": "德惠",
    "220184": "公主岭",
    "220202": "昌邑",
    "220203": "龙潭",
    "220204": "船营",
    "220211": "丰满",
    "220221": "永吉",
    "220281": "蛟河",
    "220282": "桦甸",
    "220283": "舒兰",
    "220284": "磐石",
    "220302": "铁西",
    "220303": "铁东",
    "220322": "梨树",
    "220323": "伊通",
    "220382": "双辽",
    "220402": "龙山",
    "220403": "西安",
    "220421": "东丰",
    "220422": "东辽",
    "220502": "东昌",
    "220503": "二道江",
    "220521": "通化县",
    "220523": "辉南",
    "220524": "柳河",
    "220581": "梅河口",
    "220582": "集安",
    "220602": "浑江",
    "220605": "江源",
    "220621": "抚松",
    "220622": "靖宇",
    "220623": "长白",
    "220681": "临江",
    "220702": "宁江",
    "220721": "前郭尔罗斯",
    "220722": "长岭",
    "220723": "乾安",
    "220781": "扶余",
    "220802": "洮北",
    "220821": "镇赉",
    "220822": "通榆",
    "220881": "洮南",
    "220882": "大安",
    "222401": "延吉",
    "222402": "图们",
    "222403": "敦化",
    "222404": "珲春",
    "222405": "龙井",
    "222406": "和龙",
    "222424": "汪清",
    "222426": "安图",
    "230102": "道里",
    "230103": "南岗",
    "230104": "道外",
    "230108": "平房",
    "230109": "松北",
    "230110": "香坊",
    "230111": "呼兰",
    "230112": "阿城",
    "230113": "双城",
    "230123": "依兰",
    "230124": "方正",
    "230125": "宾县",
    "230126": "巴彦",
    "230127": "木兰",
    "230128": "通河",
    "230129": "延寿",
    "230183": "尚志",
    "230184": "五常",
    "230202": "龙沙",
    "230203": "建华",
    "230204": "铁锋",
    "230205": "昂昂溪",
    "230206": "富拉尔基区",
    "230207": "碾子山",
    "230208": "梅里斯",
    "230221": "龙江",
    "230223": "依安",
    "230224": "泰来",
    "230225": "甘南",
    "230227": "富裕",
    "230229": "克山",
    "230230": "克东",
    "230231": "拜泉",
    "230281": "讷河",
    "230302": "鸡冠",
    "230303": "恒山",
    "230304": "滴道",
    "230305": "梨树",
    "230306": "城子河",
    "230307": "麻山",
    "230321": "鸡东",
    "230381": "虎林",
    "230382": "密山",
    "230402": "向阳",
    "230403": "工农",
    "230404": "南山",
    "230405": "兴安",
    "230406": "东山",
    "230407": "兴山",
    "230421": "萝北",
    "230422": "绥滨",
    "230502": "尖山",
    "230503": "岭东",
    "230505": "四方台",
    "230506": "宝山",
    "230521": "集贤",
    "230522": "友谊",
    "230523": "宝清",
    "230524": "饶河",
    "230602": "萨尔图",
    "230603": "龙凤",
    "230604": "让胡路",
    "230605": "红岗",
    "230606": "大同",
    "230621": "肇州",
    "230622": "肇源",
    "230623": "林甸",
    "230624": "杜尔伯特",
    "230717": "伊美",
    "230718": "乌翠",
    "230719": "友好",
    "230722": "嘉荫",
    "230723": "汤旺",
    "230724": "丰林",
    "230725": "大箐山",
    "230726": "南岔",
    "230751": "金林",
    "230781": "铁力",
    "230803": "向阳",
    "230804": "前进",
    "230805": "东风",
    "230811": "郊区",
    "230822": "桦南",
    "230826": "桦川",
    "230828": "汤原",
    "230881": "同江",
    "230882": "富锦",
    "230883": "抚远",
    "230902": "新兴",
    "230903": "桃山",
    "230904": "茄子河",
    "230921": "勃利",
    "231002": "东安",
    "231003": "阳明",
    "231004": "爱民",
    "231005": "西安",
    "231025": "林口",
    "231081": "绥芬河",
    "231083": "海林",
    "231084": "宁安",
    "231085": "穆棱",
    "231086": "东宁",
    "231102": "爱辉",
    "231123": "逊克",
    "231124": "孙吴",
    "231181": "北安",
    "231182": "五大连池",
    "231183": "嫩江",
    "231202": "北林",
    "231221": "望奎",
    "231222": "兰西",
    "231223": "青冈",
    "231224": "庆安",
    "231225": "明水",
    "231226": "绥棱",
    "231281": "安达",
    "231282": "肇东",
    "231283": "海伦",
    "232701": "漠河",
    "232718": "加格达奇区",
    "232721": "呼玛",
    "232722": "塔河",
    "310101": "黄浦",
    "310104": "徐汇",
    "310105": "长宁",
    "310106": "静安",
    "310107": "普陀",
    "310109": "虹口",
    "310110": "杨浦",
    "310112": "闵行",
    "310113": "宝山",
    "310114": "嘉定",
    "310115": "浦东新区",
    "310116": "金山",
    "310117": "松江",
    "310118": "青浦",
    "310120": "奉贤",
    "310151": "崇明",
    "320102": "玄武",
    "320104": "秦淮",
    "320105": "建邺",
    "320106": "鼓楼",
    "320111": "浦口",
    "320113": "栖霞",
    "320114": "雨花台",
    "320115": "江宁",
    "320116": "六合",
    "320117": "溧水",
    "320118": "高淳",
    "320205": "锡山",
    "320206": "惠山",
    "320211": "滨湖",
    "320213": "梁溪",
    "320214": "新吴",
    "320281": "江阴",
    "320282": "宜兴",
    "320302": "鼓楼",
    "320303": "云龙",
    "320305": "贾汪",
    "320311": "泉山",
    "320312": "铜山",
    "320321": "丰县",
    "320322": "沛县",
    "320324": "睢宁",
    "320381": "新沂",
    "320382": "邳州",
    "320402": "天宁",
    "320404": "钟楼",
    "320411": "新北",
    "320412": "武进",
    "320413": "金坛",
    "320481": "溧阳",
    "320505": "虎丘",
    "320506": "吴中",
    "320507": "相城",
    "320508": "姑苏",
    "320509": "吴江",
    "320581": "常熟",
    "320582": "张家港",
    "320583": "昆山",
    "320585": "太仓",
    "320612": "通州",
    "320613": "崇川",
    "320614": "海门",
    "320623": "如东",
    "320681": "启东",
    "320682": "如皋",
    "320685": "海安",
    "320703": "连云",
    "320706": "海州",
    "320707": "赣榆",
    "320722": "东海",
    "320723": "灌云",
    "320724": "灌南",
    "320803": "淮安区",
    "320804": "淮阴",
    "320812": "清江浦",
    "320813": "洪泽",
    "320826": "涟水",
    "320830": "盱眙",
    "320831": "金湖",
    "320902": "亭湖",
    "320903": "盐都",
    "320904": "大丰",
    "320921": "响水",
    "320922": "滨海",
    "320923": "阜宁",
    "320924": "射阳",
    "320925": "建湖",
    "320981": "东台",
    "321002": "广陵",
    "321003": "邗江",
    "321012": "江都",
    "321023": "宝应",
    "321081": "仪征",
    "321084": "高邮",
    "321102": "京口",
    "321111": "润州",
    "321112": "丹徒",
    "321181": "丹阳",
    "321182": "扬中",
    "321183": "句容",
    "321202": "海陵",
    "321203": "高港",
    "321204": "姜堰",
    "321281": "兴化",
    "321282": "靖江",
    "321283": "泰兴",
    "321302": "宿城",
    "321311": "宿豫",
    "321322": "沭阳",
    "321323": "泗阳",
    "321324": "泗洪",
    "330102": "上城",
    "330105": "拱墅",
    "330106": "西湖",
    "330108": "滨江",
    "330109": "萧山",
    "330110": "余杭",
    "330111": "富阳",
    "330112": "临安",
    "330113": "临平",
    "330114": "钱塘",
    "330122": "桐庐",
    "330127": "淳安",
    "330182": "建德",
    "330203": "海曙",
    "330205": "江北",
    "330206": "北仑",
    "330211": "镇海",
    "330212": "鄞州",
    "330213": "奉化",
    "330225": "象山",
    "330226": "宁海",
    "330281": "余姚",
    "330282": "慈溪",
    "330302": "鹿城",
    "330303": "龙湾",
    "330304": "瓯海",
    "330305": "洞头",
    "330324": "永嘉",
    "330326": "平阳",
    "330327": "苍南",
    "330328": "文成",
    "330329": "泰顺",
    "330381": "瑞安",
    "330382": "乐清",
    "330383": "龙港",
    "330402": "南湖",
    "330411": "秀洲",
    "330421": "嘉善",
    "330424": "海盐",
    "330481": "海宁",
    "330482": "平湖",
    "330483": "桐乡",
    "330502": "吴兴",
    "330503": "南浔",
    "330521": "德清",
    "330522": "长兴",
    "330523": "安吉",
    "330602": "越城",
    "330603": "柯桥",
    "330604": "上虞",
    "330624": "新昌",
    "330681": "诸暨",
    "330683": "嵊州",
    "330702": "婺城",
    "330703": "金东",
    "330723": "武义",
    "330726": "浦江",
    "330727": "磐安",
    "330781": "兰溪",
    "330782": "义乌",
    "330783": "东阳",
    "330784": "永康",
    "330802": "柯城",
    "330803": "衢江",
    "330822": "常山",
    "330824": "开化",
    "330825": "龙游",
    "330881": "江山",
    "330902": "定海",
    "330903": "普陀",
    "330921": "岱山",
    "330922": "嵊泗",
    "331002": "椒江",
    "331003": "黄岩",
    "331004": "路桥",
    "331022": "三门",
    "331023": "天台",
    "331024": "仙居",
    "331081": "温岭",
    "331082": "临海",
    "331083": "玉环",
    "331102": "莲都",
    "331121": "青田",
    "331122": "缙云",
    "331123": "遂昌",
    "331124": "松阳",
    "331125": "云和",
    "331126": "庆元",
    "331127": "景宁",
    "331181": "龙泉",
    "340102": "瑶海",
    "340103": "庐阳",
    "340104": "蜀山",
    "340111": "包河",
    "340121": "长丰",
    "340122": "肥东",
    "340123": "肥西",
    "340124": "庐江",
    "340181": "巢湖",
    "340202": "镜湖",
    "340207": "鸠江",
    "340209": "弋江",
    "340210": "湾沚",
    "340212": "繁昌",
    "340223": "南陵",
    "340281": "无为",
    "340302": "龙子湖",
    "340303": "蚌山",
    "340304": "禹会",
    "340311": "淮上",
    "340321": "怀远",
    "340322": "五河",
    "340323": "固镇",
    "340402": "大通",
    "340403": "田家庵",
    "340404": "谢家集",
    "340405": "八公山",
    "340406": "潘集",
    "340421": "凤台",
    "340422": "寿县",
    "340503": "花山",
    "340504": "雨山",
    "340506": "博望",
    "340521": "当涂",
    "340522": "含山",
    "340523": "和县",
    "340602": "杜集",
    "340603": "相山",
    "340604": "烈山",
    "340621": "濉溪",
    "340705": "铜官",
    "340706": "义安",
    "340711": "郊区",
    "340722": "枞阳",
    "340802": "迎江",
    "340803": "大观",
    "340811": "宜秀",
    "340822": "怀宁",
    "340825": "太湖",
    "340826": "宿松",
    "340827": "望江",
    "340828": "岳西",
    "340881": "桐城",
    "340882": "潜山",
    "341002": "屯溪",
    "341003": "黄山区",
    "341004": "徽州",
    "341021": "歙县",
    "341022": "休宁",
    "341023": "黟县",
    "341024": "祁门",
    "341102": "琅琊",
    "341103": "南谯",
    "341122": "来安",
    "341124": "全椒",
    "341125": "定远",
    "341126": "凤阳",
    "341181": "天长",
    "341182": "明光",
    "341202": "颍州",
    "341203": "颍东",
    "341204": "颍泉",
    "341221": "临泉",
    "341222": "太和",
    "341225": "阜南",
    "341226": "颍上",
    "341282": "界首",
    "341302": "埇桥",
    "341321": "砀山",
    "341322": "萧县",
    "341323": "灵璧",
    "341324": "泗县",
    "341502": "金安",
    "341503": "裕安",
    "341504": "叶集",
    "341522": "霍邱",
    "341523": "舒城",
    "341524": "金寨",
    "341525": "霍山",
    "341602": "谯城",
    "341621": "涡阳",
    "341622": "蒙城",
    "341623": "利辛",
    "341702": "贵池",
    "341721": "东至",
    "341722": "石台",
    "341723": "青阳",
    "341802": "宣州",
    "341821": "郎溪",
    "341823": "泾县",
    "341824": "绩溪",
    "341825": "旌德",
    "341881": "宁国",
    "341882": "广德",
    "350102": "鼓楼",
    "350103": "台江",
    "350104": "仓山",
    "350105": "马尾",
    "350111": "晋安",
    "350112": "长乐",
    "350121": "闽侯",
    "350122": "连江",
    "350123": "罗源",
    "350124": "闽清",
    "350125": "永泰",
    "350128": "平潭",
    "350181": "福清",
    "350203": "思明",
    "350205": "海沧",
    "350206": "湖里",
    "350211": "集美",
    "350212": "同安",
    "350213": "翔安",
    "350302": "城厢",
    "350303": "涵江",
    "350304": "荔城",
    "350305": "秀屿",
    "350322": "仙游",
    "350404": "三元",
    "350405": "沙县",
    "350421": "明溪",
    "350423": "清流",
    "350424": "宁化",
    "350425": "大田",
    "350426": "尤溪",
    "350428": "将乐",
    "350429": "泰宁",
    "350430": "建宁",
    "350481": "永安",
    "350502": "鲤城",
    "350503": "丰泽",
    "350504": "洛江",
    "350505": "泉港",
    "350521": "惠安",
    "350524": "安溪",
    "350525": "永春",
    "350526": "德化",
    "350527": "金门",
    "350581": "石狮",
    "350582": "晋江",
    "350583": "南安",
    "350602": "芗城",
    "350603": "龙文",
    "350604": "龙海",
    "350605": "长泰",
    "350622": "云霄",
    "350623": "漳浦",
    "350624": "诏安",
    "350626": "东山",
    "350627": "南靖",
    "350628": "平和",
    "350629": "华安",
    "350702": "延平",
    "350703": "建阳",
    "350721": "顺昌",
    "350722": "浦城",
    "350723": "光泽",
    "350724": "松溪",
    "350725": "政和",
    "350781": "邵武",
    "350782": "武夷山",
    "350783": "建瓯",
    "350802": "新罗",
    "350803": "永定",
    "350821": "长汀",
    "350823": "上杭",
    "350824": "武平",
    "350825": "连城",
    "350881": "漳平",
    "350902": "蕉城",
    "350921": "霞浦",
    "350922": "古田",
    "350923": "屏南",
    "350924": "寿宁",
    "350925": "周宁",
    "350926": "柘荣",
    "350981": "福安",
    "350982": "福鼎",
    "360102": "东湖",
    "360103": "西湖",
    "360104": "青云谱",
    "360111": "青山湖",
    "360112": "新建",
    "360113": "红谷滩",
    "360121": "南昌县",
    "360123": "安义",
    "360124": "进贤",
    "360202": "昌江",
    "360203": "珠山",
    "360222": "浮梁",
    "360281": "乐平",
    "360302": "安源",
    "360313": "湘东",
    "360321": "莲花",
    "360322": "上栗",
    "360323": "芦溪",
    "360402": "濂溪",
    "360403": "浔阳",
    "360404": "柴桑",
    "360423": "武宁",
    "360424": "修水",
    "360425": "永修",
    "360426": "德安",
    "360428": "都昌",
    "360429": "湖口",
    "360430": "彭泽",
    "360481": "瑞昌",
    "360482": "共青城",
    "360483": "庐山",
    "360502": "渝水",
    "360521": "分宜",
    "360602": "月湖",
    "360603": "余江",
    "360681": "贵溪",
    "360702": "章贡",
    "360703": "南康",
    "360704": "赣县",
    "360722": "信丰",
    "360723": "大余",
    "360724": "上犹",
    "360725": "崇义",
    "360726": "安远",
    "360728": "定南",
    "360729": "全南",
    "360730": "宁都",
    "360731": "于都",
    "360732": "兴国",
    "360733": "会昌",
    "360734": "寻乌",
    "360735": "石城",
    "360781": "瑞金",
    "360783": "龙南",
    "360802": "吉州",
    "360803": "青原",
    "360821": "吉安县",
    "360822": "吉水",
    "360823": "峡江",
    "360824": "新干",
    "360825": "永丰",
    "360826": "泰和",
    "360827": "遂川",
    "360828": "万安",
    "360829": "安福",
    "360830": "永新",
    "360881": "井冈山",
    "360902": "袁州",
    "360921": "奉新",
    "360922": "万载",
    "360923": "上高",
    "360924": "宜丰",
    "360925": "靖安",
    "360926": "铜鼓",
    "360981": "丰城",
    "360982": "樟树",
    "360983": "高安",
    "361002": "临川",
    "361003": "东乡",
    "361021": "南城",
    "361022": "黎川",
    "361023": "南丰",
    "361024": "崇仁",
    "361025": "乐安",
    "361026": "宜黄",
    "361027": "金溪",
    "361028": "资溪",
    "361030": "广昌",
    "361102": "信州",
    "361103": "广丰",
    "361104": "广信",
    "361123": "玉山",
    "361124": "铅山",
    "361125": "横峰",
    "361126": "弋阳",
    "361127": "余干",
    "361128": "鄱阳",
    "361129": "万年",
    "361130": "婺源",
    "361181": "德兴",
    "370102": "历下",
    "370103": "市中",
    "370104": "槐荫",
    "370105": "天桥",
    "370112": "历城",
    "370113": "长清",
    "370114": "章丘",
    "370115": "济阳",
    "370116": "莱芜",
    "370117": "钢城",
    "370124": "平阴",
    "370126": "商河",
    "370202": "市南",
    "370203": "市北",
    "370211": "黄岛",
    "370212": "崂山",
    "370213": "李沧",
    "370214": "城阳",
    "370215": "即墨",
    "370281": "胶州",
    "370283": "平度",
    "370285": "莱西",
    "370302": "淄川",
    "370303": "张店",
    "370304": "博山",
    "370305": "临淄",
    "370306": "周村",
    "370321": "桓台",
    "370322": "高青",
    "370323": "沂源",
    "370402": "市中",
    "370403": "薛城",
    "370404": "峄城",
    "370405": "台儿庄",
    "370406": "山亭",
    "370481": "滕州",
    "370502": "东营区",
    "370503": "河口",
    "370505": "垦利",
    "370522": "利津",
    "370523": "广饶",
    "370602": "芝罘",
    "370611": "福山",
    "370612": "牟平",
    "370613": "莱山",
    "370614": "蓬莱",
    "370681": "龙口",
    "370682": "莱阳",
    "370683": "莱州",
    "370685": "招远",
    "370686": "栖霞",
    "370687": "海阳",
    "370702": "潍城",
    "370703": "寒亭",
    "370704": "坊子",
    "370705": "奎文",
    "370724": "临朐",
    "370725": "昌乐",
    "370781": "青州",
    "370782": "诸城",
    "370783": "寿光",
    "370784": "安丘",
    "370785": "高密",
    "370786": "昌邑",
    "370811": "任城",
    "370812": "兖州",
    "370826": "微山",
    "370827": "鱼台",
    "370828": "金乡",
    "370829": "嘉祥",
    "370830": "汶上",
    "370831": "泗水",
    "370832": "梁山",
    "370881": "曲阜",
    "370883": "邹城",
    "370902": "泰山",
    "370911": "岱岳",
    "370921": "宁阳",
    "370923": "东平",
    "370982": "新泰",
    "370983": "肥城",
    "371002": "环翠",
    "371003": "文登",
    "371082": "荣成",
    "371083": "乳山",
    "371102": "东港",
    "371103": "岚山",
    "371121": "五莲",
    "371122": "莒县",
    "371302": "兰山",
    "371311": "罗庄",
    "371312": "河东",
    "371321": "沂南",
    "371322": "郯城",
    "371323": "沂水",
    "371324": "兰陵",
    "371325": "费县",
    "371326": "平邑",
    "371327": "莒南",
    "371328": "蒙阴",
    "371329": "临沭",
    "371402": "德城",
    "371403": "陵城",
    "371422": "宁津",
    "371423": "庆云",
    "371424": "临邑",
    "371425": "齐河",
    "371426": "平原",
    "371427": "夏津",
    "371428": "武城",
    "371481": "乐陵",
    "371482": "禹城",
    "371502": "东昌府",
    "371503": "茌平",
    "371521": "阳谷",
    "371522": "莘县",
    "371524": "东阿",
    "371525": "冠县",
    "371526": "高唐",
    "371581": "临清",
    "371602": "滨城",
    "371603": "沾化",
    "371621": "惠民",
    "371622": "阳信",
    "371623": "无棣",
    "371625": "博兴",
    "371681": "邹平",
    "371702": "牡丹",
    "371703": "定陶",
    "371721": "曹县",
    "371722": "单县",
    "371723": "成武",
    "371724": "巨野",
    "371725": "郓城",
    "371726": "鄄城",
    "371728": "东明",
    "410102": "中原",
    "410103": "二七",
    "410104": "管城",
    "410105": "金水",
    "410106": "上街",
    "410108": "惠济",
    "410122": "中牟",
    "410181": "巩义",
    "410182": "荥阳",
    "410183": "新密",
    "410184": "新郑",
    "410185": "登封",
    "410202": "龙亭",
    "410203": "顺河",
    "410204": "鼓楼",
    "410205": "禹王台",
    "410212": "祥符",
    "410221": "杞县",
    "410222": "通许",
    "410223": "尉氏",
    "410225": "兰考",
    "410302": "老城",
    "410303": "西工",
    "410304": "瀍河",
    "410305": "涧西",
    "410307": "偃师",
    "410308": "孟津",
    "410311": "洛龙",
    "410323": "新安",
    "410324": "栾川",
    "410325": "嵩县",
    "410326": "汝阳",
    "410327": "宜阳",
    "410328": "洛宁",
    "410329": "伊川",
    "410402": "新华",
    "410403": "卫东",
    "410404": "石龙",
    "410411": "湛河",
    "410421": "宝丰",
    "410422": "叶县",
    "410423": "鲁山",
    "410425": "郏县",
    "410481": "舞钢",
    "410482": "汝州",
    "410502": "文峰",
    "410503": "北关",
    "410505": "殷都",
    "410506": "龙安",
    "410522": "安阳县",
    "410523": "汤阴",
    "410526": "滑县",
    "410527": "内黄",
    "410581": "林州",
    "410602": "鹤山",
    "410603": "山城",
    "410611": "淇滨",
    "410621": "浚县",
    "410622": "淇县",
    "410702": "红旗",
    "410703": "卫滨",
    "410704": "凤泉",
    "410711": "牧野",
    "410721": "新乡县",
    "410724": "获嘉",
    "410725": "原阳",
    "410726": "延津",
    "410727": "封丘",
    "410781": "卫辉",
    "410782": "辉县",
    "410783": "长垣",
    "410802": "解放",
    "410803": "中站",
    "410804": "马村",
    "410811": "山阳",
    "410821": "修武",
    "410822": "博爱",
    "410823": "武陟",
    "410825": "温县",
    "410882": "沁阳",
    "410883": "孟州",
    "410902": "华龙",
    "410922": "清丰",
    "410923": "南乐",
    "410926": "范县",
    "410927": "台前",
    "410928": "濮阳县",
    "411002": "魏都",
    "411003": "建安",
    "411024": "鄢陵",
    "411025": "襄城",
    "411081": "禹州",
    "411082": "长葛",
    "411102": "源汇",
    "411103": "郾城",
    "411104": "召陵",
    "411121": "舞阳",
    "411122": "临颍",
    "411202": "湖滨",
    "411203": "陕州",
    "411221": "渑池",
    "411224": "卢氏",
    "411281": "义马",
    "411282": "灵宝",
    "411302": "宛城",
    "411303": "卧龙",
    "411321": "南召",
    "411322": "方城",
    "411323": "西峡",
    "411324": "镇平",
    "411325": "内乡",
    "411326": "淅川",
    "411327": "社旗",
    "411328": "唐河",
    "411329": "新野",
    "411330": "桐柏",
    "411381": "邓州",
    "411402": "梁园",
    "411403": "睢阳",
    "411421": "民权",
    "411422": "睢县",
    "411423": "宁陵",
    "411424": "柘城",
    "411425": "虞城",
    "411426": "夏邑",
    "411481": "永城",
    "411502": "浉河",
    "411503": "平桥",
    "411521": "罗山",
    "411522": "光山",
    "411523": "新县",
    "411524": "商城",
    "411525": "固始",
    "411526": "潢川",
    "411527": "淮滨",
    "411528": "息县",
    "411602": "川汇",
    "411603": "淮阳",
    "411621": "扶沟",
    "411622": "西华",
    "411623": "商水",
    "411624": "沈丘",
    "411625": "郸城",
    "411627": "太康",
    "411628": "鹿邑",
    "411681": "项城",
    "411702": "驿城",
    "411721": "西平",
    "411722": "上蔡",
    "411723": "平舆",
    "411724": "正阳",
    "411725": "确山",
    "411726": "泌阳",
    "411727": "汝南",
    "411728": "遂平",
    "411729": "新蔡",
    "419001": "济源",
    "420102": "江岸",
    "420103": "江汉",
    "420104": "硚口",
    "420105": "汉阳",
    "420106": "武昌",
    "420107": "青山",
    "420111": "洪山",
    "420112": "东西湖",
    "420113": "汉南",
    "420114": "蔡甸",
    "420115": "江夏",
    "420116": "黄陂",
    "420117": "新洲",
    "420202": "黄石港",
    "420203": "西塞山",
    "420204": "下陆",
    "420205": "铁山",
    "420222": "阳新",
    "420281": "大冶",
    "420302": "茅箭",
    "420303": "张湾",
    "420304": "郧阳",
    "420322": "郧西",
    "420323": "竹山",
    "420324": "竹溪",
    "420325": "房县",
    "420381": "丹江口",
    "420502": "西陵",
    "420503": "伍家岗",
    "420504": "点军",
    "420505": "猇亭",
    "420506": "夷陵",
    "420525": "远安",
    "420526": "兴山",
    "420527": "秭归",
    "420528": "长阳",
    "420529": "五峰",
    "420581": "宜都",
    "420582": "当阳",
    "420583": "枝江",
    "420602": "襄城",
    "420606": "樊城",
    "420607": "襄州",
    "420624": "南漳",
    "420625": "谷城",
    "420626": "保康",
    "420682": "老河口",
    "420683": "枣阳",
    "420684": "宜城",
    "420702": "梁子湖",
    "420703": "华容",
    "420704": "鄂城",
    "420802": "东宝",
    "420804": "掇刀",
    "420822": "沙洋",
    "420881": "钟祥",
    "420882": "京山",
    "420902": "孝南",
    "420921": "孝昌",
    "420922": "大悟",
    "420923": "云梦",
    "420981": "应城",
    "420982": "安陆",
    "420984": "汉川",
    "421002": "沙市",
    "421003": "荆州区",
    "421022": "公安",
    "421024": "江陵",
    "421081": "石首",
    "421083": "洪湖",
    "421087": "松滋",
    "421088": "监利",
    "421102": "黄州",
    "421121": "团风",
    "421122": "红安",
    "421123": "罗田",
    "421124": "英山",
    "421125": "浠水",
    "421126": "蕲春",
    "421127": "黄梅",
    "421181": "麻城",
    "421182": "武穴",
    "421202": "咸安",
    "421221": "嘉鱼",
    "421222": "通城",
    "421223": "崇阳",
    "421224": "通山",
    "421281": "赤壁",
    "421303": "曾都",
    "421321": "随县",
    "421381": "广水",
    "422801": "恩施市",
    "422802": "利川",
    "422822": "建始",
    "422823": "巴东",
    "422825": "宣恩",
    "422826": "咸丰",
    "422827": "来凤",
    "422828": "鹤峰",
    "429004": "仙桃",
    "429005": "潜江",
    "429006": "天门",
    "429021": "神农架林区",
    "430102": "芙蓉",
    "430103": "天心",
    "430104": "岳麓",
    "430105": "开福",
    "430111": "雨花",
    "430112": "望城",
    "430121": "长沙县",
    "430181": "浏阳",
    "430182": "宁乡",
    "430202": "荷塘",
    "430203": "芦淞",
    "430204": "石峰",
    "430211": "天元",
    "430212": "渌口",
    "430223": "攸县",
    "430224": "茶陵",
    "430225": "炎陵",
    "430281": "醴陵",
    "430302": "雨湖",
    "430304": "岳塘",
    "430321": "湘潭县",
    "430381": "湘乡",
    "430382": "韶山",
    "430405": "珠晖",
    "430406": "雁峰",
    "430407": "石鼓",
    "430408": "蒸湘",
    "430412": "南岳",
    "430421": "衡阳县",
    "430422": "衡南",
    "430423": "衡山",
    "430424": "衡东",
    "430426": "祁东",
    "430481": "耒阳",
    "430482": "常宁",
    "430502": "双清",
    "430503": "大祥",
    "430511": "北塔",
    "430522": "新邵",
    "430523": "邵阳县",
    "430524": "隆回",
    "430525": "洞口",
    "430527": "绥宁",
    "430528": "新宁",
    "430529": "城步",
    "430581": "武冈",
    "430582": "邵东",
    "430602": "岳阳楼",
    "430603": "云溪",
    "430611": "君山",
    "430621": "岳阳县",
    "430623": "华容",
    "430624": "湘阴",
    "430626": "平江",
    "430681": "汨罗",
    "430682": "临湘",
    "430702": "武陵",
    "430703": "鼎城",
    "430721": "安乡",
    "430722": "汉寿",
    "430723": "澧县",
    "430724": "临澧",
    "430725": "桃源",
    "430726": "石门",
    "430781": "津市",
    "430802": "永定",
    "430811": "武陵源",
    "430821": "慈利",
    "430822": "桑植",
    "430902": "资阳",
    "430903": "赫山",
    "430921": "南县",
    "430922": "桃江",
    "430923": "安化",
    "430981": "沅江",
    "431002": "北湖",
    "431003": "苏仙",
    "431021": "桂阳",
    "431022": "宜章",
    "431023": "永兴",
    "431024": "嘉禾",
    "431025": "临武",
    "431026": "汝城",
    "431027": "桂东",
    "431028": "安仁",
    "431081": "资兴",
    "431102": "零陵",
    "431103": "冷水滩",
    "431122": "东安",
    "431123": "双牌",
    "431124": "道县",
    "431125": "江永",
    "431126": "宁远",
    "431127": "蓝山",
    "431128": "新田",
    "431129": "江华",
    "431181": "祁阳",
    "431202": "鹤城",
    "431221": "中方",
    "431222": "沅陵",
    "431223": "辰溪",
    "431224": "溆浦",
    "431225": "会同",
    "431226": "麻阳",
    "431227": "新晃",
    "431228": "芷江",
    "431229": "靖州",
    "431230": "通道",
    "431281": "洪江",
    "431302": "娄星",
    "431321": "双峰",
    "431322": "新化",
    "431381": "冷水江",
    "431382": "涟源",
    "433101": "吉首",
    "433122": "泸溪",
    "433123": "凤凰",
    "433124": "花垣",
    "433125": "保靖",
    "433126": "古丈",
    "433127": "永顺",
    "433130": "龙山",
    "440103": "荔湾",
    "440104": "越秀",
    "440105": "海珠",
    "440106": "天河",
    "440111": "白云",
    "440112": "黄埔",
    "440113": "番禺",
    "440114": "花都",
    "440115": "南沙",
    "440117": "从化",
    "440118": "增城",
    "440203": "武江",
    "440204": "浈江",
    "440205": "曲江",
    "440222": "始兴",
    "440224": "仁化",
    "440229": "翁源",
    "440232": "乳源",
    "440233": "新丰",
    "440281": "乐昌",
    "440282": "南雄",
    "440303": "罗湖",
    "440304": "福田",
    "440305": "南山",
    "440306": "宝安",
    "440307": "龙岗",
    "440308": "盐田",
    "440309": "龙华",
    "440310": "坪山",
    "440311": "光明",
    "440402": "香洲",
    "440403": "斗门",
    "440404": "金湾",
    "440507": "龙湖",
    "440511": "金平",
    "440512": "濠江",
    "440513": "潮阳",
    "440514": "潮南",
    "440515": "澄海",
    "440523": "南澳",
    "440604": "禅城",
    "440605": "南海",
    "440606": "顺德",
    "440607": "三水",
    "440608": "高明",
    "440703": "蓬江",
    "440704": "江海",
    "440705": "新会",
    "440781": "台山",
    "440783": "开平",
    "440784": "鹤山",
    "440785": "恩平",
    "440802": "赤坎",
    "440803": "霞山",
    "440804": "坡头",
    "440811": "麻章",
    "440823": "遂溪",
    "440825": "徐闻",
    "440881": "廉江",
    "440882": "雷州",
    "440883": "吴川",
    "440902": "茂南",
    "440904": "电白",
    "440981": "高州",
    "440982": "化州",
    "440983": "信宜",
    "441202": "端州",
    "441203": "鼎湖",
    "441204": "高要",
    "441223": "广宁",
    "441224": "怀集",
    "441225": "封开",
    "441226": "德庆",
    "441284": "四会",
    "441302": "惠城",
    "441303": "惠阳",
    "441322": "博罗",
    "441323": "惠东",
    "441324": "龙门",
    "441402": "梅江",
    "441403": "梅县",
    "441422": "大埔",
    "441423": "丰顺",
    "441424": "五华",
    "441426": "平远",
    "441427": "蕉岭",
    "441481": "兴宁",
    "441502": "城区",
    "441521": "海丰",
    "441523": "陆河",
    "441581": "陆丰",
    "441602": "源城",
    "441621": "紫金",
    "441622": "龙川",
    "441623": "连平",
    "441624": "和平",
    "441625": "东源",
    "441702": "江城",
    "441704": "阳东",
    "441721": "阳西",
    "441781": "阳春",
    "441802": "清城",
    "441803": "清新区",
    "441821": "佛冈",
    "441823": "阳山",
    "441825": "连山",
    "441826": "连南",
    "441881": "英德",
    "441882": "连州",
    "445102": "湘桥",
    "445103": "潮安",
    "445122": "饶平",
    "445202": "榕城",
    "445203": "揭东",
    "445222": "揭西",
    "445224": "惠来",
    "445281": "普宁",
    "445302": "云城",
    "445303": "云安",
    "445321": "新兴",
    "445322": "郁南",
    "445381": "罗定",
    "450102": "兴宁",
    "450103": "青秀",
    "450105": "江南",
    "450107": "西乡塘",
    "450108": "良庆",
    "450109": "邕宁",
    "450110": "武鸣",
    "450123": "隆安",
    "450124": "马山",
    "450125": "上林",
    "450126": "宾阳",
    "450181": "横州",
    "450202": "城中",
    "450203": "鱼峰",
    "450204": "柳南",
    "450205": "柳北",
    "450206": "柳江",
    "450222": "柳城",
    "450223": "鹿寨",
    "450224": "融安",
    "450225": "融水",
    "450226": "三江",
    "450302": "秀峰",
    "450303": "叠彩",
    "450304": "象山",
    "450305": "七星",
    "450311": "雁山",
    "450312": "临桂",
    "450321": "阳朔",
    "450323": "灵川",
    "450324": "全州",
    "450325": "兴安",
    "450326": "永福",
    "450327": "灌阳",
    "450328": "龙胜",
    "450329": "资源",
    "450330": "平乐",
    "450332": "恭城",
    "450381": "荔浦",
    "450403": "万秀",
    "450405": "长洲",
    "450406": "龙圩",
    "450421": "苍梧",
    "450422": "藤县",
    "450423": "蒙山",
    "450481": "岑溪",
    "450502": "海城",
    "450503": "银海",
    "450512": "铁山港",
    "450521": "合浦",
    "450602": "港口",
    "450603": "防城",
    "450621": "上思",
    "450681": "东兴",
    "450702": "钦南",
    "450703": "钦北",
    "450721": "灵山",
    "450722": "浦北",
    "450802": "港北",
    "450803": "港南",
    "450804": "覃塘",
    "450821": "平南",
    "450881": "桂平",
    "450902": "玉州",
    "450903": "福绵",
    "450921": "容县",
    "450922": "陆川",
    "450923": "博白",
    "450924": "兴业",
    "450981": "北流",
    "451002": "右江",
    "451003": "田阳",
    "451022": "田东",
    "451024": "德保",
    "451026": "那坡",
    "451027": "凌云",
    "451028": "乐业",
    "451029": "田林",
    "451030": "西林",
    "451031": "隆林",
    "451081": "靖西",
    "451082": "平果",
    "451102": "八步",
    "451103": "平桂",
    "451121": "昭平",
    "451122": "钟山",
    "451123": "富川",
    "451202": "金城江",
    "451203": "宜州",
    "451221": "南丹",
    "451222": "天峨",
    "451223": "凤山",
    "451224": "东兰",
    "451225": "罗城",
    "451226": "环江",
    "451227": "巴马",
    "451228": "都安",
    "451229": "大化",
    "451302": "兴宾",
    "451321": "忻城",
    "451322": "象州",
    "451323": "武宣",
    "451324": "金秀",
    "451381": "合山",
    "451402": "江州",
    "451421": "扶绥",
    "451422": "宁明",
    "451423": "龙州",
    "451424": "大新",
    "451425": "天等",
    "451481": "凭祥",
    "460105": "秀英",
    "460106": "龙华",
    "460107": "琼山",
    "460108": "美兰",
    "460202": "海棠",
    "460203": "吉阳",
    "460204": "天涯",
    "460205": "崖州",
    "460301": "西沙",
    "460302": "南沙",
    "469001": "五指山",
    "469002": "琼海",
    "469005": "文昌",
    "469006": "万宁",
    "469007": "东方",
    "469021": "定安",
    "469022": "屯昌",
    "469023": "澄迈",
    "469024": "临高",
    "469025": "白沙",
    "469026": "昌江",
    "469027": "乐东",
    "469028": "陵水",
    "469029": "保亭",
    "469030": "琼中",
    "500101": "万州",
    "500102": "涪陵",
    "500103": "渝中",
    "500104": "大渡口",
    "500105": "江北",
    "500106": "沙坪坝",
    "500107": "九龙坡",
    "500108": "南岸",
    "500109": "北碚",
    "500110": "綦江",
    "500111": "大足",
    "500112": "渝北",
    "500113": "巴南",
    "500114": "黔江",
    "500115": "长寿",
    "500116": "江津",
    "500117": "合川",
    "500118": "永川",
    "500119": "南川",
    "500120": "璧山",
    "500151": "铜梁",
    "500152": "潼南",
    "500153": "荣昌",
    "500154": "开州",
    "500155": "梁平",
    "500156": "武隆",
    "500229": "城口",
    "500230": "丰都",
    "500231": "垫江",
    "500233": "忠县",
    "500235": "云阳",
    "500236": "奉节",
    "500237": "巫山",
    "500238": "巫溪",
    "500240": "石柱",
    "500241": "秀山",
    "500242": "酉阳",
    "500243": "彭水",
    "510104": "锦江",
    "510105": "青羊",
    "510106": "金牛",
    "510107": "武侯",
    "510108": "成华",
    "510112": "龙泉驿",
    "510113": "青白江",
    "510114": "新都",
    "510115": "温江",
    "510116": "双流",
    "510117": "郫都",
    "510118": "新津",
    "510121": "金堂",
    "510129": "大邑",
    "510131": "蒲江",
    "510181": "都江堰",
    "510182": "彭州",
    "510183": "邛崃",
    "510184": "崇州",
    "510185": "简阳",
    "510302": "自流井",
    "510303": "贡井",
    "510304": "大安",
    "510311": "沿滩",
    "510321": "荣县",
    "510322": "富顺",
    "510402": "东区",
    "510403": "西区",
    "510411": "仁和",
    "510421": "米易",
    "510422": "盐边",
    "510502": "江阳",
    "510503": "纳溪",
    "510504": "龙马潭",
    "510521": "泸县",
    "510522": "合江",
    "510524": "叙永",
    "510525": "古蔺",
    "510603": "旌阳",
    "510604": "罗江",
    "510623": "中江",
    "510681": "广汉",
    "510682": "什邡",
    "510683": "绵竹",
    "510703": "涪城",
    "510704": "游仙",
    "510705": "安州",
    "510722": "三台",
    "510723": "盐亭",
    "510725": "梓潼",
    "510726": "北川",
    "510727": "平武",
    "510781": "江油",
    "510802": "利州",
    "510811": "昭化",
    "510812": "朝天",
    "510821": "旺苍",
    "510822": "青川",
    "510823": "剑阁",
    "510824": "苍溪",
    "510903": "船山",
    "510904": "安居",
    "510921": "蓬溪",
    "510923": "大英",
    "510981": "射洪",
    "511002": "市中",
    "511011": "东兴",
    "511024": "威远",
    "511025": "资中",
    "511083": "隆昌",
    "511102": "市中",
    "511111": "沙湾",
    "511112": "五通桥",
    "511113": "金口河",
    "511123": "犍为",
    "511124": "井研",
    "511126": "夹江",
    "511129": "沐川",
    "511132": "峨边",
    "511133": "马边",
    "511181": "峨眉山",
    "511302": "顺庆",
    "511303": "高坪",
    "511304": "嘉陵",
    "511321": "南部",
    "511322": "营山",
    "511323": "蓬安",
    "511324": "仪陇",
    "511325": "西充",
    "511381": "阆中",
    "511402": "东坡",
    "511403": "彭山",
    "511421": "仁寿",
    "511423": "洪雅",
    "511424": "丹棱",
    "511425": "青神",
    "511502": "翠屏",
    "511503": "南溪",
    "511504": "叙州",
    "511523": "江安",
    "511524": "长宁",
    "511525": "高县",
    "511526": "珙县",
    "511527": "筠连",
    "511528": "兴文",
    "511529": "屏山",
    "511602": "广安区",
    "511603": "前锋",
    "511621": "岳池",
    "511622": "武胜",
    "511623": "邻水",
    "511681": "华蓥",
    "511702": "通川",
    "511703": "达川",
    "511722": "宣汉",
    "511723": "开江",
    "511724": "大竹",
    "511725": "渠县",
    "511781": "万源",
    "511802": "雨城",
    "511803": "名山",
    "511822": "荥经",
    "511823": "汉源",
    "511824": "石棉",
    "511825": "天全",
    "511826": "芦山",
    "511827": "宝兴",
    "511902": "巴州",
    "511903": "恩阳",
    "511921": "通江",
    "511922": "南江",
    "511923": "平昌",
    "512002": "雁江",
    "512021": "安岳",
    "512022": "乐至",
    "513201": "马尔康",
    "513221": "汶川",
    "513222": "理县",
    "513223": "茂县",
    "513224": "松潘",
    "513225": "九寨沟",
    "513226": "金川",
    "513227": "小金",
    "513228": "黑水",
    "513230": "壤塘",
    "513231": "阿坝县",
    "513232": "若尔盖",
    "513233": "红原",
    "513301": "康定",
    "513322": "泸定",
    "513323": "丹巴",
    "513324": "九龙",
    "513325": "雅江",
    "513326": "道孚",
    "513327": "炉霍",
    "513328": "甘孜县",
    "513329": "新龙",
    "513330": "德格",
    "513331": "白玉",
    "513332": "石渠",
    "513333": "色达",
    "513334": "理塘",
    "513335": "巴塘",
    "513336": "乡城",
    "513337": "稻城",
    "513338": "得荣",
    "513401": "西昌",
    "513402": "会理",
    "513422": "木里",
    "513423": "盐源",
    "513424": "德昌",
    "513426": "会东",
    "513427": "宁南",
    "513428": "普格",
    "513429": "布拖",
    "513430": "金阳",
    "513431": "昭觉",
    "513432": "喜德",
    "513433": "冕宁",
    "513434": "越西",
    "513435": "甘洛",
    "513436": "美姑",
    "513437": "雷波",
    "520102": "南明",
    "520103": "云岩",
    "520111": "花溪",
    "520112": "乌当",
    "520113": "白云",
    "520115": "观山湖",
    "520121": "开阳",
    "520122": "息烽",
    "520123": "修文",
    "520181": "清镇",
    "520201": "钟山",
    "520203": "六枝特",
    "520204": "水城",
    "520281": "盘州",
    "520302": "红花岗",
    "520303": "汇川",
    "520304": "播州",
    "520322": "桐梓",
    "520323": "绥阳",
    "520324": "正安",
    "520325": "道真",
    "520326": "务川",
    "520327": "凤冈",
    "520328": "湄潭",
    "520329": "余庆",
    "520330": "习水",
    "520381": "赤水",
    "520382": "仁怀",
    "520402": "西秀",
    "520403": "平坝",
    "520422": "普定",
    "520423": "镇宁",
    "520424": "关岭",
    "520425": "紫云",
    "520502": "七星关",
    "520521": "大方",
    "520523": "金沙",
    "520524": "织金",
    "520525": "纳雍",
    "520526": "威宁",
    "520527": "赫章",
    "520581": "黔西",
    "520602": "碧江",
    "520603": "万山",
    "520621": "江口",
    "520622": "玉屏",
    "520623": "石阡",
    "520624": "思南",
    "520625": "印江",
    "520626": "德江",
    "520627": "沿河",
    "520628": "松桃",
    "522301": "兴义",
    "522302": "兴仁",
    "522323": "普安",
    "522324": "晴隆",
    "522325": "贞丰",
    "522326": "望谟",
    "522327": "册亨",
    "522328": "安龙",
    "522601": "凯里",
    "522622": "黄平",
    "522623": "施秉",
    "522624": "三穗",
    "522625": "镇远",
    "522626": "岑巩",
    "522627": "天柱",
    "522628": "锦屏",
    "522629": "剑河",
    "522630": "台江",
    "522631": "黎平",
    "522632": "榕江",
    "522633": "从江",
    "522634": "雷山",
    "522635": "麻江",
    "522636": "丹寨",
    "522701": "都匀",
    "522702": "福泉",
    "522722": "荔波",
    "522723": "贵定",
    "522725": "瓮安",
    "522726": "独山",
    "522727": "平塘",
    "522728": "罗甸",
    "522729": "长顺",
    "522730": "龙里",
    "522731": "惠水",
    "522732": "三都",
    "530102": "五华",
    "530103": "盘龙",
    "530111": "官渡",
    "530112": "西山",
    "530113": "东川",
    "530114": "呈贡",
    "530115": "晋宁",
    "530124": "富民",
    "530125": "宜良",
    "530126": "石林",
    "530127": "嵩明",
    "530128": "禄劝",
    "530129": "寻甸",
    "530181": "安宁",
    "530302": "麒麟",
    "530303": "沾益",
    "530304": "马龙",
    "530322": "陆良",
    "530323": "师宗",
    "530324": "罗平",
    "530325": "富源",
    "530326": "会泽",
    "530381": "宣威",
    "530402": "红塔",
    "530403": "江川",
    "530423": "通海",
    "530424": "华宁",
    "530425": "易门",
    "530426": "峨山",
    "530427": "新平",
    "530428": "元江",
    "530481": "澄江",
    "530502": "隆阳",
    "530521": "施甸",
    "530523": "龙陵",
    "530524": "昌宁",
    "530581": "腾冲",
    "530602": "昭阳",
    "530621": "鲁甸",
    "530622": "巧家",
    "530623": "盐津",
    "530624": "大关",
    "530625": "永善",
    "530626": "绥江",
    "530627": "镇雄",
    "530628": "彝良",
    "530629": "威信",
    "530681": "水富",
    "530702": "古城",
    "530721": "玉龙",
    "530722": "永胜",
    "530723": "华坪",
    "530724": "宁蒗",
    "530802": "思茅",
    "530821": "宁洱",
    "530822": "墨江",
    "530823": "景东",
    "530824": "景谷",
    "530825": "镇沅",
    "530826": "江城",
    "530827": "孟连",
    "530828": "澜沧",
    "530829": "西盟",
    "530902": "临翔",
    "530921": "凤庆",
    "530922": "云县",
    "530923": "永德",
    "530924": "镇康",
    "530925": "双江",
    "530926": "耿马",
    "530927": "沧源",
    "532301": "楚雄市",
    "532302": "禄丰",
    "532322": "双柏",
    "532323": "牟定",
    "532324": "南华",
    "532325": "姚安",
    "532326": "大姚",
    "532327": "永仁",
    "532328": "元谋",
    "532329": "武定",
    "532501": "个旧",
    "532502": "开远",
    "532503": "蒙自",
    "532504": "弥勒",
    "532523": "屏边",
    "532524": "建水",
    "532525": "石屏",
    "532527": "泸西",
    "532528": "元阳",
    "532529": "红河县",
    "532530": "金平",
    "532531": "绿春",
    "532532": "河口",
    "532601": "文山市",
    "532622": "砚山",
    "532623": "西畴",
    "532624": "麻栗坡",
    "532625": "马关",
    "532626": "丘北",
    "532627": "广南",
    "532628": "富宁",
    "532801": "景洪",
    "532822": "勐海",
    "532823": "勐腊",
    "532901": "大理市",
    "532922": "漾濞",
    "532923": "祥云",
    "532924": "宾川",
    "532925": "弥渡",
    "532926": "南涧",
    "532927": "巍山",
    "532928": "永平",
    "532929": "云龙",
    "532930": "洱源",
    "532931": "剑川",
    "532932": "鹤庆",
    "533102": "瑞丽",
    "533103": "芒市",
    "533122": "梁河",
    "533123": "盈江",
    "533124": "陇川",
    "533301": "泸水",
    "533323": "福贡",
    "533324": "贡山",
    "533325": "兰坪",
    "533401": "香格里拉",
    "533422": "德钦",
    "533423": "维西",
    "540102": "城关",
    "540103": "堆龙德庆区",
    "540104": "达孜",
    "540121": "林周",
    "540122": "当雄",
    "540123": "尼木",
    "540124": "曲水",
    "540127": "墨竹工卡",
    "540202": "桑珠孜",
    "540221": "南木林",
    "540222": "江孜",
    "540223": "定日",
    "540224": "萨迦",
    "540225": "拉孜",
    "540226": "昂仁",
    "540227": "谢通门",
    "540228": "白朗",
    "540229": "仁布",
    "540230": "康马",
    "540231": "定结",
    "540232": "仲巴",
    "540233": "亚东",
    "540234": "吉隆",
    "540235": "聂拉木",
    "540236": "萨嘎",
    "540237": "岗巴",
    "540302": "卡若",
    "540321": "江达",
    "540322": "贡觉",
    "540323": "类乌齐",
    "540324": "丁青",
    "540325": "察雅",
    "540326": "八宿",
    "540327": "左贡",
    "540328": "芒康",
    "540329": "洛隆",
    "540330": "边坝",
    "540402": "巴宜",
    "540421": "工布江达",
    "540422": "米林",
    "540423": "墨脱",
    "540424": "波密",
    "540425": "察隅",
    "540426": "朗县",
    "540502": "乃东",
    "540521": "扎囊",
    "540522": "贡嘎",
    "540523": "桑日",
    "540524": "琼结",
    "540525": "曲松",
    "540526": "措美",
    "540527": "洛扎",
    "540528": "加查",
    "540529": "隆子",
    "540530": "错那",
    "540531": "浪卡子",
    "540602": "色尼",
    "540621": "嘉黎",
    "540622": "比如",
    "540623": "聂荣",
    "540624": "安多",
    "540625": "申扎",
    "540626": "索县",
    "540627": "班戈",
    "540628": "巴青",
    "540629": "尼玛",
    "540630": "双湖",
    "542521": "普兰",
    "542522": "札达",
    "542523": "噶尔",
    "542524": "日土",
    "542525": "革吉",
    "542526": "改则",
    "542527": "措勤",
    "610102": "新城",
    "610103": "碑林",
    "610104": "莲湖",
    "610111": "灞桥",
    "610112": "未央",
    "610113": "雁塔",
    "610114": "阎良",
    "610115": "临潼",
    "610116": "长安",
    "610117": "高陵",
    "610118": "鄠邑",
    "610122": "蓝田",
    "610124": "周至",
    "610202": "王益",
    "610203": "印台",
    "610204": "耀州",
    "610222": "宜君",
    "610302": "渭滨",
    "610303": "金台",
    "610304": "陈仓",
    "610305": "凤翔",
    "610323": "岐山",
    "610324": "扶风",
    "610326": "眉县",
    "610327": "陇县",
    "610328": "千阳",
    "610329": "麟游",
    "610330": "凤县",
    "610331": "太白",
    "610402": "秦都",
    "610403": "杨陵",
    "610404": "渭城",
    "610422": "三原",
    "610423": "泾阳",
    "610424": "乾县",
    "610425": "礼泉",
    "610426": "永寿",
    "610428": "长武",
    "610429": "旬邑",
    "610430": "淳化",
    "610431": "武功",
    "610481": "兴平",
    "610482": "彬州",
    "610502": "临渭",
    "610503": "华州",
    "610522": "潼关",
    "610523": "大荔",
    "610524": "合阳",
    "610525": "澄城",
    "610526": "蒲城",
    "610527": "白水",
    "610528": "富平",
    "610581": "韩城",
    "610582": "华阴",
    "610602": "宝塔",
    "610603": "安塞",
    "610621": "延长",
    "610622": "延川",
    "610625": "志丹",
    "610626": "吴起",
    "610627": "甘泉",
    "610628": "富县",
    "610629": "洛川",
    "610630": "宜川",
    "610631": "黄龙",
    "610632": "黄陵",
    "610681": "子长",
    "610702": "汉台",
    "610703": "南郑",
    "610722": "城固",
    "610723": "洋县",
    "610724": "西乡",
    "610725": "勉县",
    "610726": "宁强",
    "610727": "略阳",
    "610728": "镇巴",
    "610729": "留坝",
    "610730": "佛坪",
    "610802": "榆阳",
    "610803": "横山",
    "610822": "府谷",
    "610824": "靖边",
    "610825": "定边",
    "610826": "绥德",
    "610827": "米脂",
    "610828": "佳县",
    "610829": "吴堡",
    "610830": "清涧",
    "610831": "子洲",
    "610881": "神木",
    "610902": "汉滨",
    "610921": "汉阴",
    "610922": "石泉",
    "610923": "宁陕",
    "610924": "紫阳",
    "610925": "岚皋",
    "610926": "平利",
    "610927": "镇坪",
    "610929": "白河",
    "610981": "旬阳",
    "611002": "商州",
    "611021": "洛南",
    "611022": "丹凤",
    "611023": "商南",
    "611024": "山阳",
    "611025": "镇安",
    "611026": "柞水",
    "620102": "城关",
    "620103": "七里河",
    "620104": "西固",
    "620105": "安宁",
    "620111": "红古",
    "620121": "永登",
    "620122": "皋兰",
    "620123": "榆中",
    "620302": "金川",
    "620321": "永昌",
    "620402": "白银区",
    "620403": "平川",
    "620421": "靖远",
    "620422": "会宁",
    "620423": "景泰",
    "620502": "秦州",
    "620503": "麦积",
    "620521": "清水",
    "620522": "秦安",
    "620523": "甘谷",
    "620524": "武山",
    "620525": "张家川",
    "620602": "凉州",
    "620621": "民勤",
    "620622": "古浪",
    "620623": "天祝",
    "620702": "甘州",
    "620721": "肃南",
    "620722": "民乐",
    "620723": "临泽",
    "620724": "高台",
    "620725": "山丹",
    "620802": "崆峒",
    "620821": "泾川",
    "620822": "灵台",
    "620823": "崇信",
    "620825": "庄浪",
    "620826": "静宁",
    "620881": "华亭",
    "620902": "肃州",
    "620921": "金塔",
    "620922": "瓜州",
    "620923": "肃北",
    "620924": "阿克塞",
    "620981": "玉门",
    "620982": "敦煌",
    "621002": "西峰",
    "621021": "庆城",
    "621022": "环县",
    "621023": "华池",
    "621024": "合水",
    "621025": "正宁",
    "621026": "宁县",
    "621027": "镇原",
    "621102": "安定",
    "621121": "通渭",
    "621122": "陇西",
    "621123": "渭源",
    "621124": "临洮",
    "621125": "漳县",
    "621126": "岷县",
    "621202": "武都",
    "621221": "成县",
    "621222": "文县",
    "621223": "宕昌",
    "621224": "康县",
    "621225": "西和",
    "621226": "礼县",
    "621227": "徽县",
    "621228": "两当",
    "622901": "临夏市",
    "622921": "临夏县",
    "622922": "康乐",
    "622923": "永靖",
    "622924": "广河",
    "622925": "和政",
    "622926": "东乡族自治县",
    "622927": "积石山",
    "623001": "合作",
    "623021": "临潭",
    "623022": "卓尼",
    "623023": "舟曲",
    "623024": "迭部",
    "623025": "玛曲",
    "623026": "碌曲",
    "623027": "夏河",
    "630102": "城东",
    "630103": "城中",
    "630104": "城西",
    "630105": "城北",
    "630106": "湟中",
    "630121": "大通",
    "630123": "湟源",
    "630202": "乐都",
    "630203": "平安",
    "630222": "民和",
    "630223": "互助",
    "630224": "化隆",
    "630225": "循化",
    "632221": "门源",
    "632222": "祁连",
    "632223": "海晏",
    "632224": "刚察",
    "632301": "同仁",
    "632322": "尖扎",
    "632323": "泽库",
    "632324": "河南",
    "632521": "共和",
    "632522": "同德",
    "632523": "贵德",
    "632524": "兴海",
    "632525": "贵南",
    "632621": "玛沁",
    "632622": "班玛",
    "632623": "甘德",
    "632624": "达日",
    "632625": "久治",
    "632626": "玛多",
    "632701": "玉树市",
    "632722": "杂多",
    "632723": "称多",
    "632724": "治多",
    "632725": "囊谦",
    "632726": "曲麻莱",
    "632801": "格尔木",
    "632802": "德令哈",
    "632803": "茫崖",
    "632821": "乌兰",
    "632822": "都兰",
    "632823": "天峻",
    "632825": "大柴旦行政委员会",
    "640104": "兴庆",
    "640105": "西夏",
    "640106": "金凤",
    "640121": "永宁",
    "640122": "贺兰",
    "640181": "灵武",
    "640202": "大武口",
    "640205": "惠农",
    "640221": "平罗",
    "640302": "利通",
    "640303": "红寺堡",
    "640323": "盐池",
    "640324": "同心",
    "640381": "青铜峡",
    "640402": "原州",
    "640422": "西吉",
    "640423": "隆德",
    "640424": "泾源",
    "640425": "彭阳",
    "640502": "沙坡头",
    "640521": "中宁",
    "640522": "海原",
    "650102": "天山",
    "650103": "沙依巴克区",
    "650104": "新市",
    "650105": "水磨沟",
    "650106": "头屯河",
    "650107": "达坂城",
    "650109": "米东",
    "650121": "乌鲁木齐县",
    "650202": "独山子",
    "650203": "克拉玛依区",
    "650204": "白碱滩",
    "650205": "乌尔禾",
    "650402": "高昌",
    "650421": "鄯善",
    "650422": "托克逊",
    "650502": "伊州",
    "650521": "巴里坤",
    "650522": "伊吾",
    "652301": "昌吉市",
    "652302": "阜康",
    "652323": "呼图壁",
    "652324": "玛纳斯",
    "652325": "奇台",
    "652327": "吉木萨尔",
    "652328": "木垒",
    "652701": "博乐",
    "652702": "阿拉山口",
    "652722": "精河",
    "652723": "温泉",
    "652801": "库尔勒",
    "652822": "轮台",
    "652823": "尉犁",
    "652824": "若羌",
    "652825": "且末",
    "652826": "焉耆",
    "652827": "和静",
    "652828": "和硕",
    "652829": "博湖",
    "652901": "阿克苏市",
    "652902": "库车",
    "652922": "温宿",
    "652924": "沙雅",
    "652925": "新和",
    "652926": "拜城",
    "652927": "乌什",
    "652928": "阿瓦提",
    "652929": "柯坪",
    "653001": "阿图什",
    "653022": "阿克陶",
    "653023": "阿合奇",
    "653024": "乌恰",
    "653101": "喀什市",
    "653121": "疏附",
    "653122": "疏勒",
    "653123": "英吉沙",
    "653124": "泽普",
    "653125": "莎车",
    "653126": "叶城",
    "653127": "麦盖提",
    "653128": "岳普湖",
    "653129": "伽师",
    "653130": "巴楚",
    "653131": "塔什库尔干",
    "653201": "和田市",
    "653221": "和田县",
    "653222": "墨玉",
    "653223": "皮山",
    "653224": "洛浦",
    "653225": "策勒",
    "653226": "于田",
    "653227": "民丰",
    "654002": "伊宁市",
    "654003": "奎屯",
    "654004": "霍尔果斯",
    "654021": "伊宁县",
    "654022": "察布查尔",
    "654023": "霍城",
    "654024": "巩留",
    "654025": "新源",
    "654026": "昭苏",
    "654027": "特克斯",
    "654028": "尼勒克",
    "654201": "塔城市",
    "654202": "乌苏",
    "654203": "沙湾",
    "654221": "额敏",
    "654224": "托里",
    "654225": "裕民",
    "654226": "和布克赛尔",
    "654301": "阿勒泰市",
    "654321": "布尔津",
    "654322": "富蕴",
    "654323": "福海",
    "654324": "哈巴河",
    "654325": "青河",
    "654326": "吉木乃",
    "659001": "石河子",
    "659002": "阿拉尔",
    "659003": "图木舒克",
    "659004": "五家渠",
    "659005": "北屯",
    "659006": "铁门关",
    "659007": "双河",
    "659008": "可克达拉",
    "659009": "昆玉",
    "659010": "胡杨河",
    "659011": "新星",
    "710101": "中正",
    "710102": "大同",
    "710103": "中山",
    "710104": "松山",
    "710105": "大安",
    "710106": "万华",
    "710107": "信义",
    "710108": "士林",
    "710109": "北投",
    "710110": "内湖",
    "710111": "南港",
    "710112": "文山",
    "710201": "新兴",
    "710202": "前金",
    "710203": "苓雅",
    "710204": "盐埕",
    "710205": "鼓山",
    "710206": "旗津",
    "710207": "前镇",
    "710208": "三民",
    "710209": "左营",
    "710210": "楠梓",
    "710211": "小港",
    "710242": "仁武",
    "710243": "大社",
    "710244": "冈山",
    "710245": "路竹",
    "710246": "阿莲",
    "710247": "田寮",
    "710248": "燕巢",
    "710249": "桥头",
    "710250": "梓官",
    "710251": "弥陀",
    "710252": "永安",
    "710253": "湖内",
    "710254": "凤山",
    "710255": "大寮",
    "710256": "林园",
    "710257": "鸟松",
    "710258": "大树",
    "710259": "旗山",
    "710260": "美浓",
    "710261": "六龟",
    "710262": "内门",
    "710263": "杉林",
    "710264": "甲仙",
    "710265": "桃源",
    "710266": "那玛夏",
    "710267": "茂林",
    "710268": "茄萣",
    "710301": "中西",
    "710302": "东区",
    "710303": "南区",
    "710304": "北区",
    "710305": "安平",
    "710306": "安南",
    "710339": "永康",
    "710340": "归仁",
    "710341": "新化",
    "710342": "左镇",
    "710343": "玉井",
    "710344": "楠西",
    "710345": "南化",
    "710346": "仁德",
    "710347": "关庙",
    "710348": "龙崎",
    "710349": "官田",
    "710350": "麻豆",
    "710351": "佳里",
    "710352": "西港",
    "710353": "七股",
    "710354": "将军",
    "710355": "学甲",
    "710356": "北门",
    "710357": "新营",
    "710358": "后壁",
    "710359": "白河",
    "710360": "东山",
    "710361": "六甲",
    "710362": "下营",
    "710363": "柳营",
    "710364": "盐水",
    "710365": "善化",
    "710366": "大内",
    "710367": "山上",
    "710368": "新市",
    "710369": "安定",
    "710401": "中区",
    "710402": "东区",
    "710403": "南区",
    "710404": "西区",
    "710405": "北区",
    "710406": "北屯",
    "710407": "西屯",
    "710408": "南屯",
    "710431": "太平",
    "710432": "大里",
    "710433": "雾峰",
    "710434": "乌日",
    "710435": "丰原",
    "710436": "后里",
    "710437": "石冈",
    "710438": "东势",
    "710439": "和平",
    "710440": "新社",
    "710441": "潭子",
    "710442": "大雅",
    "710443": "神冈",
    "710444": "大肚",
    "710445": "沙鹿",
    "710446": "龙井",
    "710447": "梧栖",
    "710448": "清水",
    "710449": "大甲",
    "710450": "外埔",
    "710451": "大安",
    "710614": "南投市",
    "710615": "中寮",
    "710616": "草屯",
    "710617": "国姓",
    "710618": "埔里",
    "710619": "仁爱",
    "710620": "名间",
    "710621": "集集",
    "710622": "水里",
    "710623": "鱼池",
    "710624": "信义",
    "710625": "竹山",
    "710626": "鹿谷",
    "710701": "仁爱",
    "710702": "信义",
    "710703": "中正",
    "710704": "中山",
    "710705": "安乐",
    "710706": "暖暖",
    "710707": "七堵",
    "710801": "东区",
    "710802": "北区",
    "710803": "香山",
    "710901": "东区",
    "710902": "西区",
    "711130": "万里",
    "711131": "金山",
    "711132": "板桥",
    "711133": "汐止",
    "711134": "深坑",
    "711135": "石碇",
    "711136": "瑞芳",
    "711137": "平溪",
    "711138": "双溪",
    "711139": "贡寮",
    "711140": "新店",
    "711141": "坪林",
    "711142": "乌来",
    "711143": "永和",
    "711144": "中和",
    "711145": "土城",
    "711146": "三峡",
    "711147": "树林",
    "711148": "莺歌",
    "711149": "三重",
    "711150": "新庄",
    "711151": "泰山",
    "711152": "林口",
    "711153": "芦洲",
    "711154": "五股",
    "711155": "八里",
    "711156": "淡水",
    "711157": "三芝",
    "711158": "石门",
    "711214": "宜兰市",
    "711215": "头城",
    "711216": "礁溪",
    "711217": "壮围",
    "711218": "员山",
    "711219": "罗东",
    "711220": "三星",
    "711221": "大同",
    "711222": "五结",
    "711223": "冬山",
    "711224": "苏澳",
    "711225": "南澳",
    "711314": "竹北",
    "711315": "湖口",
    "711316": "新丰",
    "711317": "新埔",
    "711318": "关西",
    "711319": "芎林",
    "711320": "宝山",
    "711321": "竹东",
    "711322": "五峰",
    "711323": "横山",
    "711324": "尖石",
    "711325": "北埔",
    "711326": "峨眉",
    "711414": "中坜",
    "711415": "平镇",
    "711416": "龙潭",
    "711417": "杨梅",
    "711418": "新屋",
    "711419": "观音",
    "711420": "桃园区",
    "711421": "龟山",
    "711422": "八德",
    "711423": "大溪",
    "711424": "复兴",
    "711425": "大园",
    "711426": "芦竹",
    "711519": "竹南",
    "711520": "头份",
    "711521": "三湾",
    "711522": "南庄",
    "711523": "狮潭",
    "711524": "后龙",
    "711525": "通霄",
    "711526": "苑里",
    "711527": "苗栗市",
    "711528": "造桥",
    "711529": "头屋",
    "711530": "公馆",
    "711531": "大湖",
    "711532": "泰安",
    "711533": "铜锣",
    "711534": "三义",
    "711535": "西湖",
    "711536": "卓兰",
    "711727": "彰化市",
    "711728": "芬园",
    "711729": "花坛",
    "711730": "秀水",
    "711731": "鹿港",
    "711732": "福兴",
    "711733": "线西",
    "711734": "和美",
    "711735": "伸港",
    "711736": "员林",
    "711737": "社头",
    "711738": "永靖",
    "711739": "埔心",
    "711740": "溪湖",
    "711741": "大村",
    "711742": "埔盐",
    "711743": "田中",
    "711744": "北斗",
    "711745": "田尾",
    "711746": "埤头",
    "711747": "溪州",
    "711748": "竹塘",
    "711749": "二林",
    "711750": "大城",
    "711751": "芳苑",
    "711752": "二水",
    "711919": "番路",
    "711920": "梅山",
    "711921": "竹崎",
    "711922": "阿里山",
    "711923": "中埔",
    "711924": "大埔",
    "711925": "水上",
    "711926": "鹿草",
    "711927": "太保",
    "711928": "朴子",
    "711929": "东石",
    "711930": "六脚",
    "711931": "新港",
    "711932": "民雄",
    "711933": "大林",
    "711934": "溪口",
    "711935": "义竹",
    "711936": "布袋",
    "712121": "斗南",
    "712122": "大埤",
    "712123": "虎尾",
    "712124": "土库",
    "712125": "褒忠",
    "712126": "东势",
    "712127": "台西",
    "712128": "仑背",
    "712129": "麦寮",
    "712130": "斗六",
    "712131": "林内",
    "712132": "古坑",
    "712133": "莿桐",
    "712134": "西螺",
    "712135": "二仑",
    "712136": "北港",
    "712137": "水林",
    "712138": "口湖",
    "712139": "四湖",
    "712140": "元长",
    "712434": "屏东市",
    "712435": "三地门",
    "712436": "雾台",
    "712437": "玛家",
    "712438": "九如",
    "712439": "里港",
    "712440": "高树",
    "712441": "盐埔",
    "712442": "长治",
    "712443": "麟洛",
    "712444": "竹田",
    "712445": "内埔",
    "712446": "万丹",
    "712447": "潮州",
    "712448": "泰武",
    "712449": "来义",
    "712450": "万峦",
    "712451": "崁顶",
    "712452": "新埤",
    "712453": "南州",
    "712454": "林边",
    "712455": "东港",
    "712456": "琉球",
    "712457": "佳冬",
    "712458": "新园",
    "712459": "枋寮",
    "712460": "枋山",
    "712461": "春日",
    "712462": "狮子",
    "712463": "车城",
    "712464": "牡丹",
    "712465": "恒春",
    "712466": "满州",
    "712517": "台东市",
    "712518": "绿岛",
    "712519": "兰屿",
    "712520": "延平",
    "712521": "卑南",
    "712522": "鹿野",
    "712523": "关山",
    "712524": "海端",
    "712525": "池上",
    "712526": "东河",
    "712527": "成功",
    "712528": "长滨",
    "712529": "金峰",
    "712530": "大武",
    "712531": "达仁",
    "712532": "太麻里",
    "712615": "花莲市",
    "712616": "新城",
    "712618": "秀林",
    "712619": "吉安",
    "712620": "寿丰",
    "712621": "凤林",
    "712622": "光复",
    "712623": "丰滨",
    "712624": "瑞穗",
    "712625": "万荣",
    "712626": "玉里",
    "712627": "卓溪",
    "712628": "富里",
    "712707": "马公",
    "712708": "西屿",
    "712709": "望安",
    "712710": "七美",
    "712711": "白沙",
    "712712": "湖西",
  },
};
