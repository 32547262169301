
import { defineComponent } from "vue";
import { Field, CellGroup, Button, Checkbox, Dialog, Notify } from "vant";

import { getHostType } from "@/utils/host";
import { resetHistoryIniOS } from "@/utils/host";
import { HostTypes } from "@/enums/host";
import { CONFIGS } from "@/config";
import {
  getUrlParam,
  getUrlParamWithPlusUntouched,
  hardReload,
} from "@/utils/url";
import { mobileLogin } from "@/api/auth";

declare const APP: any;

export default defineComponent({
  name: "Login",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      hostOS: "",

      mobile_id: "",
      mobile_type: "",

      isLoading: true,
      isLogining: false,

      loginname: "",
      password: "",

      isAgreedTerm: false,
      showAdvisorDialog: false,

      showCopyright: true,

      resizeListener: null,
      originalHeight: 0,
    };
  },

  watch: {

  },

  created() {
    this.hostOS = getHostType();

    //版本检测
    if (this.hostOS !== HostTypes.WeChat) {
      const version = getUrlParam("v");
      if (version !== CONFIGS.APP_VERSION) {
        if (this.hostOS === HostTypes.iOS) {
          Dialog.alert({
            message: "您的版本已落后，请前往App Store更新",
          });
          (window as any).bridge?.call(
            "updateSoftware",
            CONFIGS.APP_STORE_LINK,
            () => { },
            () => { }
          );
        }

        hardReload("/update");
      }
    } else {
      this.isLoading = false;
    }

    this.mobile_id = getUrlParamWithPlusUntouched("mobile_id");
    this.mobile_type = getUrlParam("mobile_type");
    this.loginname = localStorage.getItem("loginname") || "";

    localStorage.setItem("mobile_id", this.mobile_id);
    localStorage.setItem("mobile_type", this.mobile_type);
    localStorage.setItem("device", getUrlParam("device"));
    localStorage.setItem("os", getUrlParam("os"));
    localStorage.setItem("SDK", getUrlParam("SDK"));
    localStorage.setItem("version", getUrlParam("v"));

    this.getMobileSecret((secret: string = "") => {
      if (!secret) {
        this.isLoading = false;
        return;
      }

      this.loginWithSecret(secret).then(
        (res: any) => {
          this.successLogin(res.data.result);
        },

        () => {
          this.isLoading = false;
        }
      );
    });
  },

  mounted: function () {
    //退出登陆后有个奇怪的bug
    //在登录页点击咨询客服无法返回，需要手动写入history
    if (JSON.stringify(history.state) === "null") {
      window.history.pushState({}, "", window.location.href);
    }

    if (localStorage.getItem("loggin_time")) {
      this.isAgreedTerm = true;
    }

    this.originalHeight = document.documentElement.clientHeight || document.body.clientHeight;

    window.addEventListener('resize', this.handleResize);


  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {

      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (resizeHeight - 0 < this.originalHeight - 0) {
        //当软键盘弹起，在此处操作
        console.log("进入到软键盘弹起:");
        this.showCopyright = false;
      } else {
        //当软键盘收起，在此处操作
        console.log("进入到软键盘收起:");
        this.showCopyright = true;
      }
    },

    changeReadTerm() {
      localStorage.setItem(
        "loggin_time",
        this.isAgreedTerm ? new Date().toString() : ""
      );
    },

    closeAdvisorDialog() {
      Notify({ type: "success", message: "复制成功" });
      this.showAdvisorDialog = false;
    },

    manualLogin() {
      if (!this.mobile_id) {
        Dialog.alert({
          message: "无效的mobile_id, 请重新打开APP或者联系客服",
        });
        return;
      }

      if (!this.isAgreedTerm) {
        Dialog.alert({
          message: "请先勾选云雀用户协议和隐私条款",
        });
        return;
      }

      if (!this.loginname || !this.password) return;

      this.isLogining = true;

      mobileLogin({
        mobile_type: this.mobile_type,
        mobile_id: this.mobile_id,
        loginname: this.loginname?.trim() || "",
        password: this.password?.trim() || "",
      }).then(
        (res: any) => {

          localStorage.setItem("loginname", this.loginname);
          localStorage.setItem("mobile_id", this.mobile_id);

          this.sendForegroundLoginNotification();
          this.successLogin(res.data.result);
        },
        (error: any) => {
          this.isLogining = false;

          Dialog.alert({
            message: error?.response?.data?.message || "登录出错",
          });
        }
      );
    },

    saveMobileSecret(secret: string) {
      switch (this.hostOS) {
        case HostTypes.Android:
          (window as any).APP.saveMobileSecret(secret);
          break;
        case HostTypes.iOS:
          (window as any).bridge.call(
            "saveMobileSecret",
            secret,
            () => { },
            () => { }
          );
          break;

        default:
          break;
      }
    },

    getMobileSecret(callback: any) {
      switch (this.hostOS) {
        case HostTypes.Android:
          callback((window as any).APP.getMobileSecret());
          break;
        case HostTypes.iOS:
          (window as any).bridge.call(
            "getMobileSecret",
            null,
            (secret: string) => {
              callback(secret);
            },
            () => { }
          );
          break;

        default:
          break;
      }
    },

    loginWithSecret(mobile_secret: string) {
      return mobileLogin({
        mobile_secret,
        mobile_id: this.mobile_id,
        mobile_type: this.mobile_type,
      });
    },

    successLogin(result: any) {
      let { token, mobile_secret, company_id } = result;

      localStorage.setItem("token", token);
      localStorage.setItem("mobile_secret", mobile_secret);
      this.saveMobileSecret(mobile_secret);

      localStorage.removeItem("showHuaweiClientTip");

      resetHistoryIniOS();

      hardReload("/loading?token=" + token + "&company_id=" + company_id);
    },

    sendForegroundLoginNotification() {
      const mobile_type = localStorage.getItem("mobile_type");
      if (mobile_type === "android-umeng" && typeof APP !== "undefined") {
        APP.showForegroundLoginNotification(localStorage.getItem("loginname"));
      }
    },
  },
});
