<template>
  <div class="weibo-emoji-panel">
    <div
      class="emoji emoji-wechat"
      @click="chooseEmoji(code)"
      v-for="(obj, code) in weiboEmojis"
      v-bind:key="code"
    >
      <img :src="obj.url" alt="" />
    </div>
  </div>
</template>

<script>
import { WEIBO_FACES } from "@/filters/weiboface";

export default {
  data() {
    return {
      weiboEmojis: WEIBO_FACES,
    };
  },

  methods: {
    chooseEmoji(code) {
      this.$emit("chooseEmoji", code);
      return;
    },
  },

  mounted() {},
};
</script>

<style lang="less" scoped>
.weibo-emoji-panel {
  display: flex;
  flex-wrap: wrap;
}

.emoji {
  width: calc(100% / 8);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
</style>
