function escapeRegExp(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export const WEIBO_FACES: any = {
  "[坏笑]": {
    phrase: "[坏笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/pcmoren_huaixiao_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/pcmoren_huaixiao_thumb.png",
    value: "[坏笑]",
    picid: "",
  },
  "[舔屏]": {
    phrase: "[舔屏]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/pcmoren_tian_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/pcmoren_tian_thumb.png",
    value: "[舔屏]",
    picid: "",
  },
  "[污]": {
    phrase: "[污]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/pcmoren_wu_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/pcmoren_wu_thumb.png",
    value: "[污]",
    picid: "",
  },
  "[允悲]": {
    phrase: "[允悲]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2c/moren_yunbei_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2c/moren_yunbei_thumb.png",
    value: "[允悲]",
    picid: "",
  },
  "[笑而不语]": {
    phrase: "[笑而不语]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3a/moren_xiaoerbuyu_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3a/moren_xiaoerbuyu_thumb.png",
    value: "[笑而不语]",
    picid: "",
  },
  "[费解]": {
    phrase: "[费解]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/moren_feijie_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/moren_feijie_thumb.png",
    value: "[费解]",
    picid: "",
  },
  "[憧憬]": {
    phrase: "[憧憬]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/37/moren_chongjing_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/37/moren_chongjing_thumb.png",
    value: "[憧憬]",
    picid: "",
  },
  "[并不简单]": {
    phrase: "[并不简单]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fc/moren_bbjdnew_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fc/moren_bbjdnew_thumb.png",
    value: "[并不简单]",
    picid: "",
  },
  "[微笑]": {
    phrase: "[微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/5c/huanglianwx_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/5c/huanglianwx_thumb.gif",
    value: "[微笑]",
    picid: "",
  },
  "[酷]": {
    phrase: "[酷]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8a/pcmoren_cool2017_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8a/pcmoren_cool2017_thumb.png",
    value: "[酷]",
    picid: "",
  },
  "[嘻嘻]": {
    phrase: "[嘻嘻]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0b/tootha_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0b/tootha_thumb.gif",
    value: "[嘻嘻]",
    picid: "",
  },
  "[哈哈]": {
    phrase: "[哈哈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6a/laugh.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6a/laugh.gif",
    value: "[哈哈]",
    picid: "",
  },
  "[可爱]": {
    phrase: "[可爱]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/14/tza_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/14/tza_thumb.gif",
    value: "[可爱]",
    picid: "",
  },
  "[可怜]": {
    phrase: "[可怜]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/af/kl_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/af/kl_thumb.gif",
    value: "[可怜]",
    picid: "",
  },
  "[挖鼻]": {
    phrase: "[挖鼻]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0b/wabi_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0b/wabi_thumb.gif",
    value: "[挖鼻]",
    picid: "",
  },
  "[吃惊]": {
    phrase: "[吃惊]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f4/cj_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f4/cj_thumb.gif",
    value: "[吃惊]",
    picid: "",
  },
  "[害羞]": {
    phrase: "[害羞]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/shamea_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/shamea_thumb.gif",
    value: "[害羞]",
    picid: "",
  },
  "[挤眼]": {
    phrase: "[挤眼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c3/zy_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c3/zy_thumb.gif",
    value: "[挤眼]",
    picid: "",
  },
  "[闭嘴]": {
    phrase: "[闭嘴]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/29/bz_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/29/bz_thumb.gif",
    value: "[闭嘴]",
    picid: "",
  },
  "[鄙视]": {
    phrase: "[鄙视]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/71/bs2_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/71/bs2_thumb.gif",
    value: "[鄙视]",
    picid: "",
  },
  "[爱你]": {
    phrase: "[爱你]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/lovea_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/lovea_thumb.gif",
    value: "[爱你]",
    picid: "",
  },
  "[泪]": {
    phrase: "[泪]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9d/sada_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9d/sada_thumb.gif",
    value: "[泪]",
    picid: "",
  },
  "[偷笑]": {
    phrase: "[偷笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/19/heia_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/19/heia_thumb.gif",
    value: "[偷笑]",
    picid: "",
  },
  "[亲亲]": {
    phrase: "[亲亲]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8f/qq_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8f/qq_thumb.gif",
    value: "[亲亲]",
    picid: "",
  },
  "[生病]": {
    phrase: "[生病]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b6/sb_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b6/sb_thumb.gif",
    value: "[生病]",
    picid: "",
  },
  "[太开心]": {
    phrase: "[太开心]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/58/mb_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/58/mb_thumb.gif",
    value: "[太开心]",
    picid: "",
  },
  "[白眼]": {
    phrase: "[白眼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/landeln_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/landeln_thumb.gif",
    value: "[白眼]",
    picid: "",
  },
  "[右哼哼]": {
    phrase: "[右哼哼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/98/yhh_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/98/yhh_thumb.gif",
    value: "[右哼哼]",
    picid: "",
  },
  "[左哼哼]": {
    phrase: "[左哼哼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/zhh_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/zhh_thumb.gif",
    value: "[左哼哼]",
    picid: "",
  },
  "[嘘]": {
    phrase: "[嘘]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a6/x_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a6/x_thumb.gif",
    value: "[嘘]",
    picid: "",
  },
  "[衰]": {
    phrase: "[衰]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/af/cry.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/af/cry.gif",
    value: "[衰]",
    picid: "",
  },
  "[委屈]": {
    phrase: "[委屈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/73/wq_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/73/wq_thumb.gif",
    value: "[委屈]",
    picid: "",
  },
  "[吐]": {
    phrase: "[吐]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/t_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/t_thumb.gif",
    value: "[吐]",
    picid: "",
  },
  "[哈欠]": {
    phrase: "[哈欠]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/cc/haqianv2_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/cc/haqianv2_thumb.gif",
    value: "[哈欠]",
    picid: "",
  },
  "[抱抱_旧]": {
    phrase: "[抱抱_旧]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/27/bba_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/27/bba_thumb.gif",
    value: "[抱抱_旧]",
    picid: "",
  },
  "[怒]": {
    phrase: "[怒]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7c/angrya_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7c/angrya_thumb.gif",
    value: "[怒]",
    picid: "",
  },
  "[疑问]": {
    phrase: "[疑问]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/5c/yw_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/5c/yw_thumb.gif",
    value: "[疑问]",
    picid: "",
  },
  "[馋嘴]": {
    phrase: "[馋嘴]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a5/cza_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a5/cza_thumb.gif",
    value: "[馋嘴]",
    picid: "",
  },
  "[拜拜]": {
    phrase: "[拜拜]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/88_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/88_thumb.gif",
    value: "[拜拜]",
    picid: "",
  },
  "[思考]": {
    phrase: "[思考]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e9/sk_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e9/sk_thumb.gif",
    value: "[思考]",
    picid: "",
  },
  "[汗]": {
    phrase: "[汗]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/24/sweata_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/24/sweata_thumb.gif",
    value: "[汗]",
    picid: "",
  },
  "[困]": {
    phrase: "[困]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/kunv2_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/kunv2_thumb.gif",
    value: "[困]",
    picid: "",
  },
  "[睡]": {
    phrase: "[睡]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/96/huangliansj_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/96/huangliansj_thumb.gif",
    value: "[睡]",
    picid: "",
  },
  "[钱]": {
    phrase: "[钱]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/90/money_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/90/money_thumb.gif",
    value: "[钱]",
    picid: "",
  },
  "[失望]": {
    phrase: "[失望]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0c/sw_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0c/sw_thumb.gif",
    value: "[失望]",
    picid: "",
  },
  "[色]": {
    phrase: "[色]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/20/huanglianse_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/20/huanglianse_thumb.gif",
    value: "[色]",
    picid: "",
  },
  "[哼]": {
    phrase: "[哼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/49/hatea_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/49/hatea_thumb.gif",
    value: "[哼]",
    picid: "",
  },
  "[鼓掌]": {
    phrase: "[鼓掌]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/36/gza_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/36/gza_thumb.gif",
    value: "[鼓掌]",
    picid: "",
  },
  "[晕]": {
    phrase: "[晕]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/dizzya_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/dizzya_thumb.gif",
    value: "[晕]",
    picid: "",
  },
  "[悲伤]": {
    phrase: "[悲伤]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1a/bs_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1a/bs_thumb.gif",
    value: "[悲伤]",
    picid: "",
  },
  "[抓狂]": {
    phrase: "[抓狂]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/62/crazya_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/62/crazya_thumb.gif",
    value: "[抓狂]",
    picid: "",
  },
  "[黑线]": {
    phrase: "[黑线]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/91/h_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/91/h_thumb.gif",
    value: "[黑线]",
    picid: "",
  },
  "[阴险]": {
    phrase: "[阴险]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/yx_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/yx_thumb.gif",
    value: "[阴险]",
    picid: "",
  },
  "[怒骂]": {
    phrase: "[怒骂]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/60/numav2_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/60/numav2_thumb.gif",
    value: "[怒骂]",
    picid: "",
  },
  "[互粉]": {
    phrase: "[互粉]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/89/hufen_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/89/hufen_thumb.gif",
    value: "[互粉]",
    picid: "",
  },
  "[打脸]": {
    phrase: "[打脸]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/32/dalian_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/32/dalian_thumb.gif",
    value: "[打脸]",
    picid: "",
  },
  "[傻眼]": {
    phrase: "[傻眼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2b/shayan_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2b/shayan_thumb.gif",
    value: "[傻眼]",
    picid: "",
  },
  "[感冒]": {
    phrase: "[感冒]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a0/gm_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a0/gm_thumb.gif",
    value: "[感冒]",
    picid: "",
  },
  "[顶]": {
    phrase: "[顶]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/91/d_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/91/d_thumb.gif",
    value: "[顶]",
    picid: "",
  },
  "[ok]": {
    phrase: "[ok]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/ok_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/ok_thumb.gif",
    value: "[ok]",
    picid: "",
  },
  "[耶]": {
    phrase: "[耶]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/ye_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/ye_thumb.gif",
    value: "[耶]",
    picid: "",
  },
  "[good]": {
    phrase: "[good]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d8/good_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d8/good_thumb.gif",
    value: "[good]",
    picid: "",
  },
  "[NO]": {
    phrase: "[NO]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ae/buyao_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ae/buyao_org.gif",
    value: "[NO]",
    picid: "",
  },
  "[赞]": {
    phrase: "[赞]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d0/z2_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d0/z2_thumb.gif",
    value: "[赞]",
    picid: "",
  },
  "[来]": {
    phrase: "[来]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/come_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/come_thumb.gif",
    value: "[来]",
    picid: "",
  },
  "[弱]": {
    phrase: "[弱]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d8/sad_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d8/sad_thumb.gif",
    value: "[弱]",
    picid: "",
  },
  "[握手]": {
    phrase: "[握手]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0c/ws_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/0c/ws_thumb.gif",
    value: "[握手]",
    picid: "",
  },
  "[拳头]": {
    phrase: "[拳头]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/cc/o_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/cc/o_thumb.gif",
    value: "[拳头]",
    picid: "",
  },
  "[haha]": {
    phrase: "[haha]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/13/ha_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/13/ha_thumb.gif",
    value: "[haha]",
    picid: "",
  },
  "[加油]": {
    phrase: "[加油]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e7/pcmoren_jiayou_org.png",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e7/pcmoren_jiayou_thumb.png",
    value: "[加油]",
    picid: "",
  },
  "[心]": {
    phrase: "[心]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/hearta_org.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/hearta_thumb.gif",
    value: "[心]",
    picid: "",
  },
  "[伤心]": {
    phrase: "[伤心]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ea/unheart.gif",
    hot: false,
    common: true,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ea/unheart.gif",
    value: "[伤心]",
    picid: "",
  },
  "[中国赞]": {
    phrase: "[中国赞]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b7/moren_zhongguozan_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b7/moren_zhongguozan_thumb.png",
    value: "[中国赞]",
    picid: "",
  },
  "[广告]": {
    phrase: "[广告]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/60/ad_new0902_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/60/ad_new0902_thumb.gif",
    value: "[广告]",
    picid: "",
  },
  "[弗莱见钱眼开]": {
    phrase: "[弗莱见钱眼开]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/83/2018newyear_richdog_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/83/2018newyear_richdog_thumb.gif",
    value: "[弗莱见钱眼开]",
    picid: "",
  },
  "[doge]": {
    phrase: "[doge]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b6/doge_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b6/doge_thumb.gif",
    value: "[doge]",
    picid: "",
  },
  "[喵喵]": {
    phrase: "[喵喵]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4a/mm_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4a/mm_thumb.gif",
    value: "[喵喵]",
    picid: "",
  },
  "[二哈]": {
    phrase: "[二哈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/74/moren_hashiqi_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/74/moren_hashiqi_thumb.png",
    value: "[二哈]",
    picid: "",
  },

  "[笑cry]": {
    phrase: "[笑cry]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/34/xiaoku_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/34/xiaoku_thumb.gif",
    value: "[笑cry]",
    picid: "",
  },
  "[摊手]": {
    phrase: "[摊手]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/09/pcmoren_tanshou_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/09/pcmoren_tanshou_thumb.png",
    value: "[摊手]",
    picid: "",
  },
  "[抱抱]": {
    phrase: "[抱抱]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/pcmoren_baobao_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/pcmoren_baobao_thumb.png",
    value: "[抱抱]",
    picid: "",
  },
  "[跪了]": {
    phrase: "[跪了]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6c/pcmoren_guile_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6c/pcmoren_guile_thumb.png",
    value: "[跪了]",
    picid: "",
  },
  "[吃瓜]": {
    phrase: "[吃瓜]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8a/moren_chiguaqunzhong_org.png",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8a/moren_chiguaqunzhong_thumb.png",
    value: "[吃瓜]",
    picid: "",
  },

  "[星星]": {
    phrase: "[星星]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/76/hot_star171109_org.png",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/76/hot_star171109_thumb.png",
    value: "[星星]",
    picid: "",
  },
  "[半星]": {
    phrase: "[半星]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f9/hot_halfstar_org.png",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f9/hot_halfstar_thumb.png",
    value: "[半星]",
    picid: "",
  },
  "[空星]": {
    phrase: "[空星]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ff/hot_blankstar_org.png",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ff/hot_blankstar_thumb.png",
    value: "[空星]",
    picid: "",
  },
  "[看涨]": {
    phrase: "[看涨]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fe/kanzhangv2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fe/kanzhangv2_thumb.gif",
    value: "[看涨]",
    picid: "",
  },
  "[看跌]": {
    phrase: "[看跌]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c5/kandiev2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c5/kandiev2_thumb.gif",
    value: "[看跌]",
    picid: "",
  },
  "[蛋糕]": {
    phrase: "[蛋糕]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3a/cakev2_thumb.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3a/cakev2_thumb.gif",
    value: "[蛋糕]",
    picid: "",
  },
  "[话筒]": {
    phrase: "[话筒]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9f/huatongv2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9f/huatongv2_thumb.gif",
    value: "[话筒]",
    picid: "",
  },
  "[蜡烛]": {
    phrase: "[蜡烛]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/lazhuv2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d9/lazhuv2_thumb.gif",
    value: "[蜡烛]",
    picid: "",
  },
  "[给力]": {
    phrase: "[给力]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1e/geiliv2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1e/geiliv2_thumb.gif",
    value: "[给力]",
    picid: "",
  },
  "[带着微博去旅行]": {
    phrase: "[带着微博去旅行]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ec/eventtravel_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ec/eventtravel_thumb.gif",
    value: "[带着微博去旅行]",
    picid: "",
  },
  "[作揖]": {
    phrase: "[作揖]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/06/zuoyi_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/06/zuoyi_thumb.gif",
    value: "[作揖]",
    picid: "",
  },
  "[浪]": {
    phrase: "[浪]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c6/lang_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c6/lang_thumb.gif",
    value: "[浪]",
    picid: "",
  },
  "[草泥马]": {
    phrase: "[草泥马]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7a/shenshou_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7a/shenshou_thumb.gif",
    value: "[草泥马]",
    picid: "",
  },
  "[偷乐]": {
    phrase: "[偷乐]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fa/lxhtouxiao_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fa/lxhtouxiao_thumb.gif",
    value: "[偷乐]",
    picid: "",
  },
  "[羞嗒嗒]": {
    phrase: "[羞嗒嗒]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/df/lxhxiudada_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/df/lxhxiudada_thumb.gif",
    value: "[羞嗒嗒]",
    picid: "",
  },
  "[好爱哦]": {
    phrase: "[好爱哦]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/74/lxhainio_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/74/lxhainio_thumb.gif",
    value: "[好爱哦]",
    picid: "",
  },
  "[赞啊]": {
    phrase: "[赞啊]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/00/lxhzan_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/00/lxhzan_thumb.gif",
    value: "[赞啊]",
    picid: "",
  },
  "[求关注]": {
    phrase: "[求关注]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ac/lxhqiuguanzhu_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ac/lxhqiuguanzhu_thumb.gif",
    value: "[求关注]",
    picid: "",
  },
  "[好喜欢]": {
    phrase: "[好喜欢]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/lxhlike_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/lxhlike_thumb.gif",
    value: "[好喜欢]",
    picid: "",
  },
  "[肥皂]": {
    phrase: "[肥皂]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e5/soap_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e5/soap_thumb.gif",
    value: "[肥皂]",
    picid: "",
  },

  "[许愿]": {
    phrase: "[许愿]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/87/lxhxuyuan_org.gif",
    hot: true,
    common: false,
    category: "",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/87/lxhxuyuan_thumb.gif",
    value: "[许愿]",
    picid: "",
  },

  "[笑哈哈]": {
    phrase: "[笑哈哈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/32/lxhwahaha_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/32/lxhwahaha_thumb.gif",
    value: "[笑哈哈]",
    picid: "",
  },
  "[绿丝带]": {
    phrase: "[绿丝带]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9b/green_band_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9b/green_band_thumb.gif",
    value: "[绿丝带]",
    picid: "",
  },
  "[女孩儿]": {
    phrase: "[女孩儿]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1b/kissgirl_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/1b/kissgirl_thumb.gif",
    value: "[女孩儿]",
    picid: "",
  },
  "[男孩儿]": {
    phrase: "[男孩儿]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4e/kissboy_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4e/kissboy_thumb.gif",
    value: "[男孩儿]",
    picid: "",
  },
  "[浮云]": {
    phrase: "[浮云]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bc/fuyun_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bc/fuyun_thumb.gif",
    value: "[浮云]",
    picid: "",
  },
  "[兔子]": {
    phrase: "[兔子]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/81/rabbit_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/81/rabbit_thumb.gif",
    value: "[兔子]",
    picid: "",
  },
  "[熊猫]": {
    phrase: "[熊猫]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/panda_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/panda_thumb.gif",
    value: "[熊猫]",
    picid: "",
  },

  "[礼物]": {
    phrase: "[礼物]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c4/liwu_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c4/liwu_thumb.gif",
    value: "[礼物]",
    picid: "",
  },
  "[喜]": {
    phrase: "[喜]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bf/xi_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bf/xi_thumb.gif",
    value: "[喜]",
    picid: "",
  },
  "[围脖]": {
    phrase: "[围脖]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3f/weijin_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3f/weijin_thumb.gif",
    value: "[围脖]",
    picid: "",
  },
  "[飞机]": {
    phrase: "[飞机]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/travel_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6d/travel_thumb.gif",
    value: "[飞机]",
    picid: "",
  },
  "[围观]": {
    phrase: "[围观]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f2/wg_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f2/wg_thumb.gif",
    value: "[围观]",
    picid: "",
  },
  "[威武]": {
    phrase: "[威武]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/vw_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/70/vw_thumb.gif",
    value: "[威武]",
    picid: "",
  },
  "[沙尘暴]": {
    phrase: "[沙尘暴]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/69/sc_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/69/sc_thumb.gif",
    value: "[沙尘暴]",
    picid: "",
  },
  "[奥特曼]": {
    phrase: "[奥特曼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bc/otm_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bc/otm_thumb.gif",
    value: "[奥特曼]",
    picid: "",
  },
  "[骷髅]": {
    phrase: "[骷髅]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bd/kl2_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bd/kl2_thumb.gif",
    value: "[骷髅]",
    picid: "",
  },

  "[钟]": {
    phrase: "[钟]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d3/clock_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d3/clock_thumb.gif",
    value: "[钟]",
    picid: "",
  },
  "[照相机]": {
    phrase: "[照相机]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/33/camera_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/33/camera_thumb.gif",
    value: "[照相机]",
    picid: "",
  },
  "[微风]": {
    phrase: "[微风]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a5/wind_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a5/wind_thumb.gif",
    value: "[微风]",
    picid: "",
  },
  "[太阳]": {
    phrase: "[太阳]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e5/sun.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e5/sun.gif",
    value: "[太阳]",
    picid: "",
  },
  "[下雨]": {
    phrase: "[下雨]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/rain.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/rain.gif",
    value: "[下雨]",
    picid: "",
  },
  "[猪头]": {
    phrase: "[猪头]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/58/pig.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/58/pig.gif",
    value: "[猪头]",
    picid: "",
  },
  "[音乐]": {
    phrase: "[音乐]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d0/music_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d0/music_thumb.gif",
    value: "[音乐]",
    picid: "",
  },
  "[月亮]": {
    phrase: "[月亮]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b9/moon.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b9/moon.gif",
    value: "[月亮]",
    picid: "",
  },
  "[鲜花]": {
    phrase: "[鲜花]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6c/flower_org.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6c/flower_thumb.gif",
    value: "[鲜花]",
    picid: "",
  },
  "[干杯]": {
    phrase: "[干杯]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bd/cheer.gif",
    hot: false,
    common: false,
    category: "热门",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bd/cheer.gif",
    value: "[干杯]",
    picid: "",
  },
  "[哆啦A梦吃惊]": {
    phrase: "[哆啦A梦吃惊]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f0/dorachijing_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f0/dorachijing_thumb.gif",
    value: "[哆啦A梦吃惊]",
    picid: "",
  },
  "[哆啦A梦花心]": {
    phrase: "[哆啦A梦花心]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/08/dorahaose_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/08/dorahaose_thumb.gif",
    value: "[哆啦A梦花心]",
    picid: "",
  },
  "[哆啦A梦害怕]": {
    phrase: "[哆啦A梦害怕]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c7/dorahaipa_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c7/dorahaipa_thumb.gif",
    value: "[哆啦A梦害怕]",
    picid: "",
  },
  "[哆啦A梦汗]": {
    phrase: "[哆啦A梦汗]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/61/dorahan_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/61/dorahan_thumb.gif",
    value: "[哆啦A梦汗]",
    picid: "",
  },
  "[哆啦A梦微笑]": {
    phrase: "[哆啦A梦微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/jqmweixiao_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/jqmweixiao_thumb.gif",
    value: "[哆啦A梦微笑]",
    picid: "",
  },
  "[伴我同行]": {
    phrase: "[伴我同行]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ef/jqmbwtxing_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ef/jqmbwtxing_thumb.gif",
    value: "[伴我同行]",
    picid: "",
  },
  "[静香微笑]": {
    phrase: "[静香微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/29/jiqimaojingxiang_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/29/jiqimaojingxiang_thumb.gif",
    value: "[静香微笑]",
    picid: "",
  },
  "[大雄微笑]": {
    phrase: "[大雄微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8e/jiqimaodaxiong_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8e/jiqimaodaxiong_thumb.gif",
    value: "[大雄微笑]",
    picid: "",
  },
  "[胖虎微笑]": {
    phrase: "[胖虎微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2f/jiqimaopanghu_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/2f/jiqimaopanghu_thumb.gif",
    value: "[胖虎微笑]",
    picid: "",
  },
  "[小夫微笑]": {
    phrase: "[小夫微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/21/jiqimaoxiaofu_org.gif",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/21/jiqimaoxiaofu_thumb.gif",
    value: "[小夫微笑]",
    picid: "",
  },
  "[哆啦A梦笑]": {
    phrase: "[哆啦A梦笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/54/dora_xiao_org.png",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/54/dora_xiao_thumb.png",
    value: "[哆啦A梦笑]",
    picid: "",
  },
  "[哆啦A梦无奈]": {
    phrase: "[哆啦A梦无奈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/96/dora_wunai_org.png",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/96/dora_wunai_thumb.png",
    value: "[哆啦A梦无奈]",
    picid: "",
  },
  "[哆啦A梦美味]": {
    phrase: "[哆啦A梦美味]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/21/dora_meiwei_org.png",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/21/dora_meiwei_thumb.png",
    value: "[哆啦A梦美味]",
    picid: "",
  },
  "[哆啦A梦开心]": {
    phrase: "[哆啦A梦开心]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/df/dora_kaixin_org.png",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/df/dora_kaixin_thumb.png",
    value: "[哆啦A梦开心]",
    picid: "",
  },
  "[哆啦A梦亲亲]": {
    phrase: "[哆啦A梦亲亲]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e0/dora_qinqin_org.png",
    hot: false,
    common: false,
    category: "哆啦A梦",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e0/dora_qinqin_thumb.png",
    value: "[哆啦A梦亲亲]",
    picid: "",
  },
  "[小黄人高兴]": {
    phrase: "[小黄人高兴]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8d/xiaohuangren_gaoxing_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/8d/xiaohuangren_gaoxing_thumb.png",
    value: "[小黄人高兴]",
    picid: "",
  },
  "[小黄人剪刀手]": {
    phrase: "[小黄人剪刀手]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ed/xiaohuangren_jiandaoshou_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/ed/xiaohuangren_jiandaoshou_thumb.png",
    value: "[小黄人剪刀手]",
    picid: "",
  },
  "[小黄人坏笑]": {
    phrase: "[小黄人坏笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/xiaohuangren_huaixiao_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/d6/xiaohuangren_huaixiao_thumb.png",
    value: "[小黄人坏笑]",
    picid: "",
  },
  "[小黄人微笑]": {
    phrase: "[小黄人微笑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b3/xiaohuangren_weixiao_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b3/xiaohuangren_weixiao_org.png",
    value: "[小黄人微笑]",
    picid: "",
  },
  "[小黄人不屑]": {
    phrase: "[小黄人不屑]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/xiaohuangren_buxie_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/xiaohuangren_buxie_thumb.png",
    value: "[小黄人不屑]",
    picid: "",
  },
  "[小黄人白眼]": {
    phrase: "[小黄人白眼]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/80/xiaohuangren_baiyan_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/80/xiaohuangren_baiyan_thumb.png",
    value: "[小黄人白眼]",
    picid: "",
  },
  "[小黄人无奈]": {
    phrase: "[小黄人无奈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4e/xiaohuangren_wunai_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4e/xiaohuangren_wunai_thumb.png",
    value: "[小黄人无奈]",
    picid: "",
  },
  "[小黄人惊讶]": {
    phrase: "[小黄人惊讶]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/82/xiaohuangren_jingya_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/82/xiaohuangren_jingya_thumb.png",
    value: "[小黄人惊讶]",
    picid: "",
  },
  "[小黄人委屈]": {
    phrase: "[小黄人委屈]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/13/xiaohuangren_weiqu_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/13/xiaohuangren_weiqu_thumb.png",
    value: "[小黄人委屈]",
    picid: "",
  },
  "[小黄人得意]": {
    phrase: "[小黄人得意]",
    type: "face",
    url: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bb/xiaohuangren_deyi_org.png",
    hot: false,
    common: false,
    category: "小黄人",
    icon: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/bb/xiaohuangren_deyi_thumb.png",
    value: "[小黄人得意]",
    picid: "",
  },
};

export function WeibofaceFilter(input: string) {
  const faces = WEIBO_FACES;

  for (const face in faces) {
    const re = new RegExp(escapeRegExp(face), "g");

    input = input.replace(
      re,
      `<img class="weibo-face" src="${faces[face].url}">`
    );
  }
  return input;
}
