import { imapiClient, apiClient } from "@/utils/http";
import { from } from "rxjs";

export function online() {
  return from(
    apiClient.post("/worker/account/online", {
      device: "mobile",
    })
  );
}

export function loop(data: any) {
  return from(imapiClient.post("/worker/conversations", data));
}

export function startConversation(uid: string) {
  return from(apiClient.post("/worker/conversation/start", { uid }));
}

export function sendMessage(data: any) {
  return from(apiClient.post("/worker/im/send", data));
}

export function closeConversation(uid: string) {
  return from(apiClient.post("/worker/conversation/close", { uid }));
}

export function switchAiStatus(data: any) {
  return from(
    apiClient.get("/worker/ai/talk-switch", {
      params: data,
    })
  );
}
