
import { defineComponent } from "vue";
import { NavBar, Button, Dialog } from "vant";
import { HostTypes } from "@/enums/host";

import {
  getNotificationInstructionText,
  isPreferWechat,
} from "@/utils/notification";
import { getHostType } from "@/utils/host";
import { sendPush } from "@/api/common";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      device: "",
      os: "",
      SDK: 0,
      mobile_id: "",
      mobile_type: "",
      instructionText: "",
      hostType: "",
      HostTypes,

      showPreferWechat: false,
    };
  },

  beforeMount() {
    this.device = localStorage.getItem("device")!;
    this.os = localStorage.getItem("os")!;
    this.SDK = parseInt(localStorage.getItem("SDK")!);
    this.mobile_id = localStorage.getItem("mobile_id")!;
    this.mobile_type = localStorage.getItem("mobile_type")!;

    this.hostType = getHostType();

    this.showPreferWechat = isPreferWechat();

    this.instructionText = getNotificationInstructionText();
  },

  methods: {
    goBack() {
      this.$router.push("/settings");
    },

    goSettings() {
      window.location.href = "/yunque_turn_on_notification";
    },

    testPush() {
      sendPush("test", this.mobile_type, this.mobile_id).subscribe(
        () => {
          Dialog.alert({
            message: "10秒后将收到推送，请切换到桌面或其它App，等待通知送达",
          });
        },
        () => {
          Dialog.alert({
            message: "请求失败",
          });
        }
      );
    },
  },
});
