<template>
    <div class="container">
        <van-nav-bar title="隐私政策" safe-area-inset-top @click-left="goBack" left-arrow />

        <div class="iframe-wrapper">
            <iframe src="https://doc.yunque360.com/x/UYs1Ag" scrolling="true" frameborder="0"></iframe>
        </div>
    </div>
</template>
  
<script>
import { Dialog, Button, NavBar } from "vant";
import { redirectToMobileLogin } from "@/utils/host";

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
    },

    data() {

    },

    mounted() {
        window.addEventListener("message", function (event) {
            let message = event.data;
            if (typeof message !== "string") return;
            if (message.indexOf("$GO_BACK") !== -1) {
                this.$router.go(-1);
            }
        });
    },

    beforeMount() {
    },

    beforeUnmount() {
        window.removeEventListener("message", function () { });
    },

    methods: {
        goBack() {
            this.$router.push("/settings");
        },
    },
};
</script>
  
<style scoped>
.container {
    height: 100%;
    position: relative;
}

.action-btn {
    margin: 0 10px;
    width: 100px;
}

.iframe-wrapper {
    position: absolute;
    top: 46px;
    bottom: 0;
    width: 100%;
}

iframe {
    width: 100%;
    border: none;
    height: 100%;
}
</style>