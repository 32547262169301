import { apiClient } from "@/utils/http";
declare const window: any;

export function mobileLogin(info: any) {
  return apiClient.post(`/worker/account/mobile-login`, info);
}

export function mobileLogout() {
  return apiClient.post(`/worker/account/mobile-logout`);
}

export function checkToken() {
  return apiClient.post(`/worker/account/workers`);
}
