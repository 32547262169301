
import { defineComponent } from "vue";
import { List, Cell, Icon, Image } from "vant";
import { getPlatformByTabList, TabList } from "@/enums/history-tabs";
import {
  getDouyinHistoryList,
  getKuaishouHistoryList,
  getWebHistoryList,
  getWechatHistoryList,
  getWeiboHistoryList,
  getRedbookHistoryList,
} from "@/api/history";
import { sanitizeLocation } from "@/utils/location";

import emitter from "@/utils/bus";
import { mapState } from "vuex";


export default defineComponent({
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Image.name]: Image,
  },

  props: {
    tabType: Number,
  },

  data() {
    return {
      loading: false,
      finished: false,
      list: [],

      page: 0,
      total: 0,
      pageSize: 15,
      TabList,

      listScrollTop: 0,

      isAdmin: false,

    };
  },

  computed: {
    ...mapState({
      showDouyinUpdateInfo: (state: any) => state.common.douyinApps.some((app: any) => app.version === 0 && app.status),
      kuaishouTab: (state: any) => state.common.kuaishouTab,
    }),
  },

  created() {
    (this as any).$store.dispatch("UPDATE_BADGE");

    emitter.on("UPDATE_HISTORY_FILTERS", () => {
      this.reload();
    });
  },

  beforeMount() {
    const workerStr = localStorage.getItem("worker_data");
    if (workerStr) {
      let worker = JSON.parse(workerStr);
      this.isAdmin = !!worker?.is_admin;
    }
  },

  activated() {
    if (this.$route.meta.isNeedRefresh) {
      this.reload();
    }

    if (this.listScrollTop) {
      (this as any).$refs.listContainer.$el.scrollTop = this.listScrollTop;
    }
  },

  watch: {
    tabType() {
      this.reload();
    },

    kuaishouTab() {
      this.reload();
    }
  },

  methods: {
    scrollEvent() {
      this.listScrollTop = (this as any).$refs.listContainer.$el.scrollTop;
    },

    reload() {
      this.resetPage();
      this.onLoad();
      (this as any).$store.dispatch("UPDATE_BADGE");
    },

    onLoad() {
      this.loading = true;
      this.getSubscriber()!.subscribe(
        (res) => {
          if (res.platform !== this.tabType) return;

          this.page = res.page;
          this.total = res.total;
          this.pageSize = res.pagesize;

          this.list = this.list.concat(res.data);

          if (this.page * this.pageSize >= this.total) {
            this.finished = true;
          }

          this.loading = false;
        },
        () => { },
        () => { }
      );
    },

    getSubscriber() {
      if (this.tabType === TabList.web) {
        return getWebHistoryList({
          page: ++this.page,
        });
      }

      if (this.tabType === TabList.wechat) {
        return getWechatHistoryList({
          page: ++this.page,
        });
      }

      if (this.tabType === TabList.weibo) {
        return getWeiboHistoryList({
          page: ++this.page,
        });
      }

      if (this.tabType === TabList.douyin) {
        return getDouyinHistoryList({
          page: ++this.page,
        });
      }

      if (this.tabType === TabList.redbook) {
        return getRedbookHistoryList({
          page: ++this.page,
        });
      }

      if (this.tabType === TabList.kuaishou) {
        return getKuaishouHistoryList({
          page: ++this.page,
        }, this.kuaishouTab);
      }
    },

    resetPage() {
      this.list = [];
      this.page = 0;
      this.total = 0;
      this.finished = false;
    },

    goToDetailPage(tabType: TabList, data: any) {
      data = { ...data };

      data.platform = getPlatformByTabList(tabType);
      data.location = sanitizeLocation(data.location || "");
      (this as any).$store.commit("UPDATE_CURRENT_HISTORY_DETAIL", data);

      this.$router.push("/history-detail");
    },

    updateKuaishouTab(tab: any) {
      (this as any).$store.commit("UPDATE_KUAISHOU_TAB", tab);
    }
  },
});
