export function getOtherTotalNewCount(
  talkList: any[],
  currentVisitorUid: string
) {
  let count = 0;
  talkList.forEach((talk: any) => {
    if (talk.uid !== currentVisitorUid) {
      count += talk.newCount;
    }
  });
  return count;
}
