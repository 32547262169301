<template>
  <div class="msg-wrapper msg-client" v-if="message.feType === 'client_typing'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble typing-bubble">
      访客正在输入：{{ message.content }}
      <img class="not-preview" :src="require('@/assets/img/typing.gif')" alt="" />
    </div>
    <app-extra-message :message="message" v-if="message?.extractedInfo"></app-extra-message>
  </div>

  <div class="msg-wrapper msg-client" v-if="message.feType === 'client_message'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="preline" v-html="wechatFaceFilter(message.displayText)"></div>
      <div class="bubble-type" v-if="message.body?.send_type === 'offline_msg'">
        留言消息
      </div>
    </div>
    <app-extra-message :message="message" v-if="message?.extractedInfo"></app-extra-message>
  </div>

  <div class="msg-wrapper msg-client" v-if="['web_client_html_message', 'web_client_text_message'].includes(
    message.feType
  )">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="preline" v-html="wechatFaceFilter(message.displayText)"></div>
      <div class="bubble-type" v-if="message.send_type === 'offline_msg'">
        留言消息
      </div>
    </div>
    <app-extra-message :message="message" v-if="message?.extractedInfo"></app-extra-message>
  </div>

  <div class="msg-wrapper msg-client" v-if="message.feType === 'client_web_image_message'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <img :src="message.url" alt="" />
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'client_message_fmc_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <img :src="message.url" alt="" />
    </div>
  </div>

  <div class="msg-wrapper msg-client" v-if="message.feType === 'client_message_web_audio'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <audio :src="message.body?.url || message.url" controls></audio>
    </div>
  </div>


  <div class="msg-wrapper msg-info" v-if="message.feType === 'shake'">
    <span>{{ message.feDateTime }} </span>
    用户给您发送了一条
    <i nz-icon nzType="shake" nzTheme="outline"></i> 抖动消息
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'pv'">
    <span>{{ message.feDateTime }} </span>

    <!-- 当前正在访问的页面 -->
    <span v-if="!message.body.url">直接访问对话页</span>

    <template v-if="message.body.url">
      <span>
        {{ message.body.prefixText }}
        <a href="#">{{ message.body.displayText }}</a>
      </span>
    </template>

    <template v-if="message.body.referrer">
      <span v-html="message.body.referrerText"></span>
    </template>
  </div>

  <div class="msg-wrapper msg-client" v-if="message.feType === 'legacy_offline_msg'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      【留言】
      {{ message.body }}
    </div>
  </div>

  <div class="msg-wrapper msg-client" v-if="message.feType === 'offline_msg'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <p>电话: {{ message.body.message.mobile }}</p>
      <p>留言内容: {{ message.body.message.content }}</p>
      <div class="bubble-type">留言消息</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";

import { WechatfaceFilter as wechatface } from "@/filters/wechatface";


export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    wechatFaceFilter() {
      return (v) => {
        return wechatface(v);
      };
    },
  },

  components: { "app-extra-message": ExtraMessage },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() { },
});
</script>
