
import BottomBar from "@/components/BottomBar.vue";
import { defineComponent } from "vue";
import {
  NavBar,
  Skeleton,
  Tag,
  Cell,
  CellGroup,
  Switch,
  Button,
  Dialog,
  Toast,
} from "vant";
import {
  getOnDutyData,
  setOndutyInfo,
  getChatNotify,
  setChatNotify,
} from "@/api/common";
import { getSiteConfigs } from "@/api/site-config";
import CommetFactory from "@/api/CommetFactory";
import {
  getHostType,
  redirectToMobileLogin,
  resetHistoryIniOS,
} from "@/utils/host";
import { HostTypes } from "@/enums/host";
import { mobileLogout } from "@/api/auth";
import { mapState } from "vuex";
import VConsole from 'vconsole';

declare const APP: any;
declare const window: any;

export default defineComponent({
  components: {
    BottomBar: BottomBar,
    [NavBar.name]: NavBar,
    [Skeleton.name]: Skeleton,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Switch.name]: Switch,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      loading: true,
      isOnDuty: false,
      showLogout: false,
      worker: null,
      companyCode: "",

      isNotifyPCOnline: false,
      isNotifyUserBehavior: false,
      showNotifyUserBehaviorDialog: false,

      clickCount: 0,

      isInspector: false,

    };
  },

  computed: {
    ...mapState({
      expiredWeiboApps: (state: any) => state.common.expiredWeiboApps,
      expiredDouyinApps: (state: any) => state.common.expiredDouyinApps,
      expiredKuaishouApps: (state: any) => state.common.expiredKuaishouApps,
      hasMobilePermission: (stage: any) => stage.auth.hasMobilePermission,
    }),
  },

  beforeMount() {
    this.loading = true;
    this.companyCode = localStorage.getItem("company_code") || "";

    const device = localStorage.getItem("device") || '';
    const deviceName = device.toLowerCase() || "";
    let isHuawei = ["huawei", "honor"].some((key) => {
      return deviceName.includes(key);
    })
    let companyId = localStorage.getItem("company_id");
    this.isInspector = companyId === 'c38cmvmmgccne';

    getOnDutyData().subscribe((isOnDuty) => {
      this.isOnDuty = isOnDuty;
      this.loading = false;
    });

    getChatNotify().subscribe((res) => {
      this.isNotifyPCOnline = res.notify_pc_online === "1";
      this.isNotifyUserBehavior = res.notify_user_behavior === "1";
    });

    this.showLogout = getHostType() !== HostTypes.WeChat;

    const workerStr = localStorage.getItem("worker_data");
    if (workerStr) {
      let worker = JSON.parse(workerStr);

      if (worker.avatar) {
        worker.avatar =
          getSiteConfigs().COMPANY_IMAGE_CDN_SITE + "/" + worker.avatar;
      } else {
        worker.avatar = require("@/assets/img/default-avatar.png");
      }

      this.worker = worker;
    }
  },

  methods: {
    clickAvatar() {
      this.clickCount++;

      if (this.clickCount === 5 && !window.vConsole) {
        window.vConsole = new VConsole();

      }
    },

    toggleOnDuty(isOnDuty: any) {
      const Commet = CommetFactory((this as any).$store);

      setOndutyInfo(isOnDuty).subscribe((res) => {

        if (isOnDuty) {
          Commet.connect();

          if (!res?.data?.result?.use_mobile) {
            this.isOnDuty = false;
          }
        } else {
          Commet.stop();
        }
      });
    },

    logout() {
      Dialog.confirm({
        message: "确认要退出当前账号吗",
      }).then(() => {
        mobileLogout().then(() => {
          console.log("quit foreground service");
          //关闭前台服务
          if (typeof APP !== "undefined") {
            APP.quitForegroundService();
          }

          resetHistoryIniOS();

          redirectToMobileLogin();
        });
      });
    },

    refreshWeibo(url: string) {
      window.location.replace(url);
    },

    toggleNotifyPCOnline() {
      setChatNotify({
        notify_pc_online: this.isNotifyPCOnline ? 1 : 0,
      }).subscribe(() => {
        Toast.success("保存成功");
      });
    },

    toggleNotifyUserBehavior() {
      if (this.isNotifyUserBehavior) {
        this.showNotifyUserBehaviorDialog = true;
      } else {
        this.doToggleNotifyUserBehavior();
      }
    },

    doToggleNotifyUserBehavior() {
      setChatNotify({
        notify_user_behavior: this.isNotifyUserBehavior ? 1 : 0,
      }).subscribe(() => {
        Toast.success("保存成功");
      });
    },
  },
});
