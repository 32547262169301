export const NavbarMixin = {
  methods: {
    goBack() {
      const previousUrl = localStorage.getItem("previous_url");

      if (previousUrl) {
        try {
          const obj = JSON.parse(previousUrl);
          (this as any).$router.push(obj);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
