import { MessageFeFrom } from "@/types/Message";
import { Talk } from "@/types/Talk";

export function getNewCountAndWorkerReadFlag(talk: Talk): [number, boolean] {
  const reversedCon = talk.conversation.slice().reverse();
  let newCount = 0;
  let workerReadFlag = false;

  reversedCon.every((msg) => {
    //手机端特殊的小红点处理逻辑，AI消息视为已读
    if (
      msg.feFrom === MessageFeFrom.worker &&
      ["ai_ask", "ai_rt_awaken", "ai_cron_awaken", "ai_reply"].includes(
        msg.auto_type!
      )
    ) {
      workerReadFlag = true;
      return false;
    }

    if (msg.feFrom === MessageFeFrom.worker && !msg.feMuted && !msg.auto_type) {
      workerReadFlag = true;
      return false;
    }

    if (msg.feFrom === MessageFeFrom.client && !msg.feMuted) {
      newCount++;
      return true;
    }

    // if (msg.feType === "connect" && chatSettings.enableNewWebUserAlert) {
    //   newCount++;
    //   return true;
    // }

    // if (msg.feType === "notice" && msg.text ) {
    //   if (chatSettings.enableNewWechatUserAlert && (msg.type === 'subscribe' || msg.type === 'follow')) {
    //     newCount++;
    //     return true;
    //   }

    //   if (
    //     chatSettings.enableWechatMenuActionAlert &&
    //     msg.text.indexOf("点击菜单") === 0
    //   ) {
    //     newCount++;
    //     return true;
    //   }
    // }

    if (msg.feType === "doassign" && msg.event === "transfer") {
      newCount++;
      return true;
    }

    return true;
  });

  return [newCount, workerReadFlag];
}
