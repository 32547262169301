<template>
    <div class="douyin-emoji-panel">
        <div class="emoji emoji-wechat" @click="chooseEmoji(obj.val)" v-for="(obj, index) in RedbookFaceList"
            v-bind:key="index">
            <img :src="require('@/assets/redbook_face/' + index + '.png')">
        </div>
    </div>
</template>

<script>
import { RedbookFaceList } from "@/filters/redbookface";

export default {
    data() {
        return {
            RedbookFaceList: RedbookFaceList,
        };
    },

    methods: {
        chooseEmoji(code) {
            this.$emit("chooseEmoji", code);
            return;
        },
    },
};
</script>

<style lang="less" scoped>
.douyin-emoji-panel {
    display: flex;
    flex-wrap: wrap;
}

.emoji {
    width: calc(100% / 8);
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: #f0f0f0;
    }

    img {
        width: 25px;
        height: 25px;
    }
}
</style>