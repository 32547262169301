import axios, { AxiosInstance } from "axios";
import {
  getHostType,
  getIsInPC,
  isOnWebDev,
  redirectToMobileLogin,
} from "@/utils/host";
import { HostTypes } from "@/enums/host";

declare const window: any;

const hostType = getHostType();
const isInPC = getIsInPC();

export const httpClient: AxiosInstance = axios.create({});

export const apiClient: AxiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

export const imapiClient: AxiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

export const commonClient: AxiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

function requestInterceptor(config: any) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}

function requestErrorInterceptor(error: any) {
  return Promise.reject(error);
}

function responseInterceptor(config: any) {
  // Do something before request is sent
  return config;
}

function responseErrorInterceptor(error: any) {
  if (error.response?.status === 401) {
    if ((hostType !== HostTypes.WeChat && !isInPC) || isOnWebDev()) {
      redirectToMobileLogin();
      return;
    }
  }

  return Promise.reject(error);
}

export function addTokenInterceptor() {
  apiClient.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor
  );
  apiClient.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );
  imapiClient.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor
  );
  imapiClient.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );
}
