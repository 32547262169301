export const DefaultSource = [
    "直接访问",
    "微博",
    "微信",
    "广点通",
    "腾讯咨询页",
    "百度咨询页",
    "头条咨询页",
    "手机百度App",
    "其它",
    "搜索-360好搜",
    "搜索-百度自然搜索",
    "搜索-百度推广",
    "搜索-搜狗搜索",
    "搜索-神马搜索",
    "搜索-必应搜索",
    "搜索-谷歌搜索",
    "小红书落地页",
  ];
  
  export const DefaultSourceMap: any = {
    direct: "直接访问",
    weibo: "微博",
    weixin: "微信",
    gdt: "广点通",
    qqfmc: "腾讯咨询页",
    imlp: "百度咨询页",
    ttpage: "头条咨询页",
    sbox: "手机百度App",
    other: "其它",
    360: "搜索-360好搜",
    baidu: "搜索-百度自然搜索",
    bdsem: "搜索-百度推广",
    sogou: "搜索-搜狗搜索",
    shenma: "搜索-神马搜索",
    bing: "搜索-必应搜索",
    google: "搜索-谷歌搜索",
    xhs: "小红书落地页",
  };
  
  export const DefaultSourceMapList = DefaultSource.map((name) => {
    for (const key in DefaultSourceMap) {
      const val = DefaultSourceMap[key];
      if (val === name)
        return {
          key,
          value: val,
        };
    }
  });
  