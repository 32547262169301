<template>
  <div class="container">
    <div id="tooltip-info" class="text-center text-white leading-6">
      提示：本页仅限24小时内查看，超时后请前往云雀客户端查看
    </div>

    <div class="card px-4 py-3 bg-white border-t border-gray-200 shadow-md">
      <p class="card-title">客资详情</p>
      <lead :lead="leadInfo" v-if="leadInfo?.name"></lead>
    </div>

    <div class="card px-4 py-3 bg-white border-t border-gray-200 shadow-md">
      <p class="card-title">聊天记录</p>

      <yunque-message
        v-for="message in conversation"
        :message="message"
        :key="message.microtime"
      ></yunque-message>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from "@/utils/url";
import Lead from "../components/Lead.vue";
import { getLeadInfo } from "@/api/lead-pusher";
import Message from "@/components/messages/Message.vue";
import { transformMessageList } from "@/utils/message";

export default {
  components: {
    Lead,
    "yunque-message": Message,
  },

  data() {
    return {
      leadInfo: {},
      conversation: [],
    };
  },

  created() {
    const id = getUrlParam("id");

    getLeadInfo(id).subscribe(
      (resInfo) => {
        const res = resInfo?.data?.result || {};
        this.leadInfo = {
          ...res.user,
          app: res.app,
          platform: res.log.platform,
          exist_user: res.exist_user || undefined,
        };

        let name = "";
        if (
          res.user?.showname &&
          res.user.nickname &&
          res.user?.showname !== res.user?.nickname
        ) {
          name = `${res.user.showname}(${res.user.nickname})`;
        } else {
          name = `${res.user?.showname || res.user?.nickname}`;
        }
        this.leadInfo.name = name;

        this.conversation = transformMessageList(
          res.conversation,
          res.log.platform,
          res.log.uid
        );
      },
      (res) => {
        if (res.response?.status === 410) {
          this.$router.replace("/lead-expire");
        }
      }
    );
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #edf3fd;
  height: 100%;
  margin: 0 auto;

  max-width: 650px;
  overflow-y: auto;
}

#tooltip-info {
  background-color: rgba(0, 0, 0, 0.388235294117647);
  font-size: 13px;
  text-align: center;
  color: white;
  line-height: 1.5rem;
}

.card {
  margin: 10px;
  border-radius: 5px;
  background-color: white;
}

.shadow-md {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
}

.card-title {
  color: rgb(2, 167, 240);
  font-weight: 650;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.border-gray-200 {
  border-color: rgb(229 231 235);
}

.border-t {
  border-top-width: 1px;
}
</style>