<template>
    <div class="msg-wrapper msg-client msg-kuaishou" v-if="message.feType === 'kuaishou_client_text'">
        <div class="msg-subtitle">
            {{ message.feDateTime }}
        </div>
        <div class="msg-bubble">
            <div v-html="kuaishoufaceFilter(message.text)"></div>
            <div class="bubble-type">私信</div>
        </div>
        <app-extra-message :message="message" v-if="message.extractedInfo"></app-extra-message>
    </div>

    <div class="msg-wrapper msg-client msg-kuaishou" v-if="message.feType === 'kuaishou_client_image'">
        <div class="msg-subtitle">
            {{ message.feDateTime }}
        </div>
        <div class="msg-bubble">
            <img :src="message.url" />
            <div class="bubble-type">私信</div>
        </div>
        <app-extra-message :message="message" v-if="message.extractedInfo"></app-extra-message>
    </div>
</template>
  
<script>
import { defineComponent } from "vue";
import {
    MessageFeFrom,
    MessagePlatform,
    MessageCategory,
    WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";

import { KuaishoufaceFilter as kuaishouface } from "@/filters/kuaishouface";

export default defineComponent({
    props: {
        message: Object,
    },

    components: {
        "app-extra-message": ExtraMessage,
    },

    computed: {
        kuaishoufaceFilter() {
            return (v) => {
                return kuaishouface(v);
            };
        },
    },

    data() {
        return {
            messageFeFroms: MessageFeFrom,
            messagePlatforms: MessagePlatform,
            messageCategories: MessageCategory,
            WeiboMessageDivision: WeiboMessageDivision,
        };
    },

    beforeMount() { },
});
</script>
  