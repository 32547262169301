<template>
  <div
    class="msg-wrapper msg-worker msg-weibo preline"
    v-if="message.feType === 'weibo_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div v-html="weiboFaceFilter(message.text)"></div>
      <div class="bubble-type">
        {{ message.auto_typeText ? message.auto_typeText : "私信" }}
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-temp preline"
    v-if="message.feType === 'temp_weibo_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i
          class="iconfont sending"
          v-if="
            message.sendingStatus !== 'sent' &&
            message.sendingStatus !== 'error'
          "
          >&#xe609;</i
        >
        <i class="iconfont error" v-if="message.sendingStatus === 'error'"
          >&#xe606;</i
        >
      </div>
      <div class="inner-bubble" v-html="message.text"></div>
      <div class="bubble-type">
        {{ message.auto_typeText ? message.auto_typeText : "私信" }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import { WeibofaceFilter as weiboface } from "@/filters/weiboface";


export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    weiboFaceFilter() {
      return (v) => {
          return weiboface(v);
      }
    },
  },

  components: {},

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {},
});
</script>
