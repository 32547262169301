export enum TabList {
  "web",
  "wechat",
  "douyin",
  "redbook",
  "kuaishou",
  "weibo",
}

export function getPlatformByTabList(tabList: TabList) {
  return ["web", "wechat", "douyin", "redbook", "kuaishou", "weibo"][tabList];
}
