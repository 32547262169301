
import { defineComponent } from "vue";
import { Button } from "vant";
import { getHostType } from "@/utils/host";
import { HostTypes } from "@/enums/host";
import { CONFIGS } from "@/config";

export default defineComponent({
  name: "Update",
  components: {
    [Button.name]: Button,
  },

  methods: {
    download() {
      switch (getHostType()) {
        case HostTypes.Android:
          window.location.href = CONFIGS.ANDROID_DOWNLOAD_LINK;
          break;
        case HostTypes.iOS:
          (window as any).bridge.call(
            "updateSoftware",
            CONFIGS.APP_STORE_LINK,
            () => {},
            () => {}
          );
          break;
        default:
          break;
      }
    },
  },
});
