<template>
  <div style="padding: 5px">
    <template v-if="isOldFormat">
      <div v-if="content" v-html="content"></div>
      <van-empty v-if="!content" image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
        image-size="80" description="暂无回复，请前往电脑客户端设置" />
    </template>



    <div v-if="!isOldFormat">

      <template v-if="parsedContent?.isEmpty">
        <van-empty image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png" image-size="80"
          description="暂无回复，请前往电脑客户端设置" />
      </template>

      <template v-if="!parsedContent?.isEmpty">
        <div v-if="parsedContent.format === 'html'"  v-html="parsedContent.content">
        </div>
        <div v-if="parsedContent.format === 'text'">
          {{ parsedContent.content }}
        </div>
        <div v-if="parsedContent.format === 'image'">
          <img :src="parsedContent.url" style="max-width: 100%" alt="" />
        </div>
        <div class="news-container" v-if="parsedContent.format === 'news'">
          <app-wechat-news :news="parsedContent"></app-wechat-news>
        </div>

        <div v-if="parsedContent.format === 'linknews'">
          <app-linknews :linknews="parsedContent.content"></app-linknews>
        </div>

        <div v-if="parsedContent.format === 'miniprogrampage'">
          <p>[小程序卡片]{{ parsedContent.title }}</p>
          <img :src="IMAGE_CDN + '/' + parsedContent.filename" style="height: 100px; width: 100px; margin: 3px 0" />
        </div>

        <div v-if="parsedContent.format === 'voice'">
          <audio :src="parsedContent.url" controls></audio>
        </div>

        <!-- 抖音视频或者微信视频 -->
        <div v-if="parsedContent.format === 'video'">
          <div class="douyin-worker-video-container">
            <img class="douyin-video-cover" :src="parsedContent.thumb_url || parsedContent.cover_url" alt="" />

            <div class="video-card">
              <p>视频标题：{{ parsedContent.title || "未知视频" }}</p>
              <!-- <p>未知视频</p> -->

              <p v-if="parsedContent.video_create_time && parsedContent.share_url">
                {{ parsedContent.video_create_timeText }}
              </p>
            </div>
          </div>



        </div>

        <div class="douyin-card-container" v-if="parsedContent.format === 'card'">
          <app-douyin-card :cardInfo="parsedContent"></app-douyin-card>
        </div>

        <div v-if="parsedContent.format === 'roll_msg'">
          <app-rollmessage :group="parsedContent"></app-rollmessage>
        </div>

        <div v-if="parsedContent.format === 'wework_card'">
          <app-wework-card :card="parsedContent"></app-wework-card>

        </div>

        <div v-if="parsedContent.format === 'faq_menu'">
          <app-faq-menu :menu="parsedContent"></app-faq-menu>

        </div>

        <div v-if="parsedContent.format === 'note'">
          小红书笔记: {{ parsedContent.title }}
        </div>

        <div v-if="parsedContent.format === 'service_card'">
          小红书服务卡: {{ parsedContent.title }}
        </div>
        <div v-if="parsedContent.format === 'loading_page'">
          小红书落地页: {{ parsedContent.title }}
        </div>

      </template>


    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getSiteConfigs } from "@/api/site-config";
import { isOldFormat } from "@/utils/common";
import LinkNews from "@/components/messages/special/LinkNews.vue";
import Minipage from "@/components/messages/special/MiniPage.vue";
import WechatNews from "@/components/messages/special/WechatNews.vue";
import WeworkCard from "@/components/messages/special/WeworkCard.vue";
import RollMessage from "@/components/messages/special/RollMessage.vue";
import FaqMenu from "@/components/messages/special/FaqMenu.vue";
import DouyinCard from "@/components/messages/special/DouyinCard.vue";
import { checkWechatEmpty } from "@/utils/message-process/isEmpty";

import { Empty } from 'vant';


export default defineComponent({
  components: {
    "app-linknews": LinkNews,
    // "app-miniapp-page": Minipage,
    "app-wechat-news": WechatNews,
    "app-wework-card": WeworkCard,
    "app-rollmessage": RollMessage,
    "app-faq-menu": FaqMenu,
    "app-douyin-card": DouyinCard,
    [Empty.name]: Empty,

  },

  data() {
    return {
      IMAGE_CDN: getSiteConfigs().CHAT_IMAGE_CDN_SITE,
    };
  },

  props: ["content"],

  computed: {
    isOldFormat: function () {
      return isOldFormat(this.content);
    },

    parsedContent: function () {
      try {
        let content = JSON.parse(this.content);

        if (content.format === 'video' && content.video_create_time) {
          content.video_create_timeText = new Date(content.video_create_time * 1000).toLocaleDateString("zh-CN") + ' ' + new Date(content.video_create_time * 1000).toLocaleTimeString("zh-CN")
        }

        content.isEmpty = checkWechatEmpty(content);

        return content;

      } catch (error) {
        return {};
      }


    },

    newsTitle: function () {
      if (this.parsedContent.news) {
        return this.parsedContent.news[0].title;
      } else {
        return this.parsedContent.news_title;
      }
    },


  },

  mounted() {
  }
});
</script>

<style scoped lang="less">
p {
  margin: 0;
}


div {
  ::v-deep img {
    max-width: 80%;
    height: auto;
  }
}

.news-container {
  ::v-deep .container {
    width: 100%;

    .wechat-news {
      max-width: 90%;
      width: 90%;
    }
  }
}

.douyin-card-container {
  ::v-deep .container {
    width: 80%;
  }
}

.douyin-worker-video-container {
  background-color: #f0f0f4;
  border-radius: 5px;
  display: flex;
  color: #4f5a62;
  padding: 10px;
  min-width: 250px;

  a {
    color: #2593f4;
  }

  .douyin-video-cover {
    width: 100px;
    margin-right: 10px;
  }

  .video-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    p {
      margin: 0;
      max-width: 350px;
      min-width: 250px;
      word-break: break-all;
    }
  }
}
</style>
