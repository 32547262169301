
import BottomBar from "@/components/BottomBar.vue";
import HistoryList from "@/components/HistoryList.vue";
import HistoryFilter from "@/components/HistoryFilter.vue";

import { defineComponent } from "vue";
import { NavBar, Tabs, Tab, Button, Icon } from "vant";
import { mapState } from "vuex";
import { TabList } from "@/enums/history-tabs";

export default defineComponent({
  components: {
    BottomBar: BottomBar,
    HistoryList: HistoryList,
    [NavBar.name]: NavBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Button.name]: Button,
    [Icon.name]: Icon,
    HistoryFilter,
  },

  data() {
    return {
      activeTab: TabList.web,
    };
  },

  computed: {
    ...mapState({
      webBadge: (state: any) => {
        return state.common.historyBadge.web
          ? state.common.historyBadge.web
          : null;
      },
      wechatBadge: (state: any) => {
        return state.common.historyBadge.wechat
          ? state.common.historyBadge.wechat
          : null;
      },
      weiboBadge: (state: any) => {
        return state.common.historyBadge.weibo
          ? state.common.historyBadge.weibo
          : null;
      },
      douyinBadge: (state: any) => {
        return state.common.historyBadge.douyin
          ? state.common.historyBadge.douyin
          : null;
      },
      kuaishoubadge: (state: any) => {
        return state.common.historyBadge.kuaishou
          ? state.common.historyBadge.kuaishou
          : null;
      },
      redbookBadge: (state: any) => {
        return state.common.historyBadge.redbook
          ? state.common.historyBadge.redbook
          : null;
      },
    }),
  },

  beforeRouteEnter(to, from, next) {
    to.meta.isNeedRefresh = from.name !== "historyDetail";
    next();
  },

  beforeMount() {
    const preferedTab = localStorage.getItem("preferedHistoryTabV2") as any;
    this.activeTab =
      preferedTab !== undefined && !isNaN(preferedTab)
        ? parseInt(preferedTab)
        : TabList.web;
  },

  watch: {
    activeTab(newVal: any) {
      localStorage.setItem("preferedHistoryTabV2", newVal);
    },
  },
});
