<template>
  <div class="snippet-selector">
    <van-tabs v-model:active="activeTab" @click-tab="changeTab">
      <van-tab title="公用回复">
        <template v-for="snippetGroup in companySnippet" :key="snippetGroup.id">
          <snippet-cell :snippet-group="snippetGroup" @snippetClicked="handleClick($event)"></snippet-cell>
        </template>

        <template v-if="!companySnippet?.length">
          <van-empty image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png" image-size="80"
            description="暂无回复，请前往电脑客户端设置" />
        </template>
      </van-tab>
      <van-tab title="个人回复">

        <template v-for="snippetGroup in workerSnippet" :key="snippetGroup.id">
          <snippet-cell :snippet-group="snippetGroup" @snippetClicked="handleClick($event)"></snippet-cell>
        </template>
        <template v-if="!workerSnippet?.length">
          <van-empty image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png" image-size="80"
            description="暂无回复，请前往电脑客户端设置" />
        </template>
      </van-tab>
    </van-tabs>
  </div>


  <van-popup v-model:show="showSnippetPicker" :overlay="false" position="bottom">
    <div class="snippet-selector">
      <h2 class="cell-group-title"> {{ currentSnippetTitle }}</h2>

      <van-cell>
        <template #title>
          <snippet-card :content="currentSnippet.originalContent" />
          <template></template>
        </template>
      </van-cell>

      <div class="action-container">
        <van-button @click="cancel">返回</van-button>
        <van-button type="primary" @click="send(currentSnippet.originalContent)">发送</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent } from "vue";
import { getFullSnippet } from "@/api/common";
import { Cell, CellGroup, Popup, Button, Tab, Tabs, Toast } from "vant";

import { mapGetters, mapState } from "vuex";
import SnippetCard from "@/components/SnippetCard.vue";
import SnippetCell from "@/components/SnippetCell.vue";
import CommetFactory from "@/api/CommetFactory";
import { formatSnippetToSend } from "@/utils/message";
import { checkWechatEmpty } from "@/utils/message-process/isEmpty";
import { Empty } from 'vant';

export default defineComponent({
  emits: ["exit"],

  beforeMount() {
    this.getSnippet();
  },

  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Empty.name]: Empty,

    SnippetCard,
    SnippetCell,
  },

  data() {



    return {
      workerSnippet: [],
      companySnippet: [],

      currentSnippetGroup: [],
      currentGroupTitle: "",

      currentSnippet: null,
      currentSnippetTitle: "",

      showSnippetPicker: false,

      activeTab: localStorage.getItem("preferedTSnippetTab") === '1' ? 1 : 0,
    };
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
      toReplyCMT: "toReplyCMT",
    }),

    ...mapState({
      wechatAppsObj: (state) => state.common.wechatAppsObj,
    })
  },

  methods: {
    changeTab() {
      localStorage.setItem("preferedTSnippetTab", this.activeTab);
    },

    getSnippet() {
      getFullSnippet().subscribe((res) => {
        const platform = this.currentVisitor.platform;

        let companySnippet, workerSnippet;

        switch (platform) {
          case "web":
            companySnippet = res.company.default || [];
            workerSnippet = res.worker.default || [];
            break;
          case "wechat":
            companySnippet =
              res.company.wechat?.[this.currentVisitor.appid] || [];
            workerSnippet = res.worker.wechat || [];
            break;
          case "weibo":
            companySnippet =
              res.company.weibo?.[this.currentVisitor.appid] || [];
            workerSnippet = res.worker.weibo || [];
            break;
          case "douyin":
            companySnippet =
              res.company.douyin?.[this.currentVisitor.appid] || [];
            workerSnippet = res.worker.douyin || [];
            break;
          case "kuaishou":
            companySnippet =
              res.company.kuaishou?.[this.currentVisitor.appid] || [];
            workerSnippet = res.worker.kuaishou || [];
            break;
          case "redbook":
            companySnippet =
              res.company.redbook?.[this.currentVisitor.appid] || [];
            workerSnippet = res.worker.redbook || [];
            break;


          default:
            break;
        }

        if (
          !companySnippet.length &&
          this.currentVisitor?.appid?.indexOf("wk") === 0
        ) {
          if (this.wechatAppsObj[this.currentVisitor.appid]?.corpid) {
            let corpid = this.wechatAppsObj[this.currentVisitor.appid]?.corpid;
            companySnippet = res.company.wechat?.[corpid];
          }

        }

        this.companySnippet = companySnippet;
        this.workerSnippet = workerSnippet;

      });
    },

    clickGroup(group) {
      this.currentSnippetGroup = group?.nodes || [];
      this.currentGroupTitle = group.title || "";

      this.showGroupSnippetPicker = true;
    },

    clickSnippet(snippet) {
      this.showSnippetPicker = true;
      this.currentSnippet = snippet;
      this.currentSnippetTitle = snippet.title;
    },

    handleClick(snippet) {

      if (snippet.content.format === 'text' && this.currentVisitor.platform !== "web"

        && !snippet.originalContent.includes('</a>')
      ) {
        this.$store.commit(
          "UPDATE_INPUT_DRAFT",
          snippet.content.content
        );
        this.exit();

        return;
      } else {
        this.clickSnippet(snippet);
      }

    },

    cancel() {
      this.showSnippetPicker = false;
    },

    exit() {
      this.showSnippetPicker = false;
      this.showGroupSnippetPicker = false;
      this.$emit("exit");
    },

    send(content) {

      if (this.toReplyCMT) {
        Toast.fail("该消息类型不支持作为评论格式发送");
        return;
      }

      const Commet = CommetFactory(this.$store);

      const msg = formatSnippetToSend(
        content,
        this.currentVisitor.platform,
        this.currentVisitor.uid
      );

      try {
        const body = typeof msg.body === 'string' ? JSON.parse(msg.body) : msg.body;
        if (!checkWechatEmpty(body)) {
          Commet.send(msg);
        }
      } catch (error) {
        console.log(error)
      }

      this.exit();
    },
  },
});
</script>

<style lang="less" scoped>
.snippet-selector {
  height: 70vh;

  ::v-deep .van-tabs {
    height: 100%;
  }

  ::v-deep .van-tabs__content {
    height: calc(100% - 59px);
    overflow-y: auto;
  }
}

.cell-group-title:first-child {
  margin: 0;
}

.cell-group-title {
  margin: 10px 0 0 0;
  padding: 10px 16px 10px 16px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.action-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .van-button {
    margin: 0 10px;
    width: 100px;
  }
}
</style>
