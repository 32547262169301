import { ENGINES_TYPES, SERACH_SOURCES } from "@/types/Referrer";
export function formatSourceText(item: {
  source?: any;
  engine?: string;
  custom_source?: string;
  app?: any;
  subscribe_from?: any;
  subscribe_ad?: any;
  qrcode_name?: string;
  platform?: any;
  corp_name?: string;
  wxkf_scene?: string;
  wechat_channel?: string;
  subscribe_scene?: string;
  note_info?: any;
}) {
  let displaySource = "";

  if (item.platform === "web") {
    if (item.source === "search" && item.engine !== "") {
      displaySource = ENGINES_TYPES[item.engine!];
    } else if (item.source === "custom") {
      displaySource = "自定义来源:" + item.custom_source;
    } else {
      displaySource = SERACH_SOURCES[item.source];
    }

    if (item.subscribe_ad?.campaign_name) {
      displaySource += `-广告计划: ${item.subscribe_ad.campaign_name}`;
    }
  }

  if (item.platform === "wechat") {
    if (item.app.type === 2) {
      displaySource = "微信小程序(" + item.app.name + ")";
    } else if (item.app.type === 3) {
      displaySource = `微信客服(${item.app.name}@${item.corp_name})`;
    } else {
      displaySource = "微信公众号(" + item.app.name + ")";
    }

    let extra = "";

    if (item.subscribe_from) {
      if (item.app.type === 3) {
        if (item.wxkf_scene) {
          displaySource +=
            `-来源链接` +
            (item.subscribe_from ? `：${item.subscribe_from}` : "");
        }
      } else {
        extra = `-${item.subscribe_from}`;

        if (item.subscribe_ad) {
          extra += `-广告计划: ${item.subscribe_ad.campaign_name}`;
        }
      }
    }

    if (item.subscribe_from === "扫描二维码") {
      if (item.qrcode_name) {
        extra = "-渠道二维码" + "-" + item.qrcode_name;
      }
    }

    if (item.subscribe_from === "视频号") {
      displaySource += "-视频号";

      if (item.wechat_channel) {
        displaySource += `: ${item.wechat_channel}`;
      }
    }

    displaySource += extra;
  }

  if (item.platform === "weibo") {
    displaySource = "微博蓝V(" + item.app.name + ")";
  }

  if (item.platform === "douyin") {
    displaySource = "抖音企业号(" + item.app.name + ")";
    if (item.subscribe_ad?.campaign_name) {
      displaySource += `-广告计划: ${item.subscribe_ad.campaign_name}`;
    }
  }

  if (item.platform === "kuaishou") {
    displaySource = "快手(" + item.app.name + ")";
    if (item.subscribe_scene) {
      switch (item.subscribe_scene) {
        case "normal":
          displaySource += "-自然流量";
          break;
        case "ad":
          displaySource += "-广告";
          break;
        case "unknown":
          displaySource += "-未知";
          break;
      }
    }
 

    if (item.subscribe_ad?.campaign_name) {
      displaySource += `-广告计划: ${item.subscribe_ad.campaign_name}`;
    }
  }

  if (item.platform === "redbook") {
    displaySource = "小红书(" + item.app.name + ")";

    if (item.subscribe_ad?.campaign_name) {
      displaySource += `-广告计划: ${item.subscribe_ad.campaign_name}`;
    }
    if(!item.subscribe_ad && item.note_info) {
      displaySource += `-笔记: ${item.note_info.title}`;
    }
  }

  return displaySource;
}
