<template>
  <div class="msg-wrapper msg-info" v-if="message.feType === 'web_worker_start'">
    <span>{{ message.feDateTime }} </span>
    {{ message.text }}
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'web_worker_message_text'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="wechatFaceFilter(message.displayText)"></div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'web_worker_message_html'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="wechatFaceFilter(message.displayText)"></div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'web_worker_message_image'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <img :src="message.url" alt="" />
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'web_worker_miniprogrampage'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <app-miniapp-page :miniapp="message"></app-miniapp-page>

      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>



  <div class="msg-wrapper msg-worker" v-if="message.feType === 'worker_message_fmc_image'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <img :src="message.url" alt="" />
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_worker_message'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="message.sendingStatus !== 'sent' &&
    message.sendingStatus !== 'error'
    ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble" v-html="message.displayText"></div>
    </div>
  </div>

  <!-- 自动回复老格式 -->
  <div class="msg-wrapper msg-worker" v-if="message.feType === 'autoreply'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="message.body"></div>
      <div class="bubble-type bubble-type-long">欢迎语</div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'noreply_reply'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="message.body"></div>
      <div class="bubble-type bubble-type-long">无回应回复</div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'auto_invite'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="message.body"></div>
      <div class="bubble-type bubble-type-long">欢迎语(邀请消息)</div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'offline_msg_cmt'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div v-html="message.body"></div>
      <div class="bubble-type">留言备注</div>
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";
import Minipage from "@/components/messages/special/MiniPage.vue";


import ExtraMessage from "@/components/messages/ExtraMessage.vue";
import { WechatfaceFilter as wechatface } from "@/filters/wechatface";


export default defineComponent({
  props: {
    message: Object,
  },

  components: {
    "app-miniapp-page": Minipage,

  },

  computed: {
    wechatFaceFilter() {
      return (v) => {
        return wechatface(v);
      };
    },
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {
  },
});
</script>
