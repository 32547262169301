import { Talk } from "@/types/Talk";

const passByTypes = [
  "tags_add",
  "tags_remove",
  "reassign",
  "transfer",
  "block",
  "doassign",
];

export function getOfflineFlag(talk: Talk) {
  const con = talk.conversation;
  const length = con.length;

  if (!length) {
    return false;
  }

  if (talk.platform === "web") {
    return getWebOfflineFlag(talk);
  }

  let i = 1;
  while (i <= length) {
    if (con[length - i].type === "unsubscribe") {
      return true;
    }

    if (passByTypes.includes(con[length - i].feType)) {
      i++;
      continue;
    }

    return false;
  }

  return false;
}

function getWebOfflineFlag(talk: Talk) {
  let i = 1;
  const con = talk.conversation;
  const length = con.length;
  while (i <= length) {
    if (con[length - i].feType === "disconnect") {
      return true;
    }

    if (con[length - i].feType === "web_worker_start") {
      return true;
    }

    //遇到访客pv则说明没有离线
    //bcp渠道访客离线后返回，不会有pv，所以通过访客发的消息类型来区分
    if (
      [
        "pv",
        "client_message",
        "client_message_web_audio",
        "client_message_fmc_image",
        "client_web_image_message",
        "web_client_text_message",
        "web_client_html_message",
      ].includes(con[length - i].feType)
    ) {
      return false;
    }

    i++;
  }

  return false;
}
