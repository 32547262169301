<template>
  <div class="container">
    用户评论：
    <span v-if="message.content" v-html="douyinfaceFilter(message.content)"></span>

    <div class="inner-bubble" nz-row>
      <!-- <img
        class="douyin-video-cover"
        :src="message.cover_url"
        v-if="message.cover_url"
        alt=""
      /> -->

      <div class="video-card">
        <p v-if="message.share_url">视频标题：{{ message.title || "" }}</p>
        <p v-if="!message.share_url">未知视频</p>
        <p class="font-gray" v-if="message.share_url">
          {{ message.video_create_time }}
        </p>
      </div>
    </div>

    <div class="bubble-type noselect">评论</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { DouyinfaceFilter as douyinface } from "@/filters/douyinface";

export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    douyinfaceFilter() {
      return (v) => {
        return douyinface(v);
      };
    },
  },

  data() {
    return {};
  },

  beforeMount() {},
});
</script>


<style lang="less" scoped>
.container {
  display: block;
  padding-bottom: 3px;
  max-width: 456px;

  .at-span {
    color: #2593f4;
  }

  .inner-bubble {
    background-color: white;
    border-radius: 3px;
    margin: 3px;
    padding: 6px 10px;
    color: #4f5a62;

    a {
      color: #2593f4;
      text-decoration: none;
    }
  }

  .weibo-user {
    font-weight: bold;
  }

  .post-footer {
    font-size: smaller;
    color: #858585;
  }

  .action-text {
    color: #2593f4;
    cursor: pointer;
  }

  .douyin-video-cover {
    width: 50px;
    margin-right: 10px;
  }

  .video-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    p {
      margin: 0;
      max-width: 350px;
      min-width: 250px;
      word-break: break-all;
    }
  }
}
</style>