<template>
  <img
    v-if="platform === 'web'"
    class="platform-image"
    :src="require('@/assets/img/lead-web.png')"
    alt=""
  />
  <img
    v-if="platform === 'wechat'"
    class="platform-image"
    :src="require('@/assets/img/lead-wechat.png')"
    alt=""
  />
  <img
    v-if="platform === 'weibo'"
    class="platform-image"
    :src="require('@/assets/img/lead-weibo.png')"
    alt=""
  />
  <img
    v-if="platform === 'douyin'"
    class="platform-image"
    :src="require('@/assets/img/douyin-icon.png')"
    alt=""
  />
  <img
    v-if="platform === 'kuaishou'"
    class="platform-image"
    :src="require('@/assets/img/kuaishou-icon.png')"
    alt=""
  />
  <img
    v-if="platform === 'redbook'"
    class="platform-image"
    :src="require('@/assets/img/redbook-icon.png')"
    alt=""
  />
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    platform: String,
  },
};
</script>

<style scoped>
.platform-image {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 5px;
}
</style>