export const clientSpokenTypes: any = [
  "client_message",
  "image",
  "shake",
  "web_client_html_message",
  "web_client_text_message",
  "client_web_image_message",
  "client_message_web_audio",

  "wechat_client_text",
  "wechat_client_image",
  "wechat_client_video",
  "wechat_client_shortvideo",
  "wechat_client_link",
  "wechat_client_location",
  "wechat_client_voice",
  "wechat_client_miniprogrampage",

  "weibo_client_text",
  "weibo_client_image",
  "weibo_client_video",
  "weibo_client_voice",
  "weibo_client_location",

  "douyin_client_text",
  "douyin_client_image",
  "douyin_client_cmt",
  "douyin_client_h5",
  "douyin_client_card",

  "kuaishou_client_text",
  "kuaishou_client_image",

  "redbook_client_text",
  "redbook_client_image",
  "redbook_client_note",
  "redbook_client_video",
  "redbook_client_note_cmt",
  "redbook_client_card",
  "redbook_client_loading_page",
  "redbook_client_social_card",

  
];
