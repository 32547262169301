<template>
    <div class="host notification-item" @click="setRead">
        <div class="container" @click="read" :class="{ 'is-read': isRead }">

            <div class="body">
                <div style="display: flex; align-items: center; justify-content: space-between;">

                    <div style="display: flex; align-items: center; gap: 10px">
                        <van-badge :dot="true" :style="{ display: !isRead ? 'block' : 'none' }"></van-badge>
                        <h4>
                            {{ input.title }}
                        </h4>
                    </div>

                    <span class="font-gray">
                        {{ input.create_time }}
                    </span>
                </div>
                <div :class="{ 'hide-more': shouldShowMore && !showMore }" style="white-space: break-spaces"
                    ref="contentRef">
                    <span v-html="input.content" style="line-height: 22px;"></span>
                    <div class="hidden" style="display: none;">
                        {{ input.id }}
                    </div>
                </div>
                <div class="more-btn" style="display: flex; justify-content: end;" v-if="shouldShowMore">
                    <a v-if="showMore" @click="showMore = !showMore">收起 <van-icon name="arrow-up" /></a>
                    <a v-if="!showMore" @click="showMore = !showMore">展开 <van-icon name="arrow-down" /></a>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Badge, Icon } from 'vant';
import { setNotiRead } from "@/api/common";
import { Toast } from "vant";
import { Dialog } from 'vant';

export default defineComponent({
    components: {
        [Badge.name]: Badge,
        [Icon.name]: Icon,
    },

    data() {
        return {
            isRead: false,
            shouldShowMore: false,
            showMore: false,
        }
    },

    watch: {
        input: function (val) {
            this.isRead = val.is_read;
        }
    },

    beforeMount() {
        this.isRead = this.input.is_read;
    },

    mounted() {
        this.$nextTick(() => {
            const height = this.$refs.contentRef.offsetHeight;
            this.shouldShowMore = height > 68;

            this.$refs.contentRef.style.height = this.showMore ? 'auto' : '68px';

            this.$refs.contentRef.querySelectorAll("a").forEach((a) => {
                a.addEventListener("click", this.clickHandler.bind(this));
            })
        })
    },

    beforeUnmount() {
        this.$refs.contentRef.querySelectorAll("a").forEach((a) => {
            a.removeEventListener("click", this.clickHandler.bind(this));
        })
    },

    props: {
        input: {},
    },

    methods: {
        setRead() {
            if (this.isRead) return;
            this.isRead = true;

            setNotiRead({
                ids: this.input.id
            }).subscribe();
        },

        clickHandler(e) {
            Dialog({ message: '请使用电脑客户端点击下载，\n有效期为2小时，超时请重新操作' });
        }
    }
})


</script>

<style lang="less" scoped>
.host {
    display: block;
    padding: 10px 15px 0 15px;

    cursor: pointer;

    ::v-deep .is-read span.font-red {
        color: #aaaaaa !important;
        font-weight: normal !important;
    }

    ::v-deep .van-badge--top-right {
        transform: none;
    }

    h4 {
        margin: 10px 0;
    }
}

.container {
    flex-wrap: nowrap;
    display: flex;
}

.hide-more {
    height: 68px;
    overflow: hidden;
}


.body {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    flex-grow: 1;

    p {
        margin-bottom: 0;
    }
}

.font-gray {
    color: gray;
}
</style>