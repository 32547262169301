<template>
  <div class="wechat-emoji-panel">
    <div
      class="emoji emoji-wechat"
      @click="chooseEmoji(code)"
      v-for="(name, code) in wechatEmojis"
      v-bind:key="name"
    >
      <span class="wechatface" :class="'wechatface_' + name"></span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      wechatEmojis: {
        "/::)": "1",
        "/::~": "2",
        "/::B": "3",
        "/::|": "4",
        "/:8-)": "5",
        "/::<": "6",
        "/::$": "7",
        "/::X": "8",
        "/::Z": "9",
        "/::'(": "10",
        "/::-|": "11",
        "/::@": "12",
        "/::P": "13",
        "/::D": "14",
        "/::O": "15",
        "/::(": "16",
        "/::+": "17",
        "[囧]": "18",
        "/::Q": "19",
        "/::T": "20",
        "/:,@P": "21",
        "/:,@-D": "22",
        "/::d": "23",
        "/:,@o": "24",
        "/::g": "25",
        "/:|-)": "26",
        "/::!": "27",
        "/::L": "28",
        "/::>": "29",
        "/::,@": "30",
        "/:,@f": "31",
        "/::-S": "32",
        "/:?": "33",
        "/:,@x": "34",
        "/:,@@": "35",
        "/::8": "36",
        "/:,@!": "37",
        "/:!!!": "38",
        "/:xx": "39",
        "/:bye": "40",
        "/:wipe": "41",
        "/:dig": "42",
        "/:handclap": "43",
        "/:&-(": "44",
        "/:B-)": "45",
        "/:<@": "46",
        "/:@>": "47",
        "/::-O": "48",
        "/:>-|": "49",
        "/:P-(": "50",
        "[快哭了]": "51",
        "/:X-)": "52",
        "/::*": "53",
        "/:@x": "54",
        "/:8*": "55",
        "[奸笑]": "106",
        "[嘿哈]": "107",
        "[捂脸]": "108",
        "[机智]": "109",
        "[红包]": "111",
        "[耶]": "113",
        "[皱眉]": "114",
        "/:pd": "56",
        "/:<W>": "57",
        "/:beer": "58",
        "/:basketb": "59",
        "/:oo": "60",
        "/:coffee": "61",
        "/:eat": "62",
        "/:pig": "63",
        "/:rose": "64",
        "/:fade": "65",
        "/:showlove": "66",
        "/:heart": "67",
        "/:break": "68",
        "/:cake": "69",
        "/:li": "70",
        "/:bome": "71",
        "/:kn": "72",
        "/:footb": "73",
        "/:ladybug": "74",
        "/:shit": "75",
        "/:moon": "76",
        "/:sun": "77",
        "/:gift": "78",
        "/:hug": "79",
        "/:strong": "80",
        "/:weak": "81",
        "/:share": "82",
        "/:v": "83",
        "/:@)": "84",
        "/:jj": "85",
        "/:@@": "86",
        "/:bad": "87",
        "/:lvu": "88",
        "/:no": "89",
        "/:ok": "90",
        "/:love": "91",
        "/:<L>": "92",
        "/:jump": "93",
        "/:shake": "94",
        "/:<O>": "95",
        "/:circle": "96",
        "/:kotow": "97",
        "/:turn": "98",
        "/:skip": "99",
        "/:oY": "100",
        "/:#-0": "101",
        "/:hiphot": "102",
        "/:kiss": "103",
        "/:<&": "104",
        "/:&>": "105",
        "[Chick]": "115",
        "[吃瓜]": "116",
        "[加油]": "117",
        "[Sweats]": "118",
        "[OMG]": "119",
        "[Emm]": "120",
        "[社会社会]": "121",
        "[旺柴]": "122",
        "[好的]": "123",
        "[打脸]": "124",
        "[哇]": "125",
        "[翻白眼]": "126",
        "[666]": "127",
        "[让我看看]": "128",
        "[叹气]": "129",
        "[苦涩]": "130",
        "[裂开]": "131",
        "[Party]": "132",
        "[Lol]": "133",
        "[Worship]": "134",
        "[Happy]": "135",
        "[Rich]": "136",
        "[Sick]": "137",
        "[Flushed]": "138",
        "[Awesome]": "139",
        "[Blessing]": "140",
        "[Fireworks]": "141",
        "[Firecracker]": "142",
        "[Terror]": "143",
        "[LetDown]": "144",
        "[Duh]": "145",
      },
    };
  },

  methods: {
    chooseEmoji(code) {
      this.$emit("chooseEmoji", code);
      return;
    },
  },

  mounted() {},
});
</script>

<style lang="less" scoped>
.wechat-emoji-panel {
  display: flex;
  flex-wrap: wrap;
}

.emoji {
  width: calc(100% / 8);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
  img {
    width: 25px;
    height: 25px;
  }

  i {
    font-size: 25px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}
</style>
