<template>
  <div class="container">
    <van-nav-bar title="隐私政策" safe-area-inset-top />

    <div class="iframe-wrapper">
      <iframe src="https://doc.yunque360.com/x/UYs1Ag" scrolling="true" frameborder="0"></iframe>
    </div>

    <div class="action-container">
      <van-button @click="denyTOS" class="action-btn">拒绝</van-button>

      <van-button type="primary" @click="acceptTOS" class="action-btn">同意</van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Button, NavBar } from "vant";
import { redirectToMobileLogin } from "@/utils/host";

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
  },

  data() {
    return {
      isMannual: false,
    };
  },

  mounted() {
    window.addEventListener("message", function (event) {
      let message = event.data;
      if (typeof message !== "string") return;
      if (message.indexOf("$GO_BACK") !== -1) {
        this.$router.go(-1);
      }
    });
  },

  beforeMount() {
    this.isMannual = !!this.$route.query.isMannual;
  },

  beforeUnmount() {
    window.removeEventListener("message", function () { });
  },

  methods: {
    acceptTOS() {
      if (!this.isMannual) {
        window.location.href = "/yunque_accept_tos";
      } else {
        localStorage.setItem("loggin_time", new Date().toString());
        redirectToMobileLogin();
      }
    },

    denyTOS() {


      if (this.isMannual) {
        redirectToMobileLogin();
      } else {
        Dialog.alert({
          message:
            "云雀需要获得您的设备ID以向您推送顾客咨询，如您拒绝将无法使用。",
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  position: relative;
}

.action-btn {
  margin: 0 10px;
  width: 100px;
}

.action-container {
  border-top: 1px solid rgb(239 232 232);
  position: absolute;
  bottom: 0;
  height: 66px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe-wrapper {
  position: absolute;
  top: 46px;
  bottom: 66px;
  width: 100%;
}

iframe {
  width: 100%;
  border: none;
  height: 100%;
}
</style>