declare const APP: any;
declare const bridge: any;
declare const window: any;

export function playNewMsgSound() {
  if (typeof APP !== "undefined") {
    APP.playNewMsgSound();
  } else if (typeof bridge !== "undefined") {
    window.bridge.call(
      "playNewMsgSound",
      null,
      () => {},
      () => {}
    );
  } else {
    const ele: any = document.getElementById("new-message-sound");
    ele.play();
  }
}

export function playSentMsgSound() {
  const ele: any = document.getElementById("sent-message-sound");
  ele.play();
}
