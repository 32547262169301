<template>
  <div class="container" v-bind:class="{
    'show-action': showActionPanel,
    'show-emoji': showEmojiPanel,
  }" :style="{
    height:
      showActionPanel || showEmojiPanel
        ? 170 + inputContainerHeight + 'px'
        : inputContainerHeight + 'px',
  }">
    <div class="cmt-container" v-if="toReplyCMT">
      <div class="cmt-text">回复：{{ toReplyCMT.content }}</div>

      <i class="iconfont" @click="clearCMT()" style="color: #acacac">&#xe68d;</i>
    </div>
    <div class="text-holder" :style="{ height: inputContainerHeight + 'px' }">
      <textarea type="text" id="input-area" ref="input" @keyup.enter="sendMsg" @input="changeInput" :placeholder="currentVisitor?.offlineFlag && currentVisitor.platform === 'web'
        ? '访客已离开，发送的消息仅访客下次进入时可见'
        : '请输入'
        " v-model="inputMsg" />

      <div id="emoji-btn" class="input-btn"
        v-if="['wechat', 'douyin', 'weibo', 'kuaishou', 'redbook'].includes(currentVisitor?.platform)"
        @click="triggerEmojiPanel">
        <i class="iconfont">&#xe60b;</i>
      </div>
      <div id="action-btn" class="input-btn" @click="triggerActionPanel">
        <i class="iconfont">&#xe611;</i>
      </div>
      <div id="send-btn" class="input-btn" v-if="inputMsg" @click="sendMsg">
        发送
      </div>
    </div>

    <div class="emoji-panel" v-if="showEmojiPanel">
      <wechat-emoji-panel v-if="currentVisitor?.platform === 'wechat'" @chooseEmoji="chooseEmoji"></wechat-emoji-panel>

      <weibo-emoji-panel v-if="currentVisitor?.platform === 'weibo'" @chooseEmoji="chooseEmoji"></weibo-emoji-panel>

      <douyin-emoji-panel v-if="currentVisitor?.platform === 'douyin'" @chooseEmoji="chooseEmoji"></douyin-emoji-panel>

      <kuaishou-emoji-panel v-if="currentVisitor?.platform === 'kuaishou'"
        @chooseEmoji="chooseEmoji"></kuaishou-emoji-panel>

      <redbook-emoji-panel v-if="currentVisitor?.platform === 'redbook'"
        @chooseEmoji="chooseEmoji"></redbook-emoji-panel>


    </div>

    <action-panel v-if="showActionPanel" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { getFormatedMsg } from "@/utils/message";
import CommetFactory from "@/api/CommetFactory";
import { playSentMsgSound } from "@/utils/audio";
import WechatEmojiPanel from "@/components/emoji-panels/WechatEmojiPanel.vue";
import WeiboEmojiPanel from "@/components/emoji-panels/WeiboEmojiPanel.vue";
import DouyinEmojiPanel from "@/components/emoji-panels/DouyinEmojiPanel.vue";
import KuaishouEmojiPanel from "@/components/emoji-panels/KuaishouEmojiPanel.vue";
import RedbookEmojiPanel from "@/components/emoji-panels/RedbookEmojiPanel.vue";
import ActionPanel from "@/components/ActionPanel.vue";

export default defineComponent({
  components: {
    "wechat-emoji-panel": WechatEmojiPanel,
    "weibo-emoji-panel": WeiboEmojiPanel,
    "douyin-emoji-panel": DouyinEmojiPanel,
    "kuaishou-emoji-panel": KuaishouEmojiPanel,
    "redbook-emoji-panel": RedbookEmojiPanel,
    "action-panel": ActionPanel,
  },

  data() {
    return {
      inputMsg: "",

      showEmojiPanel: false,
      showActionPanel: false,

      inputContainerHeight: 50,
    };
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
      toReplyCMT: "toReplyCMT",
    }),

    ...mapState({
      isMessageInputPulledUp: (state) => state.chat.isMessageInputPulledUp,
      inputDraft: (state) => state.chat.inputDraft,
    }),
  },

  watch: {
    isMessageInputPulledUp() {
      if (!this.isMessageInputPulledUp) {
        this.showEmojiPanel = false;
        this.showActionPanel = false;
      }
    },

    inputDraft() {
      if (this.inputDraft) {
        this.inputMsg = this.inputDraft;

        this.$nextTick(() => {
          this.changeInput();

        })
      }
    },

    toReplyCMT() {
      this.$nextTick(() => {
        this.updatePulledUp();
      });
    }
  },

  beforeMount() {
    this.$store.commit(
      "UPDATE_INPUT_PULLEDUP_HEIGHT",
      this.inputContainerHeight
    );
  },

  methods: {
    chooseEmoji(code) {
      this.inputMsg += code;
    },

    changeInput() {

      let scrollHeight =
        this.$refs.input.scrollHeight > 68 ? 68 : this.$refs.input.scrollHeight;
      if (this.inputMsg?.length < 20) scrollHeight = 40;

      this.$refs.input.style.height = scrollHeight + "px";

      this.inputContainerHeight = scrollHeight + 10;

      this.$store.commit(
        "UPDATE_INPUT_PULLEDUP_HEIGHT",
        this.inputContainerHeight
      );
    },

    updatePulledUp() {
      this.$store.commit(
        "UPDATE_INPUT_PULLEDUP",
        this.showEmojiPanel || this.showActionPanel
      );

      this.$store.commit(
        "UPDATE_INPUT_PULLEDUP_HEIGHT",
        this.inputContainerHeight
      );
    },

    clearCMT() {
      this.$store.commit("UPDATE_TO_REPLY_CMT", null);
    },

    triggerEmojiPanel() {
      this.showActionPanel = false;
      this.showEmojiPanel = !this.showEmojiPanel;

      this.updatePulledUp();
    },

    triggerActionPanel() {
      this.showEmojiPanel = false;
      this.showActionPanel = !this.showActionPanel;

      this.updatePulledUp();
    },

    sendMsg() {
      if (!this.inputMsg?.trim()) return;

      let [msg, tempMsg] = getFormatedMsg(
        this.currentVisitor.platform,
        this.currentVisitor.uid,
        {
          format: "text",
          content: this.inputMsg,
        },
        this.toReplyCMT
      );

      const Commet = CommetFactory(this.$store);
      this.$store.commit("UPDATE_TEMP_MESSAGE", tempMsg);

      if (this.toReplyCMT) {
        this.$store.commit("UPDATE_TO_REPLY_CMT", null);

      }
      console.log(msg)

      Commet.send(msg);
      playSentMsgSound();

      this.inputMsg = "";
      this.$store.commit(
        "UPDATE_INPUT_DRAFT",
        ""
      );
    },
  },
});
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.04), 0 -2px 0 0 rgba(0, 0, 0, 0.01);
  background: white;
}

.text-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;

  #input-area {
    display: block;
    width: 100%;
    padding: 12px 10px;
    margin-top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    resize: none;
    outline: 0;
    word-break: break-all;
    font-size: 14px;
    line-height: 14px;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;

    caret-color: var(--van-primary-color);

    height: 40px;

    box-sizing: border-box;
  }

  #emoji-btn,
  #action-btn {
    i.iconfont {
      font-size: 26px;
      line-height: 50px;
    }
  }

  .input-btn {
    width: 50px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
  }

  #send-btn {
    width: 70px;
    background-color: #04be02;
    color: white;
    font-size: 1rem;
    -webkit-appearance: none;
    border-radius: 3px;
    margin: 8px 5px;
    line-height: 34px;
    height: 34px;
  }
}

.emoji-panel {
  overflow-y: auto;
  height: 170px;
}

.cmt-container {
  position: absolute;
  top: -40px;
  background-color: #D7D7D7;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;

  .cmt-text {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>