import { apiClient } from "@/utils/http";
import { from } from "rxjs";
import { map, pluck } from "rxjs/operators";
import axios from "axios";

export function getWechatApps() {
  return from(apiClient.post(`/worker/wechat/index`)).pipe(
    pluck("data", "result", "apps")
  );
}

export function getWeiboApps() {
  return from(apiClient.post(`/worker/weibo/index`)).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.apps = res.apps.map((app: any) => {
        app.bind_url = res.bind_url;
        return app;
      });

      return res.apps;
    })
  );
}

export function getDouyinApps() {
  return from(apiClient.post(`/worker/douyin/index`)).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.apps = res.apps.map((app: any) => {
        app.bind_url = res.bind_url;
        return app;
      });

      return res.apps;
    })
  );
}

export function getKuaishouApps() {
  return from(apiClient.post(`/worker/kuaishou/index`)).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.apps = res.apps.map((app: any) => {
        app.bind_url = res.bind_url;
        return app;
      });

      return res.apps;
    })
  );
}

export function getRedbookApps() {
  return from(apiClient.post(`/worker/redbook/index`)).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.apps = res.apps.map((app: any) => {
        app.bind_url = res.bind_url;
        return app;
      });

      return res.apps;
    })
  );
}

export function getAllBadgeCount() {
  return from(apiClient.post(`/worker/conversation/badge-count`)).pipe(
    pluck("data", "result")
  );
}

export function getWorkerList() {
  return from(apiClient.post(`/worker/account/workers`)).pipe(
    pluck("data", "result")
  );
}

export function getCompanyInfo() {
  return from(apiClient.post(`/worker/account/company-info`)).pipe(
    pluck("data", "result")
  );
}

export function getOnDutyData() {
  return from(apiClient.post(`/worker/account/info`)).pipe(
    pluck("data", "result", "monline"),
    map((monline: number) => monline === 1)
  );
}

export function setOndutyInfo(val: boolean) {
  const status = val ? 1 : 0;

  return from(apiClient.post(`/worker/account/set-mobile-status`, { status }));
}

export function getRoutineData() {
  return from(apiClient.post(`/worker/account/auto-onoff`)).pipe(
    pluck("data", "result", "settings")
  );
}

export function setRoutineData(data: any) {
  return from(apiClient.post(`/worker/account/auto-onoff`, data));
}

export function sendPush(push_type: any, mobile_type: any, mobile_id: any) {
  return from(
    apiClient.post(`/worker/account/send-push`, {
      push_type,
      mobile_type,
      mobile_id,
    })
  );
}

export function sendWechatPush() {
  return from(
    apiClient.post(`/worker/account/send-mp-push`, {
      mobile_type: 'wechat-mp',
      push_type: 'test',
    })
  );
}

export function getTagsList() {
  return from(apiClient.get(`/worker/user-tag-new/index`)).pipe(
    pluck("data", "result", "tags"),
    map((tagsObj: any) => {
      const result: any = [];
      Object.keys(tagsObj).forEach((key) => {
        result.push(tagsObj[key]);
      });
      return result;
    })
  );
}

export function getUserInfo(uid: string) {
  return from(apiClient.get(`/worker/user/info?uid=${uid}`)).pipe(
    pluck("data", "result")
  );
}

export function setUserInfo(uid: string, info: any) {
  const data = {
    uid,
    ...info,
  };

  return from(apiClient.post(`/worker/user/edit`, data)).pipe(
    pluck("data", "result")
  );
}

export function checkMobile(data: any) {
  return from(apiClient.post(`/worker/mobile-check/verify`, data)).pipe(
    pluck("data", "result")
  );
}

export function getMobileValidationInfo(mobile: string) {
  return from(
    apiClient.get(`/worker/mobile-check/search?mobile=` + mobile)
  ).pipe(pluck("data", "result"));
}

export function getUserTag(uid: string) {
  return getUserInfo(uid).pipe(
    pluck("tags"),
    map((tagsObj: any) => {
      const result: any = [];
      Object.keys(tagsObj).forEach((key) => {
        result.push(tagsObj[key]);
      });
      return result;
    })
  );
}

export function setTag(data: any) {
  data.wait_refresh = true;

  return from(apiClient.post(`/worker/user/set-tags`, data));
}

export function block(data: any) {
  return from(apiClient.post(`/worker/blacklist/create`, data));
}

export function getOnlineWorkers() {
  return from(apiClient.post(`/worker/account/workers`)).pipe(
    pluck("data", "result"),
    map((list) => {
      return list.filter((worker: any) => {
        return worker.online === 1 || worker.monline === 1;
      });
    })
  );
}

export function transfer(data: any) {
  return from(apiClient.post(`/worker/conversation/transfer`, data));
}

export function getLeadInfo(uid: string) {
  return from(apiClient.get(`/worker/user/info?uid=${uid}`)).pipe(
    pluck("data", "result")
  );
}

export function setLeadInfo(data: any) {
  return from(apiClient.post(`/worker/user/edit`, data));
}

export function getToken(ext: any, type = "chatcdn") {
  const data = {
    ext: ext,
    type: type,
    greeting:
      "Hello, this is not a free image bed, signature will be added to the web interface, abused images will be deleted. 你好，这不是一个免费图床，我们正在增加接口签名，滥用的图片将被删除。",
  };

  return apiClient.post("/chat/im/uptoken", data);
}

export function sendImage(file: any, platform: string) {
  const type = "chatcdn";

  if (platform === "wechat") {
    if (file.size >= 1024 * 1024 * 2) {
      alert("上传文件不能超过2MB");
      return Promise.reject();
    }
  }
  if (platform === "douyin") {
    if (file.size >= 1024 * 1024 * 2) {
      alert("上传文件不能超过2MB");
      return Promise.reject();
    }
  } else {
    if (file.size >= 1024 * 1024 * 8) {
      alert("上传文件不能超过8MB");
      return Promise.reject();
    }
  }

  try {
    const ext = file.name.split(".").pop();
    let storedViewhost: any, storedFilename: any;
    return getToken(ext, type)
      .then((res: any) => {
        console.log(res);
        const data = res.data.data;

        let host = data.host;
        if (host.indexOf("http") !== -1) {
          host = host.slice(5);
        }
        host = "https:" + host;
        storedViewhost = data.viewhost;
        const OSSAccessKeyId = data.access_id;
        const policy = data.policy;
        const Signature = data.signature;
        const key = data.filename;

        storedFilename = key;
        const postData = new FormData();
        postData.append("OSSAccessKeyId", OSSAccessKeyId);
        postData.append("policy", policy);
        postData.append("Signature", Signature);
        postData.append("key", key);
        postData.append("file", file);

        return axios.post(host, postData);
      })
      .then(() => {
        // return this.fileData;
        return [storedViewhost, storedFilename];
      });
  } catch (error) {
    //say something;
  }
}

export function getFullSnippet() {
  return from(apiClient.post("/worker/draft/full-view")).pipe(
    pluck("data", "result")
  );
}

export function getChatSettings() {
  return from(apiClient.post("/worker/company/set")).pipe(
    pluck("data", "result")
  );
}

export function setChatSettings(data: any) {
  return from(apiClient.post("/worker/company/set", data)).pipe(
    pluck("data", "result")
  );
}

export function getChatNotify() {
  return from(apiClient.post("/worker/account/set-notify")).pipe(
    pluck("data", "result")
  );
}

export function setChatNotify(data: any) {
  return from(apiClient.post("/worker/account/set-notify", data)).pipe(
    pluck("data", "result")
  );
}

export function getUnreadCount() {
  return from(apiClient.post(`/worker/notice/unread-count`)).pipe(
    pluck("data", "result", "data", "unread")
  );
}

export function setNotiRead(data: any) {
  return from(apiClient.post(`/worker/notice/read`, data)).pipe(pluck("data"));
}

export function getNotiList() {
  return from(apiClient.get(`/worker/notice/list?page=1&pagesize=100`)).pipe(
    pluck("data", "result", "list")
  );
}

export function recallMsg(data: any) {
  return from(apiClient.post("/worker/common/recall-msg", data));
}

export function getNameCardList(data: any) {
  return from(apiClient.get("/worker/redbook/social-card-list", {
    params: data,
  })).pipe(
    pluck("data", "result", "list", "data")
  );
}
