<template>
    <div class="container">
        <div>
            {{ menu.tip }}
        </div>

        <div class="question-list">
            <div class="question" v-if="menu.items && menu.items[0]">
                <span class="font-blue cursor-pointer">
                    <template v-if="menu.showSort">
                        {{ menu.items[0].sort }}、
                    </template>
                    {{ menu.items[0].question }}
                </span>
            </div>

            <div class="question" v-if="menu.items && menu.items[1]">
                <span class="font-blue cursor-pointer">
                    <template v-if="menu.showSort">
                        {{ menu.items[1].sort }}、
                    </template>
                    {{ menu.items[1].question }}

                    <template v-if="menu.items[2] || menu.remark">...</template>
                </span>
            </div>
        </div>
    </div>
</template>
  
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        menu: Object,
    },

    data() {
        return {

        };
    },

    beforeMount() {
    },
});
</script>
  
  
<style lang="less" scoped>
.container {
    display: inline-block;
    width: 310px;
    height: 85px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: left;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);

    overflow-y: auto;

    padding: 10px;
}


.font-blue {
    color: #2593f4;
}
</style>