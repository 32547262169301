import { httpClient, apiClient, imapiClient, commonClient } from "./http";
import { combineLatest, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { getSiteConfigs } from "@/api/site-config";

const option: any = {
  responseType: "text",
};

declare const window: any;

function getTimestampUrl(url: string) {
  return url + "/time?_=" + Math.floor(Date.now() / 1000);
}

function findSite(
  normal: string,
  backup: string,
  esite: string,
  hasSuffix: boolean
) {
  const site$ = new Subject();
  const suffix = hasSuffix ? "/v1" : "";

  httpClient.get(getTimestampUrl(normal), option).then(
    () => {
      site$.next(normal + suffix);
    },
    () => {
      // backup site
      httpClient.get(getTimestampUrl(backup), option).then(
        () => {
          site$.next(backup + suffix);
        },
        () => {
          httpClient
            .get(
              `https://dns.alidns.com/resolve?name=${esite}&type=16&time=` +
                Math.floor(Date.now() / 1000)
            )
            .then(
              (res: any) => {
                if (res?.Answer && res.Answer[0]?.data) {
                  const e_url =
                    "https://" + res.Answer[0].data.replace(/"/g, "");

                  httpClient.get(getTimestampUrl(e_url), option).then(
                    () => {
                      site$.next(e_url + suffix);
                    },

                    () => {
                      site$.next(null);
                    }
                  );
                } else {
                  site$.next(null);
                }
              },
              () => {
                site$.next(null);
              }
            );
        }
      );
    }
  );

  return site$.asObservable() as Observable<string> | Observable<null>;
}

export function findServer() {
  const {
    POLLING_SITE,
    B_POLLING_SITE,
    E_POLLING_SITE,
    API_SITE,
    B_API_SITE,
    E_API_SITE,
  } = getSiteConfigs();

  return combineLatest([
    findSite(POLLING_SITE, B_POLLING_SITE, E_POLLING_SITE, false),

    findSite(API_SITE, B_API_SITE, E_API_SITE, true),
  ]).pipe(
    map(([imapi, api]) => {
      if (imapi && api) {
        window.API_SITE = api;
        window.POLLING_SITE = imapi;

        Object.assign(apiClient.defaults, {
          baseURL: api,
        });
        Object.assign(imapiClient.defaults, {
          baseURL: imapi,
        });
        Object.assign(commonClient.defaults, {
          baseURL: api,
        });

        return true;
      } else {
        throw new Error();
      }
    })
  );
}
