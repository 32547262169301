<template>
  <van-button class="filter-btn" size="mini" @click.self="show = !show">
    <van-icon name="filter-o" />筛选</van-button>

  <van-popup v-model:show="show" v-if="show" position="top" :style="{ height: height, top: '45px', display: 'flex' }"
    :overlay-style="{ top: '45px' }" :close-on-popstate="true" :transition-appear="false" :close-on-click-overlay="true"
    :teleport="'#app'">
    <div class="popup-container">
      <template v-if="platform === TabList.web">
        <p>网页访客状态：</p>
        <van-checkbox-group v-model="webStatus" direction="horizontal">
          <van-checkbox name="spoken">开口</van-checkbox>
          <van-checkbox name="interaction">互动</van-checkbox>
          <van-checkbox name="silent">沉默</van-checkbox>
          <van-checkbox name="unread_comment">未读留言</van-checkbox>
          <van-checkbox name="read_unreply">已读未回</van-checkbox>
        </van-checkbox-group>
      </template>

      <template v-if="platform === TabList.wechat">
        <p>微信访客状态：</p>
        <van-checkbox-group v-model="wechatStatus" direction="horizontal">
          <van-checkbox name="2">开口</van-checkbox>
          <van-checkbox name="1">互动</van-checkbox>
          <van-checkbox name="0">沉默</van-checkbox>
          <van-checkbox name="reply_status_1">未读未回</van-checkbox>
          <van-checkbox name="reply_status_2">已读未回</van-checkbox>
        </van-checkbox-group>
      </template>

      <template v-if="platform === TabList.weibo">
        <p>微博访客状态：</p>
        <van-checkbox-group v-model="weiboStatus" direction="horizontal">
          <van-checkbox name="2">开口</van-checkbox>
          <van-checkbox name="1">互动</van-checkbox>
          <van-checkbox name="0">沉默</van-checkbox>
          <van-checkbox name="reply_status_1">未读未回</van-checkbox>
          <van-checkbox name="reply_status_2">已读未回</van-checkbox>
        </van-checkbox-group>
      </template>

      <template v-if="platform === TabList.douyin">
        <p>抖音访客状态：</p>
        <van-checkbox-group v-model="douyinStatus" direction="horizontal">
          <van-checkbox name="2">开口</van-checkbox>
          <van-checkbox name="1">互动</van-checkbox>
          <van-checkbox name="0">沉默</van-checkbox>
          <van-checkbox name="reply_status_1">未读未回</van-checkbox>
          <van-checkbox name="reply_status_2">已读未回</van-checkbox>
        </van-checkbox-group>
      </template>

      <template v-if="platform === TabList.redbook">
        <p>小红书访客状态：</p>
        <van-checkbox-group v-model="redbookStatus" direction="horizontal">
          <van-checkbox name="2">开口</van-checkbox>
          <van-checkbox name="1">互动</van-checkbox>
          <van-checkbox name="reply_status_1">未读未回</van-checkbox>
          <van-checkbox name="reply_status_2">已读未回</van-checkbox>
        </van-checkbox-group>
      </template>

      <template v-if="platform === TabList.kuaishou">
        <template v-if="kuaishouTab !== 1">
          <p>快手访客状态：</p>
          <van-checkbox-group v-model="kuaishouStatus" direction="horizontal">
            <van-checkbox name="2">开口</van-checkbox>
            <van-checkbox name="1">互动</van-checkbox>
            <van-checkbox name="reply_status_1">未读未回</van-checkbox>
            <van-checkbox name="reply_status_2">已读未回</van-checkbox>
          </van-checkbox-group>
        </template>

        <template v-if="kuaishouTab === 1">
          <p>快手访客状态：</p>
          <van-checkbox-group v-model="kuaishouPotentialStatus" direction="horizontal">
            <van-checkbox name="live">直播评论</van-checkbox>
            <van-checkbox name="video">视频评论</van-checkbox>
          </van-checkbox-group>
        </template>

      </template>

      <template v-if="platform !== TabList.weibo && kuaishouTab !== 1">
        <p>智能客服接入状态：</p>
        <van-radio-group v-model="ai_cs" direction="horizontal">
          <van-radio name="1">接入智能客服</van-radio>
          <van-radio name="0">未接入智能客服</van-radio>
          <van-radio name="2">未查看智能客服</van-radio>
        </van-radio-group>
      </template>

      <div class="actions-container">
        <van-button @click="reset">重置</van-button>
        <van-button type="primary" @click="save">保存设置</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent } from "vue";

import { Button, Icon, Popup, Checkbox, CheckboxGroup, RadioGroup, Radio } from "vant";
import { TabList } from "@/enums/history-tabs";
import {
  getHistoryFilters,
  getDefaultSettings,
  getHistoryAiFilter,
  setHistoryAiFilter,
  setHistoryFilters,
  getKuaishouPotentialFilters,
  setKuaishouPotentialFilters
} from "@/utils/historyFilter";

import emitter from "@/utils/bus";
import { mapState } from "vuex";
import { getPlatformByTabList } from "@/enums/history-tabs";

export default defineComponent({
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  props: {
    platform: Number,
  },

  computed: {
    ...mapState({
      kuaishouTab: (state) => state.common.kuaishouTab,
    }),
  },

  data() {
    return {
      show: false,
      TabList,
      webStatus: getHistoryFilters("web"),
      wechatStatus: getHistoryFilters("wechat"),
      weiboStatus: getHistoryFilters("weibo"),
      douyinStatus: getHistoryFilters("douyin"),
      kuaishouStatus: getHistoryFilters("kuaishou"),
      redbookStatus: getHistoryFilters("redbook"),
      ai_cs: getHistoryAiFilter("ai_cs"),
      kuaishouPotentialStatus: getKuaishouPotentialFilters(),
      height: '270px'
    };
  },

  beforeMount() {
    this.changeheight();
  },

  watch: {
    platform(val) {
      const platform = getPlatformByTabList(val);
      this[platform + "Status"] = getHistoryFilters(platform);
    },

    show(val) {
      if (val) {
        const platform = getPlatformByTabList(this.platform);
        this[platform + "Status"] = getHistoryFilters(platform);
      }
    },

    kuaishouTab() {
      this.changeheight();
    }
  },

  methods: {
    changeheight() {
      const platform = this.platform;
      if (platform === TabList.weibo) {
        this.height = '170px';
      } else
        if (platform === TabList.kuaishou && this.kuaishouTab === 1) {
          this.height = '170px';
        } else {
          this.height = '270px';
        }
    },

    save() {

      if (this.platform === TabList.kuaishou && this.kuaishouTab === 1) {
        setKuaishouPotentialFilters(this.kuaishouPotentialStatus);
        emitter.emit("UPDATE_HISTORY_FILTERS");
        this.show = false;

        return;
      }

      const platform = getPlatformByTabList(this.platform);

      setHistoryFilters(platform, this[platform + "Status"]);
      setHistoryAiFilter(this.ai_cs);

      emitter.emit("UPDATE_HISTORY_FILTERS");

      this.show = false;
    },

    reset() {

      if (this.platform === TabList.kuaishou && this.kuaishouTab === 1) {
        setKuaishouPotentialFilters(['video', 'live']);
        this.kuaishouPotentialStatus = ['video', 'live'];
        emitter.emit("UPDATE_HISTORY_FILTERS");

        return;
      }

      const platform = getPlatformByTabList(this.platform);

      const status = getDefaultSettings(platform);
      this[platform + "Status"] = status;
      setHistoryFilters(platform, status);

      this.ai_cs = '';
      setHistoryAiFilter('');

      emitter.emit("UPDATE_HISTORY_FILTERS");
    },
  },
});
</script>

<style lang="less" scoped>
.van-icon {
  color: black;
}

.popup-container {
  padding: 0 15px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;

  font-size: 14px;

  p {
    margin: 16px 0 4px 0;
  }

  .van-checkbox,
  .van-radio {
    margin-top: 8px;
  }
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  border-top: 1px solid #ddd;
  padding: 10px;

  display: flex;
  justify-content: center;

  &>* {
    margin: 0 10px;
    width: 150px;
  }
}
</style>
