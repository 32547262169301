<template>
  <div class="container">
    <template v-if="platform === 'web'">
      <i class="iconfont">&#xe6f5;</i>
    </template>

    <template v-if="platform === 'wechat'">
      <i class="iconfont">&#xe600;</i>
    </template>

    <template v-if="platform === 'weibo'">
      <i class="iconfont">&#xe62f;</i>
    </template>

    <template v-if="platform === 'douyin'">
      <i class="iconfont">&#xe607;</i>
    </template>

    <template v-if="platform === 'redbook'">
      <i class="iconfont">&#xe7eb;</i>
    </template>

    <template v-if="platform === 'kuaishou'">
      <i class="iconfont">&#xe66f;</i>
    </template>

    <span class="subtitle">
      {{ subTtile }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { DefaultSourceMap } from "@/data/source";

export default defineComponent({
  name: "ChatPageSubtitle",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
    }),
    ...mapState({
      wechatApps: (state) => state.common.wechatAppsObj,
      weiboApps: (state) => state.common.weiboAppsObj,
      douyinApps: (state) => state.common.douyinAppsObj,
      kuaishouApps: (state) => state.common.kuaishouAppsObj,
      redbookApps: (state) => state.common.redbookAppsObj,
    }),
    platform: function () {
      return this.currentVisitor?.platform || "";
    },
    subTtile: function () {
      if (!this.currentVisitor) return "";
      let displayText = "";
      switch (this.currentVisitor.platform) {
        case "web":
          return this.getWebSubtitle(this.currentVisitor.referrer);
        case "wechat":
          return this.getWechatSubTitle();
        case "weibo":
          return this.weiboApps[this.currentVisitor.appid]?.name;
        case "douyin":
          displayText = this.douyinApps[this.currentVisitor.appid]?.name;
          if (this.currentVisitor.subscribe_ad?.campaign_name) {
            displayText +=
              " - 广告计划: " + this.currentVisitor.subscribe_ad.campaign_name;
          }
          return displayText;
        case "kuaishou":
          return this.getKuaishouSubTitle();
        case "redbook":
          displayText = this.redbookApps[this.currentVisitor.appid]?.name;
          if (this.currentVisitor.subscribe_ad?.campaign_name) {
            displayText +=
              " - 广告计划: " + this.currentVisitor.subscribe_ad.campaign_name;
          }
          if (
            !this.currentVisitor.subscribe_ad?.campaign_name &&
            this.currentVisitor.subscribe_ad?.note_info
          ) {
            displayText += ` - 笔记: ${this.currentVisitor.subscribe_ad?.note_info.title}`;
          }
          return displayText;
        default:
          return "";
      }
    },
  },

  methods: {
    getWebSubtitle(referrer) {
      const source = referrer.source;
      let displayText = "";

      if (source === "search") {
        displayText = DefaultSourceMap[referrer.engine];
      } else if (source === "custom") {
        displayText = `自定义(${referrer.custom_source})`;
      } else {
        displayText = DefaultSourceMap[source];
      }

      if (referrer.keyword) {
        displayText += `(${referrer.keyword})`;
      }

      return displayText;
    },

    getWechatSubTitle() {
      const wechatApp = this.wechatApps[this.currentVisitor.appid];

      let displayText = "";

      if (wechatApp?.corp_name) {
        displayText = `${wechatApp.name} @${wechatApp.corp_name}`;
      } else {
        displayText = wechatApp?.name;
      }

      if (this.currentVisitor.subscribe_from) {
        let subscribeText = `${this.currentVisitor.subscribe_from}`;

        if (this.currentVisitor.subscribe_ad) {
          if (this.currentVisitor.subscribe_ad.campaign_name) {
            subscribeText += ` - 广告计划: ${this.currentVisitor.subscribe_ad.campaign_name}`;
          } else if (this.currentVisitor.subscribe_ad.adgroup_name) {
            subscribeText += ` - 广告: ${this.currentVisitor.subscribe_ad?.adgroup_name}`;
          } else if (this.currentVisitor.subscribe_ad.campaign_id) {
            subscribeText += ` - 广告计划: ${this.currentVisitor.subscribe_ad.campaign_id}`;
          } else if (this.currentVisitor.subscribe_ad.adgroup_id) {
            subscribeText += ` - 广告: ${this.currentVisitor.subscribe_ad.adgroup_id}`;
          }
        }

        if (this.currentVisitor.qrcode_name) {
          subscribeText += `-${this.currentVisitor.qrcode_name}`;
        }

        displayText += `-${subscribeText}`;
      }

      return displayText;
    },

    getKuaishouSubTitle() {
      let displayText = this.kuaishouApps[this.currentVisitor.appid]?.name;

      let suffix = this.currentVisitor.subscribe_from
        ? `-${this.currentVisitor.subscribe_from}`
        : "";

      if (this.currentVisitor.subscribe_ad?.campaign_name) {
        suffix +=
          "-广告计划: " + this.currentVisitor.subscribe_ad.campaign_name;
      }

      suffix = suffix.replace("流量", "");

      return displayText + suffix;
    },
  },
});
</script>

<style lang="less" scoped>
.container {
  line-height: 15px;
  font-size: 11px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: center;

  .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
