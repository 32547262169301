import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05d96530"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "nickname" }
const _hoisted_5 = { class: "msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_badge = _resolveComponent("van-badge")!
  const _component_app_tag = _resolveComponent("app-tag")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToChatDetail && _ctx.goToChatDetail(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_badge, {
        content: _ctx.visitor.newCount ? _ctx.visitor.newCount : null
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.avatar,
            alt: "头像",
            class: "img"
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["content"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.visitor.feLocation) + " " + _toDisplayString(_ctx.visitor.name || _ctx.visitor.nickname), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitor.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_app_tag, {
            key: tag.id,
            tag: tag
          }, null, 8, ["tag"]))
        }), 128))
      ]),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.visitor.latestMsgText), 1)
      ])
    ])
  ]))
}