const devConfig = {
  API_SITE: "https://wapi.yunque123.cn",
  POLLING_SITE: "https://wimapi.yunque123.cn",

  B_API_SITE: "https://wapi-1.yunque123.cn",
  B_POLLING_SITE: "https://wimapi-1.yunque123.cn",

  E_API_SITE: "wapi-e.yunque123.cn",
  E_POLLING_SITE: "wimapi-e.yunque123.cn",

  COMPANY_IMAGE_CDN_SITE: "//yunque-test001-cdn.yunque123.cn",
  CHAT_IMAGE_CDN_SITE: "//yunque-test001-cdn.yunque123.cn",

  WS_SITE: "wss://api.yunque123.cn/link",

};

const prodConfig = {
  API_SITE: "https://wapi.yunque360.com",
  POLLING_SITE: "https://wimapi.yunque360.com",

  B_API_SITE: "https://wapi-1.yunque360.com",
  B_POLLING_SITE: "https://wimapi-1.yunque360.com",

  E_API_SITE: "wapi-e.yunque360.com",
  E_POLLING_SITE: "wimapi-e.yunque360.com",

  COMPANY_IMAGE_CDN_SITE: "//yunque-company-cdn.yunque360.com",
  CHAT_IMAGE_CDN_SITE: '//yunque-chatcdn-cdn.yunque360.com',

  WS_SITE: "wss://wapi.yunque360.com/link",

};

export function getSiteConfigs() {
  if (process.env.NODE_ENV === "development") {
    return devConfig;
  } else {
    if (window.location.host.includes("yunque360")) {
      return prodConfig;
    } else {
      return devConfig;
    }
  }
}
