
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Badge } from "vant";
import Tag from "@/components/Tag.vue";

export default defineComponent({
  components: {
    [Badge.name]: Badge,
    "app-tag": Tag,
  },

  props: {
    visitor: Object,
  },

  computed: {
    ...mapState({
      avatar() {
        const platform = this.visitor.platform;
        if (platform === "wechat") {
          return (
            this.visitor.avatar || require("@/assets/img/wechat-avatars.png")
          );
        }

        if (platform === "weibo") {
          return (
            this.visitor.avatar || require("@/assets/img/weibo-avatars.png")
          );
        }

        if (platform === "douyin") {
          return (
            this.visitor.avatar || require("@/assets/img/douyin-avatars.png")
          );
        }

        if (platform === "kuaishou") {
          return (
            this.visitor.avatar || require("@/assets/img/kuaishou-icon.png")
          );
        }

        if (platform === "redbook") {
          return (
            this.visitor.avatar || require("@/assets/img/redbook-icon.png")
          );
        }

        if (platform === "web") {
          let rawPlatform = this.visitor.rawPlatform;
          if (rawPlatform === "pc") {
            return require("@/assets/img/computer.png");
          }

          if (rawPlatform === "mobile") {
            return require("@/assets/img/mobile.png");
          }
        }

        return "";
      },
    }),
  },

  methods: {
    goToChatDetail() {
      if (!this.visitor?.uid) return;

      this.$router.push({
        path: "/chat",
        query: {
          uid: this.visitor.uid,
        },
      });
    },
  },
});
