import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_history_filter = _resolveComponent("history-filter")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_van_tabs = _resolveComponent("van-tabs")!
  const _component_history_list = _resolveComponent("history-list")!
  const _component_bottom_bar = _resolveComponent("bottom-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, { title: "历史记录" }, {
      right: _withCtx(() => [
        _createVNode(_component_history_filter, { platform: _ctx.activeTab }, null, 8, ["platform"])
      ]),
      _: 1
    }),
    _createVNode(_component_van_tabs, {
      active: _ctx.activeTab,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, {
          title: "网页",
          badge: _ctx.webBadge
        }, null, 8, ["badge"]),
        _createVNode(_component_van_tab, {
          title: "微信",
          badge: _ctx.wechatBadge
        }, null, 8, ["badge"]),
        _createVNode(_component_van_tab, {
          title: "抖音",
          badge: _ctx.douyinBadge
        }, null, 8, ["badge"]),
        _createVNode(_component_van_tab, {
          title: "小红书",
          badge: _ctx.redbookBadge
        }, null, 8, ["badge"]),
        _createVNode(_component_van_tab, {
          title: "快手",
          badge: _ctx.kuaishoubadge
        }, null, 8, ["badge"]),
        _createVNode(_component_van_tab, {
          title: "微博",
          badge: _ctx.weiboBadge
        }, null, 8, ["badge"])
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_history_list, { tabType: _ctx.activeTab }, null, 8, ["tabType"]),
    _createVNode(_component_bottom_bar)
  ], 64))
}