<template>
  <van-nav-bar
    title="拉黑"
    safe-area-inset-top
    @click-left="goBack"
    left-text="返回"
    left-arrow
  />

  <p class="warning-info" v-if="platform === 'wechat'">
    <sup>*</sup
    >加入黑名单后，你将无法接收该用户发来的消息，且该用户无法接收公众号发出的消息，无法参与留言和赞赏
  </p>
  <van-cell-group inset>
    <template v-if="isPureWeb">
      <van-field
        v-model="blockMethod"
        is-link
        readonly
        label="拉黑方式"
        placeholder="点击选择拉黑方式"
        @click="showIPMethodsPicker = true"
      />
      <van-field v-model="ip" label="IP地址" placeholder="请输入IP地址" />

      <van-field
        v-model="ipprefix"
        label="IP地址段"
        placeholder="请输入IP地址段"
      />
    </template>

    <van-field
      v-model="black_time"
      is-link
      readonly
      label="拉黑时长"
      placeholder="点击选择拉黑时常"
      @click="showBlackTimePicker = true"
    />

    <van-field v-model="reason" label="拉黑原因" placeholder="请输入拉黑原因" />
  </van-cell-group>

  <van-popup v-model:show="showBlackTimePicker" position="bottom">
    <van-picker
      :columns="timeList"
      @confirm="chooseBlackTime"
      @cancel="showBlackTimePicker = false"
      :default-index="1"
    />
  </van-popup>

  <van-popup v-model:show="showIPMethodsPicker" position="bottom">
    <van-picker
      :columns="methodsList"
      @confirm="chooseMethod"
      @cancel="showIPMethodsPicker = false"
      :default-index="0"
    />
  </van-popup>

  <van-button id="save-btn" @click="save" block type="primary">保存</van-button>
</template>

<script>
import { NavbarMixin } from "@/mixins/NavbarMixin";
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import {
  NavBar,
  Button,
  Toast,
  Picker,
  Field,
  CellGroup,
  Popup,
  Dialog,
} from "vant";
import { block } from "@/api/common";

const black_time_options = [
  { key: "0", value: "永久" },
  { key: "1", value: "1个月" },
  { key: "3", value: "3个月" },
  { key: "6", value: "6个月" },
  { key: "12", value: "12个月" },
];

export default defineComponent({
  mixins: [NavbarMixin],

  beforeRouteEnter(to, from) {
    const obj = {
      path: from.path,
      query: from.query,
    };

    localStorage.setItem("previous_url", JSON.stringify(obj));
  },

  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
  },

  data() {
    return {
      platform: "web",
      isPureWeb: false,
      useIpPrefix: ["访客IP"],
      black_time: "1个月",
      ip: "",
      ipprefix: "",
      timeList: ["永久", "1个月", "3个月", "6个月", "12个月"],
      methodsList: ["访客IP", "访客IP段"],
      blockMethod: "访客IP",
      showBlackTimePicker: false,
      showIPMethodsPicker: false,
      reason: "",
    };
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
    }),

    ...mapState({}),
  },

  watch: {
    currentVisitor() {
      if (!this.currentVisitor) {
        Toast.error("对话已结束");
        this.$router.replace("/chat-list");
      }
    },
  },

  beforeMount() {
    if (!this.currentVisitor) {
      this.$router.replace("/chat-list");
    }

    this.platform = this.currentVisitor.platform;

    if (this.platform === "web") {
      if (
        !["qqfmc", "ttpage", "imlp", "sbox"].includes(
          this.currentVisitor.referrer?.source
        )
      ) {
        this.isPureWeb = true;
      }
    }

    if (this.isPureWeb) {
      this.ip = this.currentVisitor.ip;
      this.ipprefix = this.ip.split(".").slice(0, 3).join(".") + ".*";
    }
  },

  methods: {
    chooseBlackTime(value) {
      this.black_time = value;
      this.showBlackTimePicker = false;
    },

    chooseMethod(value) {
      this.blockMethod = value;
      this.showIPMethodsPicker = false;
    },

    save() {
      if (!this.reason?.trim()) {
        Dialog.alert({
          message: "请填写拉黑原因",
        });
        return;
      }

      if (!this.isPureWeb) {
        this.saveWechat();
      } else {
        this.saveWeb();
      }
    },

    saveWeb() {
      if (!this.ip || (!this.ipprefix && this.blockMethod === "访客IP段")) {
        Dialog.alert({
          message: "请填写IP相关信息",
        });
        return;
      }
      const black_time = black_time_options.find((option) => {
        return option.value === this.black_time;
      });
      const data = {
        uid: this.currentVisitor.uid,
        reason: this.reason,
        black_time: black_time.key,
        platform: "web",
        ip: this.ip,
      };
      if (this.blockMethod === "访客IP段") {
        data.ipprefix = this.ipprefix;
      }

      block(data).subscribe(
        () => {
          this.$router.replace("/chat-list");
        },
        (err) => {
          if (err?.response?.data?.invalid?.message) {
            Toast.fail(err.response.data.invalid.message);
          }
        }
      );
    },

    saveWechat() {
      const black_time = black_time_options.find((option) => {
        return option.value === this.black_time;
      });

      const data = {
        uid: this.currentVisitor.uid,
        reason: this.reason,
        black_time: black_time.key,
        platform: this.platform,
      };
      block(data).subscribe(
        () => {
          this.$router.replace("/chat-list");
        },
        (err) => {
          if (err?.response?.data?.invalid?.message) {
            Toast.fail(err.response.data.invalid.message);
          }
        }
      );
    },
  },
});
</script>

<style lang="less" scoped>
.warning-info {
  font-size: 14px;
  margin: 0;
  padding: 10px;
}

#save-btn {
  width: 80%;
  text-align: center;
  margin: 3px auto;
  margin-top: 20px;
}
</style>
