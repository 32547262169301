<template>
  <div class="douyin-emoji-panel">
    <div
      class="emoji emoji-wechat"
      @click="chooseEmoji(obj)"
      v-for="(obj, index) in douyinEmojis"
      v-bind:key="index"
    >
      <span class="douyinface" :class="'douyinface_' + (index + 1)"></span>
    </div>
  </div>
</template>

<script>
import { DOUYIN_FACES } from "@/filters/douyinface";

export default {
  data() {
    return {
      douyinEmojis: DOUYIN_FACES,
    };
  },

  methods: {
    chooseEmoji(code) {
      this.$emit("chooseEmoji", code);
      return;
    },
  },
};
</script>

<style lang="less" scoped>
.douyin-emoji-panel {
  display: flex;
  flex-wrap: wrap;
}

.emoji {
  width: calc(100% / 8);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
</style>
