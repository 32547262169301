<template>
  <div class="container" :class="{ 'pure-h5': isPureH5 }">
    <template v-if="card">
      <p class="title">
        {{ card.card_name }}
      </p>

      <div class="card-body">
        <template v-if="card.card_type === 'unsupport'">
          <div class="unsupport">暂不支持展示的格式</div>
        </template>

        <template v-if="card.card_type === 'h5'">
          <div class="h5-body">
            <img :src="card.content.image_url" alt="" />
            <div>
              <h4>{{ card.content.title }}</h4>
              {{ card.content.text }}
            </div>
          </div>

          <div class="h5-footer">链接</div>
        </template>

        <template v-if="card.card_type === 'question_list'">
          <div class="question-body">
            <p>
              {{ card.content.text }}
            </p>
            <p v-for="q in card.content.questions" :key="q" class="font-blue">
              {{ q.text }}
            </p>
          </div>
        </template>

        <template v-if="card.card_type === 'h5'">
          <div class="h5-body">
            <img :src="card.content.image_url" alt="" />
            <div>
              <h4>{{ card.content.title }}</h4>
              {{ card.content.text }}
            </div>
          </div>

          <div class="h5-footer">链接</div>
        </template>

        <template v-if="card.card_type === 'consult_card'">
          <div class="consult-card-body">
            <img :src="COMPANY_IMAGE_CDN_SITE + '/' + card.media_url" alt="" />
            <p>
              {{ card.content }}
            </p>
          </div>
        </template>

        <template v-if="card.card_type === 'dial_phone'">
          <div class="dial-phone-body">
            <p>
              {{ card.content }}
            </p>
            <p class="font-yellow">
              {{ card.button_name }}
            </p>
          </div>
        </template>

        <template v-if="card.card_type === 'mina'">
          <div class="mina-body">
            <img :src="COMPANY_IMAGE_CDN_SITE + '/' + card.media_url" alt="" />
            <p>
              {{ card.mina_subtitle }}
            </p>
          </div>
        </template>

        <template v-if="card.card_type === 'text_link'">
          <div class="text-link-body">
            <p>
              {{ card.content }}
            </p>
            <p class="font-yellow">
              {{ card.link_title }}
            </p>
          </div>
        </template>

        <template v-if="card.card_type === 'picture_link'">
          <template v-if="card.card_style === 1">
            <div class="pic-body-l picture-link-body">
              <img :src="COMPANY_IMAGE_CDN_SITE + '/' + card.media_url" alt="" />
              <p>
                {{ card.link_title }}
              </p>
            </div>
          </template>

          <template v-if="card.card_style === 0">
            <div class="pic-body-s picture-link-body">
              <img :src="COMPANY_IMAGE_CDN_SITE + '/' + card.media_url" alt="" />
              <p>
                {{ card.link_title }}
              </p>
            </div>
          </template>
        </template>

      </div>
    </template>
  </div>
</template>

<script>
import { getSiteConfigs } from "@/api/site-config";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    cardInfo: Object,
  },

  data() {
    return {
      card: {},
      isPureH5: false,
      COMPANY_IMAGE_CDN_SITE: getSiteConfigs().COMPANY_IMAGE_CDN_SITE,
    };
  },

  beforeMount() {
    const oldCardTypeList = ["question_list", "h5", "unsupport"];
    let content = "";
    if (oldCardTypeList.includes(this.cardInfo.card_type)) {
      content = this.cardInfo.content ? JSON.parse(this.cardInfo.content) : "";
    } else {
      content = this.cardInfo.content;
    }

    this.card = {
      ...this.cardInfo,
      content,
    };

    if (!this.card.card_name) {
      this.isPureH5 = true;
    }

    console.log(this.card)
  },
});
</script>


<style lang="less" scoped>
.container {
  display: block;
  width: 100%;
  height: 155px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  // background: rgba(22, 24, 35, 0.03);
  background-color: white;
  border-radius: 4px;
  margin-right: 16px;
  position: relative;
  text-align: left;
  color: #161823;

  &.pure-h5 {
    height: 92px;
    padding: 0;

    div.card-body {
      margin-top: 0;
    }

    .h5-body {
      p {
        margin: 0;
      }
    }
  }

  p.title {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #161823;
  }

  div.card-body {
    // height: 92px;
    height: auto;
    background-color: #fff;
    margin-top: 8px;
  }

  .unsupport {
    padding: 8px;
  }

  .h5-body {
    display: flex;
    cursor: pointer;
    height: 74px;
    padding: 8px;

    img {
      width: 45px;
      height: 45px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .h5-footer {
    border-top: 1px solid #ddd;
    height: 18px;
    line-height: 18px;
    padding-left: 8px;
    font-size: 10px;
    color: rgba(22, 24, 35, 0.5);
  }

  .question-body {
    padding: 8px;

    p {
      margin: 4px;
    }
  }

  h4 {
    margin: 0;
  }
}

.consult-card-body,
.mina-body,
.picture-link-body {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;

  img {
    max-width: 100%;
  }
}

.consult-card-body {
  img {
    width: 100%;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.dial-phone-body {
  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.mina-body {
  img {
    width: 100%;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.text-link-body {
  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.picture-link-body {
  &.pic-body-l {
    img {
      width: 100%;
    }
  }

  &.pic-body-s {
    display: flex;
    cursor: pointer;
    height: 74px;
    padding: 8px;

    img {
      width: 45px;
      height: 45px;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.font-yellow {
  color: #f5a623;
}
</style>