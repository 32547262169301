import { Message } from "@/types/Message";

export function removeRecallMsg(msgs: Message[]) {
    const microtimesArr = msgs
      .filter((msg) => {
        return msg.type === "recall";
      })
      .map((msg) => msg.recall_microtime);
  
    const toBeRecalled: number[] = [];
  
    msgs.forEach((item, index) => {
      if (item.type !== "recall" && microtimesArr.includes(item.microtime)) {
        toBeRecalled.push(index);
      }
    });
    return msgs.map((_, index) => {
      if (toBeRecalled.includes(index)) {
        return getRecalledMsg(_);
      }
      return _;
    });
  }
  
  export function isInRecallTimeMargin(microtime: number) {
    const now = Date.now();
  
    if (microtime.toString().length === 16) {
      microtime = microtime / 1000;
    }
  
    // 2分钟内的消息可以撤回
    return now - microtime < 2 * 60 * 1000;
  }
  
  function getRecalledMsg(_: Message) {
    if (_.feFrom === "client") {
      return {
        ..._,
        recalled: 1,
        style: "message",
        text: "(消息已被访客撤回)",
        type: "msg",
        format: "text",
        feType: "wechat_client_text",
      };
    }
  
    return {
      ..._,
      recalled: 1,
    };
  }