import { Message, MessageFeFrom } from "@/types/Message";

export function removeTempMsg(msgs: Message[]) {
  const toBeDeleted: any = [];

  msgs.forEach((item, index) => {
    if (item.feFrom === MessageFeFrom.worker_temp) {
      for (let i = index + 1; i < msgs.length; i++) {
        if (
          //滚动消息特殊处理
          item.feType.includes("temp_") &&
          msgs[i].cmicrotime === item.cmicrotime
        ) {
          toBeDeleted.push(index);
        }
      }
    }
  });

  for (let i = toBeDeleted.length - 1; i >= 0; i--) {
    msgs.splice(toBeDeleted[i], 1);
  }

  return msgs;
}
