<template>
  <div class="container">
    <img class="logo" src="../assets/logo.png" />
    <h2 class="title">欢迎使用云雀客服平台</h2>

    <template v-if="!isInPC">
      <van-loading size="24px" color="#1989fa">加载中...</van-loading>
    </template>

    <p class="text" v-if="isInPC">
      请使用手机微信客户或者下载
      <a href="https://www.yunque360.com/download.html" target="_blank"
        >电脑客户端</a
      >
      访问
    </p>
  </div>
</template>


<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Loading, Dialog } from "vant";
import { getIsInPC } from "@/utils/host";
import { parseJwt } from "@/utils/common";
import { getUrlParam } from "@/utils/url";
import { quitWechat } from "@/utils/host";
import { ERROR_TYPES } from "@/enums/error-types";
import { addTokenInterceptor } from "@/utils/http";


export default defineComponent({
  name: "Loading",
  components: {
    [Loading.name]: Loading,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      isInPC: getIsInPC(),
    };
  },

  computed: mapState({
    token: (state) => state.auth.token,
    failure: (state) => state.auth.failure,
  }),

  watch: {
    token() {
        if(this.token) {
          this.$router.replace("/chat-list")
        }
    },

    failure() {
      if (this.failure === ERROR_TYPES.INVALID_TOKEN) {
        Dialog.alert({
          message: "您的登录链接已失效，请返回重试",
        });
      }

      if (this.failure === ERROR_TYPES.USE_MOBILE_PERMISSION_FAIL) {
        Dialog.alert({
          message: "您已被禁止使用手机值班功能，请联系管理员为您开启",
        });
      }
    },
  },

  created() {
    if (this.isInPC) return;

    const token = this.getToken();
    const companyID = this.getCompanyID();

    if (!token || !companyID) {
      Dialog.alert({
        message: "链接出错，请返回重试",
      });
      quitWechat();
      return;
    }

    addTokenInterceptor();

    const JwtObj = parseJwt(token);
    if (JwtObj?.name) {
      localStorage.setItem("workername", JwtObj.name);
    }

    this.$store.dispatch("AUTHENTICATE", {
      token,
      companyID,
    });
  },

  methods: {
    getToken() {
      const token = getUrlParam("token") || "";
      localStorage.setItem("token", token);
      return token;
    },

    getCompanyID() {
      let companyID = getUrlParam("company_id") || "";
      localStorage.setItem("company_id", companyID);

      return companyID;
    },
  },
});
</script>

<style lang="less" scoped>
.container {
  text-align: center;
  position: relative;
  height: 100%;
}

.logo {
  width: 100px;
  height: 100px;
  margin-top: 100px;
}

.title {
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 50px;
}
</style>
