

import { NavBar, Button, Empty, Notify } from "vant";


import { defineComponent } from "vue";

import { setNotiRead, getNotiList } from "@/api/common";
import NotificationItem from "@/components/NotificationItem.vue";
import { Subject, fromEvent, merge, of } from "rxjs";
import { map, startWith, throttleTime, delay, tap, switchMap, distinct, filter } from "rxjs/operators";

import { elementIsVisibleInViewport } from "@/utils/common";


let subscription: any;

export default defineComponent({

    components: {
        NotificationItem: NotificationItem,
        [NavBar.name]: NavBar,
        [Button.name]: Button,
        [Empty.name]: Empty,

    },

    data() {
        return {
            list: [] as any[],
            readIDArr: new Set(),
            loading: true,
        }
    },

    beforeMount() {
        this.getData();
    },

    mounted() {

        this.$nextTick(() => {

            const listEle = this.$refs.listRef as any;

            subscription =
                merge(
                    fromEvent(listEle, 'scroll').pipe(throttleTime(100)),
                    of(null).pipe(delay(500)) //获取首屏数据，延迟500ms，以便子组件渲染完毕
                ).pipe(
                    map(this.getUnreadIDs.bind(this)),
                    distinct(),
                    filter((ids: string[]) => {
                        return ids.some((id) => !this.readIDArr.has(id))
                    }),
                    tap((ids) => {
                        ids.forEach((id: string) => {
                            this.readIDArr.add(id);
                        })
                    }),
                    switchMap(this.setNotiRead.bind(this)),
                )
                    .subscribe((res) => {
                        if (res) this.getData();
                    })
        })
    },


    beforeUnmount() {
        subscription?.unsubscribe();
    },

    methods: {
        getData() {
            getNotiList().subscribe((res) => {
                this.list = res.data;
                this.loading = false;
            })
        },

        goBack() {
            this.$router.push("/chat-list");
        },

        readAll() {
            setNotiRead({
                type: 'all'
            }).subscribe(() => {
                this.getData();
                Notify({ type: "success", message: "已将消息全部标记为已读" });
            })
        },

        getUnreadIDs() {
            const notiItems = (this.$refs.listRef as any).querySelectorAll('.notification-item');

            return Array.from(notiItems).filter((node: any) => {
                const isRead = node.querySelectorAll('.is-read').length > 0;
                const isShowingMore = node.querySelectorAll('.more-btn').length > 0;
                return !isRead && !isShowingMore && elementIsVisibleInViewport(node);
            })
                .map((node: any) => {
                    return node.querySelector('.hidden').innerHTML;
                    // return node.querySelector('h4').innerHTML;
                })
        },

        setNotiRead() {
            if (this.readIDArr.size === 0) return of(null);

            return setNotiRead({
                ids: Array.from(this.readIDArr).join(',')
            })
        }
    }
})


