function escapeRegExp(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export const KuaishouFaceList = [
  {
    val: "[奸笑]",
    index: 0,
  },
  {
    val: "[捂脸]",
    index: 1,
  },
  {
    val: "[龇牙]",
    index: 2,
  },
  {
    val: "[哼]",
    index: 3,
  },
  {
    val: "[哦]",
    index: 4,
  },
  {
    val: "[微笑]",
    index: 5,
  },
  {
    val: "[调皮]",
    index: 6,
  },
  {
    val: "[呆住]",
    index: 7,
  },
  {
    val: "[星星眼]",
    index: 8,
  },
  {
    val: "[疑问]",
    index: 9,
  },
  {
    val: "[生气]",
    index: 10,
  },
  {
    val: "[难过]",
    index: 11,
  },
  {
    val: "[撇嘴]",
    index: 12,
  },
  {
    val: "[惊讶]",
    index: 13,
  },
  {
    val: "[羞涩]",
    index: 14,
  },
  {
    val: "[色]",
    index: 15,
  },
  {
    val: "[汗]",
    index: 16,
  },
  {
    val: "[呕吐]",
    index: 17,
  },
  {
    val: "[老司机]",
    index: 18,
  },
  {
    val: "[头盔]",
    index: 19,
  },
  {
    val: "[酷]",
    index: 20,
  },
  {
    val: "[笑哭]",
    index: 21,
  },
  {
    val: "[愉快]",
    index: 22,
  },
  {
    val: "[委屈]",
    index: 23,
  },
  {
    val: "[鄙视]",
    index: 24,
  },
  {
    val: "[白眼]",
    index: 25,
  },
  {
    val: "[安排]",
    index: 26,
  },
  {
    val: "[点点关注]",
    index: 27,
  },
  {
    val: "[鼓掌]",
    index: 28,
  },
  {
    val: "[哈欠]",
    index: 29,
  },
  {
    val: "[骂你]",
    index: 30,
  },
  {
    val: "[大哭]",
    index: 31,
  },
  {
    val: "[庆祝]",
    index: 32,
  },
  {
    val: "[闭嘴]",
    index: 33,
  },
  {
    val: "[惊恐]",
    index: 34,
  },
  {
    val: "[红脸蛋]",
    index: 35,
  },
  {
    val: "[亲亲]",
    index: 36,
  },
  {
    val: "[冷汗]",
    index: 37,
  },
  {
    val: "[晕]",
    index: 38,
  },
  {
    val: "[坏笑]",
    index: 39,
  },
  {
    val: "[可怜]",
    index: 40,
  },
  {
    val: "[抠鼻]",
    index: 41,
  },
  {
    val: "[再见]",
    index: 42,
  },
  {
    val: "[平底锅]",
    index: 43,
  },
  {
    val: "[囧]",
    index: 44,
  },
  {
    val: "[大哥]",
    index: 45,
  },
  {
    val: "[抓狂]",
    index: 46,
  },
  {
    val: "[嘘]",
    index: 47,
  },
  {
    val: "[快哭了]",
    index: 48,
  },
  {
    val: "[骷髅]",
    index: 49,
  },
  {
    val: "[偷笑]",
    index: 50,
  },
  {
    val: "[落泪]",
    index: 51,
  },
  {
    val: "[挑逗]",
    index: 52,
  },
  {
    val: "[困]",
    index: 53,
  },
  {
    val: "[睡觉]",
    index: 54,
  },
  {
    val: "[右哼哼]",
    index: 55,
  },
  {
    val: "[左哼哼]",
    index: 56,
  },
  {
    val: "[流鼻血]",
    index: 57,
  },
  {
    val: "[偷瞄]",
    index: 58,
  },
  {
    val: "[吃瓜]",
    index: 59,
  },
  {
    val: "[黑脸问]",
    index: 60,
  },
  {
    val: "[旋转]",
    index: 61,
  },
  {
    val: "[憨笑]",
    index: 62,
  },
  {
    val: "[吐彩虹]",
    index: 63,
  },
  {
    val: "[擦鼻涕]",
    index: 64,
  },
  {
    val: "[怒言]",
    index: 65,
  },
  {
    val: "[拜托]",
    index: 66,
  },
  {
    val: "[加油]",
    index: 67,
  },
  {
    val: "[暴汗]",
    index: 68,
  },
  {
    val: "[想吃]",
    index: 69,
  },
  {
    val: "[打脸]",
    index: 70,
  },
  {
    val: "[吐血]",
    index: 71,
  },
  {
    val: "[尴尬]",
    index: 72,
  },
  {
    val: "[大鼻孔]",
    index: 73,
  },
  {
    val: "[嘣]",
    index: 74,
  },
  {
    val: "[天啊]",
    index: 75,
  },
  {
    val: "[石化]",
    index: 76,
  },
  {
    val: "[皱眉]",
    index: 77,
  },
  {
    val: "[装傻]",
    index: 78,
  },
  {
    val: "[化妆]",
    index: 79,
  },
  {
    val: "[爱你]",
    index: 80,
  },
  {
    val: "[摸头]",
    index: 81,
  },
  {
    val: "[雾霾]",
    index: 82,
  },
  {
    val: "[期待]",
    index: 83,
  },
  {
    val: "[抱抱]",
    index: 84,
  },
  {
    val: "[礼花]",
    index: 85,
  },
  {
    val: "[老铁]",
    index: 86,
  },
  {
    val: "[双鸡]",
    index: 87,
  },
  {
    val: "[爱心]",
    index: 88,
  },
  {
    val: "[皇冠]",
    index: 89,
  },
  {
    val: "[火]",
    index: 90,
  },
  {
    val: "[666]",
    index: 91,
  },
  {
    val: "[扎心]",
    index: 92,
  },
  {
    val: "[爆炸]",
    index: 93,
  },
  {
    val: "[大便]",
    index: 94,
  },
  {
    val: "[摄像机]",
    index: 95,
  },
  {
    val: "[玫瑰]",
    index: 96,
  },
  {
    val: "[花谢了]",
    index: 97,
  },
  {
    val: "[板砖]",
    index: 98,
  },
  {
    val: "[干杯]",
    index: 99,
  },
  {
    val: "[祈祷]",
    index: 100,
  },
  {
    val: "[跪下]",
    index: 101,
  },
  {
    val: "[菜刀]",
    index: 102,
  },
  {
    val: "[南]",
    index: 103,
  },
  {
    val: "[发]",
    index: 104,
  },
  {
    val: "[红包]",
    index: 105,
  },
  {
    val: "[灯笼]",
    index: 106,
  },
  {
    val: "[福字]",
    index: 107,
  },
  {
    val: "[鞭炮]",
    index: 108,
  },
  {
    val: "[烟花]",
    index: 109,
  },
  {
    val: "[元宝]",
    index: 110,
  },
  {
    val: "[钱]",
    index: 111,
  },
  {
    val: "[气球]",
    index: 112,
  },
  {
    val: "[狗粮]",
    index: 113,
  },
  {
    val: "[赞]",
    index: 114,
  },
  {
    val: "[打招呼]",
    index: 115,
  },
  {
    val: "[欢迎]",
    index: 116,
  },
  {
    val: "[左拳]",
    index: 117,
  },
  {
    val: "[右拳]",
    index: 118,
  },
  {
    val: "[我爱你]",
    index: 119,
  },
  {
    val: "[比心]",
    index: 120,
  },
  {
    val: "[肌肉]",
    index: 121,
  },
  {
    val: "[涂指甲]",
    index: 122,
  },
  {
    val: "[酸了]",
    index: 123,
  },
  {
    val: "[柴犬]",
    index: 124,
  },
  {
    val: "[狮子]",
    index: 125,
  },
  {
    val: "[龙]",
    index: 126,
  },
  {
    val: "[狗]",
    index: 127,
  },
  {
    val: "[网红猫]",
    index: 128,
  },
  {
    val: "[猫]",
    index: 129,
  },
  {
    val: "[老鼠]",
    index: 130,
  },
  {
    val: "[不看]",
    index: 131,
  },
  {
    val: "[不说]",
    index: 132,
  },
  {
    val: "[猪头]",
    index: 133,
  },
  {
    val: "[猪鼻子]",
    index: 134,
  },
  {
    val: "[猪蹄]",
    index: 135,
  },
  {
    val: "[羊驼]",
    index: 136,
  },
  {
    val: "[麦克风]",
    index: 137,
  },
  {
    val: "[蛋糕]",
    index: 138,
  },
  {
    val: "[口红]",
    index: 139,
  },
  {
    val: "[水枪]",
    index: 140,
  },
  {
    val: "[空投]",
    index: 141,
  },
  {
    val: "[手柄]",
    index: 142,
  },
  {
    val: "[坑]",
    index: 143,
  },
  {
    val: "[八倍镜]",
    index: 144,
  },
  {
    val: "[网红]",
    index: 145,
  },
  {
    val: "[优秀]",
    index: 146,
  },
  {
    val: "[减1]",
    index: 147,
  },
  {
    val: "[必胜]",
    index: 148,
  },
  {
    val: "[戴口罩]",
    index: 149,
  },
  {
    val: "[勤洗手]",
    index: 150,
  },
  {
    val: "[心心]",
    index: 151,
  },
  {
    val: "[哭笑]",
    index: 152,
  },
  {
    val: "[点赞]",
    index: 153,
  },
  {
    val: "[拍一拍]",
    index: 154,
  },
];

export const Kuaishou_Face_Texts = KuaishouFaceList.map((face) => {
  return face.val;
});

export function KuaishoufaceFilter(text: string): string {
  if (!text.includes("[") || !text.includes("]")) return text;



  Kuaishou_Face_Texts.forEach((token: string, index: number) => {
    const re = new RegExp(escapeRegExp(token), "g");

    const config = KuaishouFaceList.find((config) => config.val === token);
    if (!config) return;
    //localhost 不显示
    text = text.replace(
      re,
      `<span  class="kuaishouface kuaishouface-mini kuaishouface_${config.index}"></span>`
    );
  });


  return text;
}
