import { format, parseISO } from "date-fns";

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function compareObjects(o: any, p: any) {
  return JSON.stringify(o) === JSON.stringify(p);
}

export function fileSizeCalc(filesize: string) {
  const size = parseInt(filesize);

  if (size < 1000) {
    return size.toString() + "B";
  } else if (size >= 1000 && size < 1000 * 1000) {
    return Math.round(size / 1000).toString() + "KB";
  } else {
    return Math.round(size / 1000 / 1000).toString() + "MB";
  }
}

export function parseAppsExpiration(apps: any) {
  const result: any = [];
  apps.forEach((app: any) => {
    if (parseInt(app.status) !== 1) return;
    const endTime: any = parseISO(
      app.access_token_expire_time || app.tokens_expire_time || app.refresh_expires_at
    );
    const now: any = new Date();
    const hoursDiff = Math.abs(endTime - now) / 36e5;
    if (hoursDiff <= 7 * 24) {
      result.push(app);
    }
  });
  return result;
}

export function isOldFormat(input: any) {
  try {
    const result = JSON.parse(input);

    return typeof result !== "object";
  } catch (error) {
    return true;
  }
}

export function slice(str: string, start: number, end: number) {
  const arr =
    str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || [];
  return arr.slice(start, end).join("");
}

export function stripHtml(text: string) {
  return text
    .replace(/<img(?:.|\n)*?>/gm, "【图片】")
    .replace(/<(?:.|\n)*?>/gm, "");
}

export function elementIsVisibleInViewport(el: any, partiallyVisible = false) {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
}
