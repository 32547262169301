import { createStore } from "vuex";
import auth from "./modules/auth";
import common from "./modules/common";
import chat from "./modules/chat";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    common,
    chat,
  },

  strict: debug,

  
});
