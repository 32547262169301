import { Message } from "@/types/Message";
import { Tag } from "@/types/Tag";

export function getTagMsg(msg: Message): { prefix: string; tags: Tag[] } {
  if (!msg.ext) {
    return parseWebMsg(msg);
  } else {
    return parseNotWebMsg(msg);
  }
}

function parseWebMsg(msg: Message) {
  let body;

  if (typeof msg.body === "object") {
    body = msg.body;
  } else {
    body = JSON.parse(msg.body);
  }

  let name;
  if (body.operator === "ai_reply") {
    name = "AI智能回复，自动";
  } else {
    name = body.worker_name ? "工号" + body.worker_name + " " : "管理员";
  }

  const type = msg.cmd === "tags_add" ? "添加" : "删除";
  return {
    prefix: `${name}${type}标签`,
    tags: body.tags,
  };
}

function parseNotWebMsg(msg: Message) {
  const body = msg.ext;
  let prefix = "";

  const type = msg.type === "tags_add" ? "添加" : "删除";

  if (body.operator === "worker") {
    const name = body.worker && body.worker.name ? body.worker.name : "管理员";
    prefix = `工号 ${name} ${type}标签`;
  } else if (body.operator === "wechat_sync") {
    prefix = `同步微信公众平台信息， ${type}标签`;
  } else if (body.operator === "qrcode_scan") {
    prefix = `扫描渠道二维码，自动${type}标签`;
  } else if (body.operator === "roll_msg") {
    prefix = `滚动消息，自动${type}标签`;
  } else if (body.operator === "open_poster") {
    prefix = `打开海报，自动${type}标签`;
  } else if (body.operator === "add_wechat") {
    prefix = `海报内添加微信，自动${type}标签`;
  } else if (body.operator === "ai_reply") {
    prefix = `AI智能回复，自动${type}标签`;
  } else if (body.operator === "ai_nonlocal_tag") {
    prefix = `服务地区异地，自动${type}标签`;
  } else if (body.operator === "worker_batch_edit") {
    const name =
      body.worker && body.worker.name ? "工号" + body.worker.name : "管理员";
    prefix = `${name} 批量${type}标签`;
  }

  return {
    prefix,
    tags: msg.ext.tags,
  };
}
