
import { defineComponent, ref } from "vue";
import {
  NavBar,
  Cell,
  CellGroup,
  Skeleton,
  ActionSheet,
  Checkbox,
  CheckboxGroup,
  Button,
  Toast,
  Switch,
  Popup,
  Picker,
} from "vant";
import { getRoutineData, setRoutineData } from "@/api/common";
import { getHourRange, getMinuteRange } from "@/utils/date";

const weekSelectsMap = [
  { key: 1, value: "周一" },
  { key: 2, value: "周二" },
  { key: 3, value: "周三" },
  { key: 4, value: "周四" },
  { key: 5, value: "周五" },
  { key: 6, value: "周六" },
  { key: 7, value: "周日" },
];

export default defineComponent({

  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Skeleton.name]: Skeleton,
    [ActionSheet.name]: ActionSheet,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },

  setup() {
    const weekSelect = ref([1, 2, 3, 4, 5]);
    const checkboxRefs = ref([]);
    const toggle = (index: number) => {
      (checkboxRefs.value[index] as any).toggle();
    };

    return {
      weekSelect,
      checkboxRefs,
      toggle,
    };
  },

  data() {
    return {
      loading: true,

      autoOnline: false,
      autoOnlineValue: ["09", "00"],

      autoOffline: false,
      autoOfflineValue: ["18", "00"],
      weekSelectText: "",

      showWeekSelector: false,
      weekSelectsMap,

      showRangePopup: false,
      rangePopupColumns: [],
      currentPopupType: "",
    };
  },

  computed: {
    onlineTimeText: function () {
      return (this as any).autoOnlineValue[0] + ":" + (this as any).autoOnlineValue[1];
    },
    offlineTimeText: function () {
      return (this as any).autoOfflineValue[0] + ":" + (this as any).autoOfflineValue[1];
    },
  },

  beforeMount() {
    this.loading = true;
    getRoutineData().subscribe((settings: any) => {
      this.weekSelect = settings.auto_onoff_weekdays
        .split("")
        .map((val: any) => {
          return parseInt(val);
        });

      if (!settings.auto_online || settings.auto_online.length !== 4) {
        this.autoOnline = false;
      } else {
        this.autoOnline = true;
        this.autoOnlineValue = [
          settings.auto_online.slice(0, 2),
          settings.auto_online.slice(2, 4),
        ];
      }

      if (!settings.auto_offline || settings.auto_offline.length !== 4) {
        this.autoOffline = false;
      } else {
        this.autoOffline = true;
        this.autoOfflineValue = [
          settings.auto_offline.slice(0, 2),
          settings.auto_offline.slice(2, 4),
        ];
      }

      this.getWeekSelectText();

      this.loading = false;
    });
  },

  methods: {
    goBack() {
      this.$router.push("/settings");
    },

    getWeekSelectText() {
      let textArr: any = [];
      this.weekSelect = this.weekSelect.sort();
      this.weekSelect.forEach((val) => {
        let item = weekSelectsMap.find((item) => {
          return item.key === val;
        });
        textArr.push(item!.value);
      });

      this.weekSelectText = textArr.join(" ");
    },

    saveWeekSelector() {
      this.showWeekSelector = false;
      this.getWeekSelectText();
      this.save();
    },

    save() {
      let data = {
        auto_online: "",
        auto_offline: "",
        auto_onoff_weekdays: this.weekSelect.join(""),
      };
      if (this.autoOnline) {
        data.auto_online = this.autoOnlineValue.join("");
      }
      if (this.autoOffline) {
        data.auto_offline = this.autoOfflineValue.join("");
      }

      setRoutineData(data).subscribe(() => {
        Toast.success("保存成功");
      });
    },

    displayRangePopup(type: string) {
      const timeValue =
        type === "online" ? this.autoOnlineValue : this.autoOfflineValue;
      this.currentPopupType = type;

      this.rangePopupColumns = [
        {
          values: getHourRange(),
          defaultIndex: parseInt(timeValue[0]),
        },
        {
          values: getMinuteRange(),
          defaultIndex: parseInt(timeValue[1]),
        },
      ] as any;

      this.showRangePopup = true;
    },

    confirmPopup(val: any) {
      this.showRangePopup = false;

      if (this.currentPopupType === "online") {
        this.autoOnlineValue = [val[0].toString(), val[1].toString()];
      } else {
        this.autoOfflineValue = [val[0].toString(), val[1].toString()];
      }

      this.save();
    },
  },
});
