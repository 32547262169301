<template>
  <div class="msg-wrapper msg-worker preline" v-if="message.feType === 'wechat_worker_text'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <span v-if="message.recalled">（已撤回）</span>
        <div v-html="wechatFaceFilter(message.text)" style="display: inline-block;"></div>
      </div>
      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp preline" v-if="message.feType === 'temp_wechat_worker_text'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble" v-html="message.text"></div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_image'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <img :src="message.url" />

      </div>
      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_wechat_worker_image'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble">
        <img :src="message.body.url || message.body.thumb_url" />
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_voice'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <audio :src="message.url" controls style="width: 200px;"></audio>

      </div>
      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_wechat_worker_voice'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble">
        <audio :src="message.body.url || message.body.thumb_url" controls></audio>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_video'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="wechat-worker-video-container">
        <div class="image-container">
          <img :src="message.thumb_url" alt="" />
        </div>
        <div class="right-container">
          <p class="title">{{ message.title }}</p>
          <p class="font-gray">{{ message.update_time }}</p>
        </div>
      </div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_wechat_worker_video'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble">
        <div class="wechat-worker-video-container">
          <div class="image-container">
            <img :src="message.body.thumb_url" alt="" />
          </div>
          <div class="right-container">
            <p class="title">{{ message.body.name }}</p>
            <p class="font-gray">{{ message.body.update_time }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_news'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <app-wechat-news :news="message"></app-wechat-news>

      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_linknews'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <app-linknews :linknews="message.content"></app-linknews>

      </div>

      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_wechat_worker_linknews'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble">
        <app-linknews :linknews="message.body"></app-linknews>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wechat_worker_miniprogrampage'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <app-miniapp-page :miniapp="message"></app-miniapp-page>
      </div>

      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp" v-if="message.feType === 'temp_wechat_worker_miniprogrampage'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div class="inner-bubble">
        <app-miniapp-page :miniapp="message.body"></app-miniapp-page>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'wework_card'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <app-wework-card :card="message"></app-wework-card>

      </div>

      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";
import LinkNews from "@/components/messages/special/LinkNews.vue";
import Minipage from "@/components/messages/special/MiniPage.vue";
import WechatNews from "@/components/messages/special/WechatNews.vue";
import WeworkCard from "@/components/messages/special/WeworkCard.vue";

import { WechatfaceFilter as wechatface } from "@/filters/wechatface";
import { isInRecallTimeMargin } from '@/utils/message-process/removeRecallMsg';

import { Toast, Dialog } from "vant";
import { recallMsg } from "@/api/common";

export default defineComponent({
  emits: ["click", "onRecall"],

  props: {
    message: Object,
  },

  watch: {

  },


  computed: {
    wechatFaceFilter() {
      return (v) => {
        return wechatface(v);
      };
    },
  },

  components: {
    "app-linknews": LinkNews,
    "app-miniapp-page": Minipage,
    "app-wechat-news": WechatNews,
    "app-wework-card": WeworkCard,
  },

  data() {
    return {
      showRecallBtn: false,
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {
    

    this.showRecallBtn = this.message.is_recallable && isInRecallTimeMargin(this.message.microtime || 0) && this.message.auto_typeText !== '关注追粉';
  },

  methods: {
    recall() {
      Dialog.confirm({
        message: "确定撤回消息吗？",
      })
        .then(() => {
          this.doRecall();
        }, () => {

        });
    },

    doRecall() {
      recallMsg({
        uid: this.message.uid,
        microtime: this.message.microtime
      }).subscribe(() => {
        Toast.success("撤回成功");
        this.$emit("onRecall", {
          microtime: this.message.microtime,
        });
      }, (err) => {
        Toast.fail(err.response.data.message);
      });
    }
  },
});
</script>

<style scoped>
.recall-text {
  color: #2593f4;
  cursor: pointer;
}
</style>
