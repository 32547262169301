import {
  getDouyinApps,
  getWechatApps,
  getWeiboApps,
  getAllBadgeCount,
  getUnreadCount,
  getKuaishouApps,
  getRedbookApps,
} from "@/api/common";
import {  parseAppsExpiration } from "@/utils/common";
import { combineLatest } from "rxjs";
import keyBy from "lodash/keyBy";


// initial State
const state = {
  wechatApps: [],
  wechatAppsObj: {},

  weiboApps: [],
  weiboAppsObj: {},

  douyinApps: [],
  douyinAppsObj: {},

  kuaishouApps: [],
  kuaishouAppsObj: {},

  redbookApps: [],
  redbookAppsObj: {},

  expiredWeiboApps: [],
  expiredDouyinApps: [],
  expiredKuaishouApps: [],

  historyBadge: {
    total: 0,
    wechat: 0,
    weibo: 0,
    douyin: 0,
    kuaishou: 0,
    web: 0,
  },

  settingsBadge: 0,

  currentHistoryDetail: {
    name: "",
    uid: "",
    appid: "",
    location: "",
    app: "",
    extraInfo: null,
    platform: "",
  },

  unreadCount: 0,

  kuaishouTab: 0,
};

const mutations = {
  UPDATE_WECHAT_APPS(s: typeof state, apps: any) {
    s.wechatApps = apps;
    s.wechatAppsObj = keyBy(apps, "appid");
  },

  UPDATE_WEIBO_APPS(s: typeof state, apps: any) {
    s.weiboApps = apps;
    s.weiboAppsObj = keyBy(apps, "appid");
  },

  UPDATE_DOUYIN_APPS(s: typeof state, apps: any) {
    s.douyinApps = apps;
    s.douyinAppsObj = keyBy(apps, "appid");
  },

  UPDATE_KUAISHOU_APPS(s: typeof state, apps: any) {
    s.kuaishouApps = apps;
    s.kuaishouAppsObj = keyBy(apps, "appid");
  },

  UPDATE_REDBOOK_APPS(s: typeof state, apps: any) {
    s.redbookApps = apps;
    s.redbookAppsObj = keyBy(apps, "appid");
  },

  UPDATE_BADGE(s: typeof state, payload: any) {
    s.historyBadge = payload;
  },

  UPDATE_EXPIRED_WEIBO_APPS(s: typeof state, apps: any) {
    s.expiredWeiboApps = apps;
  },

  UPDATE_EXPIRED_DOUYIN_APPS(s: typeof state, apps: any) {
    s.expiredDouyinApps = apps;
  },

  UPDATE_EXPIRED_KUAISHOU_APPS(s: typeof state, apps: any) {
    s.expiredKuaishouApps = apps;
  },

  UPDATE_SETTINGS_BADGE(s: typeof state, count: any) {
    s.settingsBadge = count;
  },

  UPDATE_CURRENT_HISTORY_DETAIL(s: typeof state, data: any) {
    s.currentHistoryDetail = {
      ...data,
    };
  },

  UPDATE_UNREAD_COUNT(s: typeof state, count: any) {
    s.unreadCount = count;
  },

  UPDATE_KUAISHOU_TAB(s: typeof state, tab: any) {
    s.kuaishouTab = tab;
  }
};

const actions = {
  GET_APPS_LIST({ commit }: any) {
    combineLatest([getWechatApps(), getWeiboApps(), getDouyinApps(), getKuaishouApps(), getRedbookApps()]).subscribe(
      ([wechatApps, weiboApps, douyinApps, kuaishouApps, redbookApps]) => {
        commit("UPDATE_WECHAT_APPS", wechatApps);
        commit("UPDATE_WEIBO_APPS", weiboApps);
        commit("UPDATE_DOUYIN_APPS", douyinApps);
        commit("UPDATE_KUAISHOU_APPS", kuaishouApps);
        commit("UPDATE_REDBOOK_APPS", redbookApps);

        const expiredWeiboApps = parseAppsExpiration(weiboApps);
        const expiredDouyinApps = parseAppsExpiration(douyinApps);
        const expiredKuaishouApps = parseAppsExpiration(kuaishouApps);

        commit("UPDATE_EXPIRED_WEIBO_APPS", expiredWeiboApps);
        commit("UPDATE_EXPIRED_DOUYIN_APPS", expiredDouyinApps);
        commit("UPDATE_EXPIRED_KUAISHOU_APPS", expiredKuaishouApps);
        commit(
          "UPDATE_SETTINGS_BADGE",
          expiredDouyinApps.length + expiredWeiboApps.length
        );
      }
    );
  },

  UPDATE_BADGE({ commit }: any) {
    getAllBadgeCount().subscribe((res: any) => {
      commit("UPDATE_BADGE", {
        wechat: res.wechat.discard_user,
        weibo: res.weibo.discard_user,
        douyin: res.douyin.discard_user,
        web: res.web.discard_user,
        kuaishou: res.kuaishou.discard_user,
        total: res.total,
      });
    });
  },

  REFREESH_UNREAD_COUNT({ commit }: any) {
    getUnreadCount().subscribe((count: any) => {
      commit("UPDATE_UNREAD_COUNT", count);
    });
  },

  UPDATE_UNREAD_COUNT({ commit }: any, count: any) {
    commit("UPDATE_UNREAD_COUNT", count);
  }
};

export default {
  state,
  mutations,
  actions,
};
