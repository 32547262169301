<template>
  <van-nav-bar title="编辑客资" safe-area-inset-top @click-left="goBack" left-text="返回" left-arrow />

  <van-cell-group inset>
    <van-field v-model="name" label="访客姓名" placeholder="请输入访客姓名" />

    <van-field v-model="gender" is-link readonly label="性别" placeholder="点击选择性别" @click="showGenderPicker = true" />

    <van-field v-model="mobile" @keyup="mobile_status = 'unchecked'" label="手机" placeholder="请输入手机号码">
      <template #button>
        <a @click="doMobileCheck">空号检测</a>
      </template>
    </van-field>

    <template v-if="mobile_status && mobile_status !== 'unchecked'">
      <van-field label="空号检测结果">
        <template #input>
          <mobile-validation-result :status="mobile_status" />
        </template>
      </van-field>

      <van-field v-if="mobile_location" label="号码归属地">
        <template #input>
          {{ mobile_location }}
        </template>
      </van-field>

      <van-field v-if="mobile_check_time" label="检测时间">
        <template #input>
          {{ mobile_check_time }}
        </template>
      </van-field>
    </template>


    <van-field v-model="qq" label="QQ" placeholder="请输入QQ" />
    <van-field v-model="weixin" label="微信" placeholder="请输入微信号">
      <template #right-icon>
        <van-icon name="photo" size="25" v-if="weixin_source_image" @click="showSourceImageDialog = true" />
      </template>
    </van-field>

    <van-field label="二维码" v-if="qrcode_text">
      <template #input>
        <span class="font-blue" @click="showImageDialog = true">查看图片</span>
      </template>
    </van-field>

    <van-field readonly is-link v-model="areaText" label="地区选择" placeholder="点击选择省市区" @click="showArea = true" />

    <van-field v-model="remark" label="备注" placeholder="请输入备注" />
  </van-cell-group>

  <van-button :loading="saving" loading-type="spinner" id="save-btn" @click="save" block type="primary">保存</van-button>

  <van-popup v-model:show="showGenderPicker" position="bottom">
    <van-picker :columns="genderData" @confirm="chooseGender" @cancel="showGenderPicker = false" :default-index="0" />
  </van-popup>

  <van-popup v-model:show="showArea" position="bottom">
    <van-area :area-list="areaList" :value="areaCode" @confirm="chooseArea" @cancel="showArea = false" />
  </van-popup>

  <van-dialog v-model:show="showImageDialog" confirmButtonText="关闭">
    <div style="text-align: center; margin: 0 auto; margin-top: 20px">
      <span class="font-red">长按保存或添加好友</span>

      <vue-qrcode tag="img" :value="qrcode_text" :options="{ width: 200 }" />
    </div>
  </van-dialog>

  <van-dialog v-model:show="showSourceImageDialog" confirmButtonText="关闭">
    <div style="text-align: center; margin: 0 auto; margin-top: 20px">
      <img :src="weixin_source_image + '!q80'" alt="" style="width: 80%" />
    </div>
  </van-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { NavbarMixin } from "@/mixins/NavbarMixin";
import {
  NavBar,
  Button,
  Toast,
  Picker,
  Field,
  CellGroup,
  Popup,
  Area,
  Dialog,
  Icon,
} from "vant";
import { checkMobile, getLeadInfo, setLeadInfo, getMobileValidationInfo } from "@/api/common";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import MobileValidationResult from "@/components/MobileValidationResult.vue";

import { areaList } from "@/data/location";

export default defineComponent({
  mixins: [NavbarMixin],

  beforeRouteEnter(to, from) {
    const obj = {
      path: from.path,
      query: from.query,
    };

    localStorage.setItem("previous_url", JSON.stringify(obj));
  },

  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Icon.name]: Icon,
    [VueQrcode.name]: VueQrcode,
    [Dialog.Component.name]: Dialog.Component,
    MobileValidationResult,


  },

  data() {
    return {
      showImageDialog: false,
      showSourceImageDialog: false,

      uid: "",

      name: "",
      mobile: "",
      qq: "",
      weixin: "",
      remark: "",
      areaList,
      areaText: "",
      areaCode: "",

      qrcode_text: '',
      weixin_source_image: '',

      saving: false,

      genderData: [
        {
          key: 0,
          text: "未知性别",
        },
        {
          key: 1,
          text: "男",
        },
        {
          key: 2,
          text: "女",
        },
      ],
      gender: "未知性别",

      showGenderPicker: false,
      showArea: false,
      platform: '',
      mobile_status: '',

      mobile_location: '',
      mobile_check_time: '',
    };
  },

  beforeMount() {
    this.uid = this.$route.query.uid;

    this.getLead();
  },

  methods: {
    chooseGender(val) {
      this.gender = val.text;
      this.showGenderPicker = false;
    },

    chooseArea(val) {
      this.areaText = val
        .map((item) => {
          return item.name;
        })
        .join("-");
      this.areaCode = val[val.length - 1].code;

      this.showArea = false;
    },

    getLead() {
      getLeadInfo(this.uid).subscribe((res) => {
        if (!res.name && res.nickname) {
          res.name = res.nickname;
        }

        this.name = res.name;
        this.gender =
          this.genderData.find((item) => item.key === res.gender)?.text ||
          "未知性别";
        this.mobile = res.mobile || "";
        this.qq = res.qq || "";
        this.weixin = res.weixin || "";
        this.remark = res.remark || "";
        this.areaText = res.region_name;
        this.areaCode = (res.region_code || "").toString();

        this.qrcode_text = res.qrcode_text || '';
        this.weixin_source_image = res.weixin_source_image || '';
        this.platform = res.platform;
        this.mobile_status = res.mobile_status;

        if (this.mobile_status && this.mobile_status !== 'unchecked') {
          this.getMobileInfo();
        }
      });
    },

    doMobileCheck() {
      let mobile = this.mobile;
      if (!/^1\d{10}$/.test(mobile.toString())) {
        Toast.fail("手机号码格式不正确");
        return;
      }

      Dialog.confirm({
        title: '提示',
        message: '空号检测可查询手机号状态、归属地等信息。请确认是否查询？',
      })
        .then(() => {
          this.doActualMobileCheck();
        })

    },

    doActualMobileCheck() {
      //show loading
      const toast = Toast.loading({
        message: "查询中...",
        forbidClick: true,
      });

      checkMobile({
        mobile: this.mobile,
        uid: this.uid,
        platform: this.platform
      }).subscribe((res) => {
        console.log(res);
        this.mobile_status = res.status;
        this.getMobileInfo();
        Toast.success("检测成功");
        toast.clear();
      }, (err) => {
        if (err?.response?.data?.message) {
          Toast.fail(err.response.data.message);
        }
      })
    },

    getMobileInfo() {
      getMobileValidationInfo(this.mobile).subscribe((res) => {
        this.mobile_check_time = res.check_time;
        this.mobile_location = res.mobile_location;
      })
    },

    save() {
      if (!this.name) {
        Dialog.alert({
          message: "请填写访客姓名",
        });
        return;
      }

      const data = {
        uid: this.uid,
        name: this.name,
        mobile: this.mobile,
        qq: this.qq,
        weixin: this.weixin,
        remark: this.remark,
        region_code: this.areaCode,
      };

      const selectedGender = this.genderData.find(
        (g) => g.text === this.gender
      );
      if (selectedGender) {
        data.gender = selectedGender.key;
      }

      this.saving = true;
      setLeadInfo(data).subscribe(
        () => {
          Toast.success("保存成功");
          this.goBack();
        },
        (err) => {
          const error = err.response?.data?.invalid;
          const errorArr = Object.keys(error).map((key) => {
            return error[key];
          });

          Dialog.alert({
            message: errorArr[0],
          });

          this.saving = false;
        }
      );
    },
  },
});
</script>

<style lang="less" scoped>
.van-cell-group {
  margin-top: 10px;
}

#save-btn {
  width: 80%;
  text-align: center;
  margin: 3px auto;
  margin-top: 20px;
}

.font-blue {
  color: rgb(2, 167, 240);
}
</style>