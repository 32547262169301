<template>
  <div class="host">
    <div class="container">
      <img :src="require('@/assets/img/wework-avatar1.png')" alt="" />

      <div>
        <p class="title">
          {{ card.name }}
        </p>

        <span class="font-gray">
          企微账号：{{ card.wework_userid || card.userid }}
        </span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    card: Object,
  },

  data() {
    return {};
  },

  beforeMount() {},
});
</script>
  
  
  <style lang="less" scoped>
.host {
  display: inline-block;
  cursor: pointer;

  padding: 10px;

  width: 270px;

  .container {
    display: flex;
    border: 1px solid #ddd;
    padding: 15px 10px;
    background-color: white;

    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }
}

img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.title {
  margin: 4px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.font-gray {
  color: gray;
}
</style>