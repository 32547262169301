<template>
    <van-cell-group :title="snippetGroup.title">

        <van-cell center :is-link="snippet.content.format !== 'text' || currentVisitor.platform === 'web'"
            v-for="snippet in snippetList" :key="snippet.id" @click="clickSnippet(snippet)">
            <template #title>
                <b>{{ snippet.title }}</b>&nbsp;
                <span class="font-gray">{{ snippet.tooltip }}</span>
            </template>
        </van-cell>
    </van-cell-group>
</template>

<script>
import { defineComponent } from "vue";
import { Cell, CellGroup } from "vant";
import { slice, stripHtml } from "@/utils/common";
import { mapGetters } from "vuex";



export default defineComponent({

    emits: ["snippetClicked"],

    data() {
        return {
        };
    },


    props: ["snippetGroup"],

    computed: {
        ...mapGetters({
            currentVisitor: "currentVisitor",
        }),

        snippetList() {
            let list = this.snippetGroup.nodes || [];

            return list.map((node) => {
                node.originalContent = node.content;

                try {
                    const result = JSON.parse(node.content);

                    if (typeof result === 'object') {
                        node.content = result;
                    } else {
                        node.content = {
                            format: 'text',
                            content: node.content
                        }
                    }

                } catch (error) {
                    node.content = {
                        format: 'text',
                        content: node.content
                    }
                }

                if (node.content.format === 'text') {
                    node.content.content = stripHtml(node.content.content);
                    // let titleLength = (node?.title?.length || 0);
                    // if (node.content.content?.length + titleLength > 48) {
                    //     node.n_tooltip = slice(node.content.content, 0, (48 - titleLength)) + '...';

                    // } else {
                    //     node.n_tooltip = node.content.content;
                    // }

                }
                node.tooltip = node.n_tooltip || node.tooltip;


                return node;
            })
        }
    },

    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
    },

    methods: {
        clickSnippet(snippet) {
            this.$emit("snippetClicked", snippet)
        }
    }

})
</script>

<style lang="less" scoped>
.font-gray {
    color: gray;
}

::v-deep .van-cell__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>