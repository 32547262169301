export function getHistoryFilters(platform: string) {
  const status = (
    localStorage.getItem(platform + "-history-filters-v2") || ""
  ).split(",");

  if (status?.length && status[0]) return status;

  switch (platform) {
    case "web":
      return ["spoken"];
    case "wechat":
      return ["2"];
    case "weibo":
      return ["2"];
    case "douyin":
      return ["2"];
    case "redbook":
      return ["2"];
    case "kuaishou":
      return ["2"];

    default:
      break;
  }
}

export function getHistoryAiFilter() {
  const aiCSStatus = localStorage.getItem("history-ai-filters") || "";

  return aiCSStatus;
}

export function getKuaishouPotentialFilters() {
  const kuaishouPotentialFilterStatus =
    localStorage.getItem("kuaishou-potential-filters") || "live,video";
  return kuaishouPotentialFilterStatus.split(",");
}

export function setHistoryAiFilter(data: string) {
  localStorage.setItem("history-ai-filters", data);
}

export function setHistoryFilters(platform: string, data: any[]) {
  localStorage.setItem(platform + "-history-filters-v2", data.join(","));
}

export function setKuaishouPotentialFilters(data: string[]) {
  localStorage.setItem("kuaishou-potential-filters", data.join(","));
}

export function getDefaultSettings(platform: string) {
  switch (platform) {
    case "web":
      return ["spoken", "interaction"];
    case "wechat":
      return ["2"];
    case "weibo":
      return ["2"];
    case "douyin":
      return ["2"];
    case "kuaishou":
      return ["2"];
    case "redbook":
      return ["2"];

    default:
      break;
  }
}
