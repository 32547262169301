<template>
  <div class="audio-divs">
    <div class="audio-div">
      <audio
        id="new-message-sound"
        :src="require('@/assets/audio/new-message.ogg')"
        preload
      ></audio>
    </div>

    <div class="audio-div">
      <audio
        id="sent-message-sound"
        :src="require('@/assets/audio/sent-message.ogg')"
        preload
      ></audio>
    </div>
  </div>
</template>

<style scoped>
.audio-divs {
  display: none;
}
</style>

<script>
export default {};
</script>