import { MessageFeFrom } from "@/types/Message";
import { Talk } from "@/types/Talk";
import { getOtherTotalNewCount } from "@/utils/chat";
import { transformMessageList } from "@/utils/message";
import { cloneDeep } from "lodash";

// initial State
const state: any = {
  isOnDuty: true,
  talkList: [],
  chatOtherTotalNewCount: 0,
  currentVisitorUid: "",
  isMessageInputPulledUp: false,
  inputContainerHeight: 50,
  inputDraft: '',

  toReplyCMT: null,
};

const mutations = {
  UPDATE_TO_REPLY_CMT(s: typeof state, cmt: any) {
    s.toReplyCMT = cmt;
  },

  UPDATE_INPUT_PULLEDUP(s: typeof state, status: boolean) {
    s.isMessageInputPulledUp = status;
  },

  RESET_INPUT_PULLUP_HEIGHT(s: typeof state) {
    s.inputContainerHeight = s.toReplyCMT ? 90 : 50;
    
  },

  UPDATE_INPUT_PULLEDUP_HEIGHT(s: typeof state, inputHeight: number) {
    let addUp = 0;
    if(s.isMessageInputPulledUp) {
      addUp += 170;
    }
    if(s.toReplyCMT) {
      addUp += 40;
    }
    s.inputContainerHeight = inputHeight + addUp;

  },

  UPDATE_DUTY_STATUS(s: typeof state, status: boolean) {
    s.isOnDuty = status;
  },

  UPDATE_TALK_LIST(s: typeof state, talkList: Talk[]) {
    s.talkList = talkList;
  },

  SET_CURRENT_VISITOR(s: typeof state, uid: string) {
    s.currentVisitorUid = uid;

    const talkList: Talk[] = cloneDeep(s.talkList);
    talkList.forEach((talk) => {
      if (uid === talk.uid) {
        talk.isActive = true;
        talk.newCount = 0;
      } else {
        talk.isActive = false;
      }
    });

    s.talkList = talkList;
  },

  CLEAR_CURRENT_VISITOR(s: typeof state) {
    s.currentVisitorUid = "";

    const talkList: Talk[] = cloneDeep(s.talkList);
    talkList.forEach((talk) => {
      talk.isActive = false;
    });

    s.talkList = talkList;
  },

  UPDATE_SEND_STATUS(
    s: typeof state,
    payload: {
      uid: string;
      cmicrotime: string;
      status: boolean;
    }
  ) {
    const talkList = cloneDeep(state.talkList);
    const talk = talkList.find((talk: any) => {
      return talk.uid === state.currentVisitorUid;
    });

    if (!talk) return;

    talk.conversation.every((msg: any) => {
      if (
        msg.feFrom === MessageFeFrom.worker_temp &&
        msg.cmicrotime === payload.cmicrotime
      ) {
        msg.sendingStatus = payload.status ? "sent" : "error";
        return false;
      }
      return true;
    });

    s.talkList = talkList;
  },

  UPDATE_TEMP_MESSAGE(s: typeof state, payload: any) {
    const talkList = cloneDeep(state.talkList);
    const talk = talkList.find((talk: any) => {
      return talk.uid === state.currentVisitorUid;
    });

    if (!talk) return;

    talk.conversation.push(payload);
    talk.conversation = transformMessageList(
      talk.conversation,
      payload.platform,
      payload.uid
    );
    s.talkList = talkList;
  },

  UPDATE_INPUT_DRAFT(s: typeof state, payload: any) {
    s.inputDraft = payload;
  }
};

const actions = {};

const getters = {
  talkList: (state: any) => {
    return state.talkList;
  },

  toReplyCMT: (state: any) => {
    return state.toReplyCMT;
  },

  currentVisitor: (state: any) => {
    return state.talkList.find((talk: any) => {
      return talk.uid === state.currentVisitorUid;
    });
  },

  chatOtherTotalNewCount: (state: any) => {
    return getOtherTotalNewCount(state.talkList, state.currentVisitorUid);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
