export const RedbookFaceList = [
    { val: "[微笑R]", index: 0 },
    { val: "[害羞R]", index: 1 },
    { val: "[失望R]", index: 2 },
    { val: "[汗颜R]", index: 3 },
    { val: "[哇R]", index: 4 },
    { val: "[喝奶茶R]", index: 5 },
    { val: "[自拍R]", index: 6 },
    { val: "[偷笑R]", index: 7 },
    { val: "[飞吻R]", index: 8 },
    { val: "[石化R]", index: 9 },
    { val: "[笑哭R]", index: 10 },
    { val: "[赞R]", index: 11 },
    { val: "[暗中观察R]", index: 12 },
    { val: "[买爆R]", index: 13 },
    { val: "[大笑R]", index: 14 },
    { val: "[色色R]", index: 15 },
    { val: "[生气R]", index: 16 },
    { val: "[哭惹R]", index: 17 },
    { val: "[萌萌哒R]", index: 18 },
    { val: "[斜眼R]", index: 19 },
    { val: "[可怜R]", index: 20 },
    { val: "[鄙视R]", index: 21 },
    { val: "[皱眉R]", index: 22 },
    { val: "[抓狂R]", index: 23 },
    { val: "[捂脸R]", index: 24 },
    { val: "[派对R]", index: 25 },
    { val: "[吧唧R]", index: 26 },
    { val: "[惊恐R]", index: 27 },
    { val: "[抠鼻R]", index: 28 },
    { val: "[再见R]", index: 29 },
    { val: "[叹气R]", index: 30 },
    { val: "[睡觉R]", index: 31 },
    { val: "[得意R]", index: 32 },
    { val: "[吃瓜R]", index: 33 },
    { val: "[扶墙R]", index: 34 },
    { val: "[黑薯问号R]", index: 35 },
    { val: "[黄金薯R]", index: 36 },
    { val: "[吐舌头H]", index: 37 },
    { val: "[扯脸H]", index: 38 },
    { val: "[doge]", index: 39 },
  ];
  
  export const Redbook_Face_Texts = RedbookFaceList.map((face) => {
    return face.val;
  });
  

function escapeRegExp(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export function redbookFaceToHtml(text: string): string {
  if (!text.includes("[") || !text.includes("]")) return text;

  Redbook_Face_Texts.forEach((token: string, index: number) => {
    const re = new RegExp(escapeRegExp(token), "g");

    const config: any = RedbookFaceList.find((config) => config.val === token);
    text = text.replace(
      re,
      `<img  src="https://${window.location.host}/redbook_face/${config.index}.png" style="width: 25px"> `
    );
  });

  return text;
}
