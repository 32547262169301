
import BottomBar from "@/components/BottomBar.vue";
import ChatUnit from "@/components/ChatUnit.vue";
import { defineComponent } from "vue";
import { NavBar, NoticeBar, Badge, Icon, Dialog, Notify } from "vant";
import { mapState } from "vuex";
import orderBy from "lodash/orderBy";


export default defineComponent({
  components: {
    BottomBar: BottomBar,
    ChatUnit: ChatUnit,
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
    [Badge.name]: Badge,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,

  },

  data() {
    return {
      showHuaweiClientTip: false,
      showAdvisorDialog: false,
      advisor: '' as any
    }
  },

  created() {
    (this as any).$store.dispatch("REFREESH_UNREAD_COUNT");
  },

  computed: {
    ...mapState({
      talkList: (state: any) => {
        const talkList = state.chat.talkList;
        return orderBy(
          talkList,
          ["hasNotReplyed", "offline", "hasOpenMouth", "latestMsgTimestamp"],
          ["desc", "asc", "desc", "desc"]
        );
      },

      isOnDuty: (state: any) => {
        return state.chat.isOnDuty;
      },

      unreadCount: (state: any) => state.common.unreadCount
    }),
  },

  methods: {
    closeHuaweiTip() {
      this.showHuaweiClientTip = false;
      localStorage.setItem('showHuaweiClientTip', "true");
    },

    displayAdvisorDialog() {
      this.advisor = JSON.parse(localStorage.getItem('advisor')! || '{}');
      if (this.advisor?.name) {
        this.showAdvisorDialog = true;
      }
    },

    closeAdvisorDialog() {
      Notify({ type: "success", message: "复制成功" });
      this.showAdvisorDialog = false;
    }
  },

  beforeMount() {
    (this as any).$store.commit("CLEAR_CURRENT_VISITOR");
  }
});
