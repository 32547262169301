export const ENGINES_TYPES: any = {
  baidu: "百度自然搜索",
  bdsem: "百度推广",
  360: "360搜索",
  bing: "必应",
  google: "谷歌",
  yodao: "有道",
  shenma: "神马搜索",
  sogou: "搜狗",
};

export const SERACH_SOURCES: any = {
  direct: "直接访问",
  search: "搜索",
  weibo: "微博",
  weixin: "微信",
  other: "其他",
  wechat: "微信公众号",
  gdt: "广点通",
  weiboapp: "微博蓝V",
  qqfmc: "腾讯智能咨询页",
  imlp: "百度智能咨询页",
  ttpage: "头条智能咨询页",
  sbox: "手机百度App",
  xhs: "小红书落地页",
};

export type Referrer = {
  url: string;
  source: string;
  engine: string;
  keyword: string;
  custom_source: string;
}
