import { format } from "date-fns";

const currentYear = new Date().getFullYear().toString();

export function removeYear(date: string): string {
  if (!date || typeof date !== "string") {
    return "-";
  }

  if (date.indexOf(currentYear) === 0) {
    return date.slice(5);
  } else {
    return date;
  }
}

export function dateShorter(date: string) {
  if (!date || !date.slice) return date;

  return date.slice(5, date.length - 3);
}

export function timestampToDateTime(timestamp: number): string {
  if (timestamp.toString().length === 16) {
    timestamp = timestamp / 1000;
  }

  const time = format(timestamp, "yyyy-MM-dd HH:mm:ss");
  return removeYear(time);
}

export function getHourRange() {
  const hourArr = [];
  for (let i = 0; i <= 23; i++) {
    const str = i.toString().length === 1 ? "0" + i.toString() : i;
    hourArr.push(str);
  }

  return hourArr;
}

export function getMinuteRange() {
  const minuteArr = [];
  for (let i = 0; i <= 59; i++) {
    const str = i.toString().length === 1 ? "0" + i.toString() : i;
    minuteArr.push(str);
  }

  return minuteArr;
}
