<template>
  <div class="msg-wrapper msg-info" v-if="message.feType === 'connect'">
    <span>{{ message.feDateTime }} 用户已经连接</span>
    <span v-if="message.body.worker">, 客服 {{ message.body.worker.name
    }}{{ message.body.worker.nickname }}</span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'disconnect'">
    <span>{{ message.feDateTime }}</span>
    <template v-if="message.text">
      {{ message.text }}
    </template>

    <template v-if="!message.text">
      <span v-if="message.body.sender === 'auto'">
        双方沉默时间过长，对话关闭</span>
      <span v-if="message.body.sender === 'worker'"> 客服关闭了对话</span>
      <span v-if="message.body.sender === 'user_offline'">
        {{ message.body.extra || "访客离开页面" }}</span>
      <span v-if="message.body.sender === 'worker_offline'">
        当前客服已离线，未匹配到其他客服，对话关闭</span>
    </template>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'block'">
    <span>{{ message.feDateTime }}</span>
    已将此用户拉黑
  </div>

  <div class="msg-wrapper msg-info msg-history noselect" v-if="message.feType === 'history'"
    @click.stop="click('history')">
    <div class="content">查看之前对话</div>
  </div>

  <div class="msg-wrapper msg-info msg-history noselect" v-if="message.feType === 'history_later'"
    @click.stop="click('history_later')">
    <div class="content">查看之后对话</div>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'reassign'">
    <span>{{ message.feDateTime }}</span>
    由于原客服离线，切换为{{ message.body.name }}{{ message.body.nickname }}
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'transfer'">
    <span>{{ message.feDateTime }}</span>
    对话已经转接给了客服{{ message.body.name }}{{ message.body.nickname }}
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'doassign' && message.body.worker">
    <span>{{ message.feDateTime }}</span>
    <span v-if="message.body.event === 'offline_reassign'">
      由于原客服离线，切换为 {{ message.body.worker.name
      }}{{ message.body.worker.nickname }}</span>
    <span v-if="message.body.event === 'transfer'">
      对话已经转接给了客服 {{ message.body.worker.name
      }}{{ message.body.worker.nickname }}</span>
    <span v-if="message.body.event === 'assign'">
      对话已接入，分配给了客服 {{ message.body.worker.name
      }}{{ message.body.worker.nickname }}</span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'send_fail'">
    <span>{{ message.feDateTime }} </span>
    <span>{{ message.text }}</span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'send_preview_notice'">
    <span>{{ message.feDateTime }} </span>
    <span v-html="message.text"></span>
    <template v-if="message.ext?.preview_count">
      <br />
      今日已用{{ message.ext.preview_count }}次，还可使用{{
        100 - message.ext.preview_count
      }}次
    </template>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'notice'">
    <span>{{ message.feDateTime }} </span>
    <span v-html="message.text"></span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'tags_add'">
    <span>{{ message.feDateTime }} </span>
    <span v-html="message.tagsData.prefix"></span>
    <p>
      <app-tag v-for="tag in message.tagsData.tags" :key="tag.id" :tag="tag"></app-tag>
    </p>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'tags_remove'">
    <span>{{ message.feDateTime }} </span>
    <span v-html="message.tagsData.prefix"></span>
    <p>
      <app-tag v-for="tag in message.tagsData.tags" :key="tag.id" :tag="tag"></app-tag>
    </p>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'lead_edit'">
    <span>{{ message.feDateTime }} </span>
    <span>
      {{ message.text }}
    </span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'check_mobile_limit'">
    <span>{{ message.feDateTime }} </span>
    <span v-html="message.text"></span>
  </div>

  <div class="msg-wrapper msg-info" v-if="message.feType === 'check_mobile'">
    <div class="center-row">
      <span>{{ message.feDateTime }} </span>
      <span v-html="message.text"></span>
      （{{ message.ext?.mobile }}）
    </div>
    <div class="center-row">
      检测结果：
      <mobile-validation-result :status="message.ext?.status" />
      <template v-if="message.ext?.mode === 2">
        （记忆）
      </template>

      <template v-if="message.ext?.location">
        ，号码归属地：{{ message.ext.location }}
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";
import Tag from "@/components/Tag.vue";
import MobileValidationResult from "../MobileValidationResult.vue";


export default defineComponent({
  props: {
    message: Object,
  },

  emits: ["click"],

  components: {
    "app-tag": Tag,
    MobileValidationResult
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() { },

  methods: {
    click(type) {
      this.$emit("click", {
        type,
        microtime: this.message.body,
      });
    },
  },
});
</script>

<style scoped>
.center-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>