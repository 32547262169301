<template>
  <div class="extra-info">
    <span class="info-tag" v-if="message?.extractedInfo.mobile" @click="doCopy('mobile', message.extractedInfo.mobile)">
      <i class="iconfont">&#xe613;</i>
      复制并保存手机
    </span>

    <span class="info-tag" v-if="message?.extractedInfo.weixin" @click="doCopy('weixin', message.extractedInfo.weixin)">
      <i class="iconfont">&#xe600;</i>

      复制并保存微信
    </span>

    <span class="info-tag" v-if="message?.extractedInfo.qq" @click="doCopy('qq', message.extractedInfo.qq)">
      <i class="iconfont">&#xe882;</i>

      复制并保存QQ
    </span>

    <span class="info-tag" v-if="message?.extractedInfo.mobile" @click="doCheckMobile(message)">
      <i class="iconfont">&#xe8ef;</i>

      空号检测
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setUserInfo, getLeadInfo, checkMobile } from "@/api/common";
import { Toast } from "vant";
import { switchMap } from "rxjs/operators";

export default defineComponent({
  props: {
    message: Object,
  },

  components: {},

  methods: {
    doCopy(platform, content) {
      this.$copyText(content, undefined, () => { });

      const uid = this.message.uid;
      if (!uid) return;

      getLeadInfo(uid)
        .pipe(switchMap((res) => {
          const name = res.name || res.nickname;

          return setUserInfo(uid, {
            [platform]: content,
            name,
          });
        }))
        .subscribe(() => {
          Toast.success("保存成功");
        });
    },

    doCheckMobile(message) {
      checkMobile({
        mobile: message.extractedInfo.mobile,
        uid: message.uid,
        platform: message.platform
      }).subscribe(() => {
        Toast.success("检测成功，请在消息流中查看");
      }, (err) => {
        if (err?.response?.data?.message) {
          Toast.fail(err.response.data.message);
        }
      })
    }
  },
});
</script>

<style lang="less" scoped>
.extra-info {
  &:before {
    clear: both;
    content: " ";
    display: block;
  }

  margin: 3px 0;
  font-size: 12px;

  .info-tag {
    border-radius: 8px;
    // background-color: white;
    border: 1px solid #2593f4;
    display: inline-block;
    padding: 5px;
    color: #2593f4;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 5px;
  }

}

i.iconfont {
  font-size: 12px;
}
</style>
