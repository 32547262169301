import { MessageFeFrom } from "@/types/Message";
import { Talk } from "@/types/Talk";

export function getWaitingFlag(talk: Talk) {
  const con = talk.conversation.slice().reverse();
  let isWaiting = false;

  con.every((msg) => {
    if (msg.feFrom === MessageFeFrom.worker && !msg.feMuted) {
      return false;
    }

    if (msg.feFrom === MessageFeFrom.client && !msg.feMuted) {
      isWaiting = true;
      return true;
    }

    return true;
  });

  return isWaiting;
}
