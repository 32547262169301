function escapeRegExp(str: string) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export const DOUYIN_FACES =
  "[666],[微笑],[爱慕],[惊呆],[酷拽],[抠鼻],[流泪],[发怒],[呲牙],[鼾睡],[害羞],[可爱],[晕],[衰],[闭嘴],[机智],[来看我],[灵光一闪],[耶],[捂脸],[打脸],[大笑],[哈欠],[震惊],[送心],[困],[what],[泣不成声],[小鼓掌],[大金牙],[偷笑],[石化],[思考],[吐血],[可怜],[嘘],[撇嘴],[黑线],[笑哭],[雾霾],[奸笑],[得意],[憨笑],[抓狂],[泪奔],[钱],[吻],[恐惧],[笑],[快哭了],[翻白眼],[互粉],[赞],[鼓掌],[祈祷],[kiss],[去污粉],[玫瑰],[胡瓜],[啤酒],[我想静静],[委屈],[舔屏],[鄙视],[飞吻],[再见],[紫薇别走],[听歌],[求抱抱],[绝望的凝视],[不失礼貌的微笑],[吐舌],[呆无辜],[看],[白眼],[熊吉],[骷髅],[黑脸],[吃瓜群众],[绿帽子],[汗],[摸头],[皱眉],[擦汗],[红脸],[尬笑],[做鬼脸],[强],[如花],[吐],[惊喜],[敲打],[奋斗],[吐彩虹],[大哭],[比心],[加油],[碰拳],[ok],[左上],[握手],[18禁],[刀],[V5],[给力],[心],[伤心],[屎],[礼物],[蛋糕],[撒花],[不看],[炸弹],[击掌]".split(
    ","
  );

export function DouyinfaceFilter(text: string) {
  DOUYIN_FACES.forEach((token, index) => {
    const re = new RegExp(escapeRegExp(token), "g");
    text = text.replace(
      re,
      `<span class="douyinface douyinface_${index + 1}"></span>`
    );
  });

  return text;
}
