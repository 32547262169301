export function isOnIMUpdateList() {
    const companyID = localStorage.getItem("company_id") || '';

    return [
      "c9i1emf65aj6",
      "cjd8vhd3v69atm",
      "cjk2irjggeuvgv",
      "cjrl1vl6udpp9b",
      "cdbfx60eg3k",
      "cjv1de0du9o7st",
    ].includes(companyID);
  }