import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "@/views/Login.vue";
import Update from "@/views/Update.vue";
import TOS from "@/views/TOS.vue";
import TOSViewer from "@/views/TOSViewer.vue";
import Loading from "@/views/Loading.vue";
import Content from "@/views/content/Content.vue";
import ChatList from "@/views/content/ChatList.vue";
import HistoryPage from "@/views/content/HistoryPage.vue";
import Settings from "@/views/content/Settings.vue";
import Routine from "@/views/content/Routine.vue";
import NotificationSettings from "@/views/content/NotificationSettings.vue";
import WechatNotificationSettings from "@/views/content/WechatNotificationSettings.vue";

import NetworkError from "@/views/NetworkError.vue";

import NotificationGuide from "@/views/NotificationGuide.vue";
import HistoryDetail from "@/views/content/HistoryDetail.vue";
import Block from "@/views/content/Block.vue";
import EditLead from "@/views/content/EditLead.vue";
import ChatPage from "@/views/ChatPage.vue";

import LeadExpire from "@/views/LeadExpire.vue";
import LeadPusher from "@/views/LeadPusher.vue";

import NotificationList from "@/views/content/NotificationList.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/lead-expire",
    name: "LeadExpire",
    component: LeadExpire,
  },

  {
    path: "/lead-pusher",
    name: "LeadPusher",
    component: LeadPusher,
  },

  {
    path: "/loading",
    name: "Loading",
    component: Loading,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/update",
    name: "Update",
    component: Update,
  },
  {
    path: "/yunque-term-of-service",
    name: "TOS",
    component: TOS,
  },

  {
    path: "/tos-viewer",
    name: "TOSViewer",
    component: TOSViewer,
  },

  {
    path: "/notification-guide",
    name: "notification-guide",
    component: NotificationGuide,
  },

  {
    path: "/network-error",
    name: "NetworkError",
    component: NetworkError,
  },

  {
    path: "/",
    component: Content,
    children: [
      {
        path: "/chat-list",
        name: "chatList",
        component: ChatList,
      },

      {
        path: "/chat",
        name: "chatPage",
        component: ChatPage,
      },

      {
        path: "/history-list",
        name: "historyList",
        component: HistoryPage,
        meta: {
          keepAlive: true,
          isNeedRefresh: true,
        },
      },
      {
        path: "/history-detail",
        name: "historyDetail",
        component: HistoryDetail,
      },

      {
        path: "/settings",
        name: "settings",
        component: Settings,
      },
      {
        path: "/routine",
        name: "routine",
        component: Routine,
      },
      {
        path: "/notification-settings",
        name: "notificationSettings",
        component: NotificationSettings,
      },
      {
        path: "/wechat-notification-settings",
        name: "wechatNotificationSettings",
        component: WechatNotificationSettings,
      },

      {
        path: "/block",
        name: "block",
        component: Block,
      },
      {
        path: "/edit-lead",
        name: "editLead",
        component: EditLead,
      },

      {
        path: "/notification-list",
        name: "notificationList",
        component: NotificationList,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
