<template>
  <div class="lead-container">
    <p>
      姓名： {{ lead.name || lead.showname || lead.nickname }}

      <span v-if="isDuplicatedLead" @click="showDuplicatedLeadDialog = true" class="font-red cursor-pointer">
        重复客资
      </span>
    </p>

    <p v-if="lead.gender === 2">性别： 女</p>
    <p v-if="lead.gender === 1">性别： 男</p>

    <p style="display: flex; align-items: center; " v-if="displaySource">
      <span style="flex-shrink: 0;">来源：</span><platform-icon :platform="lead.platform" />{{ displaySource }}
    </p>

    <p>联系方式：</p>

    <div class="contact-container pl-8">
      <div class="flex align-middle my-2" v-if="lead.mobile">
        <span class="block w-3/4">手机：{{ lead.mobile }}</span>
        <van-button plain type="primary" size="mini" class="px-4 ml-4 flex-none" v-if="isMobile"
          @click="dial(lead.mobile)">
          拨打
        </van-button>
        <van-button plain type="primary" size="mini" class="px-4 ml-4 flex-none" v-if="!isMobile"
          v-clipboard:copy="lead.mobile" v-clipboard:success="onSuccess">
          复制
        </van-button>
      </div>

      <div class="flex align-middle my-2" v-if="lead.mobile_status && lead.mobile_status !== 'unchecked'">
        <span class="flex w-3/4" style="flex-wrap: nowrap;">
          空号检测结果：<mobile-validation-result :status="lead.mobile_status" />
        </span>

      </div>

      <div class="flex align-middle my-2" v-if="lead.mobile_loc">
        <span class="flex w-3/4" style="flex-wrap: nowrap;">
          号码归属地：{{ lead.mobile_loc }}
        </span>
      </div>

      <div class="flex align-middle my-2" v-if="lead.mobile_check_time">
        <span class="flex w-3/4" style="flex-wrap: nowrap;">
          检测时间：{{ lead.mobile_check_time }}
        </span>
      </div>

      <div class="flex align-middle my-2" v-if="lead.weixin">
        <div class="block w-3/4">
          微信：{{ lead.weixin }}
          <p v-if="lead.weixin_source_image">
            （根据
            <span class="font-blue" @click="showSourceImageDialog = true">图片</span>
            智能识别）
          </p>
        </div>
        <van-button plain type="primary" size="mini" class="px-4 ml-4 flex-none" v-clipboard:copy="lead.weixin"
          v-clipboard:success="onSuccess">复制</van-button>
      </div>

      <div class="flex align-middle my-2" v-if="lead.qq">
        <span class="block w-3/4"> Q Q ：{{ lead.qq }}</span>
        <van-button plain type="primary" size="mini" class="px-4 ml-4 flex-none" v-clipboard:copy="lead.qq"
          v-clipboard:success="onSuccess">复制</van-button>
      </div>

      <div class="flex align-middle my-2" v-if="lead.qrcode_text">
        <div class="block w-3/4">
          二维码：
          <span class="font-blue" @click="showImageDialog = true">查看图片</span>
        </div>
      </div>
    </div>

    <div class="my-2" v-if="lead.remark">
      备注：
      <div class="pl-8 my-2" style="word-break: break-all">
        {{ lead.remark }}
      </div>
    </div>
  </div>

  <van-dialog v-model:show="showImageDialog" confirmButtonText="关闭">
    <div style="text-align: center; margin: 0 auto; margin-top: 20px">
      <span class="font-red">长按保存或添加好友</span>

      <vue-qrcode tag="img" :value="lead.qrcode_text" :options="{ width: 200 }" />
    </div>
  </van-dialog>

  <van-dialog v-model:show="showSourceImageDialog" confirmButtonText="关闭">
    <div style="text-align: center; margin: 0 auto; margin-top: 20px">
      <img :src="lead.weixin_source_image + '!q80'" alt="" style="width: 80%" />
    </div>
  </van-dialog>

  <van-dialog v-model:show="showDuplicatedLeadDialog" title="重复客资" confirmButtonText="关闭">
    <div style="padding: 20px">
      <lead :lead="lead.exist_user" v-if="lead.exist_user"></lead>
    </div>
  </van-dialog>
</template>

<script>
import { Button } from "vant";
import { Dialog } from "vant";
import { Notify } from "vant";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { isMobile } from "@/utils/host";
import PlatformIcon from "@/components/PlatformIcon.vue";
import MobileValidationResult from "./MobileValidationResult.vue";
import { formatSourceText } from "@/utils/lead";

const platformDict = {
  web: "网页",
  wechat: "微信",
  weibo: "微博",
  douyin: "抖音",
  kuaishou: "快手",
  redbook: "小红书",
};

export default {
  components: {
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [VueQrcode.name]: VueQrcode,
    PlatformIcon,
    MobileValidationResult
  },

  data: function () {
    return {
      showImageDialog: false,
      showSourceImageDialog: false,
      showDuplicatedLeadDialog: false,
      displaySource: "",
      isMobile: isMobile(),
      isDuplicatedLead: false,
    };
  },

  props: {
    lead: {},
  },

  created() {
    this.displaySource = formatSourceText(this.lead);
    this.isDuplicatedLead = !!Object.keys(this.lead.exist_user || {})?.length;

  },

  methods: {
    onSuccess() {
      Notify({ type: "success", message: "复制成功" });
    },

    dial(number) {
      if (!number.includes("+86") || number.indexOf("86") !== 0) {
        number = "86" + number;
      }

      window.open("tel:+" + number);
    },

    duplicateLead() { },
  },
};
</script>

<style scoped>
p {
  margin: 10px 0;
}

.font-blue {
  color: rgb(2, 167, 240);
}

.pl-8 {
  padding-left: 2rem;
}

.align-middle {
  vertical-align: middle;
}

.flex {
  display: flex;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.w-3\/4 {
  width: 75%;
}

.block {
  display: block;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.qrcode {
  max-width: 80%;
  margin: 10px 0;
}

::v-deep a,
a:visited {
  color: var(--van-primary-color);
}

.flex-none {
  flex: none;
}

.cursor-pointer {
  cursor: pointer;
}
</style>