import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@vant/touch-emulator";

import "./assets/styles/main.css";
import { findServer } from "@/utils/network";

import { Image as VanImage } from "vant";
import { Divider } from "vant";
import VueChatScroll from "vue-chat-scroll";
/* eslint-disable */
import VueClipboard from "vue3-clipboard";

import "./styles/wechatface.less";
import "./styles/douyinface.less";
import "./styles/kuaishouface.less";

// import VConsole from 'vconsole';

// const vConsole = new VConsole();

findServer().subscribe(
  () => {
    createApp(App)
      .use(store)
      .use(router)
      .use(VanImage)
      .use(Divider)
      .use(VueChatScroll)
      .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
      })
      .mount("#app");
  },
  () => {
    if (
      window.confirm(
        "通讯失败，请重试。如果多次重试一直失败，请截图联系云雀技术支持"
      )
    ) {
      setTimeout(function () {
        window.location.reload();
      }, 5000);
    }
  }
);
