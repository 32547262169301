<template>
  <div class="container">
    <div class="title">[小程序卡片]：{{ title }}</div>
    <div class="img-container">
      <img :src="url" alt="" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    miniapp: Object,
  },

  data() {
    return {
      title: "",
      url: "",
      filename: "",
      mini_appid: "",
      pagepath: "",
    };
  },

  beforeMount() {
    const val = this.miniapp;
    this.title = val.title;
    this.url = val.url;
    this.filename = val.filename;
    this.mini_appid = val.mini_appid;
    this.pagepath = val.pagepath;
  },
});
</script>


<style lang="less" scoped>
.container {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: white;
  color: rgba(0, 0, 0, 0.85);

  .title {
    padding: 4px;
  }

  img {
    max-height: 128px!important;
    max-width: 128px!important;
  }

  .img-container {
    text-align: center;
  }
}
</style>